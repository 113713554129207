<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<defs>
			<linearGradient id="linear-gradient" x1="0.5" y1="0.983" x2="0.5" y2="-0.018" gradientUnits="objectBoundingBox">
				<stop offset="0" stop-color="#edeeef" />
				<stop offset="0.185" stop-color="#e9eaeb" />
				<stop offset="0.361" stop-color="#dddee0" />
				<stop offset="0.533" stop-color="#cacbcd" />
				<stop offset="0.703" stop-color="#afb1b3" />
				<stop offset="0.87" stop-color="#8d8f91" />
				<stop offset="1" stop-color="#6d6f72" />
			</linearGradient>
		</defs>
		<g id="TSHIRT_LEFT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -504.332245, -252.202164)">
			<g id="COLORS">
				<path
					id="MAIN"
					d="M565.1,268.34l2.219-2.526c17.145-1.9,27.838-9.975,29.676-11.574l.081-.082s16.4,14.936,14.387,41.556-6.523,41.8-6.17,56.689c0,0,.353,4.661,1.763,7.307a6.875,6.875,0,0,1-.529,5.112s.705,1.587,1.234,3.174,1.411,2.468.352,4.231,1.343,4.407,1.343,4.407,2.472,16.4,2.151,16.4h-.321s-10.4,7.051-28.031,7.051-30.144-1.763-35.081-6.875c0,0,3-34.378,3-44.955s.854-53.4,1.56-57.98,1.462-9.006,12.37-21.931"
					:fill="mainColor"
				/>
				<g id="SEC">
					<path
						id="Pfad_101"
						data-name="Pfad 101"
						d="M577,310.992c.075,1.015,8.188,1.335,16.039,1.869,8.687.59,15.123-.531,16.491-1.535a.45.45,0,0,0,.168-.2l.241-.091c.572-4.673,1.1-9.744,1.522-15.317a56.977,56.977,0,0,0-.85-15.208l-.078-.4c-3.609-18.777-22.079-13.661-22.079-13.661l-.222.045c-1.777.38-13.643,3.419-13.969,16.792l-.019.191c-.091.971-.407,5.458,1.077,8.975,1.675,3.967,1.921,16.094,1.678,18.493Z"
						:fill="secColor"
					/>
					<path
						id="Pfad_102"
						data-name="Pfad 102"
						d="M565.2,268.34c21.039-1.822,33.539-12.584,33.539-12.584l-.134-.123c-.882-.977-1.429-1.475-1.429-1.475l-.081.082c-1.838,1.6-12.531,9.675-29.676,11.574l-2.035,2.318Z"
						:fill="secColor"
					/>
				</g>
			</g>
			<g id="LINES">
				<path
					id="Pfad_103"
					data-name="Pfad 103"
					d="M548.278,392.017c4.53,4.28,16.289,7.1,34.979,7.1a55.872,55.872,0,0,0,28.294-7.4"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_104"
					data-name="Pfad 104"
					d="M548.278,391.057c4.53,4.281,16.289,7.1,34.979,7.1a55.865,55.865,0,0,0,28.294-7.4"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_105"
					data-name="Pfad 105"
					d="M587.754,312.52c.391,3.855-2.015,10.285-2.2,14.017-.193,3.783-1.91,6.814-1.939,10.6-.016,2.009-.418,3.771-.529,5.823-.089,1.615.143,2.916-.52,4.24-.2.4-.656.592-.881,1.358a27.48,27.48,0,0,0-.714,6.395c-.1,2.728.846,5.127.881,7.741.08,6.053,1.178,11.5,1.94,17.4.319,2.477,1.259,5.068,1.413,7.51.138,2.18-.183,4.527-.18,6.742,0,1.815.393,4.09-.235,5.73"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_106"
					data-name="Pfad 106"
					d="M598.641,255.756s-12.5,10.762-33.539,12.584"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_107"
					data-name="Pfad 107"
					d="M599.189,256.3a68.256,68.256,0,0,1-34.438,12.672"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_108"
					data-name="Pfad 108"
					d="M565.1,268.34l2.219-2.526c17.145-1.9,27.838-9.975,29.676-11.574l.081-.082s16.4,14.936,14.387,41.556-6.523,41.8-6.17,56.689c0,0,.353,4.661,1.763,7.307a6.875,6.875,0,0,1-.529,5.112s.705,1.587,1.234,3.174,1.411,2.468.352,4.231,1.343,4.407,1.343,4.407,2.472,16.4,2.151,16.4h-.321s-10.4,7.051-28.031,7.051-30.144-1.763-35.081-6.875c0,0,3-34.378,3-44.955s.854-53.4,1.56-57.98,1.462-9.006,12.37-21.931"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_109"
					data-name="Pfad 109"
					d="M565.1,268.34l2.219-2.526c17.145-1.9,27.838-9.975,29.676-11.574l.081-.082s16.4,14.936,14.387,41.556-6.523,41.8-6.17,56.689c0,0,.353,4.661,1.763,7.307a6.875,6.875,0,0,1-.529,5.112s.705,1.587,1.234,3.174,1.411,2.468.352,4.231,1.343,4.407,1.343,4.407,2.472,16.4,2.151,16.4h-.321s-10.4,7.051-28.031,7.051-30.144-1.763-35.081-6.875c0,0,3-34.378,3-44.955s.854-53.4,1.56-57.98,1.462-9.006,12.37-21.931"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_110"
					data-name="Pfad 110"
					d="M587.1,262.633a12.479,12.479,0,0,1,1.354,3.817"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_111"
					data-name="Pfad 111"
					d="M586.54,262.893a11.4,11.4,0,0,1,1.353,3.681"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_112"
					data-name="Pfad 112"
					d="M587.673,262.374a12.938,12.938,0,0,1,1.406,3.928"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_113"
					data-name="Pfad 113"
					d="M577.263,310.643a46.169,46.169,0,0,1,17.493-3.035c9.853.236,16.146,2.714,14.778,3.718s-7.8,2.125-16.491,1.535S575.349,311.941,577.263,310.643Z"
					fill="none"
					stroke="#464749"
					stroke-width="0.216"
				/>
				<path
					id="Pfad_114"
					data-name="Pfad 114"
					d="M576.931,309.316a36.15,36.15,0,0,1,17.551-4.337c6.481.239,13.193,2.066,15.563,4.663"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_115"
					data-name="Pfad 115"
					d="M576.931,308.41a36.156,36.156,0,0,1,17.551-4.336c6.481.24,13.193,2.065,15.563,4.662"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_116"
					data-name="Pfad 116"
					d="M574.267,283.287s-.616,5.2,1.058,9.166,1.921,16.094,1.678,18.493"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_117"
					data-name="Pfad 117"
					d="M588.458,266.45s-13.838,2.381-14.191,16.837"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_118"
					data-name="Pfad 118"
					d="M588.458,266.45s18.47-5.116,22.079,13.661"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1300"
					data-name="Pfad 1300"
					d="M0,.908.273,0"
					transform="translate(609.761 310.268)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<!-- <path id="Pfad_119" data-name="Pfad 119" d="M582.788,373.039l1.568-.142.892,5.036-1.692.451-.363-2.761Z" fill="#1a1818" /> -->
				<path id="Pfad_120" data-name="Pfad 120" d="M602.484,262.842" fill="#1a1818" />
			</g>
			<g id="SHADING">
				<path
					id="Pfad_92"
					data-name="Pfad 92"
					d="M593.012,332.679c.13,1.585.956,2.571,1.425,4,.649,1.97.41,4.28.874,6.347.65,2.893,3.423,4.8,5.054,7.222,1.811,2.694,2.286,5.077,4.582,7.4a4.781,4.781,0,0,1,1.7,3.237c.17,1.377-.824,2.984-.695,3.789-.385-.62-.931-.531-1.357-.969-.7-.721-.488-.824-1.006-1.592-.752-1.123-.843-2.106-2.309-2.913a8.65,8.65,0,0,0-1.151-3.321c-.148-.258-.907-.764-1.176-1.166-.429-.648-.2-1.332-.6-2-.88-1.475-2.349-2.1-2.94-3.854a14.77,14.77,0,0,1-.316-3.915c-3.617-.191-1.729-8.7-2.322-11.089"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_93"
					data-name="Pfad 93"
					d="M595.128,355.714c-.31,4.543,3.8,5.271,6.621,8.044,1.866,1.834,6.592,4.039,6.137,7.064-.38,2.524-2.68,2.175-4.312.813-.992-.83-.767-1.21-1.377-2.052-.7-.974-1.709-1.371-2.384-2.11a33.937,33.937,0,0,1-3.222-3.82c-.964-1.48-.2-3.57-1.463-4.648"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_94"
					data-name="Pfad 94"
					d="M574.156,285.783s-12.406,6.6-9.821,27.454S571.2,344.2,571.2,344.2s2.9,16.632,2.955,17.132,2.085,12.616,2.085,12.616l3.665,5.982-3.665-10.61v-13.6s-3.845-15.514,0-20.686,6.293-22.82,6.293-22.82-3.705-.407-4.77-.652c-1.3-.3-.945-.056-.7-5.16,0,0-.523-8.061-1.061-11.6-.257-1.69-1.1-3.127-1.411-4.412A15.856,15.856,0,0,1,574.156,285.783Z"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_95"
					data-name="Pfad 95"
					d="M584.473,275.823c-.426,1.666-1.171,3.074-1.665,4.709a44.952,44.952,0,0,0-1.325,6.083c-.4,2.539-.725,5.558.284,8.008a13.6,13.6,0,0,0,2.147,3.289c-.752-2.389-1.747-4.665-1.6-7.249.067-1.22.543-2.4.624-3.636a28.553,28.553,0,0,1,.375-3.611c.233-1.2.524-2.394.693-3.554a17.082,17.082,0,0,0,.488-3.8"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_96"
					data-name="Pfad 96"
					d="M584.479,282.7a19.346,19.346,0,0,1-.353,2.831,34.812,34.812,0,0,0-.41,3.487c0,1.849.368,3.743.531,5.588a9.874,9.874,0,0,0,1,3.949c.525.964,1.478,1.959,1.741,3.049.033.135.122.263.16.391-4.3-3.494-4.016-10.23-4.039-15.226a13.232,13.232,0,0,1-.053-2.765,4.45,4.45,0,0,0,.5,1.631c.3-.936.635-1.853.882-2.79"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_97"
					data-name="Pfad 97"
					d="M588.915,269.643a7.1,7.1,0,0,1-.455,3.593,12.645,12.645,0,0,0-.474,4.519c.106,1.348.014,2.613.038,3.949.022,1.286.368,2.555.46,3.814a19.884,19.884,0,0,0,1.3,6.74,12.465,12.465,0,0,1,.98,2.688,10.668,10.668,0,0,1,.655,2.771c-1.032-.151-1.523-3.011-1.959-3.877a12.734,12.734,0,0,1-1.486-4.512,18.508,18.508,0,0,0-.82-3.673c-.432-1.266-.063-2.353-.22-3.618a11.541,11.541,0,0,1-.209-2.893c.129-1.136.408-2.279.637-3.392a58.379,58.379,0,0,1,1.627-5.783"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_98"
					data-name="Pfad 98"
					d="M584.132,271.178a3.3,3.3,0,0,0,.9-1.661c.319-.679-.134-.754-.521-.923a8.512,8.512,0,0,0-1.99-.726,6.007,6.007,0,0,1-1.7-.441.306.306,0,0,1-.189-.312,3.42,3.42,0,0,1,1.885.041c.784.172,1.77.162,2.55.344.2.047.825.414,1.382.07.774-.478-.029-1.021-.074-1.153-.125-.354-.125-.825-.253-1.2a5.811,5.811,0,0,0-.826-1.7,4.233,4.233,0,0,1-.8-2.089.5.5,0,0,1,0-.306c.285.2.21.734.29,1.1a3.911,3.911,0,0,0,.382,1.03c.373.708.863,1.329,1.238,2.023.127.237.228.525.367.765.106.187.3.577.774.409a2.426,2.426,0,0,0,.358-.749,9.348,9.348,0,0,1,.5-.888c.359-.62.379-1.05.219-1.321-.218-.375-.88-.4-1.217-.674a1.488,1.488,0,0,1-.6-.969c-.043-.337.148-.869-.005-1.116a7.746,7.746,0,0,0,1.179-.541,4.029,4.029,0,0,0-.361,1.593,1.642,1.642,0,0,0,.284.9c.12.152.157.525.491.489a1.485,1.485,0,0,0,.284-.922,10.262,10.262,0,0,0-.007-1.069c.011-.263.05-.551.068-.809.014-.189-.076-.381.074-.642.417.537.054,1.656.088,2.5a15.88,15.88,0,0,1-.122,2.166c-.036.352-.141.77-.149,1.106a4.571,4.571,0,0,1-.233,1.21,4.977,4.977,0,0,1-1.348,2.5,30.42,30.42,0,0,1-2.95,1.918"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_99"
					data-name="Pfad 99"
					d="M577.263,310.643a46.286,46.286,0,0,1,17.515-3.035c9.864.236,16.166,2.714,14.795,3.718s-7.815,2.125-16.51,1.535S575.346,311.941,577.263,310.643Z"
					fill="url(#linear-gradient)"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<!-- <g id="POSITIONEN" transform="translate(297.118 -225.48)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(296.305 513.922)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<g id="SIZE_REF" transform="translate(287.118 -207.48)">
				<circle
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(278.305 473.922)"
					fill="transparent"
				/>
				<circle
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(310.305 461.922)"
					fill="transparent"
				/>
			</g>
			<g
				id="PRINT-AREA"
				transform="translate(576.373 271.392)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="33.9" height="35.9" fill="none" ref="print-area" />
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'ShirtLeft',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
}
</script>