<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<g id="CREWNECK_LEFT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -190.401978, -783.720032)">
			<g id="COLORS">
				<path
					id="A"
					d="M865.9,153.515c0-3,.81-15.139,1.026-19.817s.917-17.977,1.079-21.236-1.424-9.548-1.316-20.323,8.088-22.616,8.734-25.649,6.94-8.129,9.913-10.183c2.543-1.757,7.3-6.967,17.9-5.86a13.432,13.432,0,0,1,3.733,1.282A18.436,18.436,0,0,1,914.43,60.1a25.6,25.6,0,0,1,1.889,6.7c1.1,4.961,2.593,13.053,2.422,15.227s-.091,4.678-.459,6.886.206,12.037.422,15.822-.581,16.5-.581,16.5-1.633,41.841-2.01,49.2-1.458,7.149-1.458,7.149l-.377.17.107-.012c-2.283,3.269-2.518,9.26-3.058,10.837s-2.773,1.1-8.28,1.831-6.856.526-13.5.736-16.467-2.313-19.166-3.154-1.512-4.731-1.4-5.677a12.429,12.429,0,0,0-.864-4.888,15.74,15.74,0,0,1-.863-4.048s-.27-1.735-.324-2.681-.378-2.26-.81-5.309S865.9,156.511,865.9,153.515Z"
					transform="translate(-626.946 739.022)"
					:fill="mainColor"
				/>
				<g id="B">
					<path
						id="Pfad_1296"
						data-name="Pfad 1296"
						d="M914.385,177.747s-17.709,2.05-21.488,1.945-16.412-.789-20.515-2.734-5.129-3.574-5.129-3.574a15.74,15.74,0,0,0,.863,4.048,12.429,12.429,0,0,1,.864,4.888c-.108.946-1.3,4.836,1.4,5.677s12.525,3.364,19.166,3.154,7.99,0,13.5-.736,7.74-.255,8.28-1.831S912.1,180.062,914.385,177.747Z"
						transform="translate(-626.946 739.022)"
						:fill="secColor"
					/>
					<path
						id="Pfad_1297"
						data-name="Pfad 1297"
						d="M918.738,91.126c-.4-3.757-2.771-10.49-2.489-13.853s.353-4.98-.492-6.685a25.034,25.034,0,0,0-6.751-7.934,15.41,15.41,0,0,0-11.273-3.94,12.543,12.543,0,0,0-2.943.314c-.7.187-1.282.251-1.98.473a36.008,36.008,0,0,0-4.357,2.042,11.088,11.088,0,0,0-5.932,6.218c-2.455,6.4,5.019,33.155,5.122,34.937s.941,3.488.748,5.26a14.953,14.953,0,0,0,.385,4.2c.086.631.913,14.4.49,21.017-.381,5.956.4,11.916.941,13.388s1.721,4.4.535,6.785-2.484,4.772-2.075,7.393.567,4.4,1.358,5.928-.029,1.62.7,2.677,1.987,1.18,1.85,2.433-.6,1.455-.609,2.082.337.319-.133,1.044-.7,1.035-.483,1.456a7.25,7.25,0,0,0,1.113,2c.4.286,1.254.336,1.171,1.162h.006c.771,1.077,15.3,1.7,15.3,1.7a11.384,11.384,0,0,0,2.8-1.63c.468-.621-.421-1.993.049-2.718a10.124,10.124,0,0,1,1.75-2.065c.7-.617,2.906-1.315,2.457-2.472a8.149,8.149,0,0,1-.875-2.941c.017-.941.968-1.449.97-2.338s.133-5.7.331-14.686,1.066-21.954,1.155-26.686-1.184-7.421-.38-15.239S919.142,94.883,918.738,91.126Z"
						transform="translate(-626.946 739.022)"
						:fill="secColor"
					/>
					<path
						id="Pfad_1299"
						data-name="Pfad 1299"
						d="M904.576,50.6s-6.531-1.048-10.885.7-5.122,2.446-6.914,3.887A58.541,58.541,0,0,0,882,59.862c-.9.917-3.883,2.567-4.523,3.266s.853-1.213,1.664-2.087,3.585-2.882,4.225-3.668,4.4-5.021,9.006-6.9,5.933-2.4,10.8-2.053C903.167,48.421,904.063,48.771,904.576,50.6Z"
						transform="translate(-626.946 739.022)"
						:fill="secColor"
					/>
				</g>
				<path
					id="C"
					d="M893.644,179.527h-.006a1.524,1.524,0,0,1-.063.318c-.363,1.145-1.243,5.628-1.369,6.253s-.833,1.87.3,2.3a42.082,42.082,0,0,0,11.154,2.052c4-.044,4.451.172,4.589-1.186s.689-8.041.689-8.041S894.415,180.6,893.644,179.527Z"
					transform="translate(-626.946 739.022)"
					:fill="secColor"
				/>
			</g>
			<g id="LINES">
				<path
					id="Pfad_1287"
					data-name="Pfad 1287"
					d="M892.714,178.346a15.5,15.5,0,0,1-1.341-1.5.819.819,0,0,1,0-1.139l.043-.061a.809.809,0,0,1,.352-.4,10.9,10.9,0,0,1,.4-1.847.8.8,0,0,1,.155-.284,3.984,3.984,0,0,1,.258-2.326.371.371,0,0,0,.013-.036c-2.784-1.284-3.188-5.214-3.646-7.976-.858-5.176,2.328-8.922,2.024-14,0-.006,0-.01,0-.016-1.684-2.682-1.6-6.966-1.585-9.692.015-3.192.2-6.369.137-9.562-.056-2.64-.729-5.352-.323-7.962a.787.787,0,0,1-.064-.268c-.108-6.488-1.388-12.9-1.576-19.35a46.313,46.313,0,0,1-2.411-9.564,25.274,25.274,0,0,1-1.261,4.9c-2.144-1.114-1.731-4.5-3.625-5.983a1.252,1.252,0,0,0-.954-.32,1.367,1.367,0,0,0-.694.438,5.345,5.345,0,0,0-.556,6.621c.57.814,1.367,1.466,1.888,2.31,1.055,1.711.8,3.878,1.336,5.805.406,1.467,1.264,2.779,1.744,4.225a7.493,7.493,0,0,1,.569,4.023c-.431,2.962-.348,6.031-1.135,8.922-.776,2.85,1.117,5.735,1.2,8.682.057,1.986-.71,3.9-.978,5.871a31.487,31.487,0,0,0,.156,6.411c.53,7.632-.824,15.324.011,22.93.3,2.752.89,5.481.949,8.249a43.369,43.369,0,0,1-.375,5.678,11.943,11.943,0,0,1-.59,2.986c-.82,2.14-2.77,3.638-4.645,5.016a20.1,20.1,0,0,0,9.772,1.85,1.1,1.1,0,0,0,.684-.207c.165-.15,1.915.374,2.9-.669s.59-1.466.859-2.758a22.788,22.788,0,0,0,.646-4.066c.064-1.548.795-2.581.8-4.057a.876.876,0,0,1-.472-.2A7.1,7.1,0,0,1,892.714,178.346Z"
					transform="translate(-627 728)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1288"
					data-name="Pfad 1288"
					d="M899.905,130.765a6.8,6.8,0,0,1,4.667.3.372.372,0,0,1,.233.2c.061.21-.216.336-.425.364l-2.87.385a2.124,2.124,0,0,1-1.243-.083.8.8,0,0,1-.385-1.078c.176-.253.577-.459.448-.743"
					transform="translate(-627 728)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1289"
					data-name="Pfad 1289"
					d="M896.68,129.355q-3-1.156-6.069-2.206c.9-.613,2.706-.52,2.828-1.461.063-.492-.464-.873-.869-1.236s-.7-.973-.251-1.311c.636-.476,1.481.269,2.207.626a5.438,5.438,0,0,0,3.421-.058c1.139-.248,2.531-.376,3.312.321l-5.575.447a2.642,2.642,0,0,0-1.354.356.557.557,0,0,0,.037.987l8.384-.426a9.358,9.358,0,0,1,2.044.045c1.454.248,2.668,1.18,4.159,1.2,1.068.018,2.094-.441,3.162-.428a3.57,3.57,0,0,1,3.4,2.768,7.17,7.17,0,0,1-.857,4.185c-.476-1.034-.214-2.2-.33-3.3a2.565,2.565,0,0,0-2.26-2.422,9.461,9.461,0,0,0-1.8.257,9.1,9.1,0,0,1-2.6-.152,100.352,100.352,0,0,0-12.03-1.088c-.59-.018-1.352.067-1.506.531-.185.56.7.9,1.389,1.043s1.512.656,1.127,1.147c-.207.317-.946-.007-.722-.317"
					transform="translate(-627 728)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1290"
					data-name="Pfad 1290"
					d="M871.537,100.733a3.816,3.816,0,0,0-.637-1.778l-.369,1.323-.994-1a27.26,27.26,0,0,1,2.092,5.324q1.981,6.321,3.965,12.642a18.725,18.725,0,0,1,.966,4.175c.108,1.473-.135,2.953-.068,4.429a20.641,20.641,0,0,0,.792,4.251q2.509,9.579,5.531,19.022c.875-4.267-1.479-8.437-2.823-12.587a55.74,55.74,0,0,1-1.956-10.328,6.533,6.533,0,0,1,.382-4.117,59.664,59.664,0,0,0,4,11.493c1.023,2.192,1.1-3.2.379-4.92-1.141-2.708-2.754-5.2-4-7.869-2.911-6.228-3.767-13.263-7.049-19.314a3.931,3.931,0,0,1-.561-1.42,1.236,1.236,0,0,1,.647-1.285"
					transform="translate(-627 728)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1291"
					data-name="Pfad 1291"
					d="M868.311,111.037q-.1,6.852-.192,13.7a133.207,133.207,0,0,0,.474,16.813c.492,4.351,1.416,8.718.93,13.07-.329,2.944-1.3,5.819-1.323,8.78a27.674,27.674,0,0,0,.867,6.1l1.248,5.7a8.361,8.361,0,0,1-3.444-5.559,16.449,16.449,0,0,1-.072-2.928q.244-7.137.288-14.276c.045-7.126-.06-14.251-.047-21.376a136.817,136.817,0,0,1,1.149-20.259"
					transform="translate(-627 728)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1292"
					data-name="Pfad 1292"
					d="M916.29,138.322a2.819,2.819,0,0,0-1.825-2.283,8.083,8.083,0,0,0-3.2-.527,11.634,11.634,0,0,0-3.345.362,18.683,18.683,0,0,0-2.976,1.323c-3.74,1.835-7.914,2.8-11.995,3.9a1.212,1.212,0,0,0-.558.26.336.336,0,0,0,0,.509.627.627,0,0,0,.381.062c.462-.026.922-.076,1.382-.126a40.449,40.449,0,0,0,4.894-.739c2.68-.634,5.173-1.809,7.854-2.443.37-.088.958.011.864.346a.527.527,0,0,1-.207.242,11.324,11.324,0,0,1-4.385,2.44c-1.134.247-2.324.163-3.477.323a3.555,3.555,0,0,0-2.821,1.623,9.727,9.727,0,0,0,3.612.467c1.059-.063,2.1-.3,3.162-.34,2.681-.107,5.268,1.018,7.953,1q-5.295.523-10.6.981a24.23,24.23,0,0,1-5.135.072,2.283,2.283,0,0,0-.883-.008,1.793,1.793,0,0,0-.449.212c-.526.319-1.061.777-1.03,1.347a1.346,1.346,0,0,0,1.121,1.071,4.874,4.874,0,0,0,1.736-.023,59.264,59.264,0,0,1,11.1-.386,51.885,51.885,0,0,1-10.5,1.12,2.189,2.189,0,0,0-1.177.222c-.331.214-.446.725-.112.935a1.507,1.507,0,0,0-.9.06c-.265.14-.366.537-.106.685a.739.739,0,0,0,.274.07,64.828,64.828,0,0,0,12.341.309,19.186,19.186,0,0,1-7.1,1.065,14.576,14.576,0,0,0-4.931.156c-.2.064-.425.167-.467.356-.059.265.269.456.548.553,4.575,1.59,9.733.137,14.6.678l-12.318,1.363a1.378,1.378,0,0,0-.891.318c-.2.222-.187.535-.277.808a3.342,3.342,0,0,1-.956,1.159,4.471,4.471,0,0,0-1.31,2.69,1.519,1.519,0,0,1-1.274-.9,4.257,4.257,0,0,1-.588-1.966,33.1,33.1,0,0,1,.3-4.69c.109-1.054-1.359-2.27-1.62-3.241-.184-.69.891-1.119.654-1.894-.593-1.943-1.811-3.731-1.965-5.643a9.735,9.735,0,0,1,.461-3.677,24.864,24.864,0,0,0,6.716.238c4.654-.723,8.706-3.632,13.417-3.936a15.869,15.869,0,0,1,3.2.147,2.848,2.848,0,0,0,1.706-.094,2.281,2.281,0,0,0,.7-.684l1.813-2.382a58.884,58.884,0,0,1-.636,7.28q-.547-.981-1.1-1.96"
					transform="translate(-627 728)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1293"
					data-name="Pfad 1293"
					d="M892.1,66.457a.538.538,0,0,1,.815.263c.1.381-.269.7-.538.994a5.372,5.372,0,0,0-.746,1.148A97.416,97.416,0,0,0,886.2,80.175a4.32,4.32,0,0,1-.085-2.322c.671-3.9,2.8-7.4,5.09-10.663.335-.476.79-1.005,1.38-.95"
					transform="translate(-627 728)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1294"
					data-name="Pfad 1294"
					d="M884.128,84.47a29.246,29.246,0,0,0,1.19,4.361,1.752,1.752,0,0,0,.871,1.162c1.629-4.15,1.918-8.675,3.1-12.965a3.484,3.484,0,0,1,1.4-2.276,50.284,50.284,0,0,0-2.22,10.3l.984-1.807q-.285,2.284-.532,4.572a14.15,14.15,0,0,0,.047,4.789,28.96,28.96,0,0,1,.825,2.98,9.673,9.673,0,0,1-.112,2.483l-.531,4.023a18.186,18.186,0,0,1-2.7-6.216c-.208-1.158-.224-2.347-.51-3.488-.216-.862-.582-1.681-.822-2.536a17.919,17.919,0,0,1-.458-2.53q-.2-1.517-.4-3.033"
					transform="translate(-627 728)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<g id="SHADING">
				<path
					id="Pfad_1138"
					data-name="Pfad 1138"
					d="M890.343,179.553c-.2,4.675-.3,6.923-.5,11.58"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1139"
					data-name="Pfad 1139"
					d="M890.343,179.553c-.2,4.675-.3,6.923-.5,11.58"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1140"
					data-name="Pfad 1140"
					d="M889.61,179.542c-.2,4.632-.3,6.993-.495,11.621"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1141"
					data-name="Pfad 1141"
					d="M889.61,179.542c-.2,4.632-.3,6.993-.495,11.621"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1142"
					data-name="Pfad 1142"
					d="M888.876,179.521c-.2,4.586-.3,7.039-.489,11.642"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1143"
					data-name="Pfad 1143"
					d="M888.141,179.49c-.2,4.538-.29,7.068-.477,11.65"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1144"
					data-name="Pfad 1144"
					d="M887.405,179.448c-.194,4.489-.288,7.173-.47,11.737"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1145"
					data-name="Pfad 1145"
					d="M887.405,179.448c-.194,4.489-.288,7.173-.47,11.737"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1146"
					data-name="Pfad 1146"
					d="M886.668,179.4c-.19,4.438-.283,7.191-.458,11.739"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1147"
					data-name="Pfad 1147"
					d="M886.668,179.4c-.19,4.438-.283,7.191-.458,11.739"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1148"
					data-name="Pfad 1148"
					d="M885.93,179.334c-.186,4.387-.276,7.21-.444,11.746"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1149"
					data-name="Pfad 1149"
					d="M885.93,179.334c-.186,4.387-.276,7.21-.444,11.746"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1150"
					data-name="Pfad 1150"
					d="M885.192,179.261c-.182,4.336-.269,7.229-.43,11.755"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1151"
					data-name="Pfad 1151"
					d="M885.192,179.261c-.182,4.336-.269,7.229-.43,11.755"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1152"
					data-name="Pfad 1152"
					d="M884.454,179.176c-.178,4.285-.262,7.174-.415,11.693"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1153"
					data-name="Pfad 1153"
					d="M883.715,179.081c-.173,4.235-.253,7.17-.4,11.684"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1154"
					data-name="Pfad 1154"
					d="M882.977,178.973c-.168,4.187-.246,7.211-.383,11.722"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1155"
					data-name="Pfad 1155"
					d="M881.872,190.7"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1156"
					data-name="Pfad 1156"
					d="M882.234,178.973c-.16,4.083-.234,7.159-.362,11.626"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1157"
					data-name="Pfad 1157"
					d="M881.15,190.448"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1158"
					data-name="Pfad 1158"
					d="M881.494,178.906c-.153,4.013-.224,7.1-.344,11.542"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1159"
					data-name="Pfad 1159"
					d="M880.428,190.346"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1160"
					data-name="Pfad 1160"
					d="M880.756,178.79c-.148,3.964-.216,7.12-.328,11.556"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1161"
					data-name="Pfad 1161"
					d="M880.016,178.723c-.141,3.894-.205,7.109-.311,11.516"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1162"
					data-name="Pfad 1162"
					d="M879.275,178.723c-.133,3.8-.194,7.056-.292,11.4"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1163"
					data-name="Pfad 1163"
					d="M878.545,178.424c-.132,3.826-.191,7.1-.285,11.5"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1164"
					data-name="Pfad 1164"
					d="M877.807,178.373c-.125,3.757-.181,7.076-.27,11.427"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1165"
					data-name="Pfad 1165"
					d="M877.073,178.247c-.12,3.721-.175,7.073-.26,11.4"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1166"
					data-name="Pfad 1166"
					d="M876.34,178.109c-.116,3.693-.169,7.031-.251,11.336"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1167"
					data-name="Pfad 1167"
					d="M875.609,177.958c-.112,3.673-.164,7.062-.245,11.344"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1168"
					data-name="Pfad 1168"
					d="M874.881,177.79c-.111,3.664-.162,7.08-.243,11.342"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1169"
					data-name="Pfad 1169"
					d="M874.154,177.59c-.109,3.669-.161,7.117-.243,11.366"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1170"
					data-name="Pfad 1170"
					d="M873.43,177.371c-.108,3.684-.161,7.163-.247,11.4"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1171"
					data-name="Pfad 1171"
					d="M872.709,177.115c-.11,3.714-.164,7.23-.255,11.469"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1172"
					data-name="Pfad 1172"
					d="M871.992,176.784c-.113,3.77-.17,7.344-.268,11.606"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1173"
					data-name="Pfad 1173"
					d="M871.279,176.406c-.119,3.843-.179,7.44-.287,11.737"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1174"
					data-name="Pfad 1174"
					d="M870.568,176.029c-.125,3.92-.172,7.607-.291,11.932"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1175"
					data-name="Pfad 1175"
					d="M869.861,175.6c-.123,3.739-.193,7.878-.31,11.954"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1176"
					data-name="Pfad 1176"
					d="M869.159,175.134c-.123,3.546-.2,7.458-.311,11.289"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1177"
					data-name="Pfad 1177"
					d="M868.461,174.608c-.041,1.134-.078,2.306-.112,3.5"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1178"
					data-name="Pfad 1178"
					d="M867.771,173.99c-.028.7-.053,1.423-.077,2.152"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1179"
					data-name="Pfad 1179"
					d="M913.66,177.821c-.012.268-.023.729-.034.982"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1180"
					data-name="Pfad 1180"
					d="M912.934,177.9c-.025.546-.027,2.213-.049,2.7"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1181"
					data-name="Pfad 1181"
					d="M912.207,177.971c-.038.809-.329,7.064-.361,7.759"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1182"
					data-name="Pfad 1182"
					d="M911.391,179.913c-.133,3.082-.358,8.472-.392,9.214"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1183"
					data-name="Pfad 1183"
					d="M910.639,180.389c-.149,3.207-.378,8.245-.42,9.218"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1184"
					data-name="Pfad 1184"
					d="M909.906,180.745c-.133,3.2-.306,7.727-.354,8.994"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1185"
					data-name="Pfad 1185"
					d="M909.172,181.1c-.114,3.119-.21,6.813-.267,8.728"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1186"
					data-name="Pfad 1186"
					d="M900.847,190.276c-.005.2-.011.215-.017.423"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1187"
					data-name="Pfad 1187"
					d="M900.12,190.159c-.008.254-.016.328-.023.6"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1188"
					data-name="Pfad 1188"
					d="M899.393,190.031c-.01.311-.02.445-.03.777"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1189"
					data-name="Pfad 1189"
					d="M898.665,189.914c-.012.362-.024.551-.036.943"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1190"
					data-name="Pfad 1190"
					d="M897.941,189.718c-.015.436-.03.705-.046,1.184"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1191"
					data-name="Pfad 1191"
					d="M897.214,189.607c-.017.482-.035.8-.054,1.337"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1192"
					data-name="Pfad 1192"
					d="M896.49,189.44c-.02.543-.041.929-.064,1.541"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1193"
					data-name="Pfad 1193"
					d="M895.769,189.184c-.024.629-.05,1.109-.077,1.83"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1194"
					data-name="Pfad 1194"
					d="M895.043,189.07c-.027.67-.055,1.2-.085,1.973"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1195"
					data-name="Pfad 1195"
					d="M894.32,188.879c-.029.732-.061,1.348-.1,2.209"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1196"
					data-name="Pfad 1196"
					d="M893.6,188.661c-.033.8-.069,1.492-.108,2.447"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1197"
					data-name="Pfad 1197"
					d="M892.878,188.428c-.037.872-.076,1.641-.12,2.7"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1198"
					data-name="Pfad 1198"
					d="M892.159,188.138c-.04.956-.083,1.819-.133,3"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1199"
					data-name="Pfad 1199"
					d="M892.525,179.537c-.1,2.285-.171,4.015-.244,5.729"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1200"
					data-name="Pfad 1200"
					d="M891.794,179.549c-.2,4.7-.3,6.889-.5,11.591"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1201"
					data-name="Pfad 1201"
					d="M891.794,179.549c-.2,4.7-.3,6.889-.5,11.591"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1202"
					data-name="Pfad 1202"
					d="M891.063,179.555c-.2,4.7-.3,6.9-.5,11.585"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1203"
					data-name="Pfad 1203"
					d="M893.6,179.691c-.269.006-.5.006-.7,0-3.779-.1-16.412-.789-20.515-2.734s-5.129-3.574-5.129-3.574a15.74,15.74,0,0,0,.863,4.048,12.429,12.429,0,0,1,.864,4.888c-.108.946-1.3,4.836,1.4,5.677s12.525,3.364,19.166,3.154,7.99,0,13.5-.736c1.229.053,3.2.123,4.2.039a1,1,0,0,0,.857-.54c1.942-.173,2.9-.37,3.227-1.33.54-1.577.775-8.522,3.058-10.837,0,0-1.04.12-2.669.3"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.179"
				/>
				<path
					id="Pfad_1204"
					data-name="Pfad 1204"
					d="M893.45,180.289c-.208,0-.393,0-.553,0-3.779-.1-16.412-.789-20.515-2.733s-5.129-3.575-5.129-3.575"
					transform="translate(-627 739)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1205"
					data-name="Pfad 1205"
					d="M914.385,178.342s-.985.114-2.539.287"
					transform="translate(-627 739)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1206"
					data-name="Pfad 1206"
					d="M893.5,178.9c-.23.005-.433,0-.605,0-3.779-.1-16.412-.789-20.515-2.734s-5.129-3.574-5.129-3.574"
					transform="translate(-627 739)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1207"
					data-name="Pfad 1207"
					d="M915.212,176.851s-1.42.165-3.553.4"
					transform="translate(-627 739)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1208"
					data-name="Pfad 1208"
					d="M867.253,173.384s-.27-1.735-.324-2.681-.449-2.274-.621-5.347-.4-8.845-.4-11.841.81-15.139,1.026-19.817.917-17.977,1.079-21.236-1.424-9.548-1.316-20.323,8.088-22.616,8.734-25.649a9.042,9.042,0,0,1,2.366-3.647c.849-.809,2.7-1.69,3.981-2.774,1.46-1.232,2.577-2.989,3.566-3.762,2.78-2.174,8.368-6.773,16.425-6.03,4.1,0,7.541,2.752,9.441,4.909,4.406,5.281,4.307,8.489,5.114,11.614,1.275,4.934,2.593,13.053,2.422,15.227s-.091,4.678-.459,6.886.206,12.037.422,15.822-.581,16.5-.581,16.5-1.633,41.841-2.01,49.2-1.458,7.149-1.458,7.149a2.349,2.349,0,0,0-.586.525"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_1209"
					data-name="Pfad 1209"
					d="M909.873,180.752s-15.6-.719-16.374-1.794"
					transform="translate(-627 739)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1210"
					data-name="Pfad 1210"
					d="M908.905,181.641s-14.618-.515-15.39-1.594"
					transform="translate(-627 739)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1211"
					data-name="Pfad 1211"
					d="M893.644,179.527c.772,1.077,15.3,1.7,15.3,1.7"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<line
					id="Linie_251"
					data-name="Linie 251"
					x1="1.005"
					y2="8.775"
					transform="translate(265.547 918.728)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_252"
					data-name="Linie 252"
					x1="1.002"
					y2="8.742"
					transform="translate(266.192 918.823)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_253"
					data-name="Linie 253"
					x1="0.993"
					y2="8.808"
					transform="translate(266.843 918.912)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_254"
					data-name="Linie 254"
					x1="0.983"
					y2="8.996"
					transform="translate(267.445 919.083)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_255"
					data-name="Linie 255"
					x1="0.983"
					y2="8.996"
					transform="translate(268.091 919.145)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_256"
					data-name="Linie 256"
					x1="0.981"
					y2="9.069"
					transform="translate(268.734 919.248)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_257"
					data-name="Linie 257"
					x1="1.066"
					y2="9.249"
					transform="translate(269.291 919.321)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_258"
					data-name="Linie 258"
					x1="1.123"
					y2="9.243"
					transform="translate(269.937 919.388)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_259"
					data-name="Linie 259"
					x1="1.12"
					y2="9.213"
					transform="translate(270.582 919.481)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_260"
					data-name="Linie 260"
					x1="1.122"
					y2="9.235"
					transform="translate(271.228 919.521)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_261"
					data-name="Linie 261"
					x1="1.124"
					y2="9.345"
					transform="translate(271.872 919.585)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_262"
					data-name="Linie 262"
					x1="1.122"
					y2="9.367"
					transform="translate(272.517 919.626)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_263"
					data-name="Linie 263"
					x1="1.115"
					y2="9.31"
					transform="translate(273.163 919.745)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_264"
					data-name="Linie 264"
					x1="1.118"
					y2="9.335"
					transform="translate(273.808 919.781)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_265"
					data-name="Linie 265"
					x1="1.12"
					y2="9.588"
					transform="translate(274.459 919.816)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_266"
					data-name="Linie 266"
					x1="1.099"
					y2="9.479"
					transform="translate(275.126 919.873)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_267"
					data-name="Linie 267"
					x1="1.1"
					y2="9.487"
					transform="translate(275.772 919.927)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_268"
					data-name="Linie 268"
					x1="1.096"
					y2="9.582"
					transform="translate(276.417 919.894)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_269"
					data-name="Linie 269"
					x1="1.062"
					y2="9.459"
					transform="translate(277.098 919.946)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_270"
					data-name="Linie 270"
					x1="1.055"
					y2="9.397"
					transform="translate(277.744 920.071)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_271"
					data-name="Linie 271"
					x1="1.042"
					y2="9.245"
					transform="translate(278.412 920.041)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_272"
					data-name="Linie 272"
					x1="1.01"
					y2="9.252"
					transform="translate(279.091 920.104)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_273"
					data-name="Linie 273"
					x1="1.012"
					y2="9.273"
					transform="translate(279.737 920.145)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_274"
					data-name="Linie 274"
					x1="1.014"
					y2="9.287"
					transform="translate(280.382 920.192)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_275"
					data-name="Linie 275"
					x1="1.022"
					y2="8.828"
					transform="translate(281.021 920.239)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1212"
					data-name="Pfad 1212"
					d="M889.177,61.172a12.352,12.352,0,0,0-6.656,6.589c-2.455,6.4,5.019,33.155,5.122,34.937s.941,3.488.748,5.26a14.953,14.953,0,0,0,.385,4.2c.086.631.913,14.4.49,21.017-.381,5.956.4,11.916.941,13.388s1.72,4.4.535,6.785-2.484,4.772-2.075,7.393.567,4.4,1.358,5.928-.029,1.62.7,2.677,1.987,1.18,1.85,2.433-.6,1.455-.609,2.082.337.319-.133,1.044-.7,1.035-.483,1.456a7.25,7.25,0,0,0,1.113,2c.444.32,1.471.336,1.108,1.48s-1.243,5.628-1.369,6.253-.833,1.87.3,2.3a42.082,42.082,0,0,0,11.154,2.052c4-.044,4.451.172,4.589-1.186s.689-8.041.689-8.041a11.384,11.384,0,0,0,2.8-1.63c.468-.621-.421-1.993.049-2.718a10.124,10.124,0,0,1,1.75-2.065c.7-.617,2.906-1.315,2.457-2.472a8.149,8.149,0,0,1-.875-2.941c.017-.941.968-1.449.97-2.338s.133-5.7.331-14.686,1.066-21.954,1.155-26.686-1.184-7.421-.38-15.239,1.948-15.569,1.544-19.326-2.771-10.49-2.489-13.853.353-4.98-.492-6.685c-.745-1.5-8.185-13.238-18.654-11.9a24.984,24.984,0,0,0-4.526.885A27.592,27.592,0,0,0,889.177,61.172Z"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_1213"
					data-name="Pfad 1213"
					d="M904.576,50.6s-6.531-1.048-10.885.7-5.122,2.446-6.914,3.887A58.541,58.541,0,0,0,882,59.862c-.9.917-3.883,2.567-4.523,3.266s.853-1.213,1.664-2.087,3.585-2.882,4.225-3.668,4.4-5.021,9.006-6.9,5.933-2.4,10.8-2.053C903.167,48.421,904.063,48.771,904.576,50.6Z"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.179"
				/>
				<path
					id="Pfad_1214"
					data-name="Pfad 1214"
					d="M904.083,50.528a11.1,11.1,0,0,0-.91-2.135"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1215"
					data-name="Pfad 1215"
					d="M903.6,50.456a9.28,9.28,0,0,0-.879-2.079"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1216"
					data-name="Pfad 1216"
					d="M903.131,50.4a7.984,7.984,0,0,0-.853-2.03"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1217"
					data-name="Pfad 1217"
					d="M902.667,50.345a7.088,7.088,0,0,0-.842-2.011"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1218"
					data-name="Pfad 1218"
					d="M902.212,50.305a6.426,6.426,0,0,0-.816-1.964"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1219"
					data-name="Pfad 1219"
					d="M901.764,50.277a5.959,5.959,0,0,0-.8-1.947"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1220"
					data-name="Pfad 1220"
					d="M901.324,50.258a5.7,5.7,0,0,0-.83-1.988"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1221"
					data-name="Pfad 1221"
					d="M900.891,50.25a5.4,5.4,0,0,0-.809-1.953"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1222"
					data-name="Pfad 1222"
					d="M900.464,50.252a5.179,5.179,0,0,0-.787-1.918"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1223"
					data-name="Pfad 1223"
					d="M900.044,50.264a5.006,5.006,0,0,0-.767-1.884"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1224"
					data-name="Pfad 1224"
					d="M899.629,50.285a4.884,4.884,0,0,0-.745-1.851"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1225"
					data-name="Pfad 1225"
					d="M899.221,50.316a4.845,4.845,0,0,0-.725-1.82"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1226"
					data-name="Pfad 1226"
					d="M898.817,50.356a4.818,4.818,0,0,0-.705-1.789"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1227"
					data-name="Pfad 1227"
					d="M898.419,50.406a4.854,4.854,0,0,0-.686-1.761"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1228"
					data-name="Pfad 1228"
					d="M898.01,50.378a5.06,5.06,0,0,0-.653-1.647"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1229"
					data-name="Pfad 1229"
					d="M897.613,50.406a5.275,5.275,0,0,0-.629-1.581"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1230"
					data-name="Pfad 1230"
					d="M897.222,50.458a5.615,5.615,0,0,0-.607-1.533"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1231"
					data-name="Pfad 1231"
					d="M896.832,50.509a6.09,6.09,0,0,0-.585-1.477"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1232"
					data-name="Pfad 1232"
					d="M896.441,50.557a6.853,6.853,0,0,0-.56-1.412"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1233"
					data-name="Pfad 1233"
					d="M895.517,49.265a7.688,7.688,0,0,1,.537,1.359"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1234"
					data-name="Pfad 1234"
					d="M895.665,50.69a8.953,8.953,0,0,0-.512-1.3"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1235"
					data-name="Pfad 1235"
					d="M895.288,50.8a10.49,10.49,0,0,0-.5-1.279"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1236"
					data-name="Pfad 1236"
					d="M894.907,50.9a13.122,13.122,0,0,0-.48-1.241"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1237"
					data-name="Pfad 1237"
					d="M894.52,50.989c-.105-.328-.235-.643-.457-1.19"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1238"
					data-name="Pfad 1238"
					d="M894.149,51.132c-.108-.328-.236-.642-.451-1.186"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1239"
					data-name="Pfad 1239"
					d="M893.775,51.271c-.111-.325-.237-.637-.444-1.174"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1240"
					data-name="Pfad 1240"
					d="M893.4,51.415c-.113-.322-.237-.633-.437-1.163"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1241"
					data-name="Pfad 1241"
					d="M893.028,51.573c-.118-.323-.241-.634-.436-1.162"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1242"
					data-name="Pfad 1242"
					d="M892.652,51.729c-.121-.323-.243-.634-.434-1.155"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1243"
					data-name="Pfad 1243"
					d="M892.275,51.889c-.125-.324-.246-.634-.435-1.149"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1244"
					data-name="Pfad 1244"
					d="M891.891,52.041c-.126-.32-.247-.629-.433-1.132"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1245"
					data-name="Pfad 1245"
					d="M891.515,52.221c-.132-.326-.254-.636-.443-1.139"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1246"
					data-name="Pfad 1246"
					d="M891.133,52.4c-.137-.329-.26-.64-.452-1.139"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1247"
					data-name="Pfad 1247"
					d="M890.749,52.576c-.142-.333-.268-.646-.464-1.142"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1248"
					data-name="Pfad 1248"
					d="M890.361,52.756c-.136-.313-.257-.607-.436-1.042"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1249"
					data-name="Pfad 1249"
					d="M889.981,52.964c-.14-.316-.263-.606-.443-1.023"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1250"
					data-name="Pfad 1250"
					d="M889.6,53.174c-.14-.311-.262-.591-.436-.976"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1251"
					data-name="Pfad 1251"
					d="M889.214,53.4c-.142-.308-.264-.581-.435-.94"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1252"
					data-name="Pfad 1252"
					d="M888.83,53.638c-.149-.311-.277-.581-.421-.921"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1253"
					data-name="Pfad 1253"
					d="M888.453,53.891c-.15-.319-.273-.588-.4-.912"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1254"
					data-name="Pfad 1254"
					d="M888.119,54.133c-.158-.323-.289-.583-.415-.891"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1255"
					data-name="Pfad 1255"
					d="M887.781,54.389a9.114,9.114,0,0,1-.428-.89"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1256"
					data-name="Pfad 1256"
					d="M887.449,54.65a6.952,6.952,0,0,1-.429-.862"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1257"
					data-name="Pfad 1257"
					d="M887.127,54.923a5.953,5.953,0,0,1-.441-.857"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1258"
					data-name="Pfad 1258"
					d="M886.794,55.176a5.066,5.066,0,0,1-.435-.828"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1259"
					data-name="Pfad 1259"
					d="M886.475,55.449a4.472,4.472,0,0,1-.438-.815"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1260"
					data-name="Pfad 1260"
					d="M886.145,55.706a4.038,4.038,0,0,1-.424-.784"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1261"
					data-name="Pfad 1261"
					d="M885.837,56a3.794,3.794,0,0,1-.424-.771"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1262"
					data-name="Pfad 1262"
					d="M885.529,56.286a3.488,3.488,0,0,1-.43-.788"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1263"
					data-name="Pfad 1263"
					d="M885.208,56.557a3.267,3.267,0,0,1-.407-.75"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1264"
					data-name="Pfad 1264"
					d="M884.912,56.865a3.183,3.183,0,0,1-.408-.755"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1265"
					data-name="Pfad 1265"
					d="M884.61,57.162a3.058,3.058,0,0,1-.4-.738"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1266"
					data-name="Pfad 1266"
					d="M884.309,57.461a3,3,0,0,1-.386-.733"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1267"
					data-name="Pfad 1267"
					d="M884.012,57.762a2.912,2.912,0,0,1-.373-.714"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1268"
					data-name="Pfad 1268"
					d="M883.716,58.065a2.93,2.93,0,0,1-.356-.689"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1269"
					data-name="Pfad 1269"
					d="M883.422,58.369a2.957,2.957,0,0,1-.343-.673"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1270"
					data-name="Pfad 1270"
					d="M883.13,58.673a2.874,2.874,0,0,1-.352-.721"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1271"
					data-name="Pfad 1271"
					d="M882.84,58.979a2.978,2.978,0,0,1-.36-.767"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1272"
					data-name="Pfad 1272"
					d="M882.55,59.284a3.093,3.093,0,0,1-.375-.836"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1273"
					data-name="Pfad 1273"
					d="M882.261,59.587a3.245,3.245,0,0,1-.382-.873"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1274"
					data-name="Pfad 1274"
					d="M881.971,59.888a3.472,3.472,0,0,1-.4-.926"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1275"
					data-name="Pfad 1275"
					d="M881.666,60.161a3.826,3.826,0,0,1-.4-.948"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1276"
					data-name="Pfad 1276"
					d="M881.348,60.409a4.246,4.246,0,0,1-.385-.95"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1277"
					data-name="Pfad 1277"
					d="M881.025,60.644a4.782,4.782,0,0,1-.373-.941"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1278"
					data-name="Pfad 1278"
					d="M880.7,60.872a5.468,5.468,0,0,1-.356-.906"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1279"
					data-name="Pfad 1279"
					d="M880.365,61.081a6.362,6.362,0,0,1-.334-.851"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1280"
					data-name="Pfad 1280"
					d="M880.042,61.313a7.18,7.18,0,0,1-.324-.818"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1281"
					data-name="Pfad 1281"
					d="M879.716,61.543c-.122-.263-.213-.5-.315-.785"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1282"
					data-name="Pfad 1282"
					d="M879.378,61.748c-.1-.227-.186-.435-.275-.674"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1283"
					data-name="Pfad 1283"
					d="M879.045,61.967c-.087-.192-.16-.369-.235-.561"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1284"
					data-name="Pfad 1284"
					d="M878.71,62.189c-.072-.159-.134-.306-.2-.458"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1285"
					data-name="Pfad 1285"
					d="M878.375,62.417c-.053-.118-.1-.229-.148-.34"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1286"
					data-name="Pfad 1286"
					d="M878.041,62.654c-.035-.078-.068-.153-.1-.227"
					transform="translate(-627 739)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_276"
					data-name="Linie 276"
					x1="0.891"
					y1="8.315"
					transform="translate(271.354 789.408)"
					fill="none"
					stroke="#464749"
					stroke-width="0.179"
				/>
				<line
					id="Linie_277"
					data-name="Linie 277"
					x1="0.891"
					y1="8.315"
					transform="translate(271.926 789.305)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.063"
					stroke-dasharray="0.505 0.253"
				/>
				<line
					id="Linie_278"
					data-name="Linie 278"
					x1="0.891"
					y1="8.315"
					transform="translate(270.733 789.408)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.063"
					stroke-dasharray="0.505 0.253"
				/>
			</g>
			<g id="SIZE_REF" transform="translate(-27.562 326.939)">
				<circle
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(278.305 473.406)"
					fill="transparent"
				/>
				<circle
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(304.188 461.922)"
					fill="transparent"
				/>
			</g>
			<!-- <g id="POSITIONEN" transform="translate(-20.679 308.939)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(296.305 513.922)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<g
				id="PRINT-AREA"
				transform="translate(266.576 812.811)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="18.9" height="89.9" fill="none" ref="print-area" />
			</g>
			<!-- <g id="POSITIONEN" transform="translate(-23.679 308.939)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(296.305 513.922)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<!-- <g
				id="PRINT-AREA"
				transform="translate(256.626 805.861)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect x="0.05" y="0.05" width="32.9" height="105.9" fill="none" ref="print-area" />
			</g> -->
		</g>
	</svg>
</template>

<script>
export default {
	name: 'CrewneckLeft',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
}
</script>