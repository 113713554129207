<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<defs>
			<linearGradient id="linear-gradient" x1="0.5" y1="0.983" x2="0.5" y2="-0.018" gradientUnits="objectBoundingBox">
				<stop offset="0" stop-color="#edeeef" />
				<stop offset="0.185" stop-color="#e9eaeb" />
				<stop offset="0.361" stop-color="#dddee0" />
				<stop offset="0.533" stop-color="#cacbcd" />
				<stop offset="0.703" stop-color="#afb1b3" />
				<stop offset="0.87" stop-color="#8d8f91" />
				<stop offset="1" stop-color="#6d6f72" />
			</linearGradient>
		</defs>
		<g id="TSHIRT_RIGHT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -504.619354, -252.097214)">
			<g id="COLORS" transform="translate(548.176 254.158)">
				<path
					id="MAIN"
					d="M594.677,268.34l-2.217-2.526c-17.132-1.9-27.817-9.975-29.654-11.574l-.081-.082s-16.383,14.936-14.376,41.556,6.518,41.8,6.165,56.689c0,0-.353,4.661-1.762,7.307a6.88,6.88,0,0,0,.529,5.112s-.7,1.587-1.233,3.174-1.41,2.468-.352,4.231-1.342,4.407-1.342,4.407-2.47,16.4-2.149,16.4h.321s10.392,7.051,28.01,7.051,30.122-1.763,35.055-6.875c0,0-2.994-34.378-2.994-44.955s-.853-53.4-1.559-57.98-1.461-9.006-12.361-21.931"
					transform="translate(-548.176 -254.158)"
					:fill="mainColor"
				/>
				<g id="SEC" transform="translate(0.002)">
					<path
						id="Pfad_101"
						data-name="Pfad 101"
						d="M608.762,310.992c-.075,1.015-8.182,1.335-16.027,1.869-8.68.59-15.112-.531-16.479-1.535a.45.45,0,0,1-.168-.2l-.241-.091c-.572-4.673-1.1-9.744-1.521-15.317a57.021,57.021,0,0,1,.849-15.208l.078-.4c3.606-18.777,22.063-13.661,22.063-13.661l.222.045c1.776.38,13.633,3.419,13.959,16.792l.019.191c.091.971.407,5.458-1.076,8.975-1.674,3.967-1.92,16.094-1.677,18.493Z"
						transform="translate(-574.157 -254.158)"
						:fill="secColor"
					/>
					<path
						id="Pfad_102"
						data-name="Pfad 102"
						d="M598.714,268.34c-21.023-1.822-33.514-12.584-33.514-12.584l.134-.123c.881-.977,1.428-1.475,1.428-1.475l.081.082c1.837,1.6,12.522,9.675,29.654,11.574l2.034,2.318Z"
						transform="translate(-552.313 -254.158)"
						:fill="secColor"
					/>
				</g>
			</g>
			<g id="LINES" transform="translate(548.176 254.158)">
				<path
					id="Pfad_103"
					data-name="Pfad 103"
					d="M611.5,392.017c-4.527,4.28-16.277,7.1-34.953,7.1a55.8,55.8,0,0,1-28.273-7.4"
					transform="translate(-548.191 -254.158)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_104"
					data-name="Pfad 104"
					d="M611.5,391.057c-4.527,4.281-16.277,7.1-34.953,7.1a55.793,55.793,0,0,1-28.273-7.4"
					transform="translate(-548.191 -254.158)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_105"
					data-name="Pfad 105"
					d="M581,312.52c-.391,3.855,2.014,10.285,2.2,14.017.193,3.783,1.909,6.814,1.938,10.6.016,2.009.418,3.771.529,5.823.089,1.615-.143,2.916.52,4.24.2.4.656.592.88,1.358a27.5,27.5,0,0,1,.714,6.395c.1,2.728-.845,5.127-.88,7.741-.08,6.053-1.177,11.5-1.939,17.4-.319,2.477-1.258,5.068-1.412,7.51-.138,2.18.183,4.527.18,6.742,0,1.815-.393,4.09.235,5.73"
					transform="translate(-557.136 -254.158)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_106"
					data-name="Pfad 106"
					d="M565.1,255.756s12.491,10.762,33.514,12.584"
					transform="translate(-552.115 -254.158)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_107"
					data-name="Pfad 107"
					d="M564.751,256.3a68.178,68.178,0,0,0,34.412,12.672"
					transform="translate(-552.311 -254.158)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_108"
					data-name="Pfad 108"
					d="M594.677,268.34l-2.217-2.526c-17.132-1.9-27.817-9.975-29.654-11.574l-.081-.082s-16.383,14.936-14.376,41.556,6.518,41.8,6.165,56.689c0,0-.353,4.661-1.762,7.307a6.88,6.88,0,0,0,.529,5.112s-.7,1.587-1.233,3.174-1.41,2.468-.352,4.231-1.342,4.407-1.342,4.407-2.47,16.4-2.149,16.4h.321s10.392,7.051,28.01,7.051,30.122-1.763,35.055-6.875c0,0-2.994-34.378-2.994-44.955s-.853-53.4-1.559-57.98-1.461-9.006-12.361-21.931"
					transform="translate(-548.176 -254.158)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_109"
					data-name="Pfad 109"
					d="M594.677,268.34l-2.217-2.526c-17.132-1.9-27.817-9.975-29.654-11.574l-.081-.082s-16.383,14.936-14.376,41.556,6.518,41.8,6.165,56.689c0,0-.353,4.661-1.762,7.307a6.88,6.88,0,0,0,.529,5.112s-.7,1.587-1.233,3.174-1.41,2.468-.352,4.231-1.342,4.407-1.342,4.407-2.47,16.4-2.149,16.4h.321s10.392,7.051,28.01,7.051,30.122-1.763,35.055-6.875c0,0-2.994-34.378-2.994-44.955s-.853-53.4-1.559-57.98-1.461-9.006-12.361-21.931"
					transform="translate(-548.176 -254.158)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_110"
					data-name="Pfad 110"
					d="M588.457,262.633a12.484,12.484,0,0,0-1.353,3.817"
					transform="translate(-563.942 -254.158)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_111"
					data-name="Pfad 111"
					d="M587.892,262.893a11.4,11.4,0,0,0-1.352,3.681"
					transform="translate(-562.813 -254.158)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_112"
					data-name="Pfad 112"
					d="M589.078,262.374a12.945,12.945,0,0,0-1.4,3.928"
					transform="translate(-565.131 -254.158)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_113"
					data-name="Pfad 113"
					d="M609.436,310.643a46.105,46.105,0,0,0-17.48-3.035c-9.846.236-16.134,2.714-14.767,3.718s7.8,2.125,16.479,1.535S611.349,311.941,609.436,310.643Z"
					transform="translate(-575.087 -254.158)"
					fill="none"
					stroke="#464749"
					stroke-width="0.216"
				/>
				<path
					id="Pfad_114"
					data-name="Pfad 114"
					d="M610.02,309.316a36.1,36.1,0,0,0-17.538-4.337c-6.476.239-13.183,2.066-15.551,4.663"
					transform="translate(-575.339 -254.158)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_115"
					data-name="Pfad 115"
					d="M610.02,308.41a36.109,36.109,0,0,0-17.538-4.336c-6.476.24-13.183,2.065-15.551,4.662"
					transform="translate(-575.339 -254.158)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_116"
					data-name="Pfad 116"
					d="M576.97,283.287s.616,5.2-1.057,9.166-1.92,16.094-1.677,18.493"
					transform="translate(-539.627 -254.158)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_117"
					data-name="Pfad 117"
					d="M574.267,266.45s13.828,2.381,14.18,16.837"
					transform="translate(-551.104 -254.158)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_118"
					data-name="Pfad 118"
					d="M610.521,266.45s-18.456-5.116-22.063,13.661"
					transform="translate(-587.358 -254.158)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1300"
					data-name="Pfad 1300"
					d="M.273.908,0,0"
					transform="translate(1.602 56.11)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<!-- <path
					id="Pfad_119"
					data-name="Pfad 119"
					d="M585.246,373.039l-1.567-.142-.891,5.036,1.691.451.363-2.761Z"
					transform="translate(-556.418 -254.158)"
					fill="#1a1818"
				/> -->
				<path id="Pfad_120" data-name="Pfad 120" d="M602.484,262.842" transform="translate(-593.337 -254.158)" fill="#1a1818" />
			</g>
			<g id="SHADING" transform="translate(550.053 259.745)">
				<path
					id="Pfad_92"
					data-name="Pfad 92"
					d="M606.421,332.679c-.13,1.585-.955,2.571-1.424,4-.648,1.97-.41,4.28-.873,6.347-.65,2.893-3.42,4.8-5.05,7.222-1.81,2.694-2.284,5.077-4.579,7.4a4.782,4.782,0,0,0-1.7,3.237c-.17,1.377.823,2.984.694,3.789.385-.62.93-.531,1.356-.969.7-.721.488-.824,1.005-1.592.751-1.123.842-2.106,2.307-2.913a8.654,8.654,0,0,1,1.15-3.321c.148-.258.906-.764,1.175-1.166.429-.648.2-1.332.6-2,.879-1.475,2.347-2.1,2.938-3.854a14.78,14.78,0,0,0,.316-3.915c3.614-.191,1.728-8.7,2.32-11.089"
					transform="translate(-589.686 -259.745)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_93"
					data-name="Pfad 93"
					d="M607.891,355.714c.31,4.543-3.8,5.271-6.616,8.044-1.865,1.834-6.587,4.039-6.132,7.064.38,2.524,2.678,2.175,4.309.813.991-.83.766-1.21,1.376-2.052.7-.974,1.708-1.371,2.382-2.11a33.938,33.938,0,0,0,3.22-3.82c.963-1.48.195-3.57,1.462-4.648"
					transform="translate(-593.27 -259.745)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_94"
					data-name="Pfad 94"
					d="M572.352,285.783s12.4,6.6,9.814,27.454-6.861,30.958-6.861,30.958-2.9,16.632-2.953,17.132-2.083,12.616-2.083,12.616l-3.662,5.982,3.662-10.61v-13.6s3.842-15.514,0-20.686-6.288-22.82-6.288-22.82,3.7-.407,4.766-.652c1.3-.3.944-.056.7-5.16,0,0,.523-8.061,1.06-11.6.257-1.69,1.1-3.127,1.41-4.412A15.868,15.868,0,0,0,572.352,285.783Z"
					transform="translate(-536.775 -259.745)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_95"
					data-name="Pfad 95"
					d="M581.134,275.823c.426,1.666,1.17,3.074,1.664,4.709a44.986,44.986,0,0,1,1.324,6.083c.4,2.539.724,5.558-.284,8.008a13.6,13.6,0,0,1-2.145,3.289c.751-2.389,1.746-4.665,1.6-7.249-.067-1.22-.543-2.4-.624-3.636a28.574,28.574,0,0,0-.375-3.611c-.233-1.2-.524-2.394-.693-3.554a17.1,17.1,0,0,1-.488-3.8"
					transform="translate(-555.866 -259.745)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_96"
					data-name="Pfad 96"
					d="M585.653,282.7a19.362,19.362,0,0,0,.353,2.831,34.829,34.829,0,0,1,.41,3.487c0,1.849-.368,3.743-.531,5.588a9.88,9.88,0,0,1-1,3.949c-.525.964-1.477,1.959-1.74,3.049-.033.135-.122.263-.16.391,4.294-3.494,4.013-10.23,4.036-15.226a13.24,13.24,0,0,0,.053-2.765,4.452,4.452,0,0,1-.5,1.631c-.3-.936-.635-1.853-.881-2.79"
					transform="translate(-560.391 -259.745)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_97"
					data-name="Pfad 97"
					d="M589.2,269.643a7.1,7.1,0,0,0,.455,3.593,12.655,12.655,0,0,1,.474,4.519c-.106,1.348-.014,2.613-.038,3.949-.022,1.286-.368,2.555-.46,3.814a19.9,19.9,0,0,1-1.3,6.74,12.47,12.47,0,0,0-.979,2.688,10.674,10.674,0,0,0-.655,2.771c1.031-.151,1.522-3.011,1.958-3.877a12.742,12.742,0,0,0,1.485-4.512,18.52,18.52,0,0,1,.819-3.673c.432-1.266.063-2.353.22-3.618a11.547,11.547,0,0,0,.209-2.893c-.129-1.136-.408-2.279-.637-3.392a58.412,58.412,0,0,0-1.626-5.783"
					transform="translate(-568.366 -259.745)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_98"
					data-name="Pfad 98"
					d="M585.527,271.178a3.3,3.3,0,0,1-.9-1.661c-.319-.679.134-.754.521-.923a8.5,8.5,0,0,1,1.988-.726,6,6,0,0,0,1.7-.441.306.306,0,0,0,.189-.312,3.416,3.416,0,0,0-1.884.041c-.783.172-1.769.162-2.548.344-.2.047-.824.414-1.381.07-.773-.478.029-1.021.074-1.153.125-.354.125-.825.253-1.2a5.814,5.814,0,0,1,.825-1.7,4.234,4.234,0,0,0,.8-2.089.5.5,0,0,0,0-.306c-.285.2-.21.734-.29,1.1a3.913,3.913,0,0,1-.382,1.03c-.373.708-.862,1.329-1.237,2.023-.127.237-.228.525-.367.765-.106.187-.3.577-.773.409a2.427,2.427,0,0,1-.358-.749,9.341,9.341,0,0,0-.5-.888c-.359-.62-.379-1.05-.219-1.321.218-.375.879-.4,1.216-.674a1.488,1.488,0,0,0,.6-.969c.043-.337-.148-.869.005-1.116a7.736,7.736,0,0,1-1.178-.541,4.032,4.032,0,0,1,.361,1.593,1.643,1.643,0,0,1-.284.9c-.12.152-.157.525-.491.489a1.486,1.486,0,0,1-.284-.922,10.279,10.279,0,0,1,.007-1.069c-.011-.263-.05-.551-.068-.809-.014-.189.076-.381-.074-.642-.417.537-.054,1.656-.088,2.5a15.889,15.889,0,0,0,.122,2.166c.036.352.141.77.149,1.106a4.575,4.575,0,0,0,.233,1.21,4.978,4.978,0,0,0,1.347,2.5,30.391,30.391,0,0,0,2.948,1.918"
					transform="translate(-559.918 -259.745)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_99"
					data-name="Pfad 99"
					d="M609.475,310.643a46.222,46.222,0,0,0-17.5-3.035c-9.857.236-16.154,2.714-14.784,3.718s7.809,2.125,16.5,1.535S611.391,311.941,609.475,310.643Z"
					transform="translate(-577.003 -259.745)"
					fill="url(#linear-gradient)"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<!-- <g id="POSITIONEN" transform="translate(565.378 288.442)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(-0.417)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<g id="SIZE_REF" transform="translate(561.381 254.442)">
				<circle
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(31.581 12)"
					fill="transparent"
				/>
				<circle
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(-0.419)"
					fill="transparent"
				/>
			</g>
			<g
				id="PRINT-AREA"
				transform="translate(548.911 271.392)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="33.9" height="35.9" fill="none" ref="print-area" />
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'ShirtRight',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
}
</script>