<template>
	<svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
		<g id="CAP_UNSTRUCTURED_SNAPBACK_FRONT" ref="main-wrapper" transform="matrix(0.525511, 0, 0, 0.525511, 0.526401, 0.491753)">
			<g id="COLORS">
				<path
					:fill="mainColor"
					d="M204.91,171.53s-.09-2.09-.24-4.29a42.43,42.43,0,0,0-2.4-9,32.61,32.61,0,0,1-2.06-5.72c-.44-2-1.49-7.59-1.78-9.55s-2.26-7.1-5.09-14.37S189,120,189,120c-6.71-7.83-14.88-8.78-24.86-10.54a143.56,143.56,0,0,0-16.71-2,3.72,3.72,0,0,1-.59.2,1.24,1.24,0,0,0,.58-1.18c0-1.42-1.5-2.19-5.95-2.19s-5.22,1-5.22,2,.29,1.07.29,1.07a.28.28,0,0,0,.06.11l-.72-.16c-3.6.24-9.72,1.27-12.79,1.64s-8,1.15-8,1.15a86.81,86.81,0,0,0-13.1,4c-5.67,3.31-7,5.72-11.33,11.88s-5,9.59-6.29,15.3-.73,11.39-.58,16.74a12.61,12.61,0,0,1-2.87,9.1,4.09,4.09,0,0,1-2.16,1.56c-.24.12-.33.61-.48.81a9.41,9.41,0,0,1-1.2.77,4.75,4.75,0,0,0-1.78,2.58c-.48,2.2.14,4.17,5.09,5.39s21.22,1.06,22.27,1.06,23.19-.49,27.22-.57S161,179,161,179l36.58-.33c9-.47,10.32-2.7,10.71-4S204.91,171.53,204.91,171.53Z"
				/>
			</g>
			<g id="LINES">
				<path
					id="Pfad_3445"
					data-name="Pfad 3445"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M201.51,171.34c.5.91.52,2.64-6.54,2.64-6.88,0-44.26-.57-50.21-.52s-32.18.56-36.82.57-27.94,1.54-27.21-3.6a1.46,1.46,0,0,1,.37-.94"
				/>
				<path
					id="Pfad_3446"
					data-name="Pfad 3446"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M203.21,171.88s3.58,3.19-6.28,3.19c-7.08,0-45.48-.57-51.59-.52s-33.06.56-37.83.57c-4.35,0-24.64,1.12-27.69-2.55-.31-.37-.91-2.38.11-3.07"
				/>
				<path
					id="Pfad_3447"
					data-name="Pfad 3447"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M199.53,171.39c-.32.8-1.8,1.59-6.45,1.59-6.69,0-42.94-.56-48.71-.51s-31.2.56-35.71.56S80,174.25,82.8,169.66"
				/>
				<path
					id="Pfad_3448"
					data-name="Pfad 3448"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M195.7,171.33a14.79,14.79,0,0,1-4.87.57c-6.41,0-41.21-.56-46.74-.51s-29.95.56-34.28.56c-3.83,0-22.56,1-24.82-2"
				/>
				<path
					id="Pfad_3449"
					data-name="Pfad 3449"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M75.25,172.79A4.75,4.75,0,0,1,77,170.21a9.41,9.41,0,0,0,1.2-.77c.15-.2.24-.69.48-.81a4.09,4.09,0,0,0,2.16-1.56,12.61,12.61,0,0,0,2.87-9.1c-.15-5.35-.67-11,.58-16.74s2-9.14,6.29-15.3,5.66-8.57,11.33-11.88a86.81,86.81,0,0,1,13.1-4s4.95-.77,8-1.15,9.19-1.4,12.79-1.64a20.71,20.71,0,0,0,5.5.66c2.83-.08,4.7.09,6.05-.49a143.56,143.56,0,0,1,16.71,2c10,1.76,18.15,2.71,24.86,10.54,0,0,1.54,1.39,4.37,8.65s4.8,12.41,5.09,14.37,1.34,7.59,1.78,9.55a32.61,32.61,0,0,0,2.06,5.72,42.43,42.43,0,0,1,2.4,9c.15,2.2.24,4.29.24,4.29s3.79,1.92,3.41,3.18-1.74,3.49-10.71,4L161,179s-27.17-.41-31.2-.33-26.17.57-27.22.57-17.33.16-22.27-1.06S74.77,175,75.25,172.79Z"
				/>
				<path
					id="Pfad_3450"
					data-name="Pfad 3450"
					class="cls-4"
					d="M136.51,107.27s-.29,0-.29-1.07.76-2,5.22-2,5.95.77,5.95,2.19c0,1.85-2,1.41-3.41,1.43-.79,0-1.94,0-3,0-.87,0-2.06-.06-2.6,0C136.73,107.94,136.51,107.27,136.51,107.27Z"
				/>
				<path
					id="Pfad_3451"
					data-name="Pfad 3451"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M141,107.87A50.06,50.06,0,0,1,142,119.31c0,7.39-.91,51-.91,51"
				/>
				<path
					id="Pfad_3452"
					data-name="Pfad 3452"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M86.15,170a1.31,1.31,0,0,1-.43-1.14c.09-.7.48-.17,1-1.59a29.86,29.86,0,0,0,2.74-8.09c.48-3.71,1.73-16.69,1.73-16.69s1-11.19,1.54-12.82a30.27,30.27,0,0,1,4.51-9.31,17.57,17.57,0,0,1,9.41-6.37,80.63,80.63,0,0,1,12.77-3.1c5-.65,13.74-1.45,18.42-3.3"
				/>
				<path
					id="Pfad_3453"
					data-name="Pfad 3453"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M144.38,107.84a40.77,40.77,0,0,0,7.06,1.67c3.07.32,19.15,1.63,25.1,4s11.05,5.3,13.59,12.08a43,43,0,0,1,2.25,14.74,156.21,156.21,0,0,0,1.54,18.57c1.25,6.82,1.73,8.66,3,10.82a.92.92,0,0,1-.09,1.45,1.14,1.14,0,0,1-.44.22"
				/>
				<path
					id="Pfad_3454"
					data-name="Pfad 3454"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M197.52,171.4s1.54-.29.86-2.33a56.63,56.63,0,0,1-2.44-10.86c-.91-6.16-1.44-12.41-1.78-18.78s-2.16-14.61-4-17.73"
				/>
				<path
					id="Pfad_3455"
					data-name="Pfad 3455"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M142.08,107.87a79.71,79.71,0,0,0,14.12,2.62c7.75.71,19.06,1.47,25.48,6.32S190.77,127.15,191,138l.19,10.89A88.1,88.1,0,0,0,194,166.67s1.6,4.24,0,4.73"
				/>
				<path
					id="Pfad_3456"
					data-name="Pfad 3456"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M204.84,170.08l-3,.26a7.31,7.31,0,0,1-3.28-.79c-1.14-.76-2.67.17-6.32,0s-51.09-1-51.09-1H118s-22.59,1-31.71-.42a10.28,10.28,0,0,0-2.6,0c-1.15.2-1.86.83-3.75-.08"
				/>
				<path
					id="Pfad_3457"
					data-name="Pfad 3457"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M138.75,107.73a107.82,107.82,0,0,1-13.65,2.76c-6.92.87-16.58,2.07-23.17,6.75s-7.62,12.47-8.26,15.84-2.18,24-2.82,27a22.2,22.2,0,0,1-2.62,7.41c-1.15,1.68-1.28,1.92-.51,2.43"
				/>
				<path
					id="Pfad_3458"
					data-name="Pfad 3458"
					class="cls-5"
					d="M116.91,115.86s-1.63.29-1.93,1.06.93,1,1.71.77,2.88-1,2-1.74C118.74,116,118.5,115.57,116.91,115.86Z"
				/>
				<path
					id="Pfad_3459"
					data-name="Pfad 3459"
					class="cls-5"
					d="M165.4,115.34s-1.42.37-.21,1.1a4.85,4.85,0,0,0,3.79.89c1.08-.41.09-1.25-.47-1.51A5.59,5.59,0,0,0,165.4,115.34Z"
				/>
				<path
					id="Pfad_3460"
					data-name="Pfad 3460"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M144,108.38s1.34,3.26,1.22,14.74-.82,47.22-.82,47.22"
				/>
				<path
					id="Pfad_3461"
					data-name="Pfad 3461"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M137.84,170l.9-37.76s.5-19.83-.9-24.23"
				/>
				<path
					id="Pfad_3462"
					data-name="Pfad 3462"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M78.71,168.63s0,.87,1.22.87,4.65.47,6.22.47,10.91,1.05,15.58.89,28.06-.55,39.38-.52,37.94.54,37.94.54,16.45.47,17.31.52,2,0,3.18,0,4.71-.24,5.37.13"
				/>
				<path
					id="Pfad_3463"
					data-name="Pfad 3463"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M94.16,120.91a111,111,0,0,0-5.32,26c-1.06,14-2,16.61-3.13,18.86s-2.1,3.36-1.5,4.09"
				/>
				<path
					id="Pfad_3464"
					data-name="Pfad 3464"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M75.52,172.05c-.8,5.93,24.38,4.8,29.43,4.79s32.84-.43,39.31-.3c7.29.15,47.81,0,55.31,0,10.44,0,6.94-4.08,6.94-4.08"
				/>
			</g>
			<g
				id="PRINT-AREA"
				transform="matrix(1, 0, 0, 1, 110.839218, 124.971809)"
				ref="print-area-wrapper"
				fill="none"
				stroke="#000"
				:opacity="currentStep === 'motive' ? 1 : 0"
				stroke-dasharray="4 4"
				stroke-width="0.4"
			>
				<rect ref="print-area" width="63" height="31.5" />
			</g>
			<g id="POSITIONEN" ref="position-wrapper" transform="matrix(1, 0, 0, 1, 141.083618, 139.480713)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import { setPositions } from '../mockupHelpers'

export default {
	name: 'CapUnstructuredSnapbackFront',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
	mounted() {
		setPositions(this, 0, true)
	},
}
</script>