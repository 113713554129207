<template>
	<div id="back-mockup" ref="svgContainer" :style="mockupWidth && { width: mockupWidth, height: mockupHeight }">
		<div
			v-for="(finish, idx) in filteredFinishes"
			:key="idx + '_filtered'"
			class="finish-wrapper"
			:style="isMounted && getFinishPosition(finish, 'finish_' + idx)"
			:ref="'finish_' + idx"
		>
			<img :src="$getFileUrlById(finish.fileName)" />
		</div>

		<DragFinish
			v-for="(finish, idx) in customFinishes"
			:key="idx + '_custom'"
			:finish="finish"
			:image="$getFileUrlById(finish.fileName)"
			:width="parseFloat(finish.size.width)"
			:height="finish.size.height"
			:initX="finish.coords.x || 0"
			:initY="finish.coords.y || 0"
			:mockupSide="mockupSide"
			:mockupType="mockupType"
			:style="cssVars"
		/>

		<ShirtBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'shirt' || mockupType === 'shirt-pocket' || mockupType === 'shirt-round-neck'"
			:style="cssVars"
		/>

		<HoodieBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'hoodie' || mockupType === 'hoodie-bag' || mockupType === 'hoodie-zipper'"
			:style="cssVars"
		/>

		<LongsleeveBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'longsleeve' || mockupType === 'longsleeve-pocket'"
			:style="cssVars"
		/>

		<CrewneckBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'crewneck'"
			:style="cssVars"
		/>

		<PoloBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'polo'"
			:style="cssVars"
		/>

		<CapBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap'"
			:style="cssVars"
		/>

		<CapStrapBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-strap'"
			:style="cssVars"
		/>

		<CapCurvedSnapbackBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-curved-snapback'"
			:style="cssVars"
		/>

		<CapDadBeltBuckleBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-dad-belt-buckle'"
			:style="cssVars"
		/>

		<CapDadBuckleBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-dad'"
			:style="cssVars"
		/>

		<CapFlatSnapbackBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-flat-snapback'"
			:style="cssVars"
		/>

		<CapUnstructuredSnapbackBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'cap-unstructured-snapback'"
			:style="cssVars"
		/>

		<OtherBack
			ref="mockupSvg"
			:currentStep="currentStep"
			:mainColor="mainColor"
			:secColor="secColor"
			:mockupWidth="mockupWidth"
			:mockupHeight="mockupHeight"
			:hideBlockedAreas="hideBlockedAreas"
			v-if="mockupType === 'custom'"
			:style="cssVars"
		/>
	</div>
</template>

<script>
import svgComponentData from './svgComponentData'
import ShirtBack from './textiles/shirt/ShirtBack'
import HoodieBack from './textiles/hoodie/HoodieBack'
import LongsleeveBack from './textiles/longsleeve/LongsleeveBack'
import CrewneckBack from './textiles/crewneck/CrewneckBack'
import PoloBack from './textiles/polo/PoloBack'
import CapBack from './textiles/cap/CapBack'
import CapStrapBack from './textiles/cap-strap/CapStrapBack'
import CapCurvedSnapbackBack from './textiles/cap-curved-snapback/CapCurvedSnapbackBack'
import CapDadBeltBuckleBack from './textiles/cap-dad/CapDadBeltBuckleBack'
import CapDadBuckleBack from './textiles/cap-dad/CapDadBuckleBack'
import CapFlatSnapbackBack from './textiles/cap-flat-snapback/CapFlatSnapbackBack'
import CapUnstructuredSnapbackBack from './textiles/cap-unstructured-snapback/CapUnstructuredSnapbackBack'
import OtherBack from './textiles/other/OtherBack'

import DragFinish from './DragFinish'

const componentData = { ...svgComponentData }

componentData.name = 'SVGBack'
componentData.components = {
	ShirtBack,
	HoodieBack,
	LongsleeveBack,
	CrewneckBack,
	PoloBack,
	CapBack,
	CapStrapBack,
	CapCurvedSnapbackBack,
	CapDadBeltBuckleBack,
	CapDadBuckleBack,
	CapFlatSnapbackBack,
	CapUnstructuredSnapbackBack,
	OtherBack,
	DragFinish,
}

export default componentData
</script>

<style scoped lang="sass">
#back-mockup
	position: relative

.finish-wrapper
	position: absolute
	z-index: 10

	img
		width: 100%
		height: 100%

	&[data-position=""]
		display: none
</style>

<style lang="sass">
#back-mockup
	#PRINT-AREA
		stroke: var(--color) !important
	#POSITIONEN
		#center, #left, #right
			stroke: var(--color) !important
			opacity: var(--dotOpacity) !important
	#drag-finish
		.image-element
			outline-color: var(--color) !important
		.resize-btn
			background: var(--color) !important
			i
				color: var(--invertColor) !important
</style>
