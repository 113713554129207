<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<g id="HOODIE_RIGHT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -817.817627, -554.961975)">
			<g id="COLORS" transform="translate(861.368 560.311)">
				<path
					id="Pfad_2205"
					data-name="Pfad 2205"
					d="M1483.311,604.2a52.709,52.709,0,0,1-2.5,18.651c-4.392,4.544-18.405,4.785-24.563,4.785a62.432,62.432,0,0,1-8.222-.533,53.083,53.083,0,0,1-9.258-2.055,30.767,30.767,0,0,1-5.648-2.305,4.832,4.832,0,0,1-.457-.391,4.289,4.289,0,0,1-.914-2.372,7.447,7.447,0,0,1,.056-1.014c.089-.426.187-.851.28-1.276a3.927,3.927,0,0,0,.131-.993c-.012-.312-.16-.6-.175-.908a6.188,6.188,0,0,1,.082-.773q.078-.954.163-1.907c.093-1.035.19-2.069.318-3.1a5.831,5.831,0,0,1,.3-1.637c.3-.651.86-1.211.909-1.925a3.474,3.474,0,0,0-.418-1.511c-.536-1.307-.229-2.194-.058-3.475a29.787,29.787,0,0,0,.042-4.014c-.009-1.47-.032-2.942-.092-4.412-.581-14.059-1.3-32.42-1.3-32.42s-.77-12.192-.562-15.819.764-13.048.408-15.164-.278-4.516-.443-6.6.95-10.078,2.728-14.632,3.244-10.924,10.631-13.472,16.056,1.485,18.928,3.454,8.958,6.852,9.582,9.759,8.339,14.255,8.443,24.582-1.429,16.355-1.272,19.479.835,15.869,1.044,20.353.991,16.121.991,18.992Z"
					transform="translate(-1420.491 -488.311)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_2206"
					data-name="Pfad 2206"
					d="M1455.555,524.922a5.826,5.826,0,0,1-2.707-.069,18.51,18.51,0,0,1-1.756-.99c-.408-.31-.649-1.5-1.142-3.1a19.251,19.251,0,0,1-.762-3.9c-.082-.887-.232-.7-.505-1.493s0,0-.023-.114-.251-2-.311-2.909-.135-.756-.259-.976-.816-1.9-1.222-3a4.838,4.838,0,0,0-.964-1.594,4.469,4.469,0,0,1-.354-2.135,40.6,40.6,0,0,0-.728-5.263c-.417-2.164.728-2.9.728-2.9a3.046,3.046,0,0,1,.652-1.505s.212-1.707,1.034-1.82,1.439.113,1.777,0a12.132,12.132,0,0,1,2.313-.68,25.7,25.7,0,0,0,3.073-.251,19.135,19.135,0,0,1,2.433-.392c.228-.037,1.207-.415,1.4-.415a3.858,3.858,0,0,1,1.266-.151c.417.113.625.321.991-.029a3.185,3.185,0,0,1,1.892-.727,5.314,5.314,0,0,0,1.957-.391,23.455,23.455,0,0,1,3.57-.969c1.479-.34,4.271-1,4.734-.8s4.651.272,5.16.385a27.528,27.528,0,0,1,4.485,1.209c1.347.6,3.773,1.36,4.791,2.267a33.667,33.667,0,0,1,3.209,3.287c.47.643.9,1.587,1.253,1.927s1.252,1.247,1.487,1.549.783.6.9.983.47.68.391.944a3.027,3.027,0,0,0,.011,1.285s-.6-.3-.716,1.1l-4-3.711s-3.2-1.692-4.529-2.335-6.341-2.682-7.046-2.909-5.576-1-8.508.6a29.987,29.987,0,0,1-3.578,1.667,11.238,11.238,0,0,0-4.142,4.12,43.857,43.857,0,0,0-3.4,7.685s-1.863,6.672-2.056,7.882-.48,2.267-.454,2.644a10.1,10.1,0,0,1-.205,2.76A16.856,16.856,0,0,0,1455.555,524.922Z"
					transform="translate(-1444.731 -488.311)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2207"
					data-name="Pfad 2207"
					d="M1450.234,531.479c.22-3.635,2.2-10.211,1.776-13.441s-.567-4.783.172-6.459c.637-1.447,7.04-12.608,16.87-12.221a22.726,22.726,0,0,1,5.056.6,23.32,23.32,0,0,1,3.606,1.646,11.9,11.9,0,0,1,6.436,5.968c2.66,6.069-3.344,32.132-3.362,33.853s-.751,3.4-.484,5.1a14.386,14.386,0,0,1-.181,4.06c-.055.611-.375,14.452.033,20.835.367,5.744-.383,11.49-.91,12.909s-1.664,4.241-.517,6.542,2.4,4.6,2.006,7.128-.547,4.243-1.313,5.717.029,1.562-.68,2.581-1.921,1.138-1.789,2.346.578,1.4.589,2.007-.325.308.128,1.006.681,1,.467,1.405a6.974,6.974,0,0,1-1.076,1.932c-.383.276.282.982.362,1.779l-.417.854c-.444.62-6.381,2.061-11.1,2.472-5.178.452-5.821-.374-5.821-.374a5.875,5.875,0,0,0-.444-1.7,9.766,9.766,0,0,0-1.209-1.847c-.452-.6.406-1.922-.047-2.621a9.751,9.751,0,0,0-1.693-1.991c-.673-.6-2.809-1.268-2.374-2.384a7.835,7.835,0,0,0,.845-2.835c-.016-.908-.935-1.4-.937-2.255s-.129-5.492-.32-14.16-1.03-21.168-1.116-25.73.939-6.664-.191-14.165S1450.013,535.114,1450.234,531.479Z"
					transform="translate(-1440.733 -488.311)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2208"
					data-name="Pfad 2208"
					d="M1439.291,524.888a56.134,56.134,0,0,1-6.311-.756c.119,1.543.267,3.472.238,4.843.107,1.392.229,2.976.351,4.565l.733,9.522,2.677,2.676h2.747a81.524,81.524,0,0,0,.709-10.652c.078-6.654-1.144-10.186-1.144-10.186"
					transform="translate(-1379.131 -488.311)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2209"
					data-name="Pfad 2209"
					d="M1436.739,501.125s4.446,3.3,4.8,5.869a53.631,53.631,0,0,0,3.3,10.85,22.806,22.806,0,0,1-2.987-5.446c-.783-2.494-3.573-8.552-5.112-10.215C1436.739,502.183,1436.516,501.276,1436.739,501.125Z"
					transform="translate(-1387.194 -488.311)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2210"
					data-name="Pfad 2210"
					d="M1433.785,503.279s3.361,2.129,3.792,4.169a42.236,42.236,0,0,0,2.792,7.865c1.409,3.173,3.053,8.2,3.444,9.068a20.277,20.277,0,0,0,.94,2.645c.352.377.7-.6.7-.6a16.367,16.367,0,0,0-1.018-3.665c-1.018-2.985-2.975-7.972-3.6-9.748s-1.538-3.536-2.047-5.323-2.439-4.028-4.29-5.5C1434.5,502.183,1433.9,501.881,1433.785,503.279Z"
					transform="translate(-1384.956 -488.311)"
					:fill="secColor"
				/>
			</g>
			<g id="LINES" transform="translate(861.368 560.311)">
				<path
					id="Pfad_2211"
					data-name="Pfad 2211"
					d="M1459.865,618.317c.042,1.4.181,2.841.18,4.22,0,1.614-.35,3.636.208,5.094"
					transform="translate(-1425.831 -488.311)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_2212"
					data-name="Pfad 2212"
					d="M1480.884,526.724a27.091,27.091,0,0,1,.8,5.994c.105,10.328-1.429,16.355-1.272,19.479s.835,15.869,1.044,20.353.991,16.121.991,18.992l.852,12.653a52.709,52.709,0,0,1-2.5,18.651c-4.392,4.544-18.405,4.785-24.563,4.785a62.432,62.432,0,0,1-8.222-.533,53.083,53.083,0,0,1-9.258-2.055,30.767,30.767,0,0,1-5.648-2.305,4.832,4.832,0,0,1-.457-.391,4.289,4.289,0,0,1-.914-2.372,7.447,7.447,0,0,1,.056-1.014c.089-.426.187-.851.28-1.276a3.927,3.927,0,0,0,.131-.993c-.012-.312-.16-.6-.175-.908a6.188,6.188,0,0,1,.082-.773q.078-.954.163-1.907c.093-1.035.19-2.069.318-3.1a5.831,5.831,0,0,1,.3-1.637c.3-.651.86-1.211.909-1.925a3.474,3.474,0,0,0-.418-1.511c-.536-1.307-.229-2.194-.058-3.475a29.787,29.787,0,0,0,.042-4.014c-.009-1.47-.032-2.942-.092-4.412-.581-14.059-1.3-32.42-1.3-32.42s-.77-12.192-.562-15.819l.02-.333"
					transform="translate(-1420.476 -488.311)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_2213"
					data-name="Pfad 2213"
					d="M1433.327,618.531a3.96,3.96,0,0,0,.765,2.286,4.832,4.832,0,0,0,.457.391,30.84,30.84,0,0,0,5.648,2.3,53.083,53.083,0,0,0,9.258,2.055,62.432,62.432,0,0,0,8.222.533c6.158,0,20.171-.241,24.563-4.785,0,0,.6-.454.644-.608"
					transform="translate(-1421.919 -488.311)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.06"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_2214"
					data-name="Pfad 2214"
					d="M1432.925,618.316a3.611,3.611,0,0,0,.72,1.344,4.824,4.824,0,0,0,.457.391,30.771,30.771,0,0,0,5.648,2.3,53.083,53.083,0,0,0,9.258,2.055,62.432,62.432,0,0,0,8.222.533c6.158,0,20.171-.241,24.563-4.785,0,0,.971-.7,1.042-.99"
					transform="translate(-1421.472 -488.311)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.06"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_2215"
					data-name="Pfad 2215"
					d="M1455.323,524.912a4.878,4.878,0,0,1-2.474-.059,18.51,18.51,0,0,1-1.756-.99c-.408-.31-.649-1.5-1.142-3.1a19.251,19.251,0,0,1-.762-3.9c-.082-.887-.232-.7-.505-1.493s0,0-.023-.114-.251-2-.311-2.909-.135-.756-.259-.976-.816-1.9-1.222-3a4.838,4.838,0,0,0-.964-1.594,4.469,4.469,0,0,1-.354-2.135,40.6,40.6,0,0,0-.728-5.263c-.417-2.164.728-2.9.728-2.9a3.046,3.046,0,0,1,.652-1.505s.212-1.707,1.034-1.82,1.439.113,1.777,0a12.132,12.132,0,0,1,2.313-.68,25.7,25.7,0,0,0,3.073-.251,19.135,19.135,0,0,1,2.433-.392c.228-.037,1.207-.415,1.4-.415a3.858,3.858,0,0,1,1.266-.151c.417.113.625.321.991-.029a3.185,3.185,0,0,1,1.892-.727,5.314,5.314,0,0,0,1.957-.391,23.455,23.455,0,0,1,3.57-.969c1.479-.34,4.271-1,4.734-.8s4.651.272,5.16.385a27.528,27.528,0,0,1,4.485,1.209c1.347.6,3.773,1.36,4.791,2.267a33.667,33.667,0,0,1,3.209,3.287c.47.643.9,1.587,1.253,1.927s1.252,1.247,1.487,1.549.783.6.9.983.47.68.391.944a3.027,3.027,0,0,0,.011,1.285s-.6-.3-.716,1.1l-4-3.711s-3.2-1.692-4.529-2.335-6.312-2.535-7.016-2.762-5.073-1-8.123.277a25.052,25.052,0,0,0-3.785,1.719,11.949,11.949,0,0,0-4.349,4.243,40.248,40.248,0,0,0-3.169,7.01c-.15.423-2.171,2.662-2.75,4.729-.453,2.152.165,6.14.192,6.518s-.159,2.394-.223,2.781C1455.68,522.851,1455.323,524.912,1455.323,524.912Z"
					transform="translate(-1444.731 -488.311)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_2216"
					data-name="Pfad 2216"
					d="M1479.93,494.973a14.884,14.884,0,0,0,3.757.182,48.488,48.488,0,0,1,4.815-.982c.43,0,3.885.189,4.467-.227"
					transform="translate(-1478.599 -488.311)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.084"
					stroke-dasharray="0.672 0.336"
				/>
				<path
					id="Pfad_2217"
					data-name="Pfad 2217"
					d="M1447.088,490.2s5.964-1,9.76-.078,7.359,1.751,9.394,3.829,3.849,4.556,4.032,6.172"
					transform="translate(-1423.066 -488.311)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.084"
					stroke-dasharray="0.672 0.336"
				/>
				<path
					id="Pfad_2218"
					data-name="Pfad 2218"
					d="M1448.261,490.583s8.792-.918,13.346.34,8.611,3.439,10.02,8.313"
					transform="translate(-1425.592 -488.311)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.084"
					stroke-dasharray="0.672 0.336"
				/>
				<path
					id="Pfad_2219"
					data-name="Pfad 2219"
					d="M1481.187,496.478s3.368.68,4.66.34,5.088-1.193,5.792-1.579,1.84-.839,1.84-.839"
					transform="translate(-1480.368 -488.311)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.084"
					stroke-dasharray="0.672 0.336"
				/>
				<path
					id="Pfad_2220"
					data-name="Pfad 2220"
					d="M1433.786,526.725l-.529.339.665,2.645.391-.227-.528-2.757"
					transform="translate(-1373.285 -488.311)"
					fill="#464749"
				/>
				<path
					id="Pfad_2221"
					data-name="Pfad 2221"
					d="M1477.423,501.482a12.046,12.046,0,0,1,6.726,6.09c2.66,6.069-3.344,32.132-3.362,33.853s-.751,3.4-.484,5.1a14.424,14.424,0,0,1-.181,4.06c-.055.612-.375,14.452.033,20.835.367,5.744-.383,11.49-.91,12.909s-1.664,4.241-.517,6.543,2.4,4.6,2.006,7.127-.547,4.243-1.312,5.717.028,1.562-.681,2.581-1.921,1.138-1.789,2.346.578,1.4.589,2.007-.325.308.128,1.007.681,1,.467,1.4-1.2,1.864-1.077,2.467.8,1.8-.291,2.223-8.567,2.462-12.432,2.42-4.3.165-4.435-1.143a5.659,5.659,0,0,0-1.467-2.846c-.452-.6.406-1.922-.047-2.621a9.751,9.751,0,0,0-1.693-1.991c-.673-.6-2.809-1.268-2.374-2.384a7.835,7.835,0,0,0,.845-2.835c-.016-.907-.935-1.4-.937-2.255s-.129-5.492-.32-14.16-1.03-21.168-1.116-25.73.939-6.664-.191-14.165-2.587-14.922-2.366-18.557,2.2-10.211,1.776-13.441-.567-4.783.172-6.459c.651-1.477,7.3-13.067,17.475-12.183a24.3,24.3,0,0,1,4.411.677A26.862,26.862,0,0,1,1477.423,501.482Z"
					transform="translate(-1440.733 -488.311)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_2222"
					data-name="Pfad 2222"
					d="M1457.912,618.4s9.282.3,17.347-2.636"
					transform="translate(-1438.875 -488.311)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.06"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_2223"
					data-name="Pfad 2223"
					d="M1457.912,617.621s9.6.371,17.669-2.56"
					transform="translate(-1439.197 -488.311)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.06"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_2224"
					data-name="Pfad 2224"
					d="M1436.739,501.125s4.446,3.3,4.8,5.869a53.631,53.631,0,0,0,3.3,10.85,22.806,22.806,0,0,1-2.987-5.446c-.783-2.494-3.573-8.552-5.112-10.215C1436.739,502.183,1436.516,501.276,1436.739,501.125Z"
					transform="translate(-1387.194 -488.311)"
					fill="#fff"
					stroke="#464749"
					stroke-width="0.172"
				/>
				<path
					id="Pfad_2225"
					data-name="Pfad 2225"
					d="M1436.739,501.125s4.446,3.3,4.8,5.869a53.631,53.631,0,0,0,3.3,10.85,22.806,22.806,0,0,1-2.987-5.446c-.783-2.494-3.573-8.552-5.112-10.215C1436.739,502.183,1436.516,501.276,1436.739,501.125Z"
					transform="translate(-1387.194 -488.311)"
					fill="none"
					stroke="#464749"
					stroke-width="0.172"
				/>
				<path
					id="Pfad_2226"
					data-name="Pfad 2226"
					d="M1433.785,503.279s3.361,2.129,3.792,4.169a42.236,42.236,0,0,0,2.792,7.865c1.409,3.173,3.053,8.2,3.444,9.068a20.277,20.277,0,0,0,.94,2.645c.352.377.7-.6.7-.6a16.367,16.367,0,0,0-1.018-3.665c-1.018-2.985-2.975-7.972-3.6-9.748s-1.538-3.536-2.047-5.323-2.439-4.028-4.29-5.5C1434.5,502.183,1433.9,501.881,1433.785,503.279Z"
					transform="translate(-1384.956 -488.311)"
					fill="#fff"
				/>
				<path
					id="Pfad_2227"
					data-name="Pfad 2227"
					d="M1433.785,503.279s3.361,2.129,3.792,4.169a42.236,42.236,0,0,0,2.792,7.865c1.409,3.173,3.053,8.2,3.444,9.068a20.277,20.277,0,0,0,.94,2.645c.352.377.7-.6.7-.6a16.367,16.367,0,0,0-1.018-3.665c-1.018-2.985-2.975-7.972-3.6-9.748s-1.538-3.536-2.047-5.323-2.439-4.028-4.29-5.5C1434.5,502.183,1433.9,501.881,1433.785,503.279Z"
					transform="translate(-1384.956 -488.311)"
					fill="none"
					stroke="#464749"
					stroke-width="0.172"
				/>
				<line
					id="Linie_443"
					data-name="Linie 443"
					y1="5.52"
					x2="0.868"
					transform="translate(30.818 5.749)"
					fill="none"
					stroke="#464749"
					stroke-width="0.179"
				/>
				<line
					id="Linie_444"
					data-name="Linie 444"
					y1="5.52"
					x2="0.868"
					transform="translate(30.246 5.678)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.063"
					stroke-dasharray="0.505 0.253"
				/>
				<line
					id="Linie_445"
					data-name="Linie 445"
					y1="5.52"
					x2="0.868"
					transform="translate(31.44 5.749)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.063"
					stroke-dasharray="0.505 0.253"
				/>
			</g>
			<g id="SHADING" transform="translate(861.368 562.9)">
				<path
					id="Pfad_2228"
					data-name="Pfad 2228"
					d="M1489.473,512.63a1.766,1.766,0,0,0,.635.89c.17.126.374.235.459.425a.95.95,0,0,1-.02.611,7.328,7.328,0,0,0-.21,1.836,3.838,3.838,0,0,1-.77-2.252q-.066-.84-.134-1.679"
					transform="translate(-1485.999 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2229"
					data-name="Pfad 2229"
					d="M1484.648,521.218a7.269,7.269,0,0,0-.427,1.844c-.021.213-.008.473.185.576s.432-.025.612-.154a2.468,2.468,0,0,0,.853-.884,2.845,2.845,0,0,0,.149-1.264,24.142,24.142,0,0,1,.737-5.408c-.519.97-.414,2.255-1.227,3.015l-.092-.308-.571,1.68a1.048,1.048,0,0,1-.416.635c-.335.182-.794-.012-1.121.181a.85.85,0,0,0-.343.542,2.618,2.618,0,0,0,.86,2.635c.205-.329-.1-.718-.266-1.067a1.587,1.587,0,0,1,.682-2.015c.143-.077.386-.089.41.067"
					transform="translate(-1475.609 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2230"
					data-name="Pfad 2230"
					d="M1476.4,497.08a.339.339,0,0,1,.3-.341c.1-.009.193.023.29.015s.2-.087.178-.178c-.017-.065-.088-.1-.152-.125-.3-.12-.665-.232-.908-.031a.891.891,0,0,0-.207.3c-.661,1.28-1.8,2.337-2.167,3.723a1.3,1.3,0,0,0,.709-.682,3.951,3.951,0,0,1,.509-.858,4.176,4.176,0,0,1,.785-.561,1.267,1.267,0,0,0,.6-.72,2.43,2.43,0,0,0,.67-.156c.091-.036.2-.1.187-.2-.01-.122-.188-.149-.31-.115s-.265.088-.366.013-.145-.3-.278-.263"
					transform="translate(-1456.886 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2231"
					data-name="Pfad 2231"
					d="M1453.881,495.3l3.966-1.487a28.352,28.352,0,0,1,2.984-.994,19.212,19.212,0,0,1,7.223-.268,57.094,57.094,0,0,1,7.087,1.557,4.057,4.057,0,0,1-1.477-.26,18.743,18.743,0,0,0-3.748-.691,6.709,6.709,0,0,1,2.336,1.35,12.386,12.386,0,0,0-6.068-1.694,21.33,21.33,0,0,0-3.891.019,20.839,20.839,0,0,0-4.305,1.185q-1.973.7-3.94,1.421l-.337-.25"
					transform="translate(-1434.802 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2232"
					data-name="Pfad 2232"
					d="M1464.227,578.138l2.34-1.184a2.118,2.118,0,0,0,.7-.485.756.756,0,0,0,.141-.808,8.893,8.893,0,0,0-2.378.9c-1.861.794-4.007.889-5.683,2.04-.935.642-1.744,1.616-2.858,1.76a1.26,1.26,0,0,1,.55-.853c2.739-2.365,5.884-4.433,9.417-4.98a1.827,1.827,0,0,1,1.871.488,1.365,1.365,0,0,1-.127,1.491,3.056,3.056,0,0,1-1.258.913,9.628,9.628,0,0,1-2.9.81"
					transform="translate(-1430.948 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2233"
					data-name="Pfad 2233"
					d="M1469.787,569.738a6.625,6.625,0,0,0-4.512.289.358.358,0,0,0-.225.2c-.058.2.208.321.41.348l2.775.37a2.066,2.066,0,0,0,1.2-.08.763.763,0,0,0,.372-1.033c-.17-.243-.557-.44-.432-.712"
					transform="translate(-1440.901 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2234"
					data-name="Pfad 2234"
					d="M1474.466,568.388q2.9-1.11,5.866-2.115c-.873-.588-2.615-.5-2.734-1.4-.062-.472.448-.837.84-1.185s.68-.932.242-1.257c-.615-.455-1.431.258-2.133.6a5.3,5.3,0,0,1-3.307-.056c-1.1-.237-2.446-.36-3.2.308l5.389.428a2.565,2.565,0,0,1,1.309.342.531.531,0,0,1-.036.945l-8.1-.408a9.05,9.05,0,0,0-1.976.043c-1.405.238-2.579,1.131-4.02,1.155-1.033.018-2.025-.423-3.058-.411a3.445,3.445,0,0,0-3.29,2.654,6.832,6.832,0,0,0,.829,4.011c.46-.992.206-2.1.319-3.163a2.468,2.468,0,0,1,2.184-2.322,9.311,9.311,0,0,1,1.745.247,8.888,8.888,0,0,0,2.509-.146,97.825,97.825,0,0,1,11.629-1.042c.571-.018,1.307.063,1.455.508.179.537-.68.859-1.342,1s-1.461.628-1.09,1.1c.2.3.915-.007.7-.3"
					transform="translate(-1442.46 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2235"
					data-name="Pfad 2235"
					d="M1476.841,499.011c.214.214-.018.6-.319.664a1.577,1.577,0,0,1-.886-.2,6.427,6.427,0,0,0-2.3-.478.749.749,0,0,0-.445.089.48.48,0,0,0-.183.278,1.263,1.263,0,0,0,.516,1.14,1.977,1.977,0,0,1,.744,1.058,2.1,2.1,0,0,1-.27,1.016,4.259,4.259,0,0,0,.368,3.875.362.362,0,0,0,.153.152c.167.068.325-.109.415-.262l.616-1.036a1.315,1.315,0,0,1,.337-.424,1.491,1.491,0,0,1,.511-.2,9.982,9.982,0,0,0,6.2-4.8,1.381,1.381,0,0,1,.411-.539,2.024,2.024,0,0,1,.887-.183,4.442,4.442,0,0,0,2.1-1.132,48.078,48.078,0,0,1,5.406-3.706,6.417,6.417,0,0,0,2.866-2.518.584.584,0,0,0,.032-.549.642.642,0,0,0-.266-.2,1.41,1.41,0,0,0-.934-.2c-.317.074-.573.435-.416.711a3.1,3.1,0,0,0,.279.308.765.765,0,0,1-.258,1.019,3.7,3.7,0,0,1-1.106.444c-1.347.444-2.388,1.485-3.652,2.118-1.989.995-4.534.989-6.141,2.5a5.464,5.464,0,0,1-1.192,1.048c-1.068.532-2.375-.17-3.557.04"
					transform="translate(-1472.686 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2236"
					data-name="Pfad 2236"
					d="M1491.714,497.7a3.016,3.016,0,0,0-1.643.036,1.945,1.945,0,0,0-.74.4,4.549,4.549,0,0,0-.5.607,4.652,4.652,0,0,1-2.969,1.6,8.672,8.672,0,0,1-3.444-.275.572.572,0,0,0-.411,0c-.206.112-.163.432.009.59a2.4,2.4,0,0,0,.628.314c.212.1.417.3.38.525s-.267.347-.331.553c-.087.28.192.567.491.615a1.682,1.682,0,0,0,.878-.185,1.184,1.184,0,0,1-.91,1,2.922,2.922,0,0,0,2.085-.567c.811-.62,1.264-1.663,2.208-2.068.56-.24,1.291-.264,1.613-.767.142-.222.168-.5.288-.73a2.33,2.33,0,0,1,1.583-.906c.609-.166,1.3-.448,1.454-1.041a1.636,1.636,0,0,0-.982.529"
					transform="translate(-1480.203 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2237"
					data-name="Pfad 2237"
					d="M1456.964,581.691a2.485,2.485,0,0,1,1.68-1.992,7.809,7.809,0,0,1,2.949-.459,11.275,11.275,0,0,1,3.08.315,17.74,17.74,0,0,1,2.741,1.154,63.433,63.433,0,0,0,11.044,3.4,1.134,1.134,0,0,1,.514.226.283.283,0,0,1,0,.444.6.6,0,0,1-.35.054c-.425-.023-.85-.066-1.273-.11a39.114,39.114,0,0,1-4.505-.645c-2.468-.552-4.763-1.578-7.232-2.13-.341-.077-.882.009-.8.3a.462.462,0,0,0,.19.211,10.584,10.584,0,0,0,4.038,2.128c1.044.216,2.14.143,3.2.283a3.308,3.308,0,0,1,2.6,1.415,9.45,9.45,0,0,1-3.326.408c-.975-.055-1.934-.26-2.911-.3-2.469-.093-4.852.888-7.324.876q4.875.456,9.756.855a23.438,23.438,0,0,0,4.728.063,2.228,2.228,0,0,1,.814-.007,1.667,1.667,0,0,1,.412.186c.484.278.978.677.949,1.174-.026.458-.512.818-1.032.935a4.744,4.744,0,0,1-1.6-.021,57.611,57.611,0,0,0-10.223-.337,50.339,50.339,0,0,0,9.666.978,2.113,2.113,0,0,1,1.084.193c.3.187.41.633.1.816a1.458,1.458,0,0,1,.829.052c.244.122.337.469.1.6a.71.71,0,0,1-.252.061,62.971,62.971,0,0,1-11.364.269,18.559,18.559,0,0,0,6.534.929,14.14,14.14,0,0,1,4.539.137c.186.055.392.145.43.31.054.231-.247.4-.5.482-4.213,1.387-8.962.12-13.443.591l11.342,1.19a1.312,1.312,0,0,1,.82.277c.183.194.173.467.255.705a2.955,2.955,0,0,0,.881,1.011,3.833,3.833,0,0,1,1.206,2.346,1.4,1.4,0,0,0,1.173-.789,3.571,3.571,0,0,0,.541-1.715,27.242,27.242,0,0,0-.272-4.091c-.1-.919,1.252-1.98,1.491-2.827.17-.6-.82-.977-.6-1.652.547-1.7,1.667-3.255,1.809-4.923a8.075,8.075,0,0,0-.424-3.207,24.158,24.158,0,0,1-6.183.208c-4.286-.631-8.016-3.168-12.354-3.433a15.458,15.458,0,0,0-2.948.127,2.757,2.757,0,0,1-1.57-.082,2.042,2.042,0,0,1-.647-.6l-1.67-2.078a49.018,49.018,0,0,0,.585,6.35l1.011-1.709"
					transform="translate(-1443.07 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2238"
					data-name="Pfad 2238"
					d="M1455.621,511.859a.562.562,0,0,0-.831.326c-.073.4.329.707.631.99a5.579,5.579,0,0,1,.856,1.136,100.942,100.942,0,0,1,6.445,11.321,4.442,4.442,0,0,0-.074-2.4c-.972-3.979-3.431-7.459-6.048-10.673-.381-.47-.892-.985-1.5-.89"
					transform="translate(-1423.599 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2239"
					data-name="Pfad 2239"
					d="M1461.011,529.027a30.069,30.069,0,0,1-.933,4.58,1.8,1.8,0,0,1-.826,1.257c-1.988-4.176-2.605-8.829-4.141-13.18a3.623,3.623,0,0,0-1.616-2.257,51.847,51.847,0,0,1,3.033,10.487q-.577-.9-1.151-1.8.457,2.338.874,4.685a14.5,14.5,0,0,1,.287,4.947,29.59,29.59,0,0,0-.651,3.13,9.921,9.921,0,0,0,.291,2.556q.416,2.059.834,4.118a18.634,18.634,0,0,0,2.38-6.595c.136-1.209.069-2.437.286-3.635.164-.9.489-1.773.679-2.672a18.425,18.425,0,0,0,.3-2.642l.205-3.157"
					transform="translate(-1420.464 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2240"
					data-name="Pfad 2240"
					d="M1444.6,620.228c.437-.386,5.928-.389,6.893-.8.212-.089.593-.233,1.065-.413a18.179,18.179,0,0,0,4.915-.911,1.773,1.773,0,0,0,1.164-2.179.433.433,0,0,0,.069-.286c-.049-.232-.094-.442-.14-.656.259-.481.519-.961.816-1.422a1.774,1.774,0,0,0-.27-2.092c-1.656-1.819.942-5.2,1.241-7.935a1.7,1.7,0,0,0,.83-1.01c1.426-4.453.514-7.868-1-12.168a1.775,1.775,0,0,0-.338-.572,5.443,5.443,0,0,0-.051-.787,13.433,13.433,0,0,0-.342-1.563c2.268-6.481,2.311-12.936,2.207-19.906-.065-4.3-.516-8.71-.374-13,.1-3.076,1.336-5.926.309-8.92.116-1.417.222-2.837.357-4.251.825-3.081,1.269-6.293,1.955-9.388.293-1.323.676-2.643.987-3.97a22.548,22.548,0,0,0,1.389,7.574c1.908-.99,1.54-4,3.225-5.317a1.112,1.112,0,0,1,.85-.285,1.206,1.206,0,0,1,.617.39,4.744,4.744,0,0,1,.495,5.884c-.506.724-1.215,1.3-1.679,2.054-.939,1.521-.715,3.447-1.189,5.16-.361,1.3-1.125,2.47-1.551,3.755a6.657,6.657,0,0,0-.507,3.576c.383,2.633.309,5.36,1.01,7.93.691,2.533-.994,5.1-1.069,7.717-.051,1.766.632,3.468.869,5.219a27.919,27.919,0,0,1-.138,5.7c-.471,6.784.734,13.62-.009,20.381-.269,2.447-.793,4.872-.846,7.332a38.565,38.565,0,0,0,.334,5.047,10.6,10.6,0,0,0,.526,2.654l-1.357,4.166s-4.457,4.835-7.408,6.075-8.5.795-8.658.65C1449.672,623.555,1442.776,621.836,1444.6,620.228Z"
					transform="translate(-1422.502 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2241"
					data-name="Pfad 2241"
					d="M1447.95,538.665a3.393,3.393,0,0,1,.566-1.581q.165.588.329,1.176l.884-.888a24.2,24.2,0,0,0-1.861,4.732q-1.764,5.619-3.527,11.237a16.578,16.578,0,0,0-.86,3.711c-.1,1.31.12,2.625.06,3.937a18.253,18.253,0,0,1-.7,3.779q-2.233,8.514-4.921,16.907c-.779-3.793,1.316-7.5,2.511-11.188a49.523,49.523,0,0,0,1.741-9.18,5.817,5.817,0,0,0-.339-3.66,53.008,53.008,0,0,1-3.557,10.216c-.91,1.948-.981-2.844-.336-4.373,1.015-2.407,2.449-4.626,3.557-6.994,2.59-5.536,3.352-11.79,6.272-17.168a3.5,3.5,0,0,0,.5-1.262,1.1,1.1,0,0,0-.576-1.142"
					transform="translate(-1393.204 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2242"
					data-name="Pfad 2242"
					d="M1436.833,547.823q.084,6.091.17,12.181a118,118,0,0,1-.421,14.945c-.438,3.868-1.259,7.749-.827,11.617.292,2.617,1.156,5.172,1.177,7.8a24.585,24.585,0,0,1-.772,5.423q-.555,2.533-1.11,5.068a7.438,7.438,0,0,0,3.064-4.941,14.684,14.684,0,0,0,.064-2.6q-.218-6.342-.257-12.689c-.039-6.333.054-12.666.043-19a121.452,121.452,0,0,0-1.023-18.007"
					transform="translate(-1379.217 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2243"
					data-name="Pfad 2243"
					d="M1440.5,542.646l.239.417-1.322-18.93-.3.384Z"
					transform="translate(-1385.811 -490.836)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<g id="SIZE_REF" transform="translate(883.517 562.181)">
				<ellipse
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.5"
					cy="0.5"
					rx="0.5"
					ry="0.5"
					transform="translate(23.507 9.071)"
					fill="transparent"
				/>
				<ellipse
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.5"
					cy="0.5"
					rx="0.5"
					ry="0.5"
					transform="translate(0)"
					fill="transparent"
				/>
			</g>
			<!-- <g id="POSITIONEN" transform="translate(886.327 595.645)">
				<ellipse
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					rx="0.5"
					ry="0.5"
					transform="translate(0)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<g
				id="PRINT-AREA"
				transform="translate(877.278 583.595)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="18.9" height="87.9" fill="none" ref="print-area" />
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'HoodieRight',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
}
</script>