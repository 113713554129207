<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<g id="LONGSLEEVE_LEFT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -496.643982, -824.339966)">
			<g id="COLORS">
				<path
					id="Pfad_1582"
					data-name="Pfad 1582"
					d="M347.351,501.781l2.118-2.412c16.366-1.813,26.574-9.522,28.328-11.048l.078-.078s15.649,14.257,13.733,39.668-6.226,39.9-5.89,54.114c0,0,.337,4.449,1.683,6.974a6.569,6.569,0,0,1-.505,4.88s.673,1.515,1.178,3.03,1.347,2.356.336,4.039,1.282,4.207,1.282,4.207,2.359,15.65,2.054,15.65h-.307s-9.928,6.731-26.757,6.731-28.775-1.682-33.488-6.563c0,0,2.86-32.816,2.86-42.913s.815-50.969,1.489-55.345,1.4-8.6,11.808-20.934"
					transform="translate(210 340)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_1583"
					data-name="Pfad 1583"
					d="M390.908,515.312c-.226,3.543-.639,14.916-.639,16.24,0,1.461-1.665,6.423-1.921,7.8a18.966,18.966,0,0,0-.64,4.38c.128,1.127-1.537,6.633-1.665,7.759s-1.025,10.387-1.153,11.013-1.792,9.136-3.126,10.888-5.965,20.4-6.222,21.275-2.945,7.509-3.329,8.385-3.043,7.173-3.171,7.924-6.3,9.471-7.073,9.847-1.437,2.348-1.782,3.312a22.97,22.97,0,0,0-1.315,4.54c-.309,1.982-1.956,1.706-1.956,1.706-5.854-.661-10.7-3.051-12.618-3.8s-1.409-2.127-1.281-2.388a47.527,47.527,0,0,0,2.406-8.124c.485-3.129,1.82-10.012,2.076-10.888a23.477,23.477,0,0,0,.768-3.379,16.44,16.44,0,0,1,1.665-4.13,32.014,32.014,0,0,0,1.024-4.63,93.1,93.1,0,0,1,2.689-9.011,20.915,20.915,0,0,0,.769-4.38c.128-.751.384-5.381.64-6.507s3.457-10.638,3.586-11.264a19.122,19.122,0,0,0,.128-7.508,59.654,59.654,0,0,1-.079-9.991l.122-1c.234-2.319,0-14.044-1.617-17.88s-1.017-8.772-1.017-8.772l0-.09c.34-13.977,13.609-16.265,13.609-16.265l.068-.013s17.516-4.947,20.994,13.208A5.777,5.777,0,0,1,390.908,515.312Z"
					transform="translate(210 340)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1584"
					data-name="Pfad 1584"
					d="M347.929,501.2l1.54-1.834c16.366-1.813,26.574-9.522,28.328-11.048l.078-.078,1.491,1.525s-11.932,10.273-32.015,12.013Z"
					transform="translate(210 340)"
					:fill="secColor"
				/>
			</g>
			<g id="LINES">
				<path
					id="Pfad_1585"
					data-name="Pfad 1585"
					d="M343.446,626.094c-5.706-1.03-9.889-2.673-12.252-5.121,0,0,2.86-32.816,2.86-42.913s.815-50.969,1.489-55.345,1.4-8.6,11.808-20.934"
					transform="translate(210 340)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1586"
					data-name="Pfad 1586"
					d="M347.351,501.781l2.118-2.412c16.366-1.813,26.574-9.522,28.328-11.048l.078-.078s15.649,14.257,13.733,39.668-6.226,39.9-5.89,54.114c0,0,.337,4.449,1.683,6.974a6.569,6.569,0,0,1-.505,4.88s.673,1.515,1.178,3.03,1.347,2.356.336,4.039,1.282,4.207,1.282,4.207,2.359,15.65,2.054,15.65h-.307s-9.928,6.731-26.757,6.731q-2.883,0-5.572-.068"
					transform="translate(210 340)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1587"
					data-name="Pfad 1587"
					d="M359.36,626.53q2.555.084,5.322.085a53.333,53.333,0,0,0,27.008-7.063"
					transform="translate(210 340)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1588"
					data-name="Pfad 1588"
					d="M331.291,619.838c2.156,2.037,6.027,3.727,11.577,4.91"
					transform="translate(210 340)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1589"
					data-name="Pfad 1589"
					d="M359.65,625.623q2.421.075,5.032.076a53.338,53.338,0,0,0,27.008-7.062"
					transform="translate(210 340)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1590"
					data-name="Pfad 1590"
					d="M331.291,618.922c2.191,2.07,6.153,3.782,11.849,4.968"
					transform="translate(210 340)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1591"
					data-name="Pfad 1591"
					d="M379.366,489.768s-11.932,10.273-32.015,12.013"
					transform="translate(210 340)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1592"
					data-name="Pfad 1592"
					d="M379.889,490.29a65.152,65.152,0,0,1-32.873,12.1"
					transform="translate(210 340)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path id="Pfad_1593" data-name="Pfad 1593" d="M383.034,496.532" transform="translate(210 340)" fill="#1a1818" />
				<path
					id="Pfad_1594"
					data-name="Pfad 1594"
					d="M343.2,623.719s9.509,4.929,15.589,5.318"
					transform="translate(210 340)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1595"
					data-name="Pfad 1595"
					d="M343.563,622.724s9.241,4.936,15.322,5.325"
					transform="translate(210 340)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1596"
					data-name="Pfad 1596"
					d="M390.908,515.312c-.226,3.543-.639,14.916-.639,16.24,0,1.461-1.665,6.423-1.921,7.8a18.966,18.966,0,0,0-.64,4.38c.128,1.127-1.537,6.633-1.665,7.759s-1.025,10.387-1.153,11.013-1.792,9.136-3.126,10.888-5.965,20.4-6.222,21.275-2.945,7.509-3.329,8.385-3.043,7.173-3.171,7.924-6.3,9.471-7.073,9.847-1.437,2.348-1.782,3.312a22.97,22.97,0,0,0-1.315,4.54c-.309,1.982-1.956,1.706-1.956,1.706-5.854-.661-10.7-3.051-12.618-3.8s-1.409-2.127-1.281-2.388a47.527,47.527,0,0,0,2.406-8.124c.485-3.129,1.82-10.012,2.076-10.888a23.477,23.477,0,0,0,.768-3.379,16.44,16.44,0,0,1,1.665-4.13,32.014,32.014,0,0,0,1.024-4.63,93.1,93.1,0,0,1,2.689-9.011,20.915,20.915,0,0,0,.769-4.38c.128-.751.384-5.381.64-6.507s3.457-10.638,3.586-11.264a19.122,19.122,0,0,0,.128-7.508,59.654,59.654,0,0,1-.079-9.991l.122-1c.234-2.319,0-14.044-1.617-17.88s-1.017-8.772-1.017-8.772l0-.09c.34-13.977,13.609-16.265,13.609-16.265l.068-.013s17.516-4.947,20.994,13.208A5.777,5.777,0,0,1,390.908,515.312Z"
					transform="translate(210 340)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1597"
					data-name="Pfad 1597"
					d="M368.9,496.254a13.242,13.242,0,0,1,1.433,4.04"
					transform="translate(210 340)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1598"
					data-name="Pfad 1598"
					d="M368.3,496.529a12.028,12.028,0,0,1,1.431,3.9"
					transform="translate(210 340)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1599"
					data-name="Pfad 1599"
					d="M369.5,495.98a13.713,13.713,0,0,1,1.488,4.158"
					transform="translate(210 340)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
			</g>
			<g id="SHADING" transform="translate(5 0.563)">
				<path
					id="Pfad_1567"
					data-name="Pfad 1567"
					d="M376.013,585.185c-.3,4.336,3.627,5.031,6.32,7.678,1.782,1.751,6.293,3.855,5.858,6.744-.362,2.409-2.558,2.076-4.115.776-.948-.793-.733-1.156-1.315-1.96-.671-.929-1.631-1.308-2.276-2.014a32.367,32.367,0,0,1-3.076-3.646c-.92-1.413-.186-3.408-1.4-4.437"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1568"
					data-name="Pfad 1568"
					d="M355.994,518.431s-11.843,6.3-9.375,26.206,6.554,29.552,6.554,29.552,2.771,15.877,2.821,16.353,1.99,12.044,1.99,12.044l3.5,5.71-3.5-10.129V585.185s-4.563-14.574-.893-19.511,1.022-17.011,1.022-17.011,0-4.945,1.325-5.63c1.136-.588-.9-.054-.673-4.926,0,0-.5-7.7-1.012-11.073a39.723,39.723,0,0,0-1.346-4.211A15.06,15.06,0,0,1,355.994,518.431Z"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1569"
					data-name="Pfad 1569"
					d="M370.082,503.024a6.781,6.781,0,0,1-.434,3.43,12.025,12.025,0,0,0-.452,4.313c.1,1.287.012,2.5.035,3.77.022,1.227.352,2.439.439,3.64a19.007,19.007,0,0,0,1.239,6.435,11.864,11.864,0,0,1,.935,2.565,10.19,10.19,0,0,1,.626,2.645c-.986-.144-1.455-2.874-1.87-3.7a12.156,12.156,0,0,1-1.419-4.308,17.639,17.639,0,0,0-.783-3.505c-.412-1.209-.06-2.247-.21-3.454a11.024,11.024,0,0,1-.2-2.762c.123-1.084.39-2.175.608-3.238a55.6,55.6,0,0,1,1.553-5.52"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1570"
					data-name="Pfad 1570"
					d="M365.517,504.49a3.15,3.15,0,0,0,.862-1.586c.3-.649-.127-.72-.5-.882a8.13,8.13,0,0,0-1.9-.692,5.7,5.7,0,0,1-1.622-.422.289.289,0,0,1-.179-.3,3.257,3.257,0,0,1,1.8.039c.749.164,1.69.155,2.435.328.193.045.787.4,1.319.068.739-.457-.028-.976-.071-1.1-.119-.339-.119-.788-.242-1.142a5.551,5.551,0,0,0-.788-1.619,4.049,4.049,0,0,1-.762-1.994.481.481,0,0,1,0-.291c.271.194.2.7.276,1.05a3.749,3.749,0,0,0,.365.983c.356.676.823,1.268,1.181,1.931.122.226.218.5.351.73.1.179.283.551.739.39a2.3,2.3,0,0,0,.341-.714,9.289,9.289,0,0,1,.477-.848c.342-.592.361-1,.209-1.261-.208-.358-.84-.378-1.162-.643a1.421,1.421,0,0,1-.57-.926c-.042-.321.141-.828,0-1.065a7.388,7.388,0,0,0,1.126-.516,3.84,3.84,0,0,0-.345,1.52,1.573,1.573,0,0,0,.27.864c.115.146.151.5.469.467a1.418,1.418,0,0,0,.272-.88,10.158,10.158,0,0,0-.007-1.02c.01-.251.047-.526.064-.772.014-.181-.072-.364.072-.614.4.513.05,1.581.083,2.39a15.088,15.088,0,0,1-.116,2.068c-.035.336-.135.734-.143,1.055a4.318,4.318,0,0,1-.222,1.155,4.754,4.754,0,0,1-1.287,2.388,29.2,29.2,0,0,1-2.816,1.831"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1571"
					data-name="Pfad 1571"
					d="M364.092,525.537c-.254-.419.177-1.068.634-.955a55.511,55.511,0,0,1-.963,6.738,109.108,109.108,0,0,1-4.984,15.834,26.4,26.4,0,0,1,1.253-6.178q1.222-4.43,2.029-8.974a9.011,9.011,0,0,0-1.875,3.908q-.879,3.035-1.667,6.1c.034-3.245,1.112-6.362,2.175-9.4l2.164-6.184a3.262,3.262,0,0,1,1.443-2.1,2.516,2.516,0,0,0,.926.671"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1572"
					data-name="Pfad 1572"
					d="M379.935,533.745c-.258-.466.5-1.044.834-.633s-.326,1.121-.718.777a2.608,2.608,0,0,0-1.269-.611l2.31,7.7a20.846,20.846,0,0,0-4.105-16.668,3.614,3.614,0,0,1,1.87,2.018c2.076,4.006,3.638,8.533,3.147,13.076-.347,3.221-1.711,6.293-1.693,9.534-1.7-3.346.345-7.585-.637-11.24-.421-1.566-1.4-3.015-1.325-4.642a.9.9,0,0,1,.113-.457c.274-.411.912-.124,1.165.3s.369.974.764,1.251"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1573"
					data-name="Pfad 1573"
					d="M375.872,541.567c1.286,3.471.036,7.391.372,11.1.164,1.806.7,3.547,1.034,5.326a23.322,23.322,0,0,1-.026,8.635c-.615-2.3-.342-4.741-.472-7.125a66.154,66.154,0,0,0-1.5-8.748,19.947,19.947,0,0,1-.115-8.816.823.823,0,0,1,.184-.39c.383-.374.9.273,1.063.8"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1574"
					data-name="Pfad 1574"
					d="M351.431,621.58a1.576,1.576,0,0,1-.827-.142.624.624,0,0,1-.291-.752.665.665,0,0,1,.337-.3,1.493,1.493,0,0,1,1.579.215c.171.145.311.328.478.48a3.313,3.313,0,0,0,.788.5,16.565,16.565,0,0,0,5.193,1.587,1.323,1.323,0,0,1,.611.182.5.5,0,0,1,.192.586,55.049,55.049,0,0,0-8.345-2.321"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1575"
					data-name="Pfad 1575"
					d="M348.931,619.052l-2.131-1.263a2.021,2.021,0,0,1-.634-.505.754.754,0,0,1-.091-.791,8.152,8.152,0,0,1,2.18.986c1.7.861,3.7,1.059,5.215,2.258.842.669,1.549,1.653,2.584,1.848a1.226,1.226,0,0,0-.47-.854c-2.442-2.429-5.281-4.59-8.559-5.3a1.675,1.675,0,0,0-1.776.382,1.363,1.363,0,0,0,.043,1.451,2.879,2.879,0,0,0,1.13.948,8.788,8.788,0,0,0,2.676.928"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1576"
					data-name="Pfad 1576"
					d="M356.668,617.637a5.786,5.786,0,0,0-1.6-.19.672.672,0,0,0-.493.155c-.289.308.092.843.495.918s.834-.065,1.218.083.608.518.9.8a4.255,4.255,0,0,0,2,.887,3.626,3.626,0,0,0,2.83-.081.454.454,0,0,0,.256-.389c-.021-.207-.256-.294-.449-.329a7.69,7.69,0,0,1-3.306-.9,13.43,13.43,0,0,1,3.23-.27,4.406,4.406,0,0,0,1.508-.089,2.089,2.089,0,0,0,1.291-1.261,4.993,4.993,0,0,0-2.593.078l-4.365.915a1.419,1.419,0,0,1-.742.021.549.549,0,0,1-.381-.6"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1577"
					data-name="Pfad 1577"
					d="M353.477,611.126a6,6,0,0,1,4.209.5.338.338,0,0,1,.2.2c.045.2-.211.3-.4.318l-2.616.221a1.881,1.881,0,0,1-1.12-.136.752.752,0,0,1-.295-1.02c.171-.228.544-.4.441-.67"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1578"
					data-name="Pfad 1578"
					d="M353.14,609.662q-2.079-1.219-4.205-2.341c.662-.526,1.933-.353,2.055-1.222.064-.455-.294-.834-.567-1.19s-.46-.938-.127-1.231c.468-.412,1.036.321,1.536.688a2.989,2.989,0,0,0,2.42.11,2.251,2.251,0,0,1,2.329.456l-3.958.148a1.509,1.509,0,0,0-.971.267c-.262.231-.313.751-.011.919l5.942,0a5.1,5.1,0,0,1,1.443.14c1.017.3,1.84,1.225,2.893,1.319.754.068,1.5-.31,2.251-.247a2.925,2.925,0,0,1,2.3,2.736,8.368,8.368,0,0,1-.766,3.847,12.317,12.317,0,0,1-.107-3.083c-.04-1.032-.541-2.221-1.5-2.358a5.2,5.2,0,0,0-1.286.153,4.974,4.974,0,0,1-1.829-.266,55.218,55.218,0,0,0-8.461-1.586c-.416-.045-.958,0-1.085.421-.152.512.464.867.942,1.036s1.044.682.753,1.12c-.159.285-.669-.052-.5-.329"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1579"
					data-name="Pfad 1579"
					d="M357.486,595.716c.267.135.134.615-.143.726a1.509,1.509,0,0,1-.872-.065c-1.254-.274-2.617.4-3.808-.1-.362-.152-.727-.56-.552-.93a.815.815,0,0,1,.259-.282c1.022-.793,2.288-1.313,2.995-2.428a5.51,5.51,0,0,1,.874-1.335,4.211,4.211,0,0,1,1.633-.6.739.739,0,0,0,.414-.248A.916.916,0,0,0,358.4,590l.089-1.046a36.6,36.6,0,0,1-3.772-.235,1.757,1.757,0,0,1-.837-.246.712.712,0,0,1-.286-.8.9.9,0,0,1,.643-.444,6.97,6.97,0,0,1,4.513.253c-1.227.687-2.809-.074-4.091.492a15.441,15.441,0,0,0,3.414.517c.414.016.918.074,1.086.477a1.121,1.121,0,0,1,.052.49,2.094,2.094,0,0,1-.486,1.392c-.463.456-1.192.427-1.73.776-.821.533-.99,1.781-1.762,2.391-.464.367-1.132.495-1.4,1.039a1.359,1.359,0,0,0,1.16.325,8.867,8.867,0,0,1,1.229-.16,1.951,1.951,0,0,1,1.583.959"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1580"
					data-name="Pfad 1580"
					d="M372.95,553.591a241.813,241.813,0,0,1,2.747,24.325,2.226,2.226,0,0,0,.532-1.567,71.533,71.533,0,0,0-.248-12.529l.943,1.212c.612-1.213.26-2.689.012-4.04a23.38,23.38,0,0,1-.072-7.973c1.273,2.072.472,4.838.92,7.266.218,1.183.743,2.359.523,3.542a12.1,12.1,0,0,1-.716,1.9c-.7,1.9-.29,4.026-.244,6.066a16.529,16.529,0,0,1-1.223,6.626c-.123.3-.394.652-.668.5a.624.624,0,0,1-.238-.39c-1.871-6.247-.837-13.059-1.958-19.506-.318-1.834-.8-3.8-.087-5.5"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1581"
					data-name="Pfad 1581"
					d="M379.093,582.319l.842-3.892c1.728,2.572,3.26,6.382,5.451,8.6,1.042,1.055,2.036,1.516,2.205,2.9.3,2.487-.439,2.758-.591,3.664a.221.221,0,0,1-.349.146,12.343,12.343,0,0,1-1.6-.931c-.665-.689-.466-.786-.96-1.52-.718-1.072-.8-2.01-2.2-2.78a8.268,8.268,0,0,0-1.1-3.171c-.142-.246-.866-.729-1.123-1.113-.41-.618-.19-1.271-.571-1.91"
					transform="translate(205 340)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<g id="SIZE_REF" transform="translate(278.468 367.542)">
				<circle
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(278.305 473.922)"
					fill="transparent"
				/>
				<circle
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(310.305 461.922)"
					fill="transparent"
				/>
			</g>
			<!-- <g id="POSITIONEN" transform="translate(288.468 349.542)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(296.305 513.922)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<g
				id="PRINT-AREA"
				transform="translate(572.723 854.721)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="24.9" height="91.16" fill="none" ref="print-area" />
			</g>
			<!-- <g
				id="PRINT-AREA"
				transform="translate(569.443 843.2) rotate(7)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect x="0.05" y="0.05" width="32.9" height="117.935" fill="none" ref="print-area" />
			</g> -->
		</g>
	</svg>
</template>

<script>
export default {
	name: 'LongsleeveLeft',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
}
</script>