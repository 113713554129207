<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<g id="POLO_LEFT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -425.485138, -598.0896)">
			<g id="COLORS" transform="translate(-302.321 -61.992)">
				<path
					id="Pfad_1427"
					data-name="Pfad 1427"
					d="M574.278,1036.172c0-9.758.787-49.254,1.438-53.483.512-3.332.469-10.008.3-14.45a12.743,12.743,0,0,1,.32-2.527l.45-.406,2.853-.9.012-.015a.885.885,0,0,1,.371-.471l-.384-4.368c.167-.956,1.228-.586,1.684-.565q.522.024,1.047.034l-.05-.26a.4.4,0,0,1-.066-.349l0-.018.011-.009c.063-.23.3-.544.97-.953,1.528-.93,15.011-8.627,20.229-11.21s9.092-4.185,10.848-4.03,1.705.827,1.809,1.86a19.3,19.3,0,0,0,.413,3.616,16.626,16.626,0,0,0,1.627,2.421c.314.439.729,1.225.629,1.564a49.989,49.989,0,0,1,11.1,36.052c-.282,3.744-.505,7.588-.872,10.879-.016.142-.167.14-.183.281-2.03,18-4.91,29.55-4.636,41.132,0,0,.325,4.3,1.626,6.74a6.349,6.349,0,0,1-.487,4.716s.65,1.464,1.137,2.928,1.3,2.276.325,3.9,1.239,4.065,1.239,4.065,2.28,15.124,1.985,15.124h-.3s-9.593,6.5-25.856,6.5-27.807-1.626-32.361-6.342C571.514,1077.64,574.278,1045.929,574.278,1036.172Z"
					transform="translate(202 -278)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_1428"
					data-name="Pfad 1428"
					d="M595.55,976.573a19,19,0,0,0,1.008,8.129c1.121,2.657,1.419,9.3,1.476,13.58.005.184.145.184.145.366.014,1.431.01,2.21-.046,2.767l-.027.38c.029.942,7.533,1.238,14.795,1.732,8.013.544,13.95-.49,15.212-1.416a.4.4,0,0,0,.159-.2l.209,0q.177-1.443.348-2.941c.017-.15.173-.2.19-.347.377-3.37.585-7.062.875-10.91a52.538,52.538,0,0,0-.78-14.008l-.092-.461c-3.328-17.321-20.35-12.525-20.35-12.525l-.366.077c-2.09.481-12.432,3.468-12.724,15.453Z"
					transform="translate(202 -278)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1429"
					data-name="Pfad 1429"
					d="M598.142,1001.592l.013.129c-.372,1,7.333,1.3,14.783,1.806,8.013.544,13.95-.49,15.212-1.416a.577.577,0,0,0,.1-.09l.046-.08.595-2.913c-2.186-2.4-8.645-4.079-14.624-4.3a32.933,32.933,0,0,0-16.049,3.921Zm4.8-1.518.1-.051a43.472,43.472,0,0,1,11.569-1.342,41.5,41.5,0,0,1,11.241,1.618l.072.055S611.727,1000.881,602.944,1000.074Z"
					transform="translate(202 -278)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1430"
					data-name="Pfad 1430"
					d="M575.413,987.968s.775-11.83.775-14.93-.62-7.232.516-7.7a28.791,28.791,0,0,1,2.939-.93l.006,0a.893.893,0,0,1,.413-.519,29.18,29.18,0,0,1-.419-4.236c-.058-1.136,1.683-.666,1.683-.666h1.153s-.772-.625.756-1.555,15.011-8.627,20.229-11.21,9.092-4.185,10.848-4.03,1.705.827,1.809,1.86a19.3,19.3,0,0,0,.413,3.616c.31.827,2.64,3.431,2.216,4.037-.558.8-9.343,2.03-15.357,6.667-6.869,5.3-12.121,12.86-12.534,13.163-.775.568-1.456,1.452-2.376,0a16.661,16.661,0,0,0-4.007-4.656,13.812,13.812,0,0,0-2.618-1.423c-.006.012-4.379,9.491-5,13.828S575.413,987.968,575.413,987.968Z"
					transform="translate(202 -278)"
					:fill="secColor"
				/>
			</g>
			<g id="LINES" transform="translate(-302.321 -61.992)">
				<path
					id="Pfad_1431"
					data-name="Pfad 1431"
					d="M571.608,1074.483c4.179,3.948,15.026,6.549,32.267,6.549a51.545,51.545,0,0,0,26.1-6.825"
					transform="translate(202 -278)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1432"
					data-name="Pfad 1432"
					d="M571.608,1075.416c4.179,3.948,15.026,6.548,32.267,6.548a51.537,51.537,0,0,0,26.1-6.824"
					transform="translate(202 -278)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1433"
					data-name="Pfad 1433"
					d="M608.022,1003.212c.361,3.556-1.858,9.488-2.033,12.93-.178,3.49-1.761,6.285-1.788,9.781-.015,1.853-.386,3.478-.488,5.371-.082,1.49.132,2.69-.48,3.911-.184.368-.6.546-.813,1.253a25.3,25.3,0,0,0-.658,5.9c-.1,2.516.78,4.729.812,7.14.074,5.584,1.087,10.612,1.79,16.053.294,2.285,1.161,4.675,1.3,6.927.128,2.011-.169,4.176-.166,6.219,0,1.674.363,3.773-.217,5.286"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1434"
					data-name="Pfad 1434"
					d="M618.772,951.692a49.938,49.938,0,0,1,11.122,36.018c-.29,3.848-.5,7.54-.875,10.91-.017.15-.173.2-.19.347-2.029,17.878-4.9,29.552-4.626,41.035,0,0,.325,4.3,1.626,6.74a6.349,6.349,0,0,1-.487,4.716s.65,1.464,1.137,2.928,1.3,2.276.325,3.9,1.239,4.066,1.239,4.066,2.28,15.124,1.985,15.124h-.3s-9.593,6.5-25.856,6.5-27.807-1.626-32.361-6.342c0,0,2.764-31.711,2.764-41.468,0-7.959.524-35.7,1.07-47.954"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1435"
					data-name="Pfad 1435"
					d="M606.828,956.112a13.8,13.8,0,0,1,1.844,4.6"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1436"
					data-name="Pfad 1436"
					d="M606.321,956.431a12.428,12.428,0,0,1,1.829,4.4"
					transform="translate(202 -278)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1437"
					data-name="Pfad 1437"
					d="M607.376,955.914a14.228,14.228,0,0,1,1.869,4.665"
					transform="translate(202 -278)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1438"
					data-name="Pfad 1438"
					d="M625.886,1000.354s-14.2.527-22.979-.28"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1439"
					data-name="Pfad 1439"
					d="M598.345,1001.481a42.6,42.6,0,0,1,16.136-2.8c9.089.218,14.894,2.5,13.632,3.43s-7.2,1.96-15.212,1.416S596.579,1002.678,598.345,1001.481Z"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.216"
				/>
				<path
					id="Pfad_1440"
					data-name="Pfad 1440"
					d="M598.154,997.454a33,33,0,0,1,16.074-3.936c5.979.221,12.543,1.9,14.729,4.3"
					transform="translate(202 -278)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1441"
					data-name="Pfad 1441"
					d="M598.179,998.648a32.933,32.933,0,0,1,16.049-3.921c5.979.222,12.439,1.905,14.625,4.3"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1442"
					data-name="Pfad 1442"
					d="M595.582,976.246s-.569,4.8.976,8.456c1.121,2.657,1.419,9.3,1.476,13.58.005.184.145.184.145.366.014,1.431-.017,2.556-.074,3.113"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1443"
					data-name="Pfad 1443"
					d="M608.672,960.716s-12.765,2.2-13.09,15.53"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1444"
					data-name="Pfad 1444"
					d="M608.672,960.716s17.022-4.8,20.35,12.525"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<line
					id="Linie_280"
					data-name="Linie 280"
					y1="0.837"
					x2="0.252"
					transform="translate(830.322 723.135)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path id="Pfad_1445" data-name="Pfad 1445" d="M621.609,957.387" transform="translate(202 -278)" fill="#1a1818" />
				<path
					id="Pfad_1446"
					data-name="Pfad 1446"
					d="M582.479,958.992s-.772-.625.756-1.555,15.011-8.627,20.229-11.21,9.092-4.185,10.848-4.03,1.705.827,1.809,1.86a19.3,19.3,0,0,0,.413,3.616c.31.827,2.64,3.431,2.216,4.037-.457.653-6.437,1.6-11.891,4.47a23.955,23.955,0,0,0-3.466,2.2c-6.869,5.3-12.121,12.86-12.534,13.163-.775.568-1.456,1.452-2.376,0a16.661,16.661,0,0,0-4.007-4.656,14.957,14.957,0,0,0-4.652-2.112s-.537-.31.238-.878,7.852-5.579,7.852-5.579,9.867-7.336,12.037-8.524,6.3-3.668,6.3-3.668l3.82-1.807.984-.466a4.067,4.067,0,0,0-1.963.465"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1447"
					data-name="Pfad 1447"
					d="M581.858,965.46s-4.379,9.489-5,13.829-1.446,8.679-1.446,8.679.775-11.83.775-14.93-.62-7.232.516-7.7a28.791,28.791,0,0,1,2.939-.93"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1448"
					data-name="Pfad 1448"
					d="M586.958,958.992h-5.632s-1.741-.47-1.683.666a29.18,29.18,0,0,0,.419,4.236"
					transform="translate(202 -278)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1449"
					data-name="Pfad 1449"
					d="M580.336,965.077s-3.548.514-3.627,2.028-.03,11.711-.155,14.22"
					transform="translate(202 -278)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1450"
					data-name="Pfad 1450"
					d="M585.939,959.8s-5.347-.417-5.347.6a23.219,23.219,0,0,0,.321,2.881"
					transform="translate(202 -278)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
			</g>
			<g id="SHADING" transform="translate(-302.321 -61.992)">
				<path
					id="Pfad_1415"
					data-name="Pfad 1415"
					d="M586.958,958.992s10.215-7.654,15.38-10.557a81.48,81.48,0,0,1,7.736-4.119s2.16-.951-1.237,0a56.141,56.141,0,0,0-9.982,4.329c-2.145,1.171-13.338,7.464-13.338,7.464s-3.853,1.9-3.13,2.723Z"
					transform="translate(202 -278)"
					opacity="0.73"
					fill="url(#linear-gradient)"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1416"
					data-name="Pfad 1416"
					d="M612.872,1021.808c.12,1.462.882,2.371,1.315,3.685.6,1.817.378,3.948.806,5.854.6,2.669,3.158,4.43,4.663,6.662,1.67,2.485,2.108,4.684,4.226,6.831a4.414,4.414,0,0,1,1.568,2.986c.156,1.27-.76,2.752-.641,3.5-.356-.573-.859-.491-1.252-.9-.642-.665-.45-.759-.928-1.468-.693-1.036-.777-1.943-2.129-2.687a8,8,0,0,0-1.062-3.064c-.137-.237-.837-.7-1.085-1.075-.4-.6-.184-1.228-.552-1.846-.812-1.36-2.167-1.937-2.712-3.555a13.685,13.685,0,0,1-.292-3.612c-3.336-.176-1.594-8.02-2.141-10.229"
					transform="translate(202 -278)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1417"
					data-name="Pfad 1417"
					d="M614.824,1043.056c-.285,4.191,3.506,4.862,6.108,7.42,1.721,1.692,6.081,3.726,5.661,6.517-.351,2.327-2.472,2.006-3.977.75-.916-.766-.708-1.117-1.271-1.894-.648-.9-1.576-1.264-2.2-1.946a31.367,31.367,0,0,1-2.972-3.523c-.889-1.366-.18-3.294-1.35-4.289"
					transform="translate(202 -278)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1418"
					data-name="Pfad 1418"
					d="M595.465,978.552s-11.444,6.09-9.059,25.324,6.333,28.558,6.333,28.558,2.678,15.342,2.726,15.8,1.923,11.638,1.923,11.638l3.381,5.518-3.381-9.787v-12.546s-3.546-14.311,0-19.081,5.8-21.051,5.8-21.051-3.418-.375-4.4-.6c-1.2-.277-.871-.052-.65-4.76,0,0-.481-7.436-.978-10.7a38.253,38.253,0,0,0-1.3-4.07A14.622,14.622,0,0,1,595.465,978.552Z"
					transform="translate(202 -278)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1419"
					data-name="Pfad 1419"
					d="M605,969.362c-.393,1.536-1.08,2.835-1.536,4.343a41.338,41.338,0,0,0-1.222,5.611c-.366,2.343-.669,5.128.262,7.388a12.553,12.553,0,0,0,1.98,3.033c-.694-2.2-1.612-4.3-1.481-6.686.063-1.126.5-2.219.576-3.354a26.478,26.478,0,0,1,.346-3.332c.215-1.108.484-2.208.639-3.277a15.729,15.729,0,0,0,.451-3.507"
					transform="translate(202 -278)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1420"
					data-name="Pfad 1420"
					d="M605,975.7a17.839,17.839,0,0,1-.325,2.611,32.336,32.336,0,0,0-.379,3.217c0,1.7.34,3.452.49,5.154a9.124,9.124,0,0,0,.925,3.643,13.191,13.191,0,0,1,1.606,2.812c.03.124.113.243.147.361-3.963-3.223-3.7-9.436-3.726-14.045a12.346,12.346,0,0,1-.049-2.551,4.131,4.131,0,0,0,.463,1.505c.282-.864.586-1.709.814-2.573"
					transform="translate(202 -278)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1421"
					data-name="Pfad 1421"
					d="M609.093,963.661a6.545,6.545,0,0,1-.419,3.315,11.624,11.624,0,0,0-.437,4.168c.1,1.243.012,2.41.034,3.643.021,1.186.34,2.356.424,3.517a18.363,18.363,0,0,0,1.2,6.218,11.5,11.5,0,0,1,.9,2.479,9.822,9.822,0,0,1,.6,2.556c-.952-.139-1.405-2.777-1.807-3.575a11.727,11.727,0,0,1-1.37-4.163,17.14,17.14,0,0,0-.757-3.388c-.4-1.168-.058-2.171-.2-3.338a10.659,10.659,0,0,1-.192-2.668c.119-1.048.376-2.1.587-3.129a53.512,53.512,0,0,1,1.5-5.334"
					transform="translate(202 -278)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1422"
					data-name="Pfad 1422"
					d="M604.682,965.077a3.048,3.048,0,0,0,.833-1.532c.294-.627-.123-.7-.48-.852a7.838,7.838,0,0,0-1.837-.669,5.57,5.57,0,0,1-1.567-.407.281.281,0,0,1-.173-.288,3.15,3.15,0,0,1,1.738.038c.723.159,1.633.15,2.352.317.187.043.761.382,1.275.065.714-.441-.027-.942-.069-1.064-.114-.327-.114-.761-.233-1.1a5.34,5.34,0,0,0-.762-1.564,3.916,3.916,0,0,1-.736-1.927.456.456,0,0,1,0-.281c.262.187.193.677.266,1.014a3.652,3.652,0,0,0,.353.95c.344.653.8,1.226,1.142,1.866.117.219.21.484.339.706.1.172.273.532.713.377a2.218,2.218,0,0,0,.331-.691,8.75,8.75,0,0,1,.46-.819c.331-.572.35-.969.2-1.219-.2-.345-.813-.365-1.124-.621a1.379,1.379,0,0,1-.551-.895c-.04-.31.136-.8,0-1.029a7.083,7.083,0,0,0,1.087-.5,3.715,3.715,0,0,0-.333,1.468,1.51,1.51,0,0,0,.262.835c.11.141.145.485.453.452a1.375,1.375,0,0,0,.262-.851,9.495,9.495,0,0,0-.007-.986c.01-.242.046-.508.063-.746.013-.174-.07-.351.069-.593.384.5.049,1.528.08,2.31a14.542,14.542,0,0,1-.112,2c-.034.324-.13.71-.138,1.02a4.134,4.134,0,0,1-.215,1.116,4.583,4.583,0,0,1-1.243,2.307,28.113,28.113,0,0,1-2.721,1.769"
					transform="translate(202 -278)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1423"
					data-name="Pfad 1423"
					d="M603.393,958.377s-8.4,9.646-9.746,11.615-3.269,3.274-3.46,2.147c0,0,2.836-3.637,4.589-5.647S599.963,961.453,603.393,958.377Z"
					transform="translate(202 -278)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1424"
					data-name="Pfad 1424"
					d="M615.692,943.213c.2-.093.4.19.332.4a.841.841,0,0,1-.487.417,29.236,29.236,0,0,1-4.415,1.66,13.091,13.091,0,0,0-2.178.732,3.377,3.377,0,0,0-1.642,1.547,9.924,9.924,0,0,0,3.978,0,11.793,11.793,0,0,1,2.552-.446,2.245,2.245,0,0,1,2.112,1.233,6.121,6.121,0,0,1,.206,1.212,1.276,1.276,0,0,0,.626,1,.4.4,0,0,0,.331.02.432.432,0,0,0,.2-.271,2.783,2.783,0,0,0-.386-1.84,12.119,12.119,0,0,1-.669-3.14l-.261-2.151"
					transform="translate(202 -278)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1425"
					data-name="Pfad 1425"
					d="M588.6,971.762c-2.617-1.369-4.5-3.786-6.3-6.131a8.826,8.826,0,0,1,3.589,2.363,26.378,26.378,0,0,1,2.654,3.429c.077.111.106.327-.03.325"
					transform="translate(202 -278)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1426"
					data-name="Pfad 1426"
					d="M598.345,1001.481a42.7,42.7,0,0,1,16.157-2.8c9.1.218,14.912,2.5,13.647,3.43s-7.209,1.96-15.23,1.416S596.577,1002.678,598.345,1001.481Z"
					transform="translate(202 -278)"
					fill="url(#linear-gradient-2)"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<g id="SIZE_REF" transform="translate(205.259 141.281)">
				<circle
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(278.305 479.813)"
					fill="transparent"
				/>
				<circle
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(308.465 461.922)"
					fill="transparent"
				/>
			</g>
			<!-- <g id="POSITIONEN" transform="translate(215.918 124.781)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(296.305 513.922)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<g
				id="PRINT-AREA"
				transform="translate(496.674 620.153)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="31.9" height="37.9" fill="none" ref="print-area" />
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'PoloLeft',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
}
</script>