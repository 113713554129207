<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<g id="LONGSLEEVE_RIGHT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -496.660767, -824.339966)">
			<g id="COLORS" transform="translate(541.617 828.243)">
				<path
					id="Pfad_1582"
					data-name="Pfad 1582"
					d="M375.586,501.781l-2.117-2.412c-16.355-1.813-26.556-9.522-28.309-11.048l-.078-.078s-15.638,14.257-13.724,39.668,6.222,39.9,5.886,54.114c0,0-.337,4.449-1.682,6.974a6.573,6.573,0,0,0,.5,4.88s-.673,1.515-1.177,3.03-1.346,2.356-.336,4.039-1.281,4.207-1.281,4.207-2.357,15.65-2.053,15.65h.307s9.921,6.731,26.739,6.731,28.755-1.682,33.465-6.563c0,0-2.858-32.816-2.858-42.913s-.814-50.969-1.488-55.345-1.4-8.6-11.8-20.934"
					transform="translate(-331.194 -488.243)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_1583"
					data-name="Pfad 1583"
					d="M342.883,515.312c.225,3.543.637,14.916.637,16.24,0,1.461,1.661,6.423,1.916,7.8a19.012,19.012,0,0,1,.638,4.38c-.128,1.127,1.533,6.633,1.661,7.759s1.022,10.387,1.15,11.013,1.787,9.136,3.118,10.888,5.949,20.4,6.205,21.275,2.937,7.509,3.32,8.385,3.035,7.173,3.163,7.924,6.288,9.471,7.054,9.847,1.433,2.348,1.777,3.312a23.016,23.016,0,0,1,1.311,4.54c.308,1.982,1.951,1.706,1.951,1.706,5.838-.661,10.67-3.051,12.584-3.8s1.405-2.127,1.278-2.388a47.621,47.621,0,0,1-2.4-8.124c-.484-3.129-1.815-10.012-2.07-10.888a23.525,23.525,0,0,1-.766-3.379,16.465,16.465,0,0,0-1.661-4.13,32.086,32.086,0,0,1-1.021-4.63,93.29,93.29,0,0,0-2.682-9.011,20.966,20.966,0,0,1-.767-4.38c-.128-.751-.383-5.381-.638-6.507s-3.448-10.638-3.577-11.264a19.172,19.172,0,0,1-.128-7.508,59.808,59.808,0,0,0,.079-9.991l-.122-1c-.233-2.319,0-14.044,1.613-17.88s1.014-8.772,1.014-8.772l0-.09c-.339-13.977-13.573-16.265-13.573-16.265l-.068-.013s-17.469-4.947-20.938,13.208A5.793,5.793,0,0,0,342.883,515.312Z"
					transform="translate(-342.02 -488.243)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1584"
					data-name="Pfad 1584"
					d="M378.7,501.2l-1.536-1.834c-16.322-1.813-26.5-9.522-28.253-11.048l-.078-.078-1.487,1.525s11.9,10.273,31.93,12.013Z"
					transform="translate(-334.925 -488.243)"
					:fill="secColor"
				/>
			</g>
			<g id="LINES" transform="translate(541.617 828.243)">
				<path
					id="Pfad_1585"
					data-name="Pfad 1585"
					d="M335.089,626.094c5.691-1.03,9.863-2.673,12.219-5.121,0,0-2.852-32.816-2.852-42.913s-.813-50.969-1.485-55.345-1.392-8.6-11.777-20.934"
					transform="translate(-286.77 -488.243)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1586"
					data-name="Pfad 1586"
					d="M391.655,501.781l-2.112-2.412c-16.322-1.813-26.5-9.522-28.253-11.048l-.078-.078s-15.607,14.257-13.7,39.668,6.209,39.9,5.874,54.114c0,0-.336,4.449-1.679,6.974a6.585,6.585,0,0,0,.5,4.88s-.671,1.515-1.175,3.03-1.343,2.356-.335,4.039-1.279,4.207-1.279,4.207-2.353,15.65-2.049,15.65h.306s9.9,6.731,26.686,6.731q2.875,0,5.557-.068"
					transform="translate(-347.351 -488.243)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1587"
					data-name="Pfad 1587"
					d="M391.6,626.53q-2.548.084-5.308.085a53.089,53.089,0,0,1-26.936-7.063"
					transform="translate(-359.277 -488.243)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1588"
					data-name="Pfad 1588"
					d="M342.837,619.838c-2.15,2.037-6.011,3.727-11.546,4.91"
					transform="translate(-282.396 -488.243)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1589"
					data-name="Pfad 1589"
					d="M391.6,625.623q-2.415.075-5.019.076a53.093,53.093,0,0,1-26.936-7.062"
					transform="translate(-359.567 -488.243)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1590"
					data-name="Pfad 1590"
					d="M343.109,618.922c-2.185,2.07-6.137,3.782-11.818,4.968"
					transform="translate(-282.667 -488.243)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1591"
					data-name="Pfad 1591"
					d="M347.351,489.768s11.9,10.273,31.93,12.013"
					transform="translate(-334.925 -488.243)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1592"
					data-name="Pfad 1592"
					d="M347.016,490.29a64.886,64.886,0,0,0,32.786,12.1"
					transform="translate(-335.112 -488.243)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path id="Pfad_1593" data-name="Pfad 1593" d="M383.034,496.532" transform="translate(-374.318 -488.243)" fill="#1a1818" />
				<path
					id="Pfad_1594"
					data-name="Pfad 1594"
					d="M358.753,623.719s-9.484,4.929-15.548,5.318"
					transform="translate(-310.226 -488.243)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1595"
					data-name="Pfad 1595"
					d="M358.844,622.724s-9.216,4.936-15.281,5.325"
					transform="translate(-310.675 -488.243)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1596"
					data-name="Pfad 1596"
					d="M342.883,515.312c.225,3.543.637,14.916.637,16.24,0,1.461,1.661,6.423,1.916,7.8a19.012,19.012,0,0,1,.638,4.38c-.128,1.127,1.533,6.633,1.661,7.759s1.022,10.387,1.15,11.013,1.787,9.136,3.118,10.888,5.949,20.4,6.205,21.275,2.937,7.509,3.32,8.385,3.035,7.173,3.163,7.924,6.288,9.471,7.054,9.847,1.433,2.348,1.777,3.312a23.016,23.016,0,0,1,1.311,4.54c.308,1.982,1.951,1.706,1.951,1.706,5.838-.661,10.67-3.051,12.584-3.8s1.405-2.127,1.278-2.388a47.621,47.621,0,0,1-2.4-8.124c-.484-3.129-1.815-10.012-2.07-10.888a23.525,23.525,0,0,1-.766-3.379,16.465,16.465,0,0,0-1.661-4.13,32.086,32.086,0,0,1-1.021-4.63,93.29,93.29,0,0,0-2.682-9.011,20.966,20.966,0,0,1-.767-4.38c-.128-.751-.383-5.381-.638-6.507s-3.448-10.638-3.577-11.264a19.172,19.172,0,0,1-.128-7.508,59.808,59.808,0,0,0,.079-9.991l-.122-1c-.233-2.319,0-14.044,1.613-17.88s1.014-8.772,1.014-8.772l0-.09c-.339-13.977-13.573-16.265-13.573-16.265l-.068-.013s-17.469-4.947-20.938,13.208A5.793,5.793,0,0,0,342.883,515.312Z"
					transform="translate(-342.02 -488.243)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1597"
					data-name="Pfad 1597"
					d="M370.33,496.254a13.266,13.266,0,0,0-1.429,4.04"
					transform="translate(-347.475 -488.243)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1598"
					data-name="Pfad 1598"
					d="M369.731,496.529a12.048,12.048,0,0,0-1.427,3.9"
					transform="translate(-346.28 -488.243)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1599"
					data-name="Pfad 1599"
					d="M370.987,495.98a13.737,13.737,0,0,0-1.484,4.158"
					transform="translate(-348.734 -488.243)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
			</g>
			<g id="SHADING" transform="translate(545.16 834.138)">
				<path
					id="Pfad_1567"
					data-name="Pfad 1567"
					d="M388.172,585.185c.3,4.336-3.617,5.031-6.3,7.678-1.777,1.751-6.276,3.855-5.842,6.744.361,2.409,2.551,2.076,4.1.776.945-.793.731-1.156,1.311-1.96.669-.929,1.627-1.308,2.27-2.014a32.344,32.344,0,0,0,3.068-3.646c.918-1.413.186-3.408,1.392-4.437"
					transform="translate(-375.997 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1568"
					data-name="Pfad 1568"
					d="M351.755,518.431s11.811,6.3,9.35,26.206-6.537,29.552-6.537,29.552-2.764,15.877-2.813,16.353-1.985,12.044-1.985,12.044l-3.49,5.71,3.49-10.129V585.185s4.551-14.574.891-19.511-1.019-17.011-1.019-17.011,0-4.945-1.321-5.63c-1.133-.588.9-.054.671-4.926,0,0,.5-7.7,1.009-11.073a39.791,39.791,0,0,1,1.342-4.211A15.1,15.1,0,0,0,351.755,518.431Z"
					transform="translate(-319.614 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1569"
					data-name="Pfad 1569"
					d="M370.346,503.024a6.8,6.8,0,0,0,.433,3.43,12.056,12.056,0,0,1,.451,4.313c-.1,1.287-.012,2.5-.035,3.77-.022,1.227-.351,2.439-.438,3.64a19.051,19.051,0,0,1-1.236,6.435,11.884,11.884,0,0,0-.932,2.565,10.214,10.214,0,0,0-.624,2.645c.983-.144,1.451-2.874,1.865-3.7a12.179,12.179,0,0,0,1.415-4.308,17.678,17.678,0,0,1,.781-3.505c.411-1.209.06-2.247.209-3.454a11.053,11.053,0,0,0,.2-2.762c-.123-1.084-.389-2.175-.606-3.238a55.72,55.72,0,0,0-1.549-5.52"
					transform="translate(-352.256 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1570"
					data-name="Pfad 1570"
					d="M366.84,504.49a3.153,3.153,0,0,1-.86-1.586c-.3-.649.127-.72.5-.882a8.1,8.1,0,0,1,1.895-.692,5.675,5.675,0,0,0,1.618-.422.289.289,0,0,0,.178-.3,3.24,3.24,0,0,0-1.793.039c-.747.164-1.686.155-2.429.328-.192.045-.785.4-1.315.068-.737-.457.028-.976.071-1.1.119-.339.119-.788.241-1.142a5.556,5.556,0,0,1,.786-1.619,4.055,4.055,0,0,0,.76-1.994.482.482,0,0,0,0-.291c-.27.194-.2.7-.275,1.05a3.756,3.756,0,0,1-.364.983c-.355.676-.821,1.268-1.178,1.931-.122.226-.217.5-.35.73-.1.179-.282.551-.737.39a2.307,2.307,0,0,1-.34-.714,9.3,9.3,0,0,0-.476-.848c-.341-.592-.36-1-.208-1.261.207-.358.838-.378,1.159-.643a1.421,1.421,0,0,0,.568-.926c.042-.321-.141-.828,0-1.065a7.357,7.357,0,0,1-1.123-.516,3.849,3.849,0,0,1,.344,1.52,1.576,1.576,0,0,1-.269.864c-.115.146-.151.5-.468.467a1.421,1.421,0,0,1-.271-.88,10.185,10.185,0,0,1,.007-1.02c-.01-.251-.047-.526-.064-.772-.014-.181.072-.364-.072-.614-.4.513-.05,1.581-.083,2.39a15.125,15.125,0,0,0,.116,2.068c.035.336.135.734.143,1.055a4.328,4.328,0,0,0,.221,1.155,4.759,4.759,0,0,0,1.284,2.388,29.113,29.113,0,0,0,2.809,1.831"
					transform="translate(-344.196 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1571"
					data-name="Pfad 1571"
					d="M359.651,525.537c.253-.419-.177-1.068-.632-.955a55.651,55.651,0,0,0,.96,6.738,109.32,109.32,0,0,0,4.971,15.834,26.459,26.459,0,0,0-1.25-6.178q-1.219-4.43-2.024-8.974a9.022,9.022,0,0,1,1.87,3.908q.877,3.035,1.663,6.1c-.034-3.245-1.109-6.362-2.169-9.4l-2.158-6.184a3.261,3.261,0,0,0-1.439-2.1,2.509,2.509,0,0,1-.924.671"
					transform="translate(-335.587 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1572"
					data-name="Pfad 1572"
					d="M379.141,533.745c.257-.466-.5-1.044-.832-.633s.325,1.121.716.777a2.6,2.6,0,0,1,1.266-.611l-2.3,7.7a20.888,20.888,0,0,1,4.094-16.668,3.61,3.61,0,0,0-1.865,2.018c-2.07,4.006-3.628,8.533-3.139,13.076.346,3.221,1.706,6.293,1.689,9.534,1.691-3.346-.344-7.585.635-11.24.42-1.566,1.392-3.015,1.321-4.642a.9.9,0,0,0-.113-.457c-.273-.411-.91-.124-1.162.3s-.368.974-.762,1.251"
					transform="translate(-370.878 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1573"
					data-name="Pfad 1573"
					d="M376.5,541.567c-1.283,3.471-.036,7.391-.371,11.1-.164,1.806-.7,3.547-1.031,5.326a23.383,23.383,0,0,0,.026,8.635c.613-2.3.341-4.741.471-7.125a66.313,66.313,0,0,1,1.5-8.748,20,20,0,0,0,.115-8.816.824.824,0,0,0-.184-.39c-.382-.374-.9.273-1.06.8"
					transform="translate(-364.189 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1574"
					data-name="Pfad 1574"
					d="M358.346,621.58a1.568,1.568,0,0,0,.825-.142.625.625,0,0,0,.29-.752.664.664,0,0,0-.336-.3,1.486,1.486,0,0,0-1.575.215c-.171.145-.31.328-.477.48a3.3,3.3,0,0,1-.786.5,16.489,16.489,0,0,1-5.179,1.587,1.317,1.317,0,0,0-.609.182.5.5,0,0,0-.191.586,54.789,54.789,0,0,1,8.323-2.321"
					transform="translate(-321.654 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1575"
					data-name="Pfad 1575"
					d="M352.163,619.052l2.125-1.263a2.017,2.017,0,0,0,.632-.505.756.756,0,0,0,.091-.791,8.12,8.12,0,0,0-2.174.986c-1.7.861-3.7,1.059-5.2,2.258-.84.669-1.545,1.653-2.577,1.848a1.227,1.227,0,0,1,.469-.854c2.436-2.429,5.267-4.59,8.536-5.3a1.668,1.668,0,0,1,1.771.382,1.366,1.366,0,0,1-.043,1.451,2.873,2.873,0,0,1-1.127.948,8.749,8.749,0,0,1-2.669.928"
					transform="translate(-312.978 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1576"
					data-name="Pfad 1576"
					d="M362.327,617.637a5.756,5.756,0,0,1,1.6-.19.669.669,0,0,1,.492.155c.288.308-.092.843-.494.918s-.832-.065-1.215.083-.606.518-.9.8a4.238,4.238,0,0,1-1.993.887,3.607,3.607,0,0,1-2.822-.081.454.454,0,0,1-.255-.389c.021-.207.255-.294.448-.329a7.654,7.654,0,0,0,3.3-.9,13.359,13.359,0,0,0-3.221-.27,4.383,4.383,0,0,1-1.5-.089,2.086,2.086,0,0,1-1.288-1.261,4.967,4.967,0,0,1,2.586.078l4.353.915a1.411,1.411,0,0,0,.74.021.549.549,0,0,0,.38-.6"
					transform="translate(-330.858 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1577"
					data-name="Pfad 1577"
					d="M357.75,611.126a5.974,5.974,0,0,0-4.2.5.337.337,0,0,0-.2.2c-.045.2.21.3.4.318l2.609.221a1.871,1.871,0,0,0,1.117-.136.753.753,0,0,0,.294-1.02c-.171-.228-.543-.4-.44-.67"
					transform="translate(-323.099 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1578"
					data-name="Pfad 1578"
					d="M362.258,609.662q2.073-1.219,4.194-2.341c-.66-.526-1.928-.353-2.05-1.222-.064-.455.293-.834.565-1.19s.459-.938.127-1.231c-.467-.412-1.033.321-1.532.688a2.974,2.974,0,0,1-2.414.11,2.241,2.241,0,0,0-2.323.456l3.947.148a1.5,1.5,0,0,1,.968.267c.261.231.312.751.011.919l-5.926,0a5.075,5.075,0,0,0-1.439.14c-1.014.3-1.835,1.225-2.885,1.319-.752.068-1.493-.31-2.245-.247a2.923,2.923,0,0,0-2.294,2.736,8.387,8.387,0,0,0,.764,3.847,12.35,12.35,0,0,0,.107-3.083c.04-1.032.54-2.221,1.5-2.358a5.169,5.169,0,0,1,1.283.153,4.949,4.949,0,0,0,1.824-.266,54.94,54.94,0,0,1,8.439-1.586c.415-.045.955,0,1.082.421.152.512-.463.867-.939,1.036s-1.041.682-.751,1.12c.159.285.667-.052.5-.329"
					transform="translate(-327.27 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1579"
					data-name="Pfad 1579"
					d="M353.8,595.716c-.266.135-.134.615.143.726a1.5,1.5,0,0,0,.87-.065c1.251-.274,2.61.4,3.8-.1.361-.152.725-.56.551-.93a.814.814,0,0,0-.258-.282c-1.019-.793-2.282-1.313-2.987-2.428a5.511,5.511,0,0,0-.872-1.335,4.193,4.193,0,0,0-1.629-.6.736.736,0,0,1-.413-.248.918.918,0,0,1-.119-.453l-.089-1.046a36.4,36.4,0,0,0,3.762-.235,1.749,1.749,0,0,0,.835-.246.713.713,0,0,0,.285-.8.9.9,0,0,0-.641-.444,6.934,6.934,0,0,0-4.5.253c1.224.687,2.8-.074,4.08.492a15.362,15.362,0,0,1-3.4.517c-.413.016-.916.074-1.083.477a1.124,1.124,0,0,0-.052.49,2.1,2.1,0,0,0,.485,1.392c.462.456,1.189.427,1.725.776.819.533.987,1.781,1.757,2.391.463.367,1.129.495,1.4,1.039a1.353,1.353,0,0,1-1.157.325,8.822,8.822,0,0,0-1.226-.16,1.945,1.945,0,0,0-1.579.959"
					transform="translate(-323.143 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1580"
					data-name="Pfad 1580"
					d="M378.2,553.591a242.428,242.428,0,0,0-2.74,24.325,2.23,2.23,0,0,1-.531-1.567,71.721,71.721,0,0,1,.247-12.529l-.941,1.212c-.61-1.213-.259-2.689-.012-4.04a23.442,23.442,0,0,0,.072-7.973c-1.27,2.072-.471,4.838-.918,7.266-.217,1.183-.741,2.359-.522,3.542a12.125,12.125,0,0,0,.714,1.9c.7,1.9.289,4.026.243,6.066a16.568,16.568,0,0,0,1.22,6.626c.123.3.393.652.666.5a.624.624,0,0,0,.237-.39c1.866-6.247.835-13.059,1.953-19.506.317-1.834.8-3.8.087-5.5"
					transform="translate(-362.969 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1581"
					data-name="Pfad 1581"
					d="M387.641,582.319l-.84-3.892c-1.723,2.572-3.251,6.382-5.436,8.6-1.039,1.055-2.031,1.516-2.2,2.9-.3,2.487.438,2.758.589,3.664a.22.22,0,0,0,.348.146,12.3,12.3,0,0,0,1.6-.931c.663-.689.465-.786.957-1.52.716-1.072.8-2.01,2.2-2.78a8.282,8.282,0,0,1,1.1-3.171c.142-.246.864-.729,1.12-1.113.409-.618.189-1.271.569-1.91"
					transform="translate(-378.537 -493.575)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<g id="SIZE_REF" transform="translate(553.638 829.464)">
				<ellipse
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.499"
					cy="0.5"
					rx="0.499"
					ry="0.5"
					transform="translate(31.915 12)"
					fill="transparent"
				/>
				<ellipse
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.499"
					cy="0.5"
					rx="0.499"
					ry="0.5"
					transform="translate(0)"
					fill="transparent"
				/>
			</g>
			<!-- <g id="POSITIONEN" transform="translate(557.607 863.464)">
				<ellipse
					id="center"
					ref="position-center"
					cx="0.499"
					cy="0.5"
					rx="0.499"
					ry="0.5"
					transform="translate(0)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<g
				id="PRINT-AREA"
				transform="translate(546.055 855.134)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="24.9" height="91.16" fill="none" ref="print-area" />
			</g>
			<!-- <g
				id="PRINT-AREA"
				transform="translate(588.247 960.362) rotate(173)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect x="0.05" y="0.05" width="32.852" height="117.941" fill="none" ref="print-area" />
			</g> -->
		</g>
	</svg>
</template>

<script>
export default {
	name: 'LongsleeveRight',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
}
</script>