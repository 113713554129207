<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<g id="POLO_RIGHT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -425.463501, -598.0896)">
			<g id="COLORS" transform="translate(471.193 602.195)">
				<path
					id="Pfad_1427"
					data-name="Pfad 1427"
					d="M627.29,1036.172c0-9.758-.787-49.254-1.438-53.483-.512-3.332-.469-10.008-.3-14.45a12.742,12.742,0,0,0-.32-2.527l-.45-.406-2.853-.9-.012-.015a.885.885,0,0,0-.371-.471l.384-4.368c-.167-.956-1.228-.586-1.684-.565q-.522.024-1.047.034l.05-.26a.4.4,0,0,0,.066-.349l0-.018-.011-.009c-.063-.23-.3-.544-.97-.953-1.528-.93-15.011-8.627-20.229-11.21s-9.092-4.185-10.848-4.03-1.705.827-1.809,1.86a19.3,19.3,0,0,1-.413,3.616,16.63,16.63,0,0,1-1.627,2.421c-.314.439-.729,1.225-.629,1.564a49.989,49.989,0,0,0-11.1,36.052c.282,3.744.505,7.588.872,10.879.016.142.167.14.183.281,2.03,18,4.91,29.55,4.636,41.132,0,0-.325,4.3-1.626,6.74a6.349,6.349,0,0,0,.487,4.716s-.65,1.464-1.137,2.928-1.3,2.276-.325,3.9-1.239,4.065-1.239,4.065-2.28,15.124-1.985,15.124h.3s9.593,6.5,25.856,6.5,27.807-1.626,32.361-6.342C630.054,1077.64,627.29,1045.929,627.29,1036.172Z"
					transform="translate(-571.514 -942.187)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_1428"
					data-name="Pfad 1428"
					d="M630.1,976.573a18.935,18.935,0,0,1-1.012,8.129c-1.125,2.657-1.424,9.3-1.481,13.58-.005.184-.146.184-.146.366-.014,1.431-.01,2.21.046,2.767l.027.38c-.029.942-7.559,1.238-14.847,1.732-8.041.544-14-.49-15.265-1.416a.4.4,0,0,1-.16-.2l-.21,0q-.178-1.443-.349-2.941c-.017-.15-.174-.2-.191-.347-.378-3.37-.587-7.062-.878-10.91a52.36,52.36,0,0,1,.783-14.008l.092-.461c3.34-17.321,20.422-12.525,20.422-12.525l.367.077c2.1.481,12.476,3.468,12.769,15.453Z"
					transform="translate(-595.477 -942.187)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1429"
					data-name="Pfad 1429"
					d="M629,1001.592l-.013.129c.373,1-7.359,1.3-14.835,1.806-8.041.544-14-.49-15.265-1.416a.578.578,0,0,1-.1-.09l-.046-.08-.6-2.913c2.194-2.4,8.675-4.079,14.675-4.3a33.137,33.137,0,0,1,16.105,3.921Zm-4.819-1.518-.1-.051a43.77,43.77,0,0,0-11.61-1.342,41.781,41.781,0,0,0-11.281,1.618l-.072.055S615.364,1000.881,624.178,1000.074Z"
					transform="translate(-596.973 -942.187)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1430"
					data-name="Pfad 1430"
					d="M618.954,987.968s-.778-11.83-.778-14.93.622-7.232-.518-7.7a28.965,28.965,0,0,0-2.949-.93l-.006,0a.894.894,0,0,0-.414-.519,29.078,29.078,0,0,0,.42-4.236c.058-1.136-1.689-.666-1.689-.666h-1.157s.775-.625-.759-1.555-15.064-8.627-20.3-11.21-9.124-4.185-10.886-4.03-1.711.827-1.815,1.86a19.23,19.23,0,0,1-.414,3.616c-.311.827-2.649,3.431-2.224,4.037.56.8,9.376,2.03,15.411,6.667,6.893,5.3,12.164,12.86,12.578,13.163.778.568,1.461,1.452,2.384,0a16.676,16.676,0,0,1,4.021-4.656,13.875,13.875,0,0,1,2.627-1.423c.006.012,4.394,9.491,5.016,13.828S618.954,987.968,618.954,987.968Z"
					transform="translate(-564.327 -942.187)"
					:fill="secColor"
				/>
			</g>
			<g id="LINES" transform="translate(471.193 602.195)">
				<path
					id="Pfad_1431"
					data-name="Pfad 1431"
					d="M629.972,1074.483c-4.179,3.948-15.026,6.549-32.266,6.549a51.544,51.544,0,0,1-26.1-6.825"
					transform="translate(-571.526 -942.187)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1432"
					data-name="Pfad 1432"
					d="M629.972,1075.416c-4.179,3.948-15.026,6.548-32.266,6.548a51.536,51.536,0,0,1-26.1-6.824"
					transform="translate(-571.526 -942.187)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1433"
					data-name="Pfad 1433"
					d="M601.795,1003.212c-.362,3.556,1.865,9.488,2.04,12.93.179,3.49,1.767,6.285,1.794,9.781.015,1.853.387,3.478.49,5.371.082,1.49-.132,2.69.482,3.911.185.368.607.546.816,1.253a25.216,25.216,0,0,1,.66,5.9c.1,2.516-.783,4.729-.815,7.14-.074,5.584-1.091,10.612-1.8,16.053-.3,2.285-1.165,4.675-1.308,6.927-.128,2.011.17,4.176.167,6.219,0,1.674-.364,3.773.218,5.286"
					transform="translate(-579.771 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1434"
					data-name="Pfad 1434"
					d="M582.8,951.692a49.939,49.939,0,0,0-11.122,36.018c.29,3.848.5,7.54.875,10.91.017.15.173.2.19.347,2.029,17.878,4.9,29.552,4.626,41.035,0,0-.325,4.3-1.626,6.74a6.349,6.349,0,0,0,.487,4.716s-.65,1.464-1.137,2.928-1.3,2.276-.325,3.9-1.239,4.066-1.239,4.066-2.28,15.124-1.985,15.124h.3s9.593,6.5,25.856,6.5,27.807-1.626,32.361-6.342c0,0-2.764-31.711-2.764-41.468,0-7.959-.524-35.7-1.07-47.954"
					transform="translate(-571.514 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1435"
					data-name="Pfad 1435"
					d="M608.679,956.112a13.778,13.778,0,0,0-1.85,4.6"
					transform="translate(-585.448 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1436"
					data-name="Pfad 1436"
					d="M608.156,956.431a12.4,12.4,0,0,0-1.835,4.4"
					transform="translate(-584.419 -942.187)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1437"
					data-name="Pfad 1437"
					d="M609.252,955.914a14.2,14.2,0,0,0-1.876,4.665"
					transform="translate(-586.569 -942.187)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1438"
					data-name="Pfad 1438"
					d="M602.907,1000.354s14.246.527,23.06-.28"
					transform="translate(-598.724 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1439"
					data-name="Pfad 1439"
					d="M628.15,1001.481a42.881,42.881,0,0,0-16.193-2.8c-9.121.218-14.946,2.5-13.68,3.43s7.224,1.96,15.265,1.416S629.923,1002.678,628.15,1001.481Z"
					transform="translate(-596.33 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.216"
				/>
				<path
					id="Pfad_1440"
					data-name="Pfad 1440"
					d="M629.065,997.454a33.207,33.207,0,0,0-16.13-3.936c-6,.221-12.587,1.9-14.781,4.3"
					transform="translate(-597.053 -942.187)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1441"
					data-name="Pfad 1441"
					d="M628.961,998.648a33.136,33.136,0,0,0-16.105-3.921c-6,.222-12.483,1.905-14.676,4.3"
					transform="translate(-596.973 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1442"
					data-name="Pfad 1442"
					d="M598.089,976.246s.571,4.8-.979,8.456c-1.125,2.657-1.424,9.3-1.481,13.58-.005.184-.146.184-.146.366-.014,1.431.017,2.556.074,3.113"
					transform="translate(-563.613 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1443"
					data-name="Pfad 1443"
					d="M595.582,960.716s12.81,2.2,13.136,15.53"
					transform="translate(-574.221 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1444"
					data-name="Pfad 1444"
					d="M629.093,960.716s-17.082-4.8-20.422,12.525"
					transform="translate(-607.636 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<line
					id="Linie_280"
					data-name="Linie 280"
					x1="0.253"
					y1="0.837"
					transform="translate(1.485 58.948)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path id="Pfad_1445" data-name="Pfad 1445" d="M621.609,957.387" transform="translate(-613.134 -942.187)" fill="#1a1818" />
				<path
					id="Pfad_1446"
					data-name="Pfad 1446"
					d="M616.093,958.992s.775-.625-.759-1.555-15.064-8.627-20.3-11.21-9.124-4.185-10.886-4.03-1.711.827-1.815,1.86a19.23,19.23,0,0,1-.414,3.616c-.311.827-2.649,3.431-2.224,4.037.459.653,6.46,1.6,11.933,4.47a24.05,24.05,0,0,1,3.478,2.2c6.893,5.3,12.164,12.86,12.578,13.163.778.568,1.461,1.452,2.384,0a16.676,16.676,0,0,1,4.021-4.656,15.038,15.038,0,0,1,4.668-2.112s.539-.31-.239-.878-7.88-5.579-7.88-5.579-9.9-7.336-12.079-8.524-6.325-3.668-6.325-3.668l-3.833-1.807-.987-.466a4.094,4.094,0,0,1,1.97.465"
					transform="translate(-568.556 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1447"
					data-name="Pfad 1447"
					d="M575.413,965.46s4.394,9.489,5.016,13.829,1.451,8.679,1.451,8.679-.778-11.83-.778-14.93.622-7.232-.518-7.7a28.965,28.965,0,0,0-2.949-.93"
					transform="translate(-527.253 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1448"
					data-name="Pfad 1448"
					d="M579.642,958.992h5.652s1.747-.47,1.689.666a29.078,29.078,0,0,1-.42,4.236"
					transform="translate(-536.6 -942.187)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<path
					id="Pfad_1449"
					data-name="Pfad 1449"
					d="M576.554,965.077s3.56.514,3.64,2.028.03,11.711.156,14.22"
					transform="translate(-526.867 -942.187)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
				<path
					id="Pfad_1450"
					data-name="Pfad 1450"
					d="M580.592,959.8s5.366-.417,5.366.6a23.143,23.143,0,0,1-.322,2.881"
					transform="translate(-536.492 -942.187)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.119"
					stroke-dasharray="0.952 0.476"
				/>
			</g>
			<g id="SHADING" transform="translate(472.932 603.203)">
				<path
					id="Pfad_1415"
					data-name="Pfad 1415"
					d="M606.173,958.992s-10.251-7.654-15.434-10.557a81.873,81.873,0,0,0-7.763-4.119s-2.168-.951,1.241,0a56.439,56.439,0,0,1,10.017,4.329c2.153,1.171,13.385,7.464,13.385,7.464s3.867,1.9,3.141,2.723Z"
					transform="translate(-564.665 -943.195)"
					opacity="0.73"
					fill="url(#linear-gradient)"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1416"
					data-name="Pfad 1416"
					d="M625.3,1021.808c-.12,1.462-.885,2.371-1.32,3.685-.6,1.817-.379,3.948-.809,5.854-.6,2.669-3.169,4.43-4.679,6.662-1.676,2.485-2.115,4.684-4.241,6.831a4.409,4.409,0,0,0-1.573,2.986c-.157,1.27.763,2.752.643,3.5.357-.573.862-.491,1.256-.9.644-.665.452-.759.931-1.468.7-1.036.78-1.943,2.137-2.687a7.977,7.977,0,0,1,1.066-3.064c.137-.237.84-.7,1.089-1.075.4-.6.185-1.228.554-1.846.815-1.36,2.175-1.937,2.722-3.555a13.639,13.639,0,0,0,.293-3.612c3.348-.176,1.6-8.02,2.149-10.229"
					transform="translate(-609.793 -943.195)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1417"
					data-name="Pfad 1417"
					d="M626.648,1043.056c.286,4.191-3.518,4.862-6.13,7.42-1.727,1.692-6.1,3.726-5.681,6.517.352,2.327,2.481,2.006,3.991.75.919-.766.711-1.117,1.276-1.894.65-.9,1.582-1.264,2.207-1.946a31.392,31.392,0,0,0,2.982-3.523c.892-1.366.181-3.294,1.355-4.289"
					transform="translate(-613.103 -943.195)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1418"
					data-name="Pfad 1418"
					d="M593.834,978.552s11.484,6.09,9.091,25.324-6.355,28.558-6.355,28.558-2.687,15.342-2.736,15.8-1.93,11.638-1.93,11.638l-3.393,5.518,3.393-9.787v-12.546s3.558-14.311,0-19.081-5.825-21.051-5.825-21.051,3.43-.375,4.415-.6c1.208-.277.874-.052.652-4.76,0,0,.483-7.436.981-10.7a38.167,38.167,0,0,1,1.306-4.07A14.572,14.572,0,0,0,593.834,978.552Z"
					transform="translate(-560.862 -943.195)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1419"
					data-name="Pfad 1419"
					d="M601.917,969.362c.394,1.536,1.084,2.835,1.541,4.343a41.214,41.214,0,0,1,1.226,5.611c.367,2.343.671,5.128-.263,7.388a12.547,12.547,0,0,1-1.987,3.033c.7-2.2,1.618-4.3,1.486-6.686-.063-1.126-.5-2.219-.578-3.354a26.38,26.38,0,0,0-.347-3.332c-.216-1.108-.486-2.208-.641-3.277a15.675,15.675,0,0,1-.453-3.507"
					transform="translate(-578.509 -943.195)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1420"
					data-name="Pfad 1420"
					d="M606.1,975.7a17.785,17.785,0,0,0,.326,2.611,32.232,32.232,0,0,1,.38,3.217c0,1.7-.341,3.452-.492,5.154a9.1,9.1,0,0,1-.928,3.643,13.181,13.181,0,0,0-1.612,2.812c-.03.124-.113.243-.148.361,3.977-3.223,3.717-9.436,3.739-14.045a12.3,12.3,0,0,0,.049-2.551,4.121,4.121,0,0,1-.465,1.505c-.283-.864-.588-1.709-.817-2.573"
					transform="translate(-582.693 -943.195)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1421"
					data-name="Pfad 1421"
					d="M609.362,963.661a6.524,6.524,0,0,0,.42,3.315,11.585,11.585,0,0,1,.439,4.168c-.1,1.243-.012,2.41-.034,3.643-.021,1.186-.341,2.356-.425,3.517a18.308,18.308,0,0,1-1.2,6.218,11.478,11.478,0,0,0-.907,2.479,9.792,9.792,0,0,0-.606,2.556c.955-.139,1.41-2.777,1.813-3.575a11.7,11.7,0,0,0,1.375-4.163,17.088,17.088,0,0,1,.76-3.388c.4-1.168.058-2.171.2-3.338a10.621,10.621,0,0,0,.193-2.668c-.119-1.048-.377-2.1-.589-3.129a53.349,53.349,0,0,0-1.506-5.334"
					transform="translate(-590.066 -943.195)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1422"
					data-name="Pfad 1422"
					d="M605.987,965.077a3.044,3.044,0,0,1-.836-1.532c-.3-.627.123-.7.482-.852a7.884,7.884,0,0,1,1.843-.669,5.605,5.605,0,0,0,1.573-.407.281.281,0,0,0,.174-.288,3.171,3.171,0,0,0-1.744.038c-.726.159-1.639.15-2.36.317-.188.043-.764.382-1.28.065-.716-.441.027-.942.069-1.064.114-.327.114-.761.234-1.1a5.332,5.332,0,0,1,.765-1.564,3.909,3.909,0,0,0,.739-1.927.454.454,0,0,0,0-.281c-.263.187-.194.677-.267,1.014a3.645,3.645,0,0,1-.354.95c-.345.653-.8,1.226-1.146,1.866-.117.219-.211.484-.34.706-.1.172-.274.532-.716.377a2.215,2.215,0,0,1-.332-.691,8.742,8.742,0,0,0-.462-.819c-.332-.572-.351-.969-.2-1.219.2-.345.816-.365,1.128-.621a1.378,1.378,0,0,0,.553-.895c.04-.31-.136-.8,0-1.029a7.121,7.121,0,0,1-1.091-.5,3.7,3.7,0,0,1,.334,1.468,1.507,1.507,0,0,1-.263.835c-.11.141-.146.485-.455.452a1.371,1.371,0,0,1-.263-.851,9.461,9.461,0,0,1,.007-.986c-.01-.242-.046-.508-.063-.746-.013-.174.07-.351-.069-.593-.385.5-.049,1.528-.08,2.31a14.485,14.485,0,0,0,.112,2c.034.324.13.71.138,1.02a4.121,4.121,0,0,0,.216,1.116,4.578,4.578,0,0,0,1.247,2.307,28.222,28.222,0,0,0,2.731,1.769"
					transform="translate(-582.265 -943.195)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1423"
					data-name="Pfad 1423"
					d="M590.187,958.377s8.425,9.646,9.78,11.615,3.28,3.274,3.472,2.147c0,0-2.846-3.637-4.605-5.647S593.629,961.453,590.187,958.377Z"
					transform="translate(-565.171 -943.195)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1424"
					data-name="Pfad 1424"
					d="M608.985,943.213c-.2-.093-.405.19-.333.4a.843.843,0,0,0,.489.417,29.4,29.4,0,0,0,4.43,1.66,13.168,13.168,0,0,1,2.186.732,3.384,3.384,0,0,1,1.648,1.547,9.993,9.993,0,0,1-3.992,0,11.872,11.872,0,0,0-2.561-.446,2.254,2.254,0,0,0-2.119,1.233,6.1,6.1,0,0,0-.207,1.212,1.275,1.275,0,0,1-.628,1,.4.4,0,0,1-.332.02.432.432,0,0,1-.2-.271,2.775,2.775,0,0,1,.387-1.84,12.082,12.082,0,0,0,.671-3.14l.262-2.151"
					transform="translate(-596.311 -943.195)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1425"
					data-name="Pfad 1425"
					d="M582.317,971.762c2.626-1.369,4.515-3.786,6.318-6.131a8.86,8.86,0,0,0-3.6,2.363,26.393,26.393,0,0,0-2.663,3.429c-.077.111-.106.327.03.325"
					transform="translate(-542.457 -943.195)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1426"
					data-name="Pfad 1426"
					d="M628.187,1001.481a42.984,42.984,0,0,0-16.214-2.8c-9.13.218-14.964,2.5-13.695,3.43s7.234,1.96,15.283,1.416S629.961,1002.678,628.187,1001.481Z"
					transform="translate(-598.105 -943.195)"
					fill="url(#linear-gradient-2)"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<g id="SIZE_REF" transform="translate(486.142 603.203)">
				<ellipse
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.502"
					cy="0.5"
					rx="0.502"
					ry="0.5"
					transform="translate(30.266 17.891)"
					fill="transparent"
				/>
				<ellipse
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.502"
					cy="0.5"
					rx="0.502"
					ry="0.5"
					transform="translate(0)"
					fill="transparent"
				/>
			</g>
			<!-- <g id="POSITIONEN" transform="translate(487.701 638.703)">
				<ellipse
					id="center"
					ref="position-center"
					cx="0.502"
					cy="0.5"
					rx="0.502"
					ry="0.5"
					transform="translate(0)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<g
				id="PRINT-AREA"
				transform="translate(472.155 620.153)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="32.012" height="37.9" fill="none" ref="print-area" />
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'PoloRight',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
}
</script>