import { render, staticRenderFns } from "./CapDadLeft.vue?vue&type=template&id=5f22a3a4&"
import script from "./CapDadLeft.vue?vue&type=script&lang=js&"
export * from "./CapDadLeft.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports