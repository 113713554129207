const setPositions = function(that, topDistance, centerVertical = false) {
	// set position wrapper transform
	const printAreaWrapper = that.$refs['print-area-wrapper']
	const positionWrapper = that.$refs['position-wrapper']
	positionWrapper.setAttribute('transform', printAreaWrapper.getAttribute('transform'))

	// get position dot radius / distance
	const radiusRef = that.$refs['position-center']
		? that.$refs['position-center']
		: that.$refs['position-left']
		? that.$refs['position-left']
		: that.$refs['position-right']
		? that.$refs['position-left']
		: null
	const positionDotRadius = radiusRef ? radiusRef.getAttribute('r') : 0.5
	const dotDistance = 20

	// set transform of single positions
	const printArea = that.$refs['print-area']
	const printAreaWidth = printArea.getAttribute('width')

	if (centerVertical) {
		const printAreaHeight = printArea.getAttribute('height')
		topDistance = printAreaHeight / 2 - positionDotRadius
	}

	if (that.$refs['position-center'])
		that.$refs['position-center'].setAttribute('transform', `translate(${printAreaWidth / 2 - positionDotRadius} ${topDistance})`)
	if (that.$refs['position-left'])
		that.$refs['position-left'].setAttribute(
			'transform',
			`translate(${printAreaWidth / 2 + dotDistance - positionDotRadius} ${topDistance})`
		)
	if (that.$refs['position-right'])
		that.$refs['position-right'].setAttribute(
			'transform',
			`translate(${printAreaWidth / 2 - dotDistance - positionDotRadius} ${topDistance})`
		)
}

export { setPositions }
