<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<g id="CREWNECK_RIGHT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -190.402939, -783.720032)">
			<g id="COLORS" transform="translate(238.903 787.342)">
				<path
					id="A"
					d="M918.7,153.515c0-3-.81-15.139-1.026-19.817s-.917-17.977-1.079-21.236,1.424-9.548,1.316-20.323-8.088-22.616-8.734-25.649-6.94-8.129-9.912-10.183c-2.543-1.757-7.3-6.967-17.9-5.86a13.432,13.432,0,0,0-3.733,1.282,18.436,18.436,0,0,0-7.458,8.375,25.6,25.6,0,0,0-1.889,6.7c-1.1,4.961-2.593,13.053-2.422,15.227s.091,4.678.459,6.886-.206,12.037-.422,15.822.581,16.5.581,16.5,1.633,41.841,2.01,49.2,1.458,7.149,1.458,7.149l.377.17-.107-.012c2.283,3.269,2.518,9.26,3.058,10.837s2.773,1.1,8.28,1.831,6.856.526,13.5.736,16.466-2.313,19.165-3.154,1.512-4.731,1.4-5.677a12.43,12.43,0,0,1,.864-4.888,15.742,15.742,0,0,0,.863-4.048s.27-1.735.324-2.681.378-2.26.81-5.309S918.7,156.511,918.7,153.515Z"
					transform="translate(-865.782 -48.32)"
					:fill="mainColor"
				/>
				<g id="B" transform="translate(0)">
					<path
						id="Pfad_1296"
						data-name="Pfad 1296"
						d="M867.253,177.747s17.71,2.05,21.489,1.945,16.413-.789,20.516-2.734,5.129-3.574,5.129-3.574a15.741,15.741,0,0,1-.863,4.048,12.428,12.428,0,0,0-.864,4.888c.108.946,1.3,4.836-1.4,5.677s-12.526,3.364-19.167,3.154-7.99,0-13.5-.736-7.74-.255-8.281-1.831S869.536,180.062,867.253,177.747Z"
						transform="translate(-862.821 -48.32)"
						:fill="secColor"
					/>
					<path
						id="Pfad_1297"
						data-name="Pfad 1297"
						d="M882.11,91.126c.4-3.757,2.768-10.49,2.486-13.853s-.353-4.98.492-6.685a25.029,25.029,0,0,1,6.744-7.934,15.384,15.384,0,0,1,11.261-3.94,12.517,12.517,0,0,1,2.94.314c.7.187,1.281.251,1.978.473a35.954,35.954,0,0,1,4.353,2.042,11.083,11.083,0,0,1,5.926,6.218c2.453,6.4-5.014,33.155-5.117,34.937s-.94,3.488-.747,5.26a14.968,14.968,0,0,1-.385,4.2c-.086.631-.912,14.4-.49,21.017.381,5.956-.4,11.916-.94,13.388s-1.719,4.4-.534,6.785,2.481,4.772,2.073,7.393-.566,4.4-1.357,5.928.029,1.62-.7,2.677-1.985,1.18-1.848,2.433.6,1.455.608,2.082-.337.319.133,1.044.7,1.035.482,1.456a7.253,7.253,0,0,1-1.112,2c-.4.286-1.253.336-1.17,1.162h-.006c-.77,1.077-15.279,1.7-15.279,1.7a11.37,11.37,0,0,1-2.8-1.63c-.468-.621.421-1.993-.049-2.718a10.12,10.12,0,0,0-1.748-2.065c-.7-.617-2.9-1.315-2.454-2.472a8.155,8.155,0,0,0,.874-2.941c-.017-.941-.967-1.449-.969-2.338s-.133-5.7-.331-14.686-1.065-21.954-1.154-26.686,1.183-7.421.38-15.239S881.706,94.883,882.11,91.126Z"
						transform="translate(-882.026 -48.32)"
						:fill="secColor"
					/>
					<path
						id="Pfad_1299"
						data-name="Pfad 1299"
						d="M877.318,50.6s6.524-1.048,10.874.7,5.117,2.446,6.907,3.887a58.5,58.5,0,0,1,4.776,4.672c.9.917,3.879,2.567,4.518,3.266s-.852-1.213-1.662-2.087-3.581-2.882-4.221-3.668-4.391-5.021-9-6.9-5.927-2.4-10.788-2.053C878.725,48.421,877.83,48.771,877.318,50.6Z"
						transform="translate(-863.056 -48.32)"
						:fill="secColor"
					/>
				</g>
				<path
					id="C"
					d="M907.152,179.527h.006a1.523,1.523,0,0,0,.063.318c.363,1.145,1.242,5.628,1.368,6.253s.832,1.87-.3,2.3a42,42,0,0,1-11.142,2.052c-3.994-.044-4.446.172-4.584-1.186s-.688-8.041-.688-8.041S906.382,180.6,907.152,179.527Z"
					transform="translate(-881.986 -48.32)"
					:fill="secColor"
				/>
			</g>
			<g id="LINES" transform="translate(240.064 794.236)">
				<path
					id="Pfad_1287"
					data-name="Pfad 1287"
					d="M878.294,178.346a15.5,15.5,0,0,0,1.34-1.5.819.819,0,0,0,0-1.139l-.043-.061a.809.809,0,0,0-.352-.4,10.913,10.913,0,0,0-.4-1.847.8.8,0,0,0-.155-.284,3.988,3.988,0,0,0-.258-2.326.371.371,0,0,1-.013-.036c2.781-1.284,3.185-5.214,3.642-7.976.857-5.176-2.326-8.922-2.022-14,0-.006,0-.01,0-.016,1.682-2.682,1.6-6.966,1.583-9.692-.015-3.192-.2-6.369-.137-9.562.056-2.64.728-5.352.323-7.962a.787.787,0,0,0,.064-.268c.108-6.488,1.387-12.9,1.574-19.35a46.352,46.352,0,0,0,2.408-9.564,25.292,25.292,0,0,0,1.26,4.9c2.142-1.114,1.729-4.5,3.621-5.983a1.25,1.25,0,0,1,.953-.32,1.365,1.365,0,0,1,.693.438,5.349,5.349,0,0,1,.555,6.621c-.569.814-1.366,1.466-1.886,2.31-1.054,1.711-.8,3.878-1.335,5.805-.406,1.467-1.263,2.779-1.742,4.225a7.5,7.5,0,0,0-.568,4.023c.431,2.962.348,6.031,1.134,8.922.775,2.85-1.116,5.735-1.2,8.682-.057,1.986.709,3.9.977,5.871a31.522,31.522,0,0,1-.156,6.411c-.529,7.632.823,15.324-.011,22.93-.3,2.752-.889,5.481-.948,8.249a43.411,43.411,0,0,0,.375,5.678,11.953,11.953,0,0,0,.589,2.986c.819,2.14,2.767,3.638,4.64,5.016a20.065,20.065,0,0,1-9.762,1.85,1.1,1.1,0,0,1-.683-.207c-.165-.15-1.913.374-2.893-.669s-.589-1.466-.858-2.758a22.8,22.8,0,0,1-.645-4.066c-.064-1.548-.794-2.581-.8-4.057a.875.875,0,0,0,.471-.2A7.1,7.1,0,0,0,878.294,178.346Z"
					transform="translate(-853.284 -66.236)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1288"
					data-name="Pfad 1288"
					d="M904.689,130.765a6.788,6.788,0,0,0-4.662.3.371.371,0,0,0-.233.2c-.061.21.216.336.424.364l2.867.385a2.12,2.12,0,0,0,1.242-.083.8.8,0,0,0,.385-1.078c-.176-.253-.576-.459-.448-.743"
					transform="translate(-886.883 -66.236)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1289"
					data-name="Pfad 1289"
					d="M909.508,129.355q3-1.156,6.063-2.206c-.9-.613-2.7-.52-2.825-1.461-.063-.492.464-.873.868-1.236s.7-.973.251-1.311c-.635-.476-1.479.269-2.2.626a5.427,5.427,0,0,1-3.418-.058c-1.138-.248-2.528-.376-3.308.321l5.569.447a2.637,2.637,0,0,1,1.353.356.558.558,0,0,1-.037.987l-8.375-.426a9.339,9.339,0,0,0-2.042.045c-1.453.248-2.665,1.18-4.155,1.2-1.067.018-2.092-.441-3.159-.428a3.567,3.567,0,0,0-3.4,2.768,7.177,7.177,0,0,0,.856,4.185c.475-1.034.214-2.2.33-3.3a2.564,2.564,0,0,1,2.258-2.422,9.442,9.442,0,0,1,1.8.257,9.079,9.079,0,0,0,2.593-.152,100.148,100.148,0,0,1,12.018-1.088c.589-.018,1.351.067,1.5.531.185.56-.7.9-1.388,1.043s-1.51.656-1.126,1.147c.207.317.945-.007.721-.317"
					transform="translate(-888.496 -66.236)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1290"
					data-name="Pfad 1290"
					d="M881.259,100.733a3.819,3.819,0,0,1,.636-1.778l.369,1.323.993-1a27.275,27.275,0,0,0-2.09,5.324q-1.979,6.321-3.961,12.642a18.743,18.743,0,0,0-.965,4.175c-.108,1.473.135,2.953.068,4.429a20.664,20.664,0,0,1-.791,4.251q-2.507,9.579-5.525,19.022c-.874-4.267,1.477-8.437,2.82-12.587a55.786,55.786,0,0,0,1.954-10.328,6.54,6.54,0,0,0-.382-4.117,59.7,59.7,0,0,1-3.993,11.493c-1.022,2.192-1.1-3.2-.379-4.92,1.14-2.708,2.751-5.2,3.995-7.869,2.908-6.228,3.763-13.263,7.042-19.314a3.934,3.934,0,0,0,.56-1.42,1.237,1.237,0,0,0-.646-1.285"
					transform="translate(-835.078 -66.236)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1291"
					data-name="Pfad 1291"
					d="M868.771,111.037l.192,13.7a133.333,133.333,0,0,1-.474,16.813c-.491,4.351-1.414,8.718-.929,13.07.329,2.944,1.3,5.819,1.322,8.78a27.7,27.7,0,0,1-.866,6.1l-1.247,5.7a8.363,8.363,0,0,0,3.44-5.559,16.466,16.466,0,0,0,.072-2.928q-.244-7.137-.288-14.276c-.045-7.126.06-14.251.047-21.376a136.965,136.965,0,0,0-1.148-20.259"
					transform="translate(-819.367 -66.236)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1292"
					data-name="Pfad 1292"
					d="M891.094,138.322a2.818,2.818,0,0,1,1.823-2.283,8.067,8.067,0,0,1,3.2-.527,11.61,11.61,0,0,1,3.341.362,18.656,18.656,0,0,1,2.973,1.323c3.736,1.835,7.906,2.8,11.983,3.9a1.21,1.21,0,0,1,.557.26.337.337,0,0,1,0,.509.625.625,0,0,1-.381.062c-.462-.026-.921-.076-1.381-.126a40.369,40.369,0,0,1-4.889-.739c-2.677-.634-5.168-1.809-7.846-2.443-.37-.088-.957.011-.863.346a.526.526,0,0,0,.207.242,11.309,11.309,0,0,0,4.38,2.44c1.133.247,2.322.163,3.473.323a3.55,3.55,0,0,1,2.818,1.623,9.707,9.707,0,0,1-3.608.467c-1.058-.063-2.1-.3-3.159-.34-2.678-.107-5.263,1.018-7.945,1q5.289.523,10.585.981a24.18,24.18,0,0,0,5.13.072,2.279,2.279,0,0,1,.882-.008,1.79,1.79,0,0,1,.449.212c.525.319,1.06.777,1.029,1.347a1.345,1.345,0,0,1-1.12,1.071,4.864,4.864,0,0,1-1.734-.023,59.143,59.143,0,0,0-11.091-.386,51.78,51.78,0,0,0,10.487,1.12,2.185,2.185,0,0,1,1.176.222c.331.214.446.725.112.935a1.5,1.5,0,0,1,.9.06c.265.14.366.537.106.685a.738.738,0,0,1-.274.07,64.7,64.7,0,0,1-12.328.309,19.148,19.148,0,0,0,7.089,1.065,14.546,14.546,0,0,1,4.926.156c.2.064.425.167.466.356.059.265-.269.456-.547.553-4.57,1.59-9.723.137-14.585.678l12.305,1.363a1.375,1.375,0,0,1,.89.318c.2.222.187.535.277.808a3.342,3.342,0,0,0,.955,1.159,4.473,4.473,0,0,1,1.309,2.69,1.518,1.518,0,0,0,1.273-.9,4.261,4.261,0,0,0,.587-1.966,33.121,33.121,0,0,0-.3-4.69c-.109-1.054,1.358-2.27,1.618-3.241.184-.69-.89-1.119-.653-1.894.592-1.943,1.809-3.731,1.963-5.643a9.745,9.745,0,0,0-.461-3.677,24.814,24.814,0,0,1-6.709.238c-4.649-.723-8.7-3.632-13.4-3.936a15.836,15.836,0,0,0-3.2.147,2.842,2.842,0,0,1-1.7-.094,2.28,2.28,0,0,1-.7-.684l-1.811-2.382a58.954,58.954,0,0,0,.635,7.28q.547-.981,1.1-1.96"
					transform="translate(-889.671 -66.236)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1293"
					data-name="Pfad 1293"
					d="M886.833,66.457a.538.538,0,0,0-.814.263c-.1.381.269.7.537.994a5.373,5.373,0,0,1,.745,1.148,97.466,97.466,0,0,1,5.422,11.313,4.325,4.325,0,0,0,.085-2.322c-.67-3.9-2.794-7.4-5.085-10.663-.335-.476-.789-1.005-1.379-.95"
					transform="translate(-861.217 -66.236)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1294"
					data-name="Pfad 1294"
					d="M890.686,84.47a29.271,29.271,0,0,1-1.189,4.361,1.752,1.752,0,0,1-.87,1.162c-1.627-4.15-1.916-8.675-3.1-12.965a3.485,3.485,0,0,0-1.4-2.276,50.332,50.332,0,0,1,2.218,10.3l-.983-1.807q.285,2.284.531,4.572a14.164,14.164,0,0,1-.047,4.789,29,29,0,0,0-.824,2.98,9.681,9.681,0,0,0,.112,2.483l.53,4.023a18.2,18.2,0,0,0,2.7-6.216c.208-1.158.224-2.347.509-3.488.216-.862.581-1.681.821-2.536a17.935,17.935,0,0,0,.458-2.53q.2-1.517.4-3.033"
					transform="translate(-857.103 -66.236)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<g id="SHADING" transform="translate(238.957 787.27)">
				<path
					id="Pfad_1138"
					data-name="Pfad 1138"
					d="M889.844,179.553c.2,4.675.3,6.923.5,11.58"
					transform="translate(-861.366 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1139"
					data-name="Pfad 1139"
					d="M889.844,179.553c.2,4.675.3,6.923.5,11.58"
					transform="translate(-861.366 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1140"
					data-name="Pfad 1140"
					d="M889.115,179.542c.2,4.632.3,6.993.495,11.621"
					transform="translate(-859.904 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1141"
					data-name="Pfad 1141"
					d="M889.115,179.542c.2,4.632.3,6.993.495,11.621"
					transform="translate(-859.904 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1142"
					data-name="Pfad 1142"
					d="M888.387,179.521c.2,4.586.3,7.039.488,11.642"
					transform="translate(-858.443 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1143"
					data-name="Pfad 1143"
					d="M887.664,179.49c.2,4.538.29,7.068.477,11.65"
					transform="translate(-856.984 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1144"
					data-name="Pfad 1144"
					d="M886.935,179.448c.194,4.489.288,7.173.47,11.737"
					transform="translate(-855.52 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1145"
					data-name="Pfad 1145"
					d="M886.935,179.448c.194,4.489.288,7.173.47,11.737"
					transform="translate(-855.52 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1146"
					data-name="Pfad 1146"
					d="M886.21,179.4c.19,4.438.283,7.191.458,11.739"
					transform="translate(-854.058 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1147"
					data-name="Pfad 1147"
					d="M886.21,179.4c.19,4.438.283,7.191.458,11.739"
					transform="translate(-854.058 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1148"
					data-name="Pfad 1148"
					d="M885.486,179.334c.186,4.387.276,7.21.443,11.746"
					transform="translate(-852.596 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1149"
					data-name="Pfad 1149"
					d="M885.486,179.334c.186,4.387.276,7.21.443,11.746"
					transform="translate(-852.596 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1150"
					data-name="Pfad 1150"
					d="M884.762,179.261c.182,4.336.269,7.229.43,11.755"
					transform="translate(-851.134 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1151"
					data-name="Pfad 1151"
					d="M884.762,179.261c.182,4.336.269,7.229.43,11.755"
					transform="translate(-851.134 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1152"
					data-name="Pfad 1152"
					d="M884.039,179.176c.178,4.285.262,7.174.415,11.693"
					transform="translate(-849.673 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1153"
					data-name="Pfad 1153"
					d="M883.316,179.081c.173,4.235.253,7.17.4,11.684"
					transform="translate(-848.211 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1154"
					data-name="Pfad 1154"
					d="M882.594,178.973c.168,4.187.246,7.211.383,11.722"
					transform="translate(-846.751 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1155"
					data-name="Pfad 1155"
					d="M881.872,190.7"
					transform="translate(-844.924 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1156"
					data-name="Pfad 1156"
					d="M881.872,178.973c.16,4.083.234,7.159.362,11.626"
					transform="translate(-845.286 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1157"
					data-name="Pfad 1157"
					d="M881.15,190.448"
					transform="translate(-843.48 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1158"
					data-name="Pfad 1158"
					d="M881.15,178.906c.153,4.013.224,7.1.344,11.542"
					transform="translate(-843.824 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1159"
					data-name="Pfad 1159"
					d="M880.428,190.346"
					transform="translate(-842.036 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1160"
					data-name="Pfad 1160"
					d="M880.428,178.79c.148,3.964.216,7.12.328,11.556"
					transform="translate(-842.364 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1161"
					data-name="Pfad 1161"
					d="M879.705,178.723c.141,3.894.2,7.109.311,11.516"
					transform="translate(-840.901 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1162"
					data-name="Pfad 1162"
					d="M878.983,178.723c.133,3.8.194,7.056.292,11.4"
					transform="translate(-839.438 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1163"
					data-name="Pfad 1163"
					d="M878.26,178.424c.132,3.826.191,7.1.285,11.5"
					transform="translate(-837.985 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1164"
					data-name="Pfad 1164"
					d="M877.537,178.373c.125,3.757.181,7.076.27,11.427"
					transform="translate(-836.524 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1165"
					data-name="Pfad 1165"
					d="M876.813,178.247c.12,3.721.175,7.073.26,11.4"
					transform="translate(-835.066 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1166"
					data-name="Pfad 1166"
					d="M876.089,178.109c.116,3.693.169,7.031.251,11.336"
					transform="translate(-833.599 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1167"
					data-name="Pfad 1167"
					d="M875.364,177.958c.112,3.673.164,7.062.245,11.344"
					transform="translate(-832.144 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1168"
					data-name="Pfad 1168"
					d="M874.638,177.79c.111,3.664.162,7.08.243,11.342"
					transform="translate(-830.691 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1169"
					data-name="Pfad 1169"
					d="M873.911,177.59c.109,3.669.161,7.117.243,11.366"
					transform="translate(-829.237 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1170"
					data-name="Pfad 1170"
					d="M873.183,177.371c.108,3.684.161,7.163.247,11.4"
					transform="translate(-827.786 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1171"
					data-name="Pfad 1171"
					d="M872.454,177.115c.11,3.714.164,7.23.255,11.469"
					transform="translate(-826.337 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1172"
					data-name="Pfad 1172"
					d="M871.724,176.784c.113,3.77.17,7.344.268,11.606"
					transform="translate(-824.891 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1173"
					data-name="Pfad 1173"
					d="M870.992,176.406c.119,3.843.179,7.44.287,11.737"
					transform="translate(-823.446 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1174"
					data-name="Pfad 1174"
					d="M870.277,176.029c.125,3.92.172,7.607.291,11.932"
					transform="translate(-822.021 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1175"
					data-name="Pfad 1175"
					d="M869.551,175.6c.123,3.739.193,7.878.31,11.954"
					transform="translate(-820.589 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1176"
					data-name="Pfad 1176"
					d="M868.848,175.134c.123,3.546.2,7.458.311,11.289"
					transform="translate(-819.185 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1177"
					data-name="Pfad 1177"
					d="M868.349,174.608c.041,1.134.078,2.306.112,3.5"
					transform="translate(-817.988 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1178"
					data-name="Pfad 1178"
					d="M867.694,173.99c.028.7.053,1.423.077,2.152"
					transform="translate(-816.644 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1179"
					data-name="Pfad 1179"
					d="M913.626,177.821c.012.268.023.729.034.982"
					transform="translate(-908.469 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1180"
					data-name="Pfad 1180"
					d="M912.885,177.9c.025.546.027,2.213.049,2.7"
					transform="translate(-907.003 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1181"
					data-name="Pfad 1181"
					d="M911.846,177.971c.038.809.329,7.064.361,7.759"
					transform="translate(-905.238 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1182"
					data-name="Pfad 1182"
					d="M911,179.913c.133,3.082.358,8.472.392,9.214"
					transform="translate(-903.576 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1183"
					data-name="Pfad 1183"
					d="M910.219,180.389c.149,3.207.378,8.245.42,9.218"
					transform="translate(-902.045 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1184"
					data-name="Pfad 1184"
					d="M909.552,180.745c.133,3.2.306,7.727.354,8.994"
					transform="translate(-900.645 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1185"
					data-name="Pfad 1185"
					d="M908.905,181.1c.114,3.119.21,6.813.267,8.728"
					transform="translate(-899.265 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1186"
					data-name="Pfad 1186"
					d="M900.83,190.276c0,.2.011.215.017.423"
					transform="translate(-882.856 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1187"
					data-name="Pfad 1187"
					d="M900.1,190.159c.008.254.016.328.023.6"
					transform="translate(-881.396 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1188"
					data-name="Pfad 1188"
					d="M899.363,190.031c.01.311.02.445.03.777"
					transform="translate(-879.935 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1189"
					data-name="Pfad 1189"
					d="M898.629,189.914c.012.362.024.551.036.943"
					transform="translate(-878.473 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1190"
					data-name="Pfad 1190"
					d="M897.9,189.718c.015.436.03.705.046,1.184"
					transform="translate(-877.015 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1191"
					data-name="Pfad 1191"
					d="M897.16,189.607c.017.482.035.8.054,1.337"
					transform="translate(-875.553 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1192"
					data-name="Pfad 1192"
					d="M896.426,189.44c.02.543.041.929.064,1.541"
					transform="translate(-874.095 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1193"
					data-name="Pfad 1193"
					d="M895.692,189.184c.024.629.05,1.109.077,1.83"
					transform="translate(-872.64 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1194"
					data-name="Pfad 1194"
					d="M894.958,189.07c.027.67.055,1.2.085,1.973"
					transform="translate(-871.18 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1195"
					data-name="Pfad 1195"
					d="M894.225,188.879c.029.732.061,1.348.095,2.209"
					transform="translate(-869.724 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1196"
					data-name="Pfad 1196"
					d="M893.491,188.661c.033.8.069,1.492.108,2.447"
					transform="translate(-868.27 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1197"
					data-name="Pfad 1197"
					d="M892.758,188.428c.037.872.076,1.641.12,2.7"
					transform="translate(-866.815 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1198"
					data-name="Pfad 1198"
					d="M892.026,188.138c.04.956.083,1.819.133,3"
					transform="translate(-865.364 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1199"
					data-name="Pfad 1199"
					d="M892.281,179.537c.1,2.285.171,4.015.244,5.729"
					transform="translate(-865.985 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1200"
					data-name="Pfad 1200"
					d="M891.294,179.549c.2,4.7.3,6.889.5,11.591"
					transform="translate(-864.267 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1201"
					data-name="Pfad 1201"
					d="M891.294,179.549c.2,4.7.3,6.889.5,11.591"
					transform="translate(-864.267 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1202"
					data-name="Pfad 1202"
					d="M890.563,179.555c.2,4.7.3,6.9.5,11.585"
					transform="translate(-862.805 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1203"
					data-name="Pfad 1203"
					d="M888.04,179.691c.269.006.5.006.7,0,3.779-.1,16.413-.789,20.516-2.734s5.129-3.574,5.129-3.574a15.741,15.741,0,0,1-.863,4.048,12.428,12.428,0,0,0-.864,4.888c.108.946,1.3,4.836-1.4,5.677s-12.526,3.364-19.167,3.154-7.99,0-13.5-.736c-1.229.053-3.2.123-4.2.039a1,1,0,0,1-.857-.54c-1.942-.173-2.9-.37-3.227-1.33-.54-1.577-.775-8.522-3.058-10.837,0,0,1.04.12,2.669.3"
					transform="translate(-862.821 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.179"
				/>
				<path
					id="Pfad_1204"
					data-name="Pfad 1204"
					d="M867.253,180.289c.208,0,.393,0,.552,0,3.775-.1,16.4-.789,20.494-2.733s5.124-3.575,5.124-3.575"
					transform="translate(-841.856 -48.27)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1205"
					data-name="Pfad 1205"
					d="M911.846,178.342s.984.114,2.536.287"
					transform="translate(-907.414 -48.27)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1206"
					data-name="Pfad 1206"
					d="M867.253,178.9c.23.005.433,0,.6,0,3.775-.1,16.4-.789,20.494-2.734s5.124-3.574,5.124-3.574"
					transform="translate(-841.907 -48.27)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1207"
					data-name="Pfad 1207"
					d="M911.659,176.851s1.419.165,3.549.4"
					transform="translate(-908.053 -48.27)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1208"
					data-name="Pfad 1208"
					d="M917.4,173.384s.27-1.735.324-2.681.449-2.274.621-5.347.4-8.845.4-11.841-.81-15.139-1.026-19.817-.917-17.977-1.079-21.236,1.424-9.548,1.316-20.323-8.088-22.616-8.734-25.649a9.042,9.042,0,0,0-2.366-3.647c-.849-.809-2.7-1.69-3.981-2.774-1.46-1.232-2.577-2.989-3.566-3.762-2.78-2.174-8.367-6.773-16.424-6.03-4.1,0-7.541,2.752-9.441,4.909-4.406,5.281-4.307,8.489-5.114,11.614-1.275,4.934-2.593,13.053-2.422,15.227s.091,4.678.459,6.886-.206,12.037-.422,15.822.581,16.5.581,16.5,1.633,41.841,2.01,49.2S870,177.589,870,177.589a2.348,2.348,0,0,1,.586.525"
					transform="translate(-865.837 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_1209"
					data-name="Pfad 1209"
					d="M893.5,180.752s15.586-.719,16.357-1.794"
					transform="translate(-884.547 -48.27)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1210"
					data-name="Pfad 1210"
					d="M893.515,181.641s14.6-.515,15.374-1.594"
					transform="translate(-883.595 -48.27)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.062"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_1211"
					data-name="Pfad 1211"
					d="M908.923,179.527c-.771,1.077-15.279,1.7-15.279,1.7"
					transform="translate(-883.758 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.176"
				/>
				<line
					id="Linie_251"
					data-name="Linie 251"
					x2="1.004"
					y2="8.775"
					transform="translate(25.269 131.458)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_252"
					data-name="Linie 252"
					x2="1.001"
					y2="8.742"
					transform="translate(24.627 131.553)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_253"
					data-name="Linie 253"
					x2="0.992"
					y2="8.808"
					transform="translate(23.985 131.642)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_254"
					data-name="Linie 254"
					x2="0.982"
					y2="8.996"
					transform="translate(23.393 131.813)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_255"
					data-name="Linie 255"
					x2="0.982"
					y2="8.996"
					transform="translate(22.748 131.875)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_256"
					data-name="Linie 256"
					x2="0.98"
					y2="9.069"
					transform="translate(22.107 131.978)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_257"
					data-name="Linie 257"
					x2="1.065"
					y2="9.249"
					transform="translate(21.463 132.051)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_258"
					data-name="Linie 258"
					x2="1.122"
					y2="9.243"
					transform="translate(20.761 132.118)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_259"
					data-name="Linie 259"
					x2="1.119"
					y2="9.213"
					transform="translate(20.119 132.211)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_260"
					data-name="Linie 260"
					x2="1.121"
					y2="9.235"
					transform="translate(19.471 132.251)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_261"
					data-name="Linie 261"
					x2="1.123"
					y2="9.345"
					transform="translate(18.826 132.315)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_262"
					data-name="Linie 262"
					x2="1.121"
					y2="9.367"
					transform="translate(18.182 132.356)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_263"
					data-name="Linie 263"
					x2="1.114"
					y2="9.31"
					transform="translate(17.543 132.475)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_264"
					data-name="Linie 264"
					x2="1.117"
					y2="9.335"
					transform="translate(16.895 132.511)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_265"
					data-name="Linie 265"
					x2="1.119"
					y2="9.588"
					transform="translate(16.242 132.546)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_266"
					data-name="Linie 266"
					x2="1.098"
					y2="9.479"
					transform="translate(15.596 132.603)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_267"
					data-name="Linie 267"
					x2="1.099"
					y2="9.487"
					transform="translate(14.949 132.657)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_268"
					data-name="Linie 268"
					x2="1.095"
					y2="9.582"
					transform="translate(14.308 132.624)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_269"
					data-name="Linie 269"
					x2="1.061"
					y2="9.459"
					transform="translate(13.661 132.676)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_270"
					data-name="Linie 270"
					x2="1.054"
					y2="9.397"
					transform="translate(13.023 132.801)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_271"
					data-name="Linie 271"
					x2="1.041"
					y2="9.245"
					transform="translate(12.367 132.771)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_272"
					data-name="Linie 272"
					x2="1.009"
					y2="9.252"
					transform="translate(11.72 132.834)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_273"
					data-name="Linie 273"
					x2="1.011"
					y2="9.273"
					transform="translate(11.072 132.875)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_274"
					data-name="Linie 274"
					x2="1.013"
					y2="9.287"
					transform="translate(10.425 132.922)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_275"
					data-name="Linie 275"
					x2="1.021"
					y2="8.828"
					transform="translate(9.778 132.969)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1212"
					data-name="Pfad 1212"
					d="M911.64,61.172a12.345,12.345,0,0,1,6.649,6.589c2.453,6.4-5.014,33.155-5.117,34.937s-.94,3.488-.747,5.26a14.968,14.968,0,0,1-.385,4.2c-.086.631-.912,14.4-.49,21.017.381,5.956-.4,11.916-.94,13.388s-1.718,4.4-.534,6.785,2.481,4.772,2.073,7.393-.566,4.4-1.357,5.928.029,1.62-.7,2.677-1.985,1.18-1.848,2.433.6,1.455.608,2.082-.337.319.133,1.044.7,1.035.482,1.456a7.253,7.253,0,0,1-1.112,2c-.444.32-1.469.336-1.107,1.48s1.242,5.628,1.368,6.253.832,1.87-.3,2.3a42,42,0,0,1-11.142,2.052c-3.994-.044-4.446.172-4.584-1.186s-.688-8.041-.688-8.041a11.37,11.37,0,0,1-2.8-1.63c-.468-.621.421-1.993-.049-2.718a10.12,10.12,0,0,0-1.748-2.065c-.7-.617-2.9-1.315-2.454-2.472a8.155,8.155,0,0,0,.874-2.941c-.017-.941-.967-1.449-.969-2.338s-.133-5.7-.331-14.686-1.065-21.954-1.154-26.686,1.183-7.421.38-15.239-1.946-15.569-1.542-19.326,2.768-10.49,2.486-13.853-.353-4.98.492-6.685c.744-1.5,8.177-13.238,18.635-11.9a24.935,24.935,0,0,1,4.521.885A27.552,27.552,0,0,1,911.64,61.172Z"
					transform="translate(-882.026 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_1213"
					data-name="Pfad 1213"
					d="M877.318,50.6s6.524-1.048,10.874.7,5.117,2.446,6.907,3.887a58.5,58.5,0,0,1,4.776,4.672c.9.917,3.879,2.567,4.518,3.266s-.852-1.213-1.662-2.087-3.581-2.882-4.221-3.668-4.391-5.021-9-6.9-5.927-2.4-10.788-2.053C878.725,48.421,877.83,48.771,877.318,50.6Z"
					transform="translate(-863.058 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.179"
				/>
				<path
					id="Pfad_1214"
					data-name="Pfad 1214"
					d="M903.173,50.528a11.1,11.1,0,0,1,.909-2.135"
					transform="translate(-888.435 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1215"
					data-name="Pfad 1215"
					d="M902.723,50.456a9.288,9.288,0,0,1,.878-2.079"
					transform="translate(-887.504 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1216"
					data-name="Pfad 1216"
					d="M902.278,50.4a7.989,7.989,0,0,1,.852-2.03"
					transform="translate(-886.588 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1217"
					data-name="Pfad 1217"
					d="M901.825,50.345a7.094,7.094,0,0,1,.841-2.011"
					transform="translate(-885.671 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1218"
					data-name="Pfad 1218"
					d="M901.4,50.305a6.429,6.429,0,0,1,.815-1.964"
					transform="translate(-884.787 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1219"
					data-name="Pfad 1219"
					d="M900.959,50.277a5.964,5.964,0,0,1,.8-1.947"
					transform="translate(-883.902 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1220"
					data-name="Pfad 1220"
					d="M900.494,50.258a5.7,5.7,0,0,1,.829-1.988"
					transform="translate(-882.997 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1221"
					data-name="Pfad 1221"
					d="M900.082,50.25a5.405,5.405,0,0,1,.808-1.953"
					transform="translate(-882.152 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1222"
					data-name="Pfad 1222"
					d="M899.677,50.252a5.182,5.182,0,0,1,.786-1.918"
					transform="translate(-881.32 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1223"
					data-name="Pfad 1223"
					d="M899.277,50.264a5.009,5.009,0,0,1,.766-1.884"
					transform="translate(-880.5 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1224"
					data-name="Pfad 1224"
					d="M898.884,50.285a4.887,4.887,0,0,1,.744-1.851"
					transform="translate(-879.692 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1225"
					data-name="Pfad 1225"
					d="M898.5,50.316a4.848,4.848,0,0,1,.724-1.82"
					transform="translate(-878.896 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1226"
					data-name="Pfad 1226"
					d="M898.112,50.356a4.821,4.821,0,0,1,.7-1.789"
					transform="translate(-878.108 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1227"
					data-name="Pfad 1227"
					d="M897.733,50.406a4.858,4.858,0,0,1,.685-1.761"
					transform="translate(-877.331 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1228"
					data-name="Pfad 1228"
					d="M897.357,50.378a5.063,5.063,0,0,1,.652-1.647"
					transform="translate(-876.546 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1229"
					data-name="Pfad 1229"
					d="M896.984,50.406a5.279,5.279,0,0,1,.628-1.581"
					transform="translate(-875.776 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1230"
					data-name="Pfad 1230"
					d="M896.615,50.458a5.62,5.62,0,0,1,.606-1.533"
					transform="translate(-875.016 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1231"
					data-name="Pfad 1231"
					d="M896.247,50.509a6.091,6.091,0,0,1,.584-1.477"
					transform="translate(-874.258 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1232"
					data-name="Pfad 1232"
					d="M895.881,50.557a6.858,6.858,0,0,1,.559-1.412"
					transform="translate(-873.501 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1233"
					data-name="Pfad 1233"
					d="M896.053,49.265a7.7,7.7,0,0,0-.536,1.359"
					transform="translate(-872.75 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1234"
					data-name="Pfad 1234"
					d="M895.153,50.69a8.959,8.959,0,0,1,.511-1.3"
					transform="translate(-871.997 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1235"
					data-name="Pfad 1235"
					d="M894.79,50.8a10.5,10.5,0,0,1,.5-1.279"
					transform="translate(-871.257 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1236"
					data-name="Pfad 1236"
					d="M894.427,50.9a13.126,13.126,0,0,1,.479-1.241"
					transform="translate(-870.513 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1237"
					data-name="Pfad 1237"
					d="M894.063,50.989c.1-.328.235-.643.457-1.19"
					transform="translate(-869.762 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1238"
					data-name="Pfad 1238"
					d="M893.7,51.132c.108-.328.236-.642.451-1.186"
					transform="translate(-869.026 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1239"
					data-name="Pfad 1239"
					d="M893.331,51.271c.111-.325.237-.637.444-1.174"
					transform="translate(-868.285 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1240"
					data-name="Pfad 1240"
					d="M892.963,51.415c.113-.322.237-.633.437-1.163"
					transform="translate(-867.542 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1241"
					data-name="Pfad 1241"
					d="M892.592,51.573c.118-.323.241-.634.436-1.162"
					transform="translate(-866.799 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1242"
					data-name="Pfad 1242"
					d="M892.218,51.729c.121-.323.243-.634.433-1.155"
					transform="translate(-866.049 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1243"
					data-name="Pfad 1243"
					d="M891.84,51.889c.125-.324.246-.634.435-1.149"
					transform="translate(-865.294 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1244"
					data-name="Pfad 1244"
					d="M891.458,52.041c.126-.32.247-.629.433-1.132"
					transform="translate(-864.528 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1245"
					data-name="Pfad 1245"
					d="M891.072,52.221c.132-.326.254-.636.443-1.139"
					transform="translate(-863.766 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1246"
					data-name="Pfad 1246"
					d="M890.681,52.4c.137-.329.26-.64.451-1.139"
					transform="translate(-862.993 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1247"
					data-name="Pfad 1247"
					d="M890.285,52.576c.142-.333.268-.646.464-1.142"
					transform="translate(-862.213 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1248"
					data-name="Pfad 1248"
					d="M889.925,52.756c.136-.313.257-.607.436-1.042"
					transform="translate(-861.466 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1249"
					data-name="Pfad 1249"
					d="M889.538,52.964c.14-.316.263-.606.443-1.023"
					transform="translate(-860.699 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1250"
					data-name="Pfad 1250"
					d="M889.16,53.174c.14-.311.262-.591.436-.976"
					transform="translate(-859.935 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1251"
					data-name="Pfad 1251"
					d="M888.779,53.4c.142-.308.264-.581.435-.94"
					transform="translate(-859.172 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1252"
					data-name="Pfad 1252"
					d="M888.409,53.638c.149-.311.277-.581.421-.921"
					transform="translate(-858.419 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1253"
					data-name="Pfad 1253"
					d="M888.051,53.891c.15-.319.273-.588.4-.912"
					transform="translate(-857.684 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1254"
					data-name="Pfad 1254"
					d="M887.7,54.133c.158-.323.289-.583.415-.891"
					transform="translate(-857.003 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1255"
					data-name="Pfad 1255"
					d="M887.353,54.389a9.12,9.12,0,0,0,.428-.89"
					transform="translate(-856.314 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1256"
					data-name="Pfad 1256"
					d="M887.02,54.65a6.953,6.953,0,0,0,.428-.862"
					transform="translate(-855.649 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1257"
					data-name="Pfad 1257"
					d="M886.686,54.923a5.954,5.954,0,0,0,.441-.857"
					transform="translate(-854.993 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1258"
					data-name="Pfad 1258"
					d="M886.359,55.176a5.07,5.07,0,0,0,.435-.828"
					transform="translate(-854.333 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1259"
					data-name="Pfad 1259"
					d="M886.037,55.449a4.471,4.471,0,0,0,.438-.815"
					transform="translate(-853.692 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1260"
					data-name="Pfad 1260"
					d="M885.721,55.706a4.04,4.04,0,0,0,.424-.784"
					transform="translate(-853.046 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1261"
					data-name="Pfad 1261"
					d="M885.413,56a3.793,3.793,0,0,0,.424-.771"
					transform="translate(-852.43 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1262"
					data-name="Pfad 1262"
					d="M885.1,56.286a3.49,3.49,0,0,0,.43-.788"
					transform="translate(-851.808 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1263"
					data-name="Pfad 1263"
					d="M884.8,56.557a3.271,3.271,0,0,0,.407-.75"
					transform="translate(-851.189 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1264"
					data-name="Pfad 1264"
					d="M884.5,56.865a3.187,3.187,0,0,0,.408-.755"
					transform="translate(-850.596 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1265"
					data-name="Pfad 1265"
					d="M884.214,57.162a3.062,3.062,0,0,0,.4-.738"
					transform="translate(-850.004 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1266"
					data-name="Pfad 1266"
					d="M883.923,57.461a3,3,0,0,0,.386-.733"
					transform="translate(-849.412 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1267"
					data-name="Pfad 1267"
					d="M883.639,57.762a2.913,2.913,0,0,0,.373-.714"
					transform="translate(-848.831 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1268"
					data-name="Pfad 1268"
					d="M883.36,58.065a2.927,2.927,0,0,0,.356-.689"
					transform="translate(-848.256 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1269"
					data-name="Pfad 1269"
					d="M883.079,58.369a2.96,2.96,0,0,0,.343-.673"
					transform="translate(-847.681 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1270"
					data-name="Pfad 1270"
					d="M882.778,58.673a2.876,2.876,0,0,0,.352-.721"
					transform="translate(-847.088 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1271"
					data-name="Pfad 1271"
					d="M882.48,58.979a2.98,2.98,0,0,0,.36-.767"
					transform="translate(-846.5 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1272"
					data-name="Pfad 1272"
					d="M882.175,59.284a3.093,3.093,0,0,0,.375-.836"
					transform="translate(-845.905 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1273"
					data-name="Pfad 1273"
					d="M881.879,59.587a3.245,3.245,0,0,0,.382-.873"
					transform="translate(-845.32 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1274"
					data-name="Pfad 1274"
					d="M881.576,59.888a3.472,3.472,0,0,0,.395-.926"
					transform="translate(-844.727 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1275"
					data-name="Pfad 1275"
					d="M881.271,60.161a3.827,3.827,0,0,0,.395-.948"
					transform="translate(-844.117 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1276"
					data-name="Pfad 1276"
					d="M880.963,60.409a4.247,4.247,0,0,0,.385-.95"
					transform="translate(-843.491 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1277"
					data-name="Pfad 1277"
					d="M880.652,60.644a4.788,4.788,0,0,0,.373-.941"
					transform="translate(-842.857 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1278"
					data-name="Pfad 1278"
					d="M880.343,60.872a5.467,5.467,0,0,0,.356-.906"
					transform="translate(-842.222 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1279"
					data-name="Pfad 1279"
					d="M880.031,61.081a6.369,6.369,0,0,0,.334-.851"
					transform="translate(-841.576 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1280"
					data-name="Pfad 1280"
					d="M879.718,61.313a7.18,7.18,0,0,0,.324-.818"
					transform="translate(-840.94 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1281"
					data-name="Pfad 1281"
					d="M879.4,61.543c.122-.263.213-.5.315-.785"
					transform="translate(-840.297 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1282"
					data-name="Pfad 1282"
					d="M879.1,61.748c.1-.227.186-.435.275-.674"
					transform="translate(-839.661 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1283"
					data-name="Pfad 1283"
					d="M878.81,61.967c.087-.192.16-.369.235-.561"
					transform="translate(-839.035 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1284"
					data-name="Pfad 1284"
					d="M878.514,62.189c.072-.159.134-.306.2-.458"
					transform="translate(-838.404 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1285"
					data-name="Pfad 1285"
					d="M878.227,62.417c.053-.118.1-.229.148-.34"
					transform="translate(-837.782 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<path
					id="Pfad_1286"
					data-name="Pfad 1286"
					d="M877.941,62.654c.035-.078.068-.153.1-.227"
					transform="translate(-837.162 -48.27)"
					fill="none"
					stroke="#464749"
					stroke-width="0.119"
				/>
				<line
					id="Linie_276"
					data-name="Linie 276"
					y1="8.315"
					x2="0.89"
					transform="translate(19.576 2.138)"
					fill="none"
					stroke="#464749"
					stroke-width="0.179"
				/>
				<line
					id="Linie_277"
					data-name="Linie 277"
					y1="8.315"
					x2="0.89"
					transform="translate(19.003 2.035)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.063"
					stroke-dasharray="0.505 0.253"
				/>
				<line
					id="Linie_278"
					data-name="Linie 278"
					y1="8.315"
					x2="0.89"
					transform="translate(20.197 2.138)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.063"
					stroke-dasharray="0.505 0.253"
				/>
			</g>
			<g id="SIZE_REF" transform="translate(253.167 788.861)">
				<ellipse
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.499"
					cy="0.5"
					rx="0.499"
					ry="0.5"
					transform="translate(25.856 11.484)"
					fill="transparent"
				/>
				<ellipse
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.499"
					cy="0.5"
					rx="0.499"
					ry="0.5"
					transform="translate(0)"
					fill="transparent"
				/>
			</g>
			<!-- <g id="POSITIONEN" transform="translate(253.838 822.861)">
				<ellipse
					id="center"
					ref="position-center"
					cx="0.499"
					cy="0.5"
					rx="0.499"
					ry="0.5"
					transform="translate(0)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<g
				id="PRINT-AREA"
				transform="translate(244.788 812.811)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="18.9" height="89.9" fill="none" ref="print-area" />
			</g>
			<!-- <g id="POSITIONEN" transform="translate(257.152 822.861)">
				<ellipse
					id="center"
					ref="position-center"
					cx="0.499"
					cy="0.5"
					rx="0.499"
					ry="0.5"
					transform="translate(0)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<!-- <g
				id="PRINT-AREA"
				transform="translate(241.151 805.861)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect x="0.05" y="0.05" width="32.866" height="105.9" fill="none" ref="print-area" />
			</g> -->
		</g>
	</svg>
</template>

<script>
export default {
	name: 'CrewneckRight',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
}
</script>