<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<g id="HOODIE_LEFT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -817.85907, -554.962036)">
			<g id="COLORS" transform="translate(-3 -1)">
				<path
					id="Pfad_2205"
					data-name="Pfad 2205"
					d="M1431.469,604.2a52.752,52.752,0,0,0,2.5,18.651c4.388,4.544,18.388,4.785,24.54,4.785a62.319,62.319,0,0,0,8.214-.533,53,53,0,0,0,9.249-2.055,30.725,30.725,0,0,0,5.643-2.305,4.826,4.826,0,0,0,.457-.391,4.291,4.291,0,0,0,.913-2.372,7.447,7.447,0,0,0-.056-1.014c-.089-.426-.187-.851-.28-1.276a3.932,3.932,0,0,1-.131-.993c.012-.312.16-.6.175-.908a6.188,6.188,0,0,0-.082-.773q-.078-.954-.163-1.907c-.093-1.035-.19-2.069-.318-3.1a5.839,5.839,0,0,0-.3-1.637c-.3-.651-.859-1.211-.908-1.925a3.475,3.475,0,0,1,.418-1.511c.535-1.307.229-2.194.058-3.475a29.828,29.828,0,0,1-.042-4.014c.009-1.47.032-2.942.092-4.412.58-14.059,1.3-32.42,1.3-32.42s.769-12.192.561-15.819-.763-13.048-.408-15.164.278-4.516.443-6.6-.949-10.078-2.725-14.632-3.241-10.924-10.621-13.472-16.041,1.485-18.911,3.454-8.95,6.852-9.573,9.759-8.331,14.255-8.435,24.582,1.428,16.355,1.271,19.479-.834,15.869-1.043,20.353-.99,16.121-.99,18.992Z"
					transform="translate(-567 73)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_2206"
					data-name="Pfad 2206"
					d="M1483.472,524.922a5.815,5.815,0,0,0,2.7-.069,18.488,18.488,0,0,0,1.754-.99c.408-.31.648-1.5,1.141-3.1a19.268,19.268,0,0,0,.761-3.9c.082-.887.232-.7.505-1.493s0,0,.023-.114.251-2,.311-2.909.135-.756.259-.976.815-1.9,1.221-3a4.838,4.838,0,0,1,.963-1.594,4.473,4.473,0,0,0,.354-2.135,40.653,40.653,0,0,1,.727-5.263c.417-2.164-.727-2.9-.727-2.9a3.048,3.048,0,0,0-.651-1.505s-.212-1.707-1.033-1.82-1.438.113-1.775,0a12.111,12.111,0,0,0-2.311-.68,25.651,25.651,0,0,1-3.07-.251,19.1,19.1,0,0,0-2.431-.392c-.228-.037-1.206-.415-1.4-.415a3.851,3.851,0,0,0-1.265-.151c-.417.113-.624.321-.99-.029a3.181,3.181,0,0,0-1.89-.727,5.3,5.3,0,0,1-1.955-.391,23.413,23.413,0,0,0-3.567-.969c-1.478-.34-4.267-1-4.73-.8s-4.647.272-5.155.385a27.483,27.483,0,0,0-4.481,1.209c-1.346.6-3.77,1.36-4.787,2.267a33.649,33.649,0,0,0-3.206,3.287c-.47.643-.9,1.587-1.252,1.927s-1.251,1.247-1.486,1.549-.782.6-.9.983-.469.68-.391.944a3.027,3.027,0,0,1-.011,1.285s.6-.3.715,1.1l4-3.711s3.2-1.692,4.525-2.335,6.335-2.682,7.039-2.909,5.571-1,8.5.6a29.949,29.949,0,0,0,3.575,1.667,11.232,11.232,0,0,1,4.138,4.12,43.873,43.873,0,0,1,3.4,7.685s1.861,6.672,2.054,7.882.48,2.267.454,2.644a10.109,10.109,0,0,0,.2,2.76A16.875,16.875,0,0,1,1483.472,524.922Z"
					transform="translate(-567 73)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2207"
					data-name="Pfad 2207"
					d="M1484.775,531.479c-.22-3.635-2.2-10.211-1.774-13.441s.566-4.783-.172-6.459c-.637-1.447-7.034-12.608-16.854-12.221a22.685,22.685,0,0,0-5.051.6,23.286,23.286,0,0,0-3.6,1.646,11.9,11.9,0,0,0-6.43,5.968c-2.658,6.069,3.341,32.132,3.359,33.853s.75,3.4.484,5.1a14.4,14.4,0,0,0,.181,4.06c.055.611.375,14.452-.033,20.835-.367,5.744.383,11.49.909,12.909s1.662,4.241.517,6.542-2.4,4.6-2,7.128.547,4.243,1.312,5.717-.029,1.562.679,2.581,1.919,1.138,1.787,2.346-.577,1.4-.588,2.007.325.308-.128,1.006-.68,1-.467,1.405a6.977,6.977,0,0,0,1.075,1.932c.383.276-.282.982-.362,1.779l.417.854c.444.62,6.375,2.061,11.087,2.472,5.173.452,5.815-.374,5.815-.374a5.881,5.881,0,0,1,.444-1.7,9.767,9.767,0,0,1,1.208-1.847c.452-.6-.406-1.922.047-2.621a9.746,9.746,0,0,1,1.691-1.991c.672-.6,2.806-1.268,2.372-2.384a7.84,7.84,0,0,1-.844-2.835c.016-.908.934-1.4.936-2.255s.129-5.492.32-14.16,1.029-21.168,1.115-25.73-.938-6.664.191-14.165S1485,535.114,1484.775,531.479Z"
					transform="translate(-567 73)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2208"
					data-name="Pfad 2208"
					d="M1434.126,524.888a56.031,56.031,0,0,0,6.305-.756c-.119,1.543-.267,3.472-.238,4.843-.107,1.392-.229,2.976-.351,4.565l-.732,9.522-2.675,2.676h-2.744a81.608,81.608,0,0,1-.708-10.652c-.078-6.654,1.143-10.186,1.143-10.186"
					transform="translate(-567 73)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2209"
					data-name="Pfad 2209"
					d="M1444.731,501.125s-4.442,3.3-4.794,5.869a53.672,53.672,0,0,1-3.3,10.85,22.812,22.812,0,0,0,2.984-5.446c.782-2.494,3.57-8.552,5.107-10.215C1444.731,502.183,1444.954,501.276,1444.731,501.125Z"
					transform="translate(-567 73)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2210"
					data-name="Pfad 2210"
					d="M1445.446,503.279s-3.358,2.129-3.788,4.169a42.268,42.268,0,0,1-2.789,7.865c-1.408,3.173-3.05,8.2-3.441,9.068a20.294,20.294,0,0,1-.939,2.645c-.352.377-.7-.6-.7-.6a16.378,16.378,0,0,1,1.017-3.665c1.017-2.985,2.972-7.972,3.6-9.748s1.537-3.536,2.045-5.323,2.437-4.028,4.286-5.5C1444.731,502.183,1445.328,501.881,1445.446,503.279Z"
					transform="translate(-567 73)"
					:fill="secColor"
				/>
			</g>
			<g id="LINES">
				<path
					id="Pfad_2211"
					data-name="Pfad 2211"
					d="M1460.253,618.317c-.042,1.4-.181,2.841-.18,4.22,0,1.614.35,3.636-.208,5.094"
					transform="translate(-570 72)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_2212"
					data-name="Pfad 2212"
					d="M1433.878,526.724a27.112,27.112,0,0,0-.8,5.994c-.1,10.328,1.428,16.355,1.271,19.479s-.834,15.869-1.043,20.353-.99,16.121-.99,18.992l-.851,12.653a52.752,52.752,0,0,0,2.5,18.651c4.388,4.544,18.388,4.785,24.54,4.785a62.319,62.319,0,0,0,8.214-.533,53,53,0,0,0,9.249-2.055,30.725,30.725,0,0,0,5.643-2.305,4.826,4.826,0,0,0,.457-.391,4.291,4.291,0,0,0,.913-2.372,7.447,7.447,0,0,0-.056-1.014c-.089-.426-.187-.851-.28-1.276a3.932,3.932,0,0,1-.131-.993c.012-.312.16-.6.175-.908a6.188,6.188,0,0,0-.082-.773q-.078-.954-.163-1.907c-.093-1.035-.19-2.069-.318-3.1a5.839,5.839,0,0,0-.3-1.637c-.3-.651-.859-1.211-.908-1.925a3.475,3.475,0,0,1,.418-1.511c.535-1.307.229-2.194.058-3.475a29.828,29.828,0,0,1-.042-4.014c.009-1.47.032-2.942.092-4.412.58-14.059,1.3-32.42,1.3-32.42s.769-12.192.561-15.819l-.02-.333"
					transform="translate(-570 72)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_2213"
					data-name="Pfad 2213"
					d="M1482.833,618.531a3.962,3.962,0,0,1-.764,2.286,4.826,4.826,0,0,1-.457.391,30.8,30.8,0,0,1-5.643,2.3,53,53,0,0,1-9.249,2.055,62.319,62.319,0,0,1-8.214.533c-6.152,0-20.152-.241-24.54-4.785,0,0-.6-.454-.643-.608"
					transform="translate(-570 72)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.06"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_2214"
					data-name="Pfad 2214"
					d="M1482.788,618.316a3.612,3.612,0,0,1-.719,1.344,4.818,4.818,0,0,1-.457.391,30.729,30.729,0,0,1-5.643,2.3,53,53,0,0,1-9.249,2.055,62.319,62.319,0,0,1-8.214.533c-6.152,0-20.152-.241-24.54-4.785,0,0-.97-.7-1.041-.99"
					transform="translate(-570 72)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.06"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_2215"
					data-name="Pfad 2215"
					d="M1483.7,524.912a4.869,4.869,0,0,0,2.472-.059,18.488,18.488,0,0,0,1.754-.99c.408-.31.648-1.5,1.141-3.1a19.268,19.268,0,0,0,.761-3.9c.082-.887.232-.7.505-1.493s0,0,.023-.114.251-2,.311-2.909.135-.756.259-.976.815-1.9,1.221-3a4.838,4.838,0,0,1,.963-1.594,4.473,4.473,0,0,0,.354-2.135,40.653,40.653,0,0,1,.727-5.263c.417-2.164-.727-2.9-.727-2.9a3.048,3.048,0,0,0-.651-1.505s-.212-1.707-1.033-1.82-1.438.113-1.775,0a12.111,12.111,0,0,0-2.311-.68,25.651,25.651,0,0,1-3.07-.251,19.1,19.1,0,0,0-2.431-.392c-.228-.037-1.206-.415-1.4-.415a3.851,3.851,0,0,0-1.265-.151c-.417.113-.624.321-.99-.029a3.181,3.181,0,0,0-1.89-.727,5.3,5.3,0,0,1-1.955-.391,23.413,23.413,0,0,0-3.567-.969c-1.478-.34-4.267-1-4.73-.8s-4.647.272-5.155.385a27.483,27.483,0,0,0-4.481,1.209c-1.346.6-3.77,1.36-4.787,2.267a33.649,33.649,0,0,0-3.206,3.287c-.47.643-.9,1.587-1.252,1.927s-1.251,1.247-1.486,1.549-.782.6-.9.983-.469.68-.391.944a3.027,3.027,0,0,1-.011,1.285s.6-.3.715,1.1l4-3.711s3.2-1.692,4.525-2.335,6.306-2.535,7.01-2.762,5.068-1,8.116.277a25.024,25.024,0,0,1,3.781,1.719,11.941,11.941,0,0,1,4.345,4.243,40.261,40.261,0,0,1,3.166,7.01c.15.423,2.169,2.662,2.747,4.729.453,2.152-.165,6.14-.192,6.518s.159,2.394.223,2.781C1483.347,522.851,1483.7,524.912,1483.7,524.912Z"
					transform="translate(-570 72)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_2216"
					data-name="Pfad 2216"
					d="M1492.957,494.973a14.857,14.857,0,0,1-3.754.182,48.407,48.407,0,0,0-4.81-.982c-.43,0-3.882.189-4.463-.227"
					transform="translate(-570 72)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.084"
					stroke-dasharray="0.672 0.336"
				/>
				<path
					id="Pfad_2217"
					data-name="Pfad 2217"
					d="M1470.252,490.2s-5.958-1-9.751-.078-7.352,1.751-9.385,3.829-3.845,4.556-4.028,6.172"
					transform="translate(-570 72)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.084"
					stroke-dasharray="0.672 0.336"
				/>
				<path
					id="Pfad_2218"
					data-name="Pfad 2218"
					d="M1471.606,490.583s-8.784-.918-13.334.34-8.6,3.439-10.011,8.313"
					transform="translate(-570 72)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.084"
					stroke-dasharray="0.672 0.336"
				/>
				<path
					id="Pfad_2219"
					data-name="Pfad 2219"
					d="M1493.468,496.478s-3.365.68-4.656.34-5.083-1.193-5.787-1.579-1.838-.839-1.838-.839"
					transform="translate(-570 72)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.084"
					stroke-dasharray="0.672 0.336"
				/>
				<path
					id="Pfad_2220"
					data-name="Pfad 2220"
					d="M1433.785,526.725l.528.339-.665,2.645-.391-.227.528-2.757"
					transform="translate(-570 72)"
					fill="#464749"
				/>
				<path
					id="Pfad_2221"
					data-name="Pfad 2221"
					d="M1457.611,501.482a12.039,12.039,0,0,0-6.72,6.09c-2.658,6.069,3.341,32.132,3.359,33.853s.75,3.4.484,5.1a14.441,14.441,0,0,0,.181,4.06c.055.612.375,14.452-.033,20.835-.367,5.744.383,11.49.909,12.909s1.662,4.241.517,6.543-2.4,4.6-2,7.127.547,4.243,1.311,5.717-.028,1.562.68,2.581,1.919,1.138,1.787,2.346-.577,1.4-.588,2.007.325.308-.128,1.007-.68,1-.467,1.4,1.2,1.864,1.076,2.467-.8,1.8.291,2.223,8.559,2.462,12.42,2.42,4.3.165,4.431-1.143a5.66,5.66,0,0,1,1.466-2.846c.452-.6-.406-1.922.047-2.621a9.746,9.746,0,0,1,1.691-1.991c.672-.6,2.806-1.268,2.372-2.384a7.84,7.84,0,0,1-.844-2.835c.016-.907.934-1.4.936-2.255s.129-5.492.32-14.16,1.029-21.168,1.115-25.73-.938-6.664.191-14.165,2.585-14.922,2.364-18.557-2.2-10.211-1.774-13.441.566-4.783-.172-6.459c-.65-1.477-7.3-13.067-17.459-12.183a24.257,24.257,0,0,0-4.407.677A26.817,26.817,0,0,0,1457.611,501.482Z"
					transform="translate(-570 72)"
					fill="none"
					stroke="#464749"
					stroke-width="0.25"
				/>
				<path
					id="Pfad_2222"
					data-name="Pfad 2222"
					d="M1475.243,618.4s-9.273.3-17.331-2.636"
					transform="translate(-570 72)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.06"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_2223"
					data-name="Pfad 2223"
					d="M1475.565,617.621s-9.595.371-17.653-2.56"
					transform="translate(-570 72)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.06"
					stroke-dasharray="0.497 0.249"
				/>
				<path
					id="Pfad_2224"
					data-name="Pfad 2224"
					d="M1444.731,501.125s-4.442,3.3-4.794,5.869a53.672,53.672,0,0,1-3.3,10.85,22.812,22.812,0,0,0,2.984-5.446c.782-2.494,3.57-8.552,5.107-10.215C1444.731,502.183,1444.954,501.276,1444.731,501.125Z"
					transform="translate(-570 72)"
					fill="#fff"
					stroke="#464749"
					stroke-width="0.172"
				/>
				<path
					id="Pfad_2225"
					data-name="Pfad 2225"
					d="M1444.731,501.125s-4.442,3.3-4.794,5.869a53.672,53.672,0,0,1-3.3,10.85,22.812,22.812,0,0,0,2.984-5.446c.782-2.494,3.57-8.552,5.107-10.215C1444.731,502.183,1444.954,501.276,1444.731,501.125Z"
					transform="translate(-570 72)"
					fill="none"
					stroke="#464749"
					stroke-width="0.172"
				/>
				<path
					id="Pfad_2226"
					data-name="Pfad 2226"
					d="M1445.446,503.279s-3.358,2.129-3.788,4.169a42.268,42.268,0,0,1-2.789,7.865c-1.408,3.173-3.05,8.2-3.441,9.068a20.294,20.294,0,0,1-.939,2.645c-.352.377-.7-.6-.7-.6a16.378,16.378,0,0,1,1.017-3.665c1.017-2.985,2.972-7.972,3.6-9.748s1.537-3.536,2.045-5.323,2.437-4.028,4.286-5.5C1444.731,502.183,1445.328,501.881,1445.446,503.279Z"
					transform="translate(-570 72)"
					fill="#fff"
				/>
				<path
					id="Pfad_2227"
					data-name="Pfad 2227"
					d="M1445.446,503.279s-3.358,2.129-3.788,4.169a42.268,42.268,0,0,1-2.789,7.865c-1.408,3.173-3.05,8.2-3.441,9.068a20.294,20.294,0,0,1-.939,2.645c-.352.377-.7-.6-.7-.6a16.378,16.378,0,0,1,1.017-3.665c1.017-2.985,2.972-7.972,3.6-9.748s1.537-3.536,2.045-5.323,2.437-4.028,4.286-5.5C1444.731,502.183,1445.328,501.881,1445.446,503.279Z"
					transform="translate(-570 72)"
					fill="none"
					stroke="#464749"
					stroke-width="0.172"
				/>
				<line
					id="Linie_443"
					data-name="Linie 443"
					x1="0.867"
					y1="5.52"
					transform="translate(892.602 566.06)"
					fill="none"
					stroke="#464749"
					stroke-width="0.179"
				/>
				<line
					id="Linie_444"
					data-name="Linie 444"
					x1="0.867"
					y1="5.52"
					transform="translate(893.174 565.989)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.063"
					stroke-dasharray="0.505 0.253"
				/>
				<line
					id="Linie_445"
					data-name="Linie 445"
					x1="0.867"
					y1="5.52"
					transform="translate(891.98 566.06)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.063"
					stroke-dasharray="0.505 0.253"
				/>
			</g>
			<g id="SHADING">
				<path
					id="Pfad_2228"
					data-name="Pfad 2228"
					d="M1490.57,512.63a1.767,1.767,0,0,1-.634.89c-.17.126-.374.235-.459.425a.951.951,0,0,0,.02.611,7.333,7.333,0,0,1,.21,1.836,3.84,3.84,0,0,0,.769-2.252q.066-.84.134-1.679"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2229"
					data-name="Pfad 2229"
					d="M1485.01,521.218a7.279,7.279,0,0,1,.427,1.844c.021.213.008.473-.185.576s-.432-.025-.611-.154a2.467,2.467,0,0,1-.852-.884,2.848,2.848,0,0,1-.149-1.264,24.162,24.162,0,0,0-.736-5.408c.519.97.414,2.255,1.226,3.015l.092-.308.571,1.68a1.047,1.047,0,0,0,.416.635c.335.182.793-.012,1.12.181a.85.85,0,0,1,.343.542,2.619,2.619,0,0,1-.859,2.635c-.2-.329.1-.718.266-1.067a1.587,1.587,0,0,0-.681-2.015c-.143-.077-.386-.089-.41.067"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2230"
					data-name="Pfad 2230"
					d="M1474.53,497.08a.339.339,0,0,0-.3-.341c-.1-.009-.193.023-.29.015s-.2-.087-.178-.178c.017-.065.088-.1.152-.125.295-.12.664-.232.907-.031a.891.891,0,0,1,.207.3c.66,1.28,1.8,2.337,2.165,3.723a1.3,1.3,0,0,1-.708-.682,3.954,3.954,0,0,0-.509-.858,4.179,4.179,0,0,0-.784-.561,1.267,1.267,0,0,1-.595-.72,2.425,2.425,0,0,1-.669-.156c-.091-.036-.195-.1-.187-.2.01-.122.188-.149.31-.115s.265.088.366.013.145-.3.278-.263"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2231"
					data-name="Pfad 2231"
					d="M1474.951,495.3l-3.962-1.487a28.3,28.3,0,0,0-2.981-.994,19.177,19.177,0,0,0-7.216-.268,57,57,0,0,0-7.081,1.557,4.049,4.049,0,0,0,1.476-.26,18.71,18.71,0,0,1,3.745-.691,6.7,6.7,0,0,0-2.334,1.35,12.365,12.365,0,0,1,6.062-1.694,21.291,21.291,0,0,1,3.887.019,20.8,20.8,0,0,1,4.3,1.185q1.971.7,3.936,1.421l.337-.25"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2232"
					data-name="Pfad 2232"
					d="M1460.762,578.138l-2.338-1.184a2.117,2.117,0,0,1-.7-.485.757.757,0,0,1-.141-.808,8.879,8.879,0,0,1,2.376.9c1.859.794,4,.889,5.678,2.04.934.642,1.742,1.616,2.855,1.76a1.26,1.26,0,0,0-.549-.853c-2.736-2.365-5.879-4.433-9.408-4.98a1.824,1.824,0,0,0-1.869.488,1.366,1.366,0,0,0,.127,1.491,3.054,3.054,0,0,0,1.257.913,9.614,9.614,0,0,0,2.9.81"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2233"
					data-name="Pfad 2233"
					d="M1465.156,569.738a6.613,6.613,0,0,1,4.508.289.358.358,0,0,1,.225.2c.058.2-.208.321-.41.348l-2.772.37a2.063,2.063,0,0,1-1.2-.08.763.763,0,0,1-.372-1.033c.17-.243.556-.44.432-.712"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2234"
					data-name="Pfad 2234"
					d="M1462.042,568.388q-2.9-1.11-5.861-2.115c.872-.588,2.613-.5,2.731-1.4.062-.472-.448-.837-.839-1.185s-.679-.932-.242-1.257c.614-.455,1.43.258,2.131.6a5.293,5.293,0,0,0,3.3-.056c1.1-.237,2.444-.36,3.2.308l-5.384.428a2.561,2.561,0,0,0-1.308.342.531.531,0,0,0,.036.945l8.1-.408a9.033,9.033,0,0,1,1.974.043c1.4.238,2.577,1.131,4.016,1.155,1.032.018,2.023-.423,3.055-.411a3.443,3.443,0,0,1,3.287,2.654,6.838,6.838,0,0,1-.828,4.011c-.46-.992-.206-2.1-.319-3.163a2.467,2.467,0,0,0-2.182-2.322,9.293,9.293,0,0,0-1.743.247,8.872,8.872,0,0,1-2.507-.146,97.648,97.648,0,0,0-11.618-1.042c-.57-.018-1.306.063-1.454.508-.179.537.679.859,1.341,1s1.46.628,1.089,1.1c-.2.3-.914-.007-.7-.3"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2235"
					data-name="Pfad 2235"
					d="M1489.89,499.011c-.214.214.018.6.319.664a1.574,1.574,0,0,0,.885-.2,6.416,6.416,0,0,1,2.3-.478.748.748,0,0,1,.445.089.48.48,0,0,1,.183.278,1.264,1.264,0,0,1-.515,1.14,1.977,1.977,0,0,0-.743,1.058,2.1,2.1,0,0,0,.27,1.016,4.262,4.262,0,0,1-.368,3.875.362.362,0,0,1-.153.152c-.167.068-.325-.109-.415-.262l-.615-1.036a1.316,1.316,0,0,0-.337-.424,1.489,1.489,0,0,0-.511-.2,9.974,9.974,0,0,1-6.2-4.8,1.381,1.381,0,0,0-.41-.539,2.021,2.021,0,0,0-.886-.183,4.437,4.437,0,0,1-2.1-1.132,48.028,48.028,0,0,0-5.4-3.706,6.413,6.413,0,0,1-2.863-2.518.585.585,0,0,1-.032-.549.642.642,0,0,1,.266-.2,1.408,1.408,0,0,1,.933-.2c.317.074.572.435.416.711a3.107,3.107,0,0,1-.279.308.765.765,0,0,0,.258,1.019,3.7,3.7,0,0,0,1.1.444c1.346.444,2.386,1.485,3.649,2.118,1.987.995,4.53.989,6.135,2.5a5.461,5.461,0,0,0,1.191,1.048c1.067.532,2.373-.17,3.554.04"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2236"
					data-name="Pfad 2236"
					d="M1482.54,497.7a3.01,3.01,0,0,1,1.641.036,1.943,1.943,0,0,1,.739.4,4.555,4.555,0,0,1,.5.607,4.646,4.646,0,0,0,2.966,1.6,8.657,8.657,0,0,0,3.441-.275.571.571,0,0,1,.411,0c.206.112.163.432-.009.59a2.394,2.394,0,0,1-.627.314c-.212.1-.417.3-.38.525s.267.347.331.553c.087.28-.192.567-.491.615a1.679,1.679,0,0,1-.877-.185,1.183,1.183,0,0,0,.909,1,2.917,2.917,0,0,1-2.083-.567c-.81-.62-1.263-1.663-2.206-2.068-.559-.24-1.29-.264-1.611-.767-.142-.222-.168-.5-.288-.73a2.328,2.328,0,0,0-1.582-.906c-.609-.166-1.3-.448-1.453-1.041a1.634,1.634,0,0,1,.981.529"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2237"
					data-name="Pfad 2237"
					d="M1480.138,581.691a2.484,2.484,0,0,0-1.678-1.992,7.8,7.8,0,0,0-2.946-.459,11.255,11.255,0,0,0-3.077.315,17.716,17.716,0,0,0-2.738,1.154,63.333,63.333,0,0,1-11.034,3.4,1.132,1.132,0,0,0-.513.226.283.283,0,0,0,0,.444.6.6,0,0,0,.35.054c.425-.023.849-.066,1.272-.11a39.043,39.043,0,0,0,4.5-.645c2.466-.552,4.759-1.578,7.225-2.13.341-.077.881.009.795.3a.462.462,0,0,1-.19.211,10.571,10.571,0,0,1-4.034,2.128c-1.043.216-2.138.143-3.2.283a3.3,3.3,0,0,0-2.595,1.415,9.434,9.434,0,0,0,3.323.408c.974-.055,1.932-.26,2.908-.3,2.467-.093,4.847.888,7.317.876q-4.87.456-9.747.855a23.4,23.4,0,0,1-4.724.063,2.223,2.223,0,0,0-.813-.007,1.665,1.665,0,0,0-.412.186c-.484.278-.977.677-.948,1.174.026.458.512.818,1.031.935a4.735,4.735,0,0,0,1.6-.021,57.5,57.5,0,0,1,10.213-.337,50.247,50.247,0,0,1-9.657.978,2.109,2.109,0,0,0-1.083.193c-.3.187-.41.633-.1.816a1.456,1.456,0,0,0-.828.052c-.244.122-.337.469-.1.6a.708.708,0,0,0,.252.061,62.854,62.854,0,0,0,11.353.269,18.527,18.527,0,0,1-6.528.929,14.113,14.113,0,0,0-4.535.137c-.186.055-.392.145-.43.31-.054.231.247.4.5.482,4.209,1.387,8.954.12,13.431.591l-11.332,1.19a1.31,1.31,0,0,0-.819.277c-.183.194-.173.467-.255.705a2.955,2.955,0,0,1-.88,1.011,3.834,3.834,0,0,0-1.2,2.346,1.4,1.4,0,0,1-1.172-.789,3.574,3.574,0,0,1-.54-1.715,27.275,27.275,0,0,1,.272-4.091c.1-.919-1.251-1.98-1.49-2.827-.17-.6.819-.977.6-1.652-.546-1.7-1.666-3.255-1.807-4.923a8.083,8.083,0,0,1,.424-3.207,24.113,24.113,0,0,0,6.177.208c4.282-.631,8.009-3.168,12.343-3.433a15.429,15.429,0,0,1,2.945.127,2.752,2.752,0,0,0,1.569-.082,2.041,2.041,0,0,0,.646-.6l1.668-2.078a49.061,49.061,0,0,1-.585,6.35l-1.01-1.709"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2238"
					data-name="Pfad 2238"
					d="M1462.013,511.859a.561.561,0,0,1,.83.326c.073.4-.329.707-.63.99a5.58,5.58,0,0,0-.855,1.136,100.97,100.97,0,0,0-6.439,11.321,4.445,4.445,0,0,1,.074-2.4c.971-3.979,3.428-7.459,6.042-10.673.381-.47.891-.985,1.5-.89"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2239"
					data-name="Pfad 2239"
					d="M1453.495,529.027a30.1,30.1,0,0,0,.932,4.58,1.8,1.8,0,0,0,.825,1.257c1.986-4.176,2.6-8.829,4.137-13.18a3.624,3.624,0,0,1,1.615-2.257,51.878,51.878,0,0,0-3.03,10.487q.576-.9,1.15-1.8-.456,2.338-.873,4.685a14.515,14.515,0,0,0-.287,4.947,29.587,29.587,0,0,1,.65,3.13,9.926,9.926,0,0,1-.291,2.556q-.415,2.059-.833,4.118a18.644,18.644,0,0,1-2.378-6.595c-.136-1.209-.069-2.437-.286-3.635-.164-.9-.488-1.773-.678-2.672a18.442,18.442,0,0,1-.3-2.642l-.2-3.157"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2240"
					data-name="Pfad 2240"
					d="M1471.928,620.228c-.436-.386-5.923-.389-6.887-.8-.212-.089-.592-.233-1.064-.413a18.147,18.147,0,0,1-4.91-.911,1.774,1.774,0,0,1-1.163-2.179.433.433,0,0,1-.069-.286c.049-.232.094-.442.14-.656-.259-.481-.519-.961-.815-1.422a1.775,1.775,0,0,1,.27-2.092c1.655-1.819-.941-5.2-1.24-7.935a1.7,1.7,0,0,1-.829-1.01c-1.425-4.453-.514-7.868,1-12.168a1.777,1.777,0,0,1,.338-.572,5.46,5.46,0,0,1,.051-.787,13.458,13.458,0,0,1,.342-1.563c-2.266-6.481-2.309-12.936-2.205-19.906.065-4.3.516-8.71.374-13-.1-3.076-1.335-5.926-.309-8.92-.116-1.417-.222-2.837-.357-4.251-.824-3.081-1.268-6.293-1.953-9.388-.293-1.323-.675-2.643-.986-3.97a22.565,22.565,0,0,1-1.388,7.574c-1.906-.99-1.539-4-3.222-5.317a1.111,1.111,0,0,0-.849-.285,1.2,1.2,0,0,0-.616.39,4.748,4.748,0,0,0-.494,5.884c.506.724,1.214,1.3,1.677,2.054.938,1.521.714,3.447,1.188,5.16.361,1.3,1.124,2.47,1.55,3.755a6.662,6.662,0,0,1,.506,3.576c-.383,2.633-.309,5.36-1.009,7.93-.69,2.533.993,5.1,1.068,7.717.051,1.766-.631,3.468-.868,5.219a27.936,27.936,0,0,0,.138,5.7c.471,6.784-.733,13.62.009,20.381.269,2.447.792,4.872.845,7.332a38.607,38.607,0,0,1-.334,5.047,10.615,10.615,0,0,1-.525,2.654l1.356,4.166s4.453,4.835,7.4,6.075,8.492.795,8.65.65C1466.857,623.555,1473.746,621.836,1471.928,620.228Z"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2241"
					data-name="Pfad 2241"
					d="M1439.289,538.665a3.4,3.4,0,0,0-.566-1.581q-.165.588-.329,1.176l-.883-.888a24.2,24.2,0,0,1,1.859,4.732q1.763,5.619,3.524,11.237a16.59,16.59,0,0,1,.859,3.711c.1,1.31-.12,2.625-.06,3.937a18.268,18.268,0,0,0,.7,3.779q2.231,8.514,4.916,16.907c.778-3.793-1.315-7.5-2.509-11.188a49.56,49.56,0,0,1-1.739-9.18,5.822,5.822,0,0,1,.339-3.66,53.046,53.046,0,0,0,3.554,10.216c.909,1.948.98-2.844.336-4.373-1.014-2.407-2.447-4.626-3.554-6.994-2.588-5.536-3.349-11.79-6.266-17.168a3.5,3.5,0,0,1-.5-1.262,1.1,1.1,0,0,1,.575-1.142"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2242"
					data-name="Pfad 2242"
					d="M1436.421,547.823q-.084,6.091-.17,12.181a118.092,118.092,0,0,0,.421,14.945c.438,3.868,1.258,7.749.826,11.617-.292,2.617-1.155,5.172-1.176,7.8a24.606,24.606,0,0,0,.771,5.423q.555,2.533,1.109,5.068a7.439,7.439,0,0,1-3.061-4.941,14.7,14.7,0,0,1-.064-2.6q.218-6.342.257-12.689c.039-6.333-.054-12.666-.043-19a121.567,121.567,0,0,1,1.022-18.007"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2243"
					data-name="Pfad 2243"
					d="M1439.349,542.646l-.239.417,1.321-18.93.3.384Z"
					transform="translate(-569.754 72.064)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<g id="SIZE_REF" transform="translate(599.336 100.259)">
				<circle
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(278.305 470.993)"
					fill="transparent"
				/>
				<circle
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(301.79 461.922)"
					fill="transparent"
				/>
			</g>
			<!-- <g id="POSITIONEN" transform="translate(602.022 81.723)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(296.305 513.922)"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g> -->
			<g
				id="PRINT-AREA"
				transform="translate(889.277 583.595)"
				fill="none"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="18.9" height="87.9" fill="none" ref="print-area" />
			</g>
		</g>
	</svg>
</template>

<script>
export default {
	name: 'HoodieLeft',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
}
</script>