<template>
	<svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
		<g id="CAP_CURVED_SNAPBACK_RIGHT" ref="main-wrapper" transform="matrix(0.633196, 0, 0, 0.633196, -14.720666, -14.596766)">
			<g id="COLORS">
				<path
					:fill="mainColor"
					d="M117.68,107.06l1.49,0c.67,0,1.32.08,1.91.13a5.77,5.77,0,0,0,2.41,0l0-.08c0-1.68-3-2-4.29-2-1.87-.08-3.43.27-4.09.91a1,1,0,0,0-.34.74c.28.35,1.41.35,2.51.34Z"
				/>
				<path
					:fill="mainColor"
					d="M152.47,116.09c2.47,3.1,4.68,9.58,5.5,14.1.7,3.85,1.09,9.3,1.41,13.68l.14,1.9a111.3,111.3,0,0,0,2.29,12.77,35.06,35.06,0,0,0,1.6,6.4,8.31,8.31,0,0,1,.68,3.52c12.36-3.44,13.74-5.66,14.26-6.5l.05-.07a2.81,2.81,0,0,0,.08-2l0-.17a26.74,26.74,0,0,1-1.6-6.63,62.37,62.37,0,0,0-1.51-6.06c-.32-1.13-.63-2.2-.89-3.27-.21-.82-.38-1.59-.58-2.5-.55-2.48-1.3-5.87-3.55-14.08-2.8-10.21-5.36-12.79-8.07-15.53l-.84-.85c-2.91-3-11.85-3-15.56-2.92-1.69.06-4.89.2-7.67.32A20.58,20.58,0,0,1,152.47,116.09Z"
				/>
				<path
					:fill="mainColor"
					d="M69.62,171.87c-.34-2.37-.52-5.78-.66-8.62-1.68.35-3.38.94-3.36,1.34a4.8,4.8,0,0,0,.2,1.11,5,5,0,0,1,.22,1.47,1.89,1.89,0,0,1-.26.94,1.28,1.28,0,0,0-.16.84,10.7,10.7,0,0,1,0,1.4,4.57,4.57,0,0,0,0,.82,3.2,3.2,0,0,0,.44,1.15,2,2,0,0,1,.26,1.08,10.59,10.59,0,0,1-.19,1.6.8.8,0,0,1,0,.21.47.47,0,0,0,0,.18s0,.07.28.14.25.08.38.12a13.19,13.19,0,0,0,.23-3.76l.42,0a14.09,14.09,0,0,1-.24,3.94,4.47,4.47,0,0,1,.7.33,13.07,13.07,0,0,0,2.77.77,22.12,22.12,0,0,1-.81-4.1Z"
				/>
				<path
					:fill="mainColor"
					d="M211,161.26c-5.7-.47-20.19-1.52-32-1.23a3.24,3.24,0,0,1-.19,2.06l-.06.09c-.59,1-2,3.21-14.62,6.72h0l-.15,0c-.66.19-2.78.53-4.85.84-1.64.26-3.31.52-4.39.62l-.21,0c-.72.34-2.19,1.24-2.66,1.53a8.45,8.45,0,0,1,1.26,1l2.54.31.69.09c15.21,2,20.21,2.44,21.73,2.51,4.15.18,16,.59,20.57.48l1.06,0c4.1-.08,10.29-.19,13.92-2.3a17.13,17.13,0,0,0,2.61-2,6.19,6.19,0,0,0,1.47-5.88C217.64,165.9,216.63,161.72,211,161.26Z"
				/>
				<path
					:fill="mainColor"
					d="M154,170.22l.35-.18h0l0,0,.25,0,4.41-.63c2-.32,3.88-.66,4.61-.8a7.91,7.91,0,0,0-.66-3.46,34.92,34.92,0,0,1-1.62-6.48,113,113,0,0,1-2.3-12.81L159,143.9c-.32-4.37-.71-9.8-1.41-13.64-.81-4.47-3-10.86-5.41-13.91a20.14,20.14,0,0,0-15.89-7.84h-.12c-6.65-.12-9.57-.32-11.32-.43l-1.15-.07a2.71,2.71,0,0,1-1,.16c-.46,0-1,0-1.68-.11s-1.2-.1-1.87-.13-1.27,0-1.83,0a7.4,7.4,0,0,1-2.71-.27,6.35,6.35,0,0,0-1.4.45A46.55,46.55,0,0,1,101.85,113c-5.57,1.44-13.53,3.72-18.31,6.87-5.07,3.35-8.33,5.61-11.13,9.59a22.88,22.88,0,0,0-2.26,3.69,9.29,9.29,0,0,1-1.31,2.06l-.37.46c-1.46,1.83-2.06,2.58-2.16,6.76a24.09,24.09,0,0,0,.68,8,71.18,71.18,0,0,1,2.07,7.81c.11,1,.2,2.68.3,4.63.15,2.89.33,6.49.67,8.93l.13.92c.48,3.56.8,5.91,3.11,6,1.44,0,14.23.3,20,0,1.14-.07,2.28-.1,3.42-.1,1.79,0,3.57.08,5.33.24a26,26,0,0,0,4.48-.42l4.45-.64,1.47-.22,3-.46c3.58-.55,9-1.38,12.39-1.84,2.33-.31,4.54-.56,6.31-.75s3.27-.37,4.07-.51c1.59-.29,5.08-.86,5.11-.86l8.19-1.44a2.56,2.56,0,0,1,1.05-.74Z"
				/>
			</g>
			<g id="LINES">
				<g id="Pfad_4230" data-name="Pfad 4230">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M102,179c-.34,0-.72,0-1.16-.07a4.24,4.24,0,0,0,2.61.11l.23-.1A13.48,13.48,0,0,1,102,179Z"
					/>
				</g>
				<g id="Pfad_4231" data-name="Pfad 4231">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M195,172h-1.2v-.29h2.35V172Zm-2.38,0-2.35-.07v-.3c.8,0,1.59.06,2.35.07Zm4.72,0v-.29c.82,0,1.61-.06,2.34-.11l0,.3C198.93,171.92,198.13,172,197.3,172Zm-8.26-.11-2.35-.11,0-.3,2.35.12Zm11.8-.08,0-.29c.83-.07,1.61-.16,2.33-.27l0,.29C202.47,171.63,201.68,171.72,200.84,171.79Zm-15.33-.1h-.11l-2.24-.14,0-.29,2.24.13h.1Zm-3.53-.21-2.35-.16,0-.3,2.35.16Zm22.38-.15-.05-.29a22.18,22.18,0,0,0,2.27-.56l.08.28A21.48,21.48,0,0,1,204.36,171.33Zm-25.91-.1-2.35-.18,0-.29,2.35.18Zm-3.53-.27-2.35-.2,0-.29,2.35.19Zm-3.52-.3-2.35-.22,0-.29,2.34.21Zm36.38-.31-.11-.27a8.8,8.8,0,0,0,2-1.13l.18.24A9.64,9.64,0,0,1,207.78,170.35Zm-39.91,0-2.34-.23,0-.29,2.34.23Zm-3.52-.35-2.34-.26,0-.29,2.34.25Zm-3.52-.4c-.71-.08-.71-.08-.7-.22h.15l0-.15.57.07Zm49.92-1.2-.21-.2a5.57,5.57,0,0,0,.52-.68,4.36,4.36,0,0,0,.59-1.29l.29.08a4.66,4.66,0,0,1-.63,1.37A5.37,5.37,0,0,1,210.75,168.38Zm1-3.29a3.86,3.86,0,0,0-.92-2l.23-.18a4.18,4.18,0,0,1,1,2.2ZM210,162.23a9.22,9.22,0,0,0-1.94-1.27l.13-.27a9.52,9.52,0,0,1,2,1.32Z"
					/>
				</g>
				<g id="Pfad_4232" data-name="Pfad 4232">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M192.38,170.62h-.11l-2.25,0v-.3c.78,0,1.53,0,2.24,0h.11Zm1.19,0v-.3c.8,0,1.59,0,2.35-.09l0,.3C195.16,170.56,194.37,170.59,193.57,170.61Zm-4.73-.07-.91,0-1.44-.08v-.3l1.44.09.92.05Zm8.27-.11,0-.29c.8-.07,1.58-.16,2.34-.26l0,.29C198.7,170.27,197.91,170.36,197.11,170.43Zm-11.8-.1-2.36-.15,0-.29,2.36.15Zm-3.53-.22-2.36-.16,0-.3,2.35.16Zm18.85-.12,0-.29c.81-.15,1.59-.31,2.29-.5l.07.29C202.23,169.67,201.45,169.84,200.63,170Zm-22.39-.13-2.35-.16,0-.3,2.35.17Zm-3.53-.25-2.35-.17,0-.29,2.35.17Zm-3.53-.26-2.35-.18,0-.29,2.35.18Zm32.9-.19-.09-.28a14.84,14.84,0,0,0,2.17-.87l.13.26A14.31,14.31,0,0,1,204.08,169.16Zm-36.43-.08-2.35-.19,0-.29,2.35.19Zm39.67-1.41-.16-.24a5.32,5.32,0,0,0,1.26-1.17,2.92,2.92,0,0,0,.29-.46l.26.12a2.55,2.55,0,0,1-.31.52A5.75,5.75,0,0,1,207.32,167.67Zm1.56-2.95a3.62,3.62,0,0,0-1.08-1.94l.21-.2a3.81,3.81,0,0,1,1.16,2.11ZM206.93,162a12.59,12.59,0,0,0-2-1.27l.13-.27a15.1,15.1,0,0,1,2,1.3Z"
					/>
				</g>
				<g id="Pfad_4233" data-name="Pfad 4233">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M191.38,169.09h-1.23v-.29c.82,0,1.6,0,2.35,0v.29Zm-2.41,0c-.75,0-1.54,0-2.36-.07v-.29l2.36.07Zm4.72,0v-.3c.82,0,1.61,0,2.34-.1l0,.29C195.31,169,194.52,169,193.69,169.06Zm-8.26-.12-1.17-.06-1.18-.06,0-.29,1.18.06,1.17.06Zm11.8-.07,0-.3c.84-.07,1.63-.17,2.33-.28l.05.29C198.87,168.69,198.08,168.79,197.23,168.87Zm-15.33-.11-2.35-.13,0-.29,2.35.12Zm-3.53-.19-.66,0-1.69-.09,0-.3,1.69.09.66,0Zm-3.52-.2-2.35-.14v-.29l2.35.13Zm25.89,0-.06-.28a14.45,14.45,0,0,0,2.24-.64l.11.28A15.52,15.52,0,0,1,200.74,168.36Zm-29.42-.2L169,168l0-.29,2.35.15Zm-3.52-.23c-.62-.05-.62-.05-.62-.2h.15l0-.14.46,0Zm36.31-.71L204,167a5.39,5.39,0,0,0,1.76-1.45l.24.18A5.83,5.83,0,0,1,204.11,167.22Zm1.95-2.68a3.44,3.44,0,0,0-1.16-1.9l.21-.21a3.71,3.71,0,0,1,1.24,2.08Zm-2-2.67a15.58,15.58,0,0,0-1.93-1.32l.14-.26a17.58,17.58,0,0,1,2,1.35Z"
					/>
				</g>
				<g id="Pfad_4234" data-name="Pfad 4234">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M192.41,167.8h-.18l-2.18,0v-.29l2.18,0h.18Zm1.18,0v-.3c.9,0,1.66-.05,2.35-.1l0,.3C195.26,167.74,194.49,167.77,193.59,167.79Zm-4.72,0-2.36-.06v-.3l2.35.07Zm-3.54-.1-2.36-.08,0-.29,2.35.08Zm11.8-.07,0-.29a16.31,16.31,0,0,0,2.29-.41l.08.29A16.83,16.83,0,0,1,197.13,167.58Zm-15.34-.05-2.35-.1v-.29l2.36.09Zm-3.53-.14-2.23-.1h-.13l0-.29H176l2.23.1Zm-3.53-.16-2.36-.12,0-.3,2.35.12Zm-3.54-.19c-1.2-.07-1.2-.07-1.24-.16l.14,0,0-.15,1.1.07Zm29.42-.23-.11-.27a8.44,8.44,0,0,0,2-1.07l.22.21-.06,0A9,9,0,0,1,200.61,166.81Zm2.89-2.1-.28-.1a1.36,1.36,0,0,0,.09-.48,2.49,2.49,0,0,0-.69-1.53l.23-.19a2.8,2.8,0,0,1,.75,1.72A1.65,1.65,0,0,1,203.5,164.71Zm-1.69-2.94a12.26,12.26,0,0,0-1.89-1.35l.14-.26a14,14,0,0,1,1.95,1.39Z"
					/>
				</g>
				<g id="Pfad_4235" data-name="Pfad 4235">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M188.77,166.52h-2.36v-.3l2.36,0Zm1.18,0v-.29l1.55,0,.8,0v.29l-.81,0Zm-4.72,0-2.36-.05v-.29l2.36,0Zm-3.54-.08-2.36-.06v-.3l2.36.07Zm11.8,0v-.29c.86-.06,1.64-.14,2.32-.25l0,.29C195.16,166.27,194.36,166.35,193.49,166.41Zm-15.34-.1-2.36-.08v-.3l2.36.09Zm-3.54-.12-2.16-.09,0-.3,2.15.09Zm22.41-.26-.07-.28a6.52,6.52,0,0,0,2.12-.85l.17.24A6.92,6.92,0,0,1,197,165.93Zm3.09-1.76-.25-.16a2.13,2.13,0,0,0,.33-.87.82.82,0,0,0,0-.22,1.47,1.47,0,0,0-.36-.92l.23-.18a1.76,1.76,0,0,1,.43,1.1,1.24,1.24,0,0,1,0,.27A2.69,2.69,0,0,1,200.11,164.17ZM199,161.26a9.62,9.62,0,0,0-2.09-1L197,160a9.49,9.49,0,0,1,2.17,1Z"
					/>
				</g>
				<g id="Pfad_4236" data-name="Pfad 4236">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M188.77,165.69l-2.37,0v-.29l2.36,0Zm1.18,0v-.3h2.35v.3H190Zm-4.73-.05-2.36,0v-.29l2.36,0Zm8.27,0,0-.3a6.18,6.18,0,0,0,2.25-.42l.12.27A6.67,6.67,0,0,1,193.49,165.63Zm-11.81-.06-2.36-.06v-.3l2.36.06Zm-3.54-.1-2.36-.07v-.29l2.36.07Zm-3.54-.1-.39,0v-.29h.39Zm22.24-.9-.21-.2a4,4,0,0,0,.49-.61,1.3,1.3,0,0,0,.23-.71,1.4,1.4,0,0,0-.15-.61l.26-.13a1.71,1.71,0,0,1,.18.74,1.52,1.52,0,0,1-.28.87A4,4,0,0,1,196.84,164.47Zm-.33-3a8.91,8.91,0,0,0-1.92-1.29l.13-.26a8.29,8.29,0,0,1,2,1.34Z"
					/>
				</g>
				<g id="Pfad_4237" data-name="Pfad 4237">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M188.4,164.83H188l-1.84,0H186v-.29h.12l1.83,0h.41Zm1.18,0v-.29a21.84,21.84,0,0,0,2.33-.14l0,.29A20.09,20.09,0,0,1,189.58,164.83Zm-4.72-.07c-.8,0-1.59,0-2.36-.06v-.29l2.36.06Zm-3.54-.09-2.36-.08v-.3l2.36.08Zm-3.54-.12-1.85-.07v-.3l1.85.07Zm15.35-.11-.1-.28a2,2,0,0,0,1.32-1.42c0-.06,0-.11,0-.17l.29.05a.83.83,0,0,1-.06.21A2.23,2.23,0,0,1,193.13,164.44Zm1-2.88a6.11,6.11,0,0,0-1.69-1.52l.16-.25a6.46,6.46,0,0,1,1.78,1.61Z"
					/>
				</g>
				<g id="Pfad_4238" data-name="Pfad 4238">
					<path
						class="cls-4"
						d="M70.87,177.18a13.12,13.12,0,0,1-3.19-.87,6.28,6.28,0,0,0-1.45-.58c-.65-.19-.38-.46-.34-.76a10.34,10.34,0,0,0,.18-1.57,1.88,1.88,0,0,0-.22-1,3.2,3.2,0,0,1-.47-1.22c-.09-.42.08-1.3,0-2.25s.38-.88.42-1.8a25.79,25.79,0,0,0-.42-2.56c0-.34.71-.73,1.39-1l-.18.08s-1.12.83,0,1.21a18,18,0,0,0,2.69.69l.6,7.25Z"
					/>
				</g>
				<g id="Pfad_4239" data-name="Pfad 4239">
					<path class="cls-5" d="M121.15,107.65c-.61-.05-1.27-.11-2-.14Z" />
				</g>
				<g id="Pfad_4240" data-name="Pfad 4240">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M196.84,176.77c-5.36,0-15.11-.33-18.81-.5-1.52-.06-6.54-.55-21.76-2.51l-.69-.09-2.61-.32h-.07l-.05,0a10.67,10.67,0,0,0-1.29-1,.38.38,0,0,1-.21-.37c0-.41.82-.79,1.15-.94l1.91-.93c2.3-.2,8.23-1.27,9.39-1.48h0c12.61-3.49,14-5.73,14.52-6.58l.05-.07a2.81,2.81,0,0,0,.08-2l-.09-.27h.29c11.9-.31,26.57.75,32.33,1.23,6,.49,7.07,5.1,7.08,5.15a6.62,6.62,0,0,1-1.58,6.28,17.54,17.54,0,0,1-2.69,2.1c-3.73,2.17-10,2.28-14.13,2.36l-1.06,0Zm-43.75-3.83,2.54.31.69.09c15.21,2,20.21,2.44,21.73,2.51,4.15.18,16,.59,20.57.48l1.06,0c4.1-.08,10.29-.19,13.92-2.3a17.13,17.13,0,0,0,2.61-2,6.19,6.19,0,0,0,1.47-5.88c0-.19-1.05-4.37-6.71-4.83s-20.19-1.52-32-1.23a3.24,3.24,0,0,1-.19,2.06l-.06.09c-.6,1-2,3.23-14.77,6.76l-.06,0c-1.16.21-7,1.27-9.4,1.47l-1.8.9a2.4,2.4,0,0,0-.91.59A8.76,8.76,0,0,1,153.09,172.94Z"
					/>
				</g>
				<g id="Pfad_4241" data-name="Pfad 4241">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M146.43,173.59c-1.57,0-3.12-.05-3.15-.05l0-.42c3.52-.74,8.23-1.45,8.28-1.46h.1l1.94,1.73-.52,0-1.54,0c-.84,0-1.71,0-2.87.1C148,173.58,147.23,173.59,146.43,173.59Zm-1.21-.43c1.11,0,2.47,0,3.39,0,1.18-.08,2-.09,2.9-.11l1,0-1-.89C150.93,172.18,148,172.63,145.22,173.16Z"
					/>
				</g>
				<g id="Pfad_4242" data-name="Pfad 4242">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M102,179.25a59.07,59.07,0,0,0-8.72-.14c-5.75.3-18.55,0-20,0-2.66-.06-3-2.69-3.52-6.32l-.12-.92c-.35-2.46-.53-6.07-.68-9-.1-1.95-.19-3.64-.3-4.59a69.26,69.26,0,0,0-2.06-7.76,24.72,24.72,0,0,1-.7-8.12c.11-4.31.79-5.15,2.26-7l.37-.46a9,9,0,0,0,1.26-2,22.64,22.64,0,0,1,2.29-3.75c2.85-4,6.13-6.32,11.25-9.7,4.83-3.19,12.83-5.48,18.43-6.92A46.18,46.18,0,0,0,113,107.72a6.86,6.86,0,0,1,3.75-.61c.17,0,5.09.41,6.65.46l1.45.09c1.74.11,4.66.3,11.29.43h.12a20.55,20.55,0,0,1,16.22,8c2.47,3.1,4.68,9.58,5.5,14.1.7,3.85,1.09,9.3,1.41,13.68l.14,1.9a111.3,111.3,0,0,0,2.29,12.77,35.06,35.06,0,0,0,1.6,6.4,8.32,8.32,0,0,1,.68,3.82v.15l-.15,0c-1.35.38-8.81,1.4-9.45,1.49-.85.41-2.79,1.61-2.81,1.62l-.08,0-8.27,1.45s-3.52.58-5.1.86c-.82.15-2.27.31-4.1.52s-4,.44-6.3.75c-3.39.45-8.8,1.29-12.39,1.84l-3,.46-1.47.21-4.44.65A26.86,26.86,0,0,1,102,179.25Zm-5.32-.66c1.79,0,3.57.08,5.33.24a26,26,0,0,0,4.48-.42l4.45-.64,1.47-.22,3-.46c3.58-.55,9-1.38,12.39-1.84,2.33-.31,4.54-.56,6.31-.75s3.27-.37,4.07-.51c1.59-.29,5.08-.86,5.11-.86l8.23-1.45c.28-.17,2-1.26,2.85-1.64h.06c.08,0,7.58-1,9.28-1.44a7.87,7.87,0,0,0-.66-3.49,34.92,34.92,0,0,1-1.62-6.48,113,113,0,0,1-2.3-12.81L159,143.9c-.32-4.37-.71-9.8-1.41-13.64-.81-4.47-3-10.86-5.41-13.91a20.14,20.14,0,0,0-15.89-7.84h-.12c-6.65-.12-9.57-.32-11.32-.43-.58,0-1-.07-1.43-.08-1.6-.06-6.63-.46-6.68-.47h0a6.48,6.48,0,0,0-3.5.56A46.55,46.55,0,0,1,101.85,113c-5.57,1.44-13.53,3.72-18.31,6.87-5.07,3.35-8.33,5.61-11.13,9.59a22.88,22.88,0,0,0-2.26,3.69,9.29,9.29,0,0,1-1.31,2.06l-.37.46c-1.46,1.83-2.06,2.58-2.16,6.76a24.09,24.09,0,0,0,.68,8,71.18,71.18,0,0,1,2.07,7.81c.11,1,.2,2.68.3,4.63.15,2.89.33,6.49.67,8.93l.13.92c.48,3.56.8,5.91,3.11,6,1.44,0,14.23.3,20,0C94.38,178.62,95.52,178.59,96.66,178.59Z"
					/>
				</g>
				<g id="Pfad_4243" data-name="Pfad 4243">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M161.57,169.36l-.29,0c0-.15,0-.31,0-.47a7.94,7.94,0,0,0-.21-1.82l.29-.07a8.56,8.56,0,0,1,.22,1.89C161.59,169,161.58,169.2,161.57,169.36Zm-.84-3.42c0-.09-.07-.18-.11-.27-.23-.65-.45-1.31-.64-2l.28-.09c.19.66.41,1.32.63,1.95,0,.09.08.18.11.27Zm-1.06-3.39c-.19-.76-.36-1.54-.5-2.31l.29-.05c.14.76.31,1.54.5,2.29Zm-.7-3.46c-.19-.79-.36-1.56-.52-2.31l.29-.06c.15.74.33,1.52.52,2.3Zm-.77-3.46-.45-2.32.29-.05q.21,1.16.45,2.31Zm-.65-3.48c-.14-.79-.27-1.58-.38-2.33l.29,0c.11.75.24,1.53.37,2.32Zm-.56-3.5c-.1-.74-.2-1.5-.29-2.25a.38.38,0,0,0,0-.1h.3v.08c.09.74.19,1.5.3,2.24Zm-.38-3.52-.17-2.35.29,0,.17,2.35Zm-.26-3.53c-.06-.77-.12-1.56-.19-2.35l.29,0c.07.79.13,1.57.19,2.35Zm-.29-3.52c-.07-.79-.15-1.58-.23-2.35l.29,0c.08.77.16,1.56.23,2.35Zm-.36-3.52c-.11-.87-.21-1.63-.32-2.33l.3,0c.1.7.2,1.47.31,2.34Zm-.51-3.49,0-.21c-.12-.67-.27-1.37-.45-2.09l.29-.07c.17.73.32,1.44.45,2.1l0,.22Zm-.77-3.44c-.21-.75-.43-1.51-.67-2.25l.28-.09c.24.74.47,1.5.67,2.27Zm-1-3.37c-.27-.75-.55-1.49-.84-2.19l.28-.11c.29.7.57,1.44.84,2.21Zm-1.3-3.27q-.53-1.15-1.05-2.1l.26-.14c.35.63.71,1.34,1.06,2.12Zm-1.65-3.1a10.18,10.18,0,0,0-.7-1c-.24-.3-.48-.6-.73-.87l.21-.2c.26.28.51.58.75.88a10.3,10.3,0,0,1,.71,1Zm-2.25-2.68a17.52,17.52,0,0,0-1.77-1.53l.18-.23a19.18,19.18,0,0,1,1.8,1.54ZM145.46,113a20,20,0,0,0-2-1.19l.14-.27a22.9,22.9,0,0,1,2,1.22Zm-3.06-1.72c-.69-.32-1.42-.62-2.16-.89l.1-.28c.75.28,1.49.58,2.19.91ZM139.13,110c-.74-.23-1.5-.44-2.27-.62l.07-.29q1.17.27,2.28.63Zm-3.41-.87c-.76-.15-1.54-.28-2.32-.39l0-.29q1.19.16,2.34.39Zm-3.48-.53c-.76-.08-1.55-.15-2.34-.19v-.3c.81.05,1.6.12,2.36.2Zm-3.52-.24c-.56,0-1.17,0-1.8,0v-.3c.63,0,1.24,0,1.81,0Z"
					/>
				</g>
				<g id="Pfad_4244" data-name="Pfad 4244">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M178.49,159.9a26.9,26.9,0,0,1-1.66-6.81,62.37,62.37,0,0,0-1.51-6.06c-.32-1.13-.63-2.2-.89-3.27-.21-.82-.38-1.59-.58-2.5-.55-2.48-1.3-5.87-3.55-14.08-2.8-10.21-5.36-12.79-8.07-15.53l-.84-.85c-2.91-3-11.85-3-15.56-2.92-3.35.11-12.71.56-12.81.56l0-.42c.1,0,9.46-.45,12.81-.57,3.77-.12,12.86-.09,15.89,3l.83.86c2.75,2.77,5.34,5.39,8.17,15.71,2.26,8.21,3,11.61,3.56,14.1.2.91.37,1.67.58,2.49.26,1.06.57,2.13.89,3.26.56,2,1.15,4,1.52,6.11a26.65,26.65,0,0,0,1.63,6.71Z"
					/>
				</g>
				<g id="Pfad_4245" data-name="Pfad 4245">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M165.76,168.17a10.14,10.14,0,0,0-.35-1.67l.28-.08a12,12,0,0,1,.37,1.73Zm-.63-2.82,0-.06c-.14-.59-.32-1.32-.54-2.22l.29-.07c.21.9.39,1.63.54,2.22v.06Zm-.84-3.43-.1-.42c-.13-.57-.28-1.19-.44-1.87l.29-.07c.16.68.3,1.3.44,1.87l.1.42Zm-.81-3.44c0-.18-.09-.37-.13-.56l-.42-1.73.29-.07c.13.58.27,1.15.41,1.73.05.19.09.38.14.57Zm-.81-3.44c-.17-.77-.33-1.54-.49-2.31l.29-.06c.15.77.32,1.54.49,2.3Zm-.72-3.47c-.15-.77-.29-1.54-.42-2.32l.29-.05c.13.77.27,1.55.42,2.32Zm-.62-3.48c-.12-.78-.24-1.56-.35-2.34l.29,0c.11.78.23,1.55.35,2.33Zm-.52-3.5c-.1-.79-.2-1.56-.29-2.35l.3,0c.09.78.18,1.56.28,2.34Zm-.41-3.52c-.09-.78-.16-1.56-.23-2.35l.3,0c.06.78.14,1.56.22,2.34Zm-.33-3.52-.24-2.34.3,0,.23,2.34Zm-.36-3.51c-.1-.91-.19-1.63-.3-2.32l.29-.05c.11.7.2,1.42.3,2.34Zm-.51-3.48c-.13-.7-.29-1.45-.49-2.29l.28-.07c.2.85.37,1.6.5,2.31Zm-.77-3.43c-.17-.68-.37-1.43-.59-2.28l.28-.07c.23.84.42,1.59.6,2.28Zm-.9-3.41c-.19-.7-.4-1.45-.63-2.27l.29-.08c.22.82.43,1.57.62,2.27Zm-1-3.4a13.84,13.84,0,0,0-.9-2.14l.27-.14a14.45,14.45,0,0,1,.91,2.19Zm-1.47-3.15a14,14,0,0,0-1.41-1.86l.22-.2a14.31,14.31,0,0,1,1.43,1.9Zm-2.22-2.7a16.7,16.7,0,0,0-1.79-1.5l.17-.24a16.29,16.29,0,0,1,1.83,1.53Zm-2.76-2.15a18.3,18.3,0,0,0-2.05-1.13l.13-.26a18.8,18.8,0,0,1,2.08,1.14ZM147,110.71c-.7-.3-1.45-.56-2.21-.8l.09-.28c.77.24,1.52.51,2.23.81Zm-3.34-1.11a17,17,0,0,0-2.3-.46l0-.3a19.12,19.12,0,0,1,2.34.47Z"
					/>
				</g>
				<g id="Pfad_4246" data-name="Pfad 4246">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M143.31,170.49l0-.29,2.17-.36.05.29C144.16,170.36,143.31,170.49,143.31,170.49Zm3.34-.56-.05-.29,2.32-.41.05.29Zm3.49-.62-.06-.29,2.32-.43.06.28C151.67,169,150.89,169.17,150.14,169.31Zm3.47-.66-.05-.29,2.31-.47.06.29Zm3.47-.71-.06-.28,2.3-.5.06.28C158.59,167.62,157.82,167.79,157.08,167.94Zm3.45-.75-.06-.29,2.29-.53.07.28Zm3.45-.81-.07-.29,2.28-.56.07.28Zm3.43-.86-.08-.28,2.29-.59.07.29Zm3.42-.89-.08-.28c.8-.23,1.55-.47,2.24-.72l.1.28C172.4,164.16,171.64,164.4,170.83,164.63Zm3.36-1.15-.12-.27a16.26,16.26,0,0,0,2.09-1.05l.15.25A18.17,18.17,0,0,1,174.19,163.48Zm3.11-1.75-.19-.23a3.71,3.71,0,0,0,1.43-1.7l.28.06S178.64,160.69,177.3,161.73Z"
					/>
				</g>
				<g id="Pfad_4247" data-name="Pfad 4247">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M105.65,178.78c-1-2.18-1.64-26.36-1.64-29.44,0-.63,0-1.64-.11-2.92-.28-6-.81-17.13,1.58-24.24a40.91,40.91,0,0,1,9.59-14.93l.3.3a40.59,40.59,0,0,0-9.5,14.77c-2.36,7-1.83,18.12-1.54,24.08.06,1.29.11,2.3.11,2.94,0,3.85.72,27.27,1.59,29.28Z"
					/>
				</g>
				<g id="Pfad_4248" data-name="Pfad 4248">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M109,178.25c-.09-.72-.19-1.46-.27-2.22l.29,0c.09.75.18,1.49.27,2.21Zm-.4-3.39c-.08-.77-.15-1.55-.22-2.35l.29,0c.07.8.14,1.58.22,2.35Zm-.32-3.53c-.07-.77-.12-1.55-.18-2.35l.3,0c0,.79.11,1.58.17,2.35ZM108,167.8c0-.77-.09-1.56-.13-2.35l.29,0c0,.79.08,1.58.13,2.35Zm-.19-3.53c0-.78-.07-1.56-.1-2.36H108c0,.79.07,1.58.1,2.36Zm-.14-3.54c0-.78,0-1.56-.07-2.35h.29c0,.79,0,1.58.07,2.35Zm-.1-3.54c0-.78,0-1.57,0-2.35h.29c0,.79,0,1.57.05,2.36Zm-.06-3.53c0-.79,0-1.58,0-2.36h.3c0,.79,0,1.58,0,2.36Zm0-3.54c0-.44,0-.88,0-1.31v-1.05h.3v2.36Zm.29-3.54h-.29c0-.79,0-1.57,0-2.36h.29C107.79,145,107.79,145.79,107.78,146.58Zm0-3.54h-.29l0-2.35h.3C107.84,141.47,107.82,142.26,107.81,143Zm.06-3.53h-.29c0-.79,0-1.58,0-2.36h.29C107.9,137.94,107.89,138.72,107.87,139.51ZM108,136h-.29c0-.79,0-1.58.06-2.35h.3C108,134.4,108,135.19,108,136Zm.1-3.53h-.29c0-.8,0-1.58.08-2.36h.29C108.11,130.87,108.08,131.65,108.05,132.45Zm.13-3.54h-.3l0-.46c0-.63.07-1.27.12-1.9l.29,0c0,.62-.09,1.25-.11,1.88Zm.24-3.51-.3,0c.09-.79.19-1.58.32-2.35l.29,0C108.6,123.83,108.5,124.62,108.42,125.4Zm.51-3.49-.29,0c.16-.78.33-1.55.53-2.31l.28.08C109.26,120.37,109.08,121.14,108.93,121.91Zm.84-3.41-.28-.09c.23-.77.48-1.52.76-2.24l.27.11C110.25,117,110,117.74,109.77,118.5Zm1.2-3.31-.27-.12c.31-.73.66-1.45,1-2.13l.25.14C111.62,113.75,111.28,114.46,111,115.19Zm1.59-3.13-.25-.15c.42-.69.87-1.35,1.33-2l.24.18A23.32,23.32,0,0,0,112.56,112.06Zm2.06-2.84-.23-.19a10.66,10.66,0,0,1,1.72-1.67l.17.24A10.71,10.71,0,0,0,114.62,109.22Z"
					/>
				</g>
				<g id="Pfad_4249" data-name="Pfad 4249">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M102.17,179l-.12-1.87.29,0,.12,1.87Zm-.2-3c0-.79-.1-1.58-.14-2.35l.29,0c0,.77.09,1.56.14,2.35Zm-.21-3.53c-.05-.79-.09-1.58-.14-2.35l.3,0c0,.78.08,1.56.13,2.35Zm-.2-3.53c0-.8-.08-1.58-.12-2.35l.29,0c0,.77.08,1.56.12,2.35Zm-.18-3.53c0-.8-.07-1.58-.11-2.36h.3c0,.77.07,1.56.11,2.35Zm-.15-3.54c0-.79-.07-1.58-.1-2.35h.3c0,.77.06,1.56.09,2.35Zm-.14-3.53c0-.8,0-1.58-.07-2.36h.29c0,.77,0,1.55.08,2.35Zm-.1-3.53c0-.8,0-1.59-.06-2.36h.3c0,.77,0,1.56,0,2.36Zm-.08-3.54c0-.8,0-1.59,0-2.36h.29c0,.77,0,1.55,0,2.35Zm0-3.54c0-.69,0-1.37,0-2v-.31h.3v2.35Zm.29-3.53h-.29c0-.81,0-1.59,0-2.36h.29C101.17,142.6,101.16,143.38,101.16,144.19Zm0-3.54h-.29c0-.81,0-1.59,0-2.36h.3C101.24,139.06,101.22,139.85,101.2,140.65Zm.09-3.53H101c0-.81.06-1.59.1-2.36l.29,0C101.35,135.53,101.32,136.32,101.29,137.12Zm.16-3.53-.3,0c0-.8.09-1.59.14-2.35l.3,0C101.54,132,101.49,132.78,101.45,133.59Zm.22-3.52-.29,0c.06-.81.12-1.6.19-2.36l.29,0C101.79,128.48,101.73,129.26,101.67,130.07Zm.3-3.52-.29,0c0-.37.07-.73.11-1.08s.11-.85.18-1.27l.29,0c-.07.41-.13.83-.18,1.25S102,126.18,102,126.55Zm.54-3.47-.29-.07a19.09,19.09,0,0,1,.72-2.26l.28.1A17.44,17.44,0,0,0,102.51,123.08Zm1.16-3.31-.27-.12c.32-.7.69-1.41,1.09-2.1l.25.14C104.35,118.38,104,119.08,103.67,119.77Zm1.69-3.08-.25-.16c.42-.64.88-1.29,1.37-1.93l.24.18C106.23,115.41,105.77,116.06,105.36,116.69Zm2.09-2.82-.22-.19c.5-.59,1-1.19,1.58-1.76l.21.2C108.47,112.69,108,113.28,107.45,113.87Zm2.41-2.58-.21-.21c.56-.54,1.15-1.08,1.75-1.59l.19.22C111,110.22,110.41,110.75,109.86,111.29ZM112.5,109l-.18-.23c1.14-.91,1.91-1.4,1.92-1.41l.16.25A22.47,22.47,0,0,0,112.5,109Z"
					/>
				</g>
				<g id="Pfad_4250" data-name="Pfad 4250">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M74,178.81a3.89,3.89,0,0,1-.79-1.6l.28-.09a4,4,0,0,0,.71,1.48Zm-1.09-2.76c-.16-.66-.31-1.44-.45-2.32l.29,0c.14.88.29,1.65.44,2.31Zm-.62-3.49c-.11-.75-.21-1.54-.29-2.35l.29,0c.09.8.18,1.59.29,2.33ZM71.85,169c-.06-.65-.11-1.28-.14-1.78l0-.58.29,0,0,.59c0,.49.08,1.12.14,1.77Zm-.26-3.53c-.06-.78-.13-1.57-.2-2.35l.29,0c.07.78.14,1.56.2,2.35ZM71.28,162c-.08-.78-.16-1.56-.25-2.34l.29,0c.09.78.17,1.56.25,2.34Zm-.39-3.51q-.14-1.17-.3-2.34l.29,0q.16,1.17.3,2.34Zm-.46-3.5c-.12-.78-.23-1.56-.36-2.33l.29,0c.13.78.24,1.56.36,2.34Zm-.55-3.5v0c-.14-.82-.28-1.57-.41-2.27l.29,0c.13.7.27,1.45.41,2.27v.05ZM69.25,148c-.16-.86-.29-1.62-.41-2.32l.29-.05c.12.7.25,1.46.41,2.32Zm-.59-3.49a23,23,0,0,1-.25-2.36h.29a22.55,22.55,0,0,0,.25,2.33Zm0-3.53-.3,0a10.28,10.28,0,0,1,.12-1.13c.07-.4.11-.8.15-1.21l.29,0a12.16,12.16,0,0,1-.15,1.23A8.18,8.18,0,0,0,68.71,141Zm0-3.53a15,15,0,0,0-.19-2.32l.29,0a14.48,14.48,0,0,1,.19,2.37Z"
					/>
				</g>
				<g id="Pfad_4251" data-name="Pfad 4251">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M70.84,177.38a13.08,13.08,0,0,1-3.24-.88,6.39,6.39,0,0,0-1.42-.57.83.83,0,0,1-.54-.34.62.62,0,0,1,0-.5c0-.05,0-.1,0-.15a10.38,10.38,0,0,0,.18-1.55,1.67,1.67,0,0,0-.2-.85,3.6,3.6,0,0,1-.49-1.29,3.47,3.47,0,0,1,0-.92,10,10,0,0,0,0-1.35,1.65,1.65,0,0,1,.21-1.07,1.56,1.56,0,0,0,.21-.75,4.49,4.49,0,0,0-.21-1.36,5.69,5.69,0,0,1-.21-1.19c-.05-1,3-1.64,3.94-1.82l.07.41c-1.75.34-3.61,1-3.59,1.39a4.8,4.8,0,0,0,.2,1.11,5,5,0,0,1,.22,1.47,1.89,1.89,0,0,1-.26.94,1.28,1.28,0,0,0-.16.84,10.7,10.7,0,0,1,0,1.4,4.57,4.57,0,0,0,0,.82,3.2,3.2,0,0,0,.44,1.15,2,2,0,0,1,.26,1.08,10.59,10.59,0,0,1-.19,1.6.8.8,0,0,1,0,.21.47.47,0,0,0,0,.18s0,.07.28.14a6.17,6.17,0,0,1,1.49.59,12.41,12.41,0,0,0,3.12.84Z"
					/>
				</g>
				<g id="Pfad_4252" data-name="Pfad 4252">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M69.26,165.8a16.82,16.82,0,0,1-2.73-.7c-.38-.12-.59-.32-.63-.59-.08-.49.51-.94.58-1l.25.34c-.19.14-.44.42-.42.59s.23.21.36.25a17,17,0,0,0,2.66.69Z"
					/>
				</g>
				<g id="Pfad_4253" data-name="Pfad 4253">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M66.9,170.1a33.59,33.59,0,0,0-.45-3.47,3.7,3.7,0,0,1,0-1.75l.41.1a3.43,3.43,0,0,0,0,1.56,32.79,32.79,0,0,1,.46,3.53Z"
					/>
				</g>
				<g id="Pfad_4254" data-name="Pfad 4254">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M67.05,176l-.41-.09a13.13,13.13,0,0,0,.26-4l.42,0A13.66,13.66,0,0,1,67.05,176Z"
					/>
				</g>
				<g id="Pfad_4255" data-name="Pfad 4255">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M66.65,172.09v-.43h.4a.37.37,0,0,0,.33-.23.25.25,0,0,0,0-.09.47.47,0,0,1,0-.16.46.46,0,0,1,0-.14.33.33,0,0,0,0-.06,1,1,0,0,0,0-.17c0-.29-.23-.51-.42-.51h-.4v-.42h.4a.93.93,0,0,1,.84.86,1.94,1.94,0,0,1,0,.24.53.53,0,0,1-.07.25l0,.05a.19.19,0,0,0,0,.08.55.55,0,0,1,0,.24.82.82,0,0,1-.7.49Z"
					/>
				</g>
				<g id="Pfad_4256" data-name="Pfad 4256">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M66.68,172.09c-.42,0-.75-.49-.75-1.11s.33-1.1.75-1.1.75.49.75,1.1S67.1,172.09,66.68,172.09Zm0-1.79c-.13,0-.32.27-.32.68s.19.68.32.68.33-.26.33-.68S66.82,170.3,66.68,170.3Z"
					/>
				</g>
				<g id="Pfad_4257" data-name="Pfad 4257">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M68.52,172.61h-.4v-.42h.4c.23,0,.43-.31.43-.68s-.2-.68-.43-.68h-.4v-.42h.4a1.13,1.13,0,0,1,0,2.2Z"
					/>
				</g>
				<g id="Pfad_4258" data-name="Pfad 4258">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M68.15,172.61c-.42,0-.75-.48-.75-1.1s.33-1.1.75-1.1.75.48.75,1.1S68.57,172.61,68.15,172.61Zm0-1.78c-.15,0-.33.3-.33.68s.2.68.33.68.33-.26.33-.68S68.3,170.83,68.15,170.83Z"
					/>
				</g>
				<g id="Pfad_4259" data-name="Pfad 4259">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M122.68,108.17c-.46,0-1,0-1.68-.11s-1.2-.1-1.87-.13-1.27,0-1.83,0c-1.56,0-2.58,0-3.08-.5a1,1,0,0,1-.28-.69,1.84,1.84,0,0,1,.6-1.35c.85-.82,2.58-1.24,4.71-1.15,3.29.14,5.1,1.17,5.1,2.89a.93.93,0,0,1-.29.7A2,2,0,0,1,122.68,108.17Zm-5-1.11,1.49,0c.67,0,1.32.08,1.91.13a5.77,5.77,0,0,0,2.41,0l0-.08c0-1.68-3-2-4.29-2-1.87-.08-3.43.27-4.09.91a1,1,0,0,0-.34.74c.28.35,1.41.35,2.51.34Z"
					/>
				</g>
				<g id="Pfad_4260" data-name="Pfad 4260">
					<path class="cls-6" d="M132.38,120.41a2,2,0,1,1-2.26-1.58,1.87,1.87,0,0,1,.34,0A1.78,1.78,0,0,1,132.38,120.41Z" />
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M130.46,123.41a2.67,2.67,0,0,1-2.62-2.2,2.59,2.59,0,0,1,.44-2,2.65,2.65,0,0,1,1.71-1.1,3.57,3.57,0,0,1,.47,0,2.49,2.49,0,0,1,2.62,2.23,2.66,2.66,0,0,1-2.16,3.05A2.45,2.45,0,0,1,130.46,123.41Zm.14-3.92h-.09l-.27,0a1.26,1.26,0,0,0-.81.51,1.31,1.31,0,0,0-.21.94,1.25,1.25,0,0,0,2.26.5,1.25,1.25,0,0,0,.21-.94v0A1.09,1.09,0,0,0,130.6,119.49Z"
					/>
				</g>
				<g id="Pfad_4261" data-name="Pfad 4261">
					<path
						class="cls-6"
						d="M89.23,120.54c.22.38-.54,1.22-1.69,1.9s-2.27.91-2.49.53.54-1.22,1.69-1.9S89,120.16,89.23,120.54Z"
					/>
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M85.51,123.85a1.42,1.42,0,0,1-.42,0,1,1,0,0,1-.65-.48c-.55-.94.64-2.09,1.94-2.85a4.58,4.58,0,0,1,2.81-.76,1,1,0,0,1,.65.47h0a1.06,1.06,0,0,1,.09.81c-.25,1-1.63,1.81-2,2.06A5.72,5.72,0,0,1,85.51,123.85ZM88,121.23a6.53,6.53,0,0,0-1,.45,7,7,0,0,0-.84.59,5.75,5.75,0,0,0,.93-.44A6.29,6.29,0,0,0,88,121.23Z"
					/>
				</g>
				<g id="Pfad_4262" data-name="Pfad 4262">
					<path
						class="cls-6"
						d="M160.27,112.06c.83.68,1.34,1.43,1.14,1.68s-1-.1-1.88-.78-1.33-1.43-1.13-1.67S159.44,111.38,160.27,112.06Z"
					/>
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M161.19,114.52a4.25,4.25,0,0,1-2.1-1c-.4-.33-1.36-1.19-1.43-2a.9.9,0,0,1,.2-.7c.75-.92,2.38.29,2.85.68s2,1.74,1.24,2.66a.9.9,0,0,1-.64.33Z"
					/>
				</g>
				<g id="Pfad_4263" data-name="Pfad 4263">
					<path
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M69.14,165c-.38,0-2.29-.29-2.57-.4a.49.49,0,0,1-.29-.46c0-.31.35-.77,2.83-1.33l.09.41c-2.44.55-2.5,1-2.5,1l0,0c.15.06,1.36.24,2.45.38Z"
					/>
				</g>
			</g>
			<g
				id="PRINT-AREA"
				transform="matrix(1, 0, 0, 1, 85.032867, 135.538086)"
				ref="print-area-wrapper"
				fill="none"
				stroke="#000"
				:opacity="currentStep === 'motive' ? 1 : 0"
				stroke-dasharray="4 4"
				stroke-width="0.4"
			>
				<rect ref="print-area" width="50" height="31.25" />
			</g>
			<g id="POSITIONEN" ref="position-wrapper" transform="matrix(1, 0, 0, 1, 115.277267, 150.046997)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import { setPositions } from '../mockupHelpers'

export default {
	name: 'CapCurvedSnapBackRight',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
	mounted() {
		setPositions(this, 0, true)
	},
}
</script>