<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<g id="POLO_FRONT" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -459.749847, -654.973999)">
			<g id="COLORS">
				<path
					id="Pfad_1301"
					data-name="Pfad 1301"
					d="M152.233,995.186s-.651-1.4-1.594-3.792-2.485-7.8-3.286-10.047-2.918-8.71-3.864-11.448-1.475-5.55-2.409-7.167a8.111,8.111,0,0,0-1.7-2v0h0a8.118,8.118,0,0,0-.852-.658l-.007.028a.873.873,0,0,0-.365-1.024,23.693,23.693,0,0,0-7.36-3c-4.44-1.106-5.928-2.161-5.928-2.161s-5.638-2.049-9.52-3.3h-.081A10.841,10.841,0,0,1,112.429,947c-.5-1.634-.966-4.369-1.816-4.9s-1.12-.817-3.669-.959-11.973-.462-11.973-.462h-.309A121.026,121.026,0,0,0,80.8,941.74c-3.322.356-3.283,2.025-4.712,4.973a15.63,15.63,0,0,1-3.188,4.208l-.351,0c-.959.3-1.79.568-3.258,1.056a76.031,76.031,0,0,0-8.223,3.4c-.629.314-6.121,1.987-7.119,2.2a36.164,36.164,0,0,0-4.41,2.041,1.026,1.026,0,0,0-.6.56s.022.589-.075.653a8.318,8.318,0,0,0-2.809,3.3c-.561,1.423-1.791,5.693-2.3,7.168s-1.792,5.063-2.641,7.878-2.1,6.007-2.4,7.269-3.136,8.554-3.441,9.289-.229,1.524,1.261,2.036c1.426.487,3.6,1.546,3.6,1.546s2.825,1.516,7.476,4.314a26.759,26.759,0,0,0,6.572,2.16c4.033.77,3.266-1.609,3.266-1.609s.352.929.349,4.839.106,8.651.109,8.916-.107,8.376.263,12.7.158,6.06-.317,7.218-1.062,4.638-1.664,7.06-.942,9.011-.075,10.907a11.538,11.538,0,0,1,.669,5.374c-.166,1.019-.465,2.686-.509,3.372a24.292,24.292,0,0,1-.644,3.265,14.055,14.055,0,0,0-.3,4.533,4.874,4.874,0,0,1-.029,2.263c-.16.424-.67,1.845.341,2.583a21.907,21.907,0,0,0,13.476,3.373c7.37-.476,11.513-1.28,13.349-1.8s7.316-.634,11.218-.318,8.964-.251,13.518,1.489,12.164,3.739,14.9,3.318,5.869-2.529,6.437-3.477.664-1.685.82-1.844a37.056,37.056,0,0,0-.076-6.216c-.193-2.74-.586-4.636-.709-5.951a21.109,21.109,0,0,0-.681-4.112c-.385-1.211-1.575-3.742-1.177-5.637s1.113-3.791,1.161-4.057a32.012,32.012,0,0,0,1.112-8.008c-.049-3.951-.258-20.864-.841-25.394a6.5,6.5,0,0,1,.294-1.845,88.6,88.6,0,0,0,1.188-10.272c.027-.61.106-1.4.215-2.268h.011c.809,2.4,6.272.069,8.708-.7,2.452-.773,6.7-3.863,8.166-4.566s4.4-1.758,5.169-2.319A1.119,1.119,0,0,0,152.233,995.186ZM138.448,960.48l-.008,0c.016-.049.026-.1.038-.152Z"
					transform="translate(441 -282)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_1302"
					data-name="Pfad 1302"
					d="M129.855,1004.177c.809,2.4,6.272.069,8.708-.7,2.452-.773,6.7-3.863,8.166-4.566s4.4-1.758,5.169-2.32a1.117,1.117,0,0,0,.335-1.4s-.651-1.4-1.594-3.793-2.485-7.795-3.286-10.046-2.918-8.71-3.864-11.448-1.475-5.55-2.409-7.167a8.111,8.111,0,0,0-1.7-2v0h0a8.118,8.118,0,0,0-.852-.658l-.007.028a1.9,1.9,0,0,0,.017-.217h-.015a20.441,20.441,0,0,1-.709,2.466c-.557,1.9-2.746,12.327-2.746,12.327-.892,4.535-4.047,21.974-4.047,21.974s-.964,5.136-1.3,8.36l.025.042c.03-.281.063-.572.1-.879Zm8.623-43.853-.03.156-.008,0C138.456,960.427,138.466,960.375,138.478,960.324Z"
					transform="translate(441 -282)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1303"
					data-name="Pfad 1303"
					d="M57.373,1002.877c-.143-1.2-.094-.665-.291-1.864-.344-2.1-.649-3.685-.647-3.676s-3.156-17.437-4.048-21.969c0,0-2.187-10.431-2.743-12.327a20.053,20.053,0,0,1-.712-2.478h0c-.009.122-.026.237-.065.263a8.318,8.318,0,0,0-2.809,3.3c-.561,1.423-1.791,5.693-2.3,7.168s-1.792,5.062-2.641,7.878-2.1,6.007-2.4,7.269-3.136,8.553-3.441,9.289-.229,1.524,1.261,2.036c1.426.487,3.6,1.546,3.6,1.546s2.825,1.516,7.476,4.314a26.759,26.759,0,0,0,6.572,2.16c4.033.77,3.266-1.609,3.266-1.609a4.66,4.66,0,0,1,.166.882,8.286,8.286,0,0,0-.208-1.909Z"
					transform="translate(441 -282)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1304"
					data-name="Pfad 1304"
					d="M93.914,959.137c-.68-1.163-2.474-.053-4.714.9a69.807,69.807,0,0,0-8.72,5.422,1,1,0,0,1-1.584-.213,44.145,44.145,0,0,1-5.291-9.2c-1.506-3.339-1.12-4.9-1.12-4.9a13.214,13.214,0,0,0,3.583-4.533c1.429-2.948,1.391-4.618,4.712-4.973a121.012,121.012,0,0,1,13.866-1.066h.309s9.423.32,11.972.462,2.82.426,3.67.959,1.313,3.268,1.815,4.9,2.178,3.262,3.722,4.47,0,4.475-1.12,7.672-2.679,6.719-3.452,7.339-2.766-1.2-2.766-1.2c-1.2-.614-1.444-.909-3.722-3.182a10.049,10.049,0,0,0-6.373-2.984c-1.893-.248-2.133,1.834-2.133,1.834l-2.247-.271A3.192,3.192,0,0,0,93.914,959.137Z"
					transform="translate(441 -282)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1305"
					data-name="Pfad 1305"
					d="M100.313,961.5s-5.587-.915-7.555-1.2c0,0-.812-.563-1.282.727a14.9,14.9,0,0,0-1.254,5.359,39.1,39.1,0,0,0-.171,6.2,69.911,69.911,0,0,1,.483,7.14c-.033,2.557-.29,5.9-.386,7.068,0,0-.161,1.279.45,1.386A47.829,47.829,0,0,0,96.131,988c1.866-.142,3.281-.177,3.506-.568a6.388,6.388,0,0,0,.386-2.487c.033-1.207.033-4.4-.193-6.891s-.643-10.106-.1-13.027S100.552,961.538,100.313,961.5Z"
					transform="translate(441 -282)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1306"
					data-name="Pfad 1306"
					d="M151.462,993.358a66.833,66.833,0,0,0-8.284,3.7c-2.51,1.549-11.933,5.555-12.94,4.148l-.409,2.86a2.083,2.083,0,0,0,2.483,1.067,43.967,43.967,0,0,0,6.892-1.944c.807-.419,6.709-4.014,7.508-4.373s4.335-1.792,4.633-1.945,1.337-.69.93-1.624S151.462,993.358,151.462,993.358Z"
					transform="translate(441 -282)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1307"
					data-name="Pfad 1307"
					d="M36.243,993.452a63.473,63.473,0,0,1,8.033,3.6c2.508,1.549,11.934,5.555,12.942,4.148l.408,3.14s-.092,1.559-1.539,1.482a19.536,19.536,0,0,1-5.975-1.352,30.947,30.947,0,0,1-6.281-3.1,34.117,34.117,0,0,0-5.353-2.84c-1.8-.762-3.949-1.127-3.239-2.752S36.243,993.452,36.243,993.452Z"
					transform="translate(441 -282)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1308"
					data-name="Pfad 1308"
					d="M116.136,951.371c-1.545-1.208-3.221-2.836-3.723-4.47s-.966-4.369-1.815-4.9-1.12-.817-3.669-.959-11.973-.462-11.973-.462h-.309a121.012,121.012,0,0,0-13.866,1.066c-3.321.355-3.283,2.024-4.712,4.973a13.214,13.214,0,0,1-3.583,4.533s-.386,1.563,1.12,4.9a44.19,44.19,0,0,0,5.291,9.2,1,1,0,0,0,1.584.213,69.564,69.564,0,0,1,8.72-5.422c2.24-.959,4.034-2.068,4.714-.905a2.732,2.732,0,0,1,.173.359,5.365,5.365,0,0,0-.578-1.052,11.886,11.886,0,0,0-3.444-1.883,18.165,18.165,0,0,1-4.14-1.776c-.834-.607-2.63-3.45-3.5-4.871L82.3,949.7c2.887-.222,7.328-.488,11.449-.488,4.841,0,10.756.158,13.65.284l-.124.268c-.6,1.28-2.038,4.215-2.812,4.883-.958.826-3.134,2.771-4.382,3.091-1.231.315-2.186.16-2.863,1.734a1.674,1.674,0,0,1,1.487-.456A10.05,10.05,0,0,1,105.075,962c2.278,2.273,2.525,2.568,3.722,3.182,0,0,1.994,1.822,2.766,1.2s2.332-4.141,3.453-7.338S117.68,952.578,116.136,951.371Z"
					transform="translate(441 -282)"
					:fill="secColor"
				/>
				<path
					id="Pfad_1309"
					data-name="Pfad 1309"
					d="M100.313,961.5s-5.587-.915-7.555-1.2c0,0-.812-.563-1.282.727a14.9,14.9,0,0,0-1.254,5.359,39.1,39.1,0,0,0-.171,6.2,69.911,69.911,0,0,1,.483,7.14c-.033,2.557-.29,5.9-.386,7.068,0,0-.161,1.279.45,1.386A47.829,47.829,0,0,0,96.131,988c1.866-.142,3.281-.177,3.506-.568a6.388,6.388,0,0,0,.386-2.487c.033-1.207.033-4.4-.193-6.891s-.643-10.106-.1-13.027S100.552,961.538,100.313,961.5Z"
					transform="translate(441 -282)"
					:fill="secColor"
				/>
				<g id="Gruppe_1" data-name="Gruppe 1" transform="translate(441 -282)">
					<path
						id="Pfad_929"
						data-name="Pfad 929"
						d="M96.568,960.846s.212-1.9,1.577-1.836a9.773,9.773,0,0,1,4.731,1.285,3.934,3.934,0,0,1-2.476,1.2Z"
						:fill="secColor"
					/>
					<path
						id="Pfad_930"
						data-name="Pfad 930"
						d="M88.581,960.34l1.406,10.217s.128-5.826.836-7.744.836-2.557,1.287-2.593a8.57,8.57,0,0,1,1.769.355h.515s-.006-2.1-1.694-1.813A17.928,17.928,0,0,0,88.581,960.34Z"
						:fill="secColor"
					/>
				</g>
			</g>
			<g id="LINES">
				<!-- <path
					id="Pfad_1310"
					data-name="Pfad 1310"
					d="M129.284,1062.707c-.207-.124-1.079.218-1.079.218l-.441-1.589-.434-1.074-.479-1.634s1.181-.259,1.223-.135S129.533,1062.856,129.284,1062.707Z"
					transform="translate(441 -282)"
					fill="#1a1718"
				/> -->
				<path
					id="Pfad_1311"
					data-name="Pfad 1311"
					d="M138.533,959.653a23.735,23.735,0,0,0-7.361-3c-4.439-1.1-5.927-2.161-5.927-2.161s-5.65-2.074-9.334-3.3"
					transform="translate(441 -282)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
				<path
					id="Pfad_1312"
					data-name="Pfad 1312"
					d="M138.511,959.979a8.818,8.818,0,0,1,2.552,2.655c.934,1.617,1.462,4.428,2.409,7.168s3.064,9.2,3.864,11.448,2.342,7.658,3.286,10.046,1.594,3.793,1.594,3.793a1.116,1.116,0,0,1-.334,1.4c-.766.562-3.7,1.618-5.17,2.32s-5.714,3.793-8.165,4.566-7.934,3.119-8.718.681"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1313"
					data-name="Pfad 1313"
					d="M151.462,993.358a66.833,66.833,0,0,0-8.284,3.7c-2.51,1.549-11.933,5.555-12.94,4.148"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1314"
					data-name="Pfad 1314"
					d="M151.139,992.8a63.818,63.818,0,0,0-8.038,3.7c-2.434,1.546-11.576,5.551-12.553,4.146"
					transform="translate(441 -282)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
				<path
					id="Pfad_1315"
					data-name="Pfad 1315"
					d="M73.087,950.623c-3.8,1.3-10.483,3.882-10.483,3.882s-1.487,1.052-5.927,2.159a23.733,23.733,0,0,0-7.361,3c-.794.478-.23,1.476.328,3.373s2.743,12.327,2.743,12.327c.892,4.532,4.048,21.969,4.048,21.969s.316,1.653.647,3.676c.2,1.2.148.664.291,1.864l.035.271a9.115,9.115,0,0,1,.218,2.313c.006.923.156,2.055.156,3.456-.006,3.911.107,8.651.109,8.915s-.107,8.377.263,12.7.158,6.059-.317,7.218-1.062,4.638-1.664,7.059-.942,9.012-.075,10.908a11.189,11.189,0,0,1,.669,5.374,32.161,32.161,0,0,0-.508,3.371,24.338,24.338,0,0,1-.645,3.266,14.056,14.056,0,0,0-.3,4.533,4.87,4.87,0,0,1-.029,2.262c-.16.424-.67,1.846.341,2.583a21.9,21.9,0,0,0,13.476,3.373c7.37-.475,11.513-1.279,13.35-1.8s7.315-.634,11.218-.317,8.963-.251,13.517,1.489,12.164,3.738,14.9,3.317,5.869-2.528,6.437-3.477.665-1.684.82-1.843a37.067,37.067,0,0,0-.076-6.217c-.193-2.739-.585-4.635-.708-5.95a21.108,21.108,0,0,0-.682-4.112c-.385-1.212-1.574-3.742-1.177-5.637s1.113-3.792,1.161-4.057a32.023,32.023,0,0,0,1.112-8.009c-.048-3.951-.258-20.863-.841-25.393a6.549,6.549,0,0,1,.294-1.846,88.377,88.377,0,0,0,1.188-10.272c.123-2.846,1.408-9.693,1.408-9.693s3.155-17.439,4.047-21.974c0,0,2.189-10.429,2.746-12.327s1.121-2.9.326-3.371a23.73,23.73,0,0,0-7.36-3c-4.44-1.107-5.929-2.162-5.929-2.162s-5.63-1.918-9.461-3.143"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1316"
					data-name="Pfad 1316"
					d="M48.923,960.34a23.745,23.745,0,0,1,7.361-3c4.438-1.109,5.926-2.16,5.926-2.16s6.585-2.6,10.231-3.856"
					transform="translate(441 -282)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
				<path
					id="Pfad_1317"
					data-name="Pfad 1317"
					d="M48.945,960.666a8.861,8.861,0,0,0-2.555,2.655c-.933,1.616-1.46,4.426-2.407,7.165s-3.066,9.2-3.866,11.451-2.342,7.656-3.284,10.046-1.594,3.792-1.594,3.792a1.12,1.12,0,0,0,.335,1.4c.766.562,3.7,1.616,5.169,2.318s5.714,3.8,8.161,4.568,7.938,3.115,8.722.682"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1318"
					data-name="Pfad 1318"
					d="M36.243,993.452a63.473,63.473,0,0,1,8.033,3.6c2.508,1.549,11.934,5.555,12.942,4.148"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1319"
					data-name="Pfad 1319"
					d="M36.472,992.859a61.73,61.73,0,0,1,7.881,3.637c2.434,1.546,11.577,5.551,12.553,4.146"
					transform="translate(441 -282)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
				<path
					id="Pfad_1320"
					data-name="Pfad 1320"
					d="M55.408,1073.212s4.236,2.31,7.611,2.519a36.207,36.207,0,0,0,8.494-.421,117.843,117.843,0,0,1,14.655-1.217c3.164.132,11.467.446,12.1.411s9.705.883,10.623,1.3,11.073,3.582,13.391,3.654,6.7-2.4,7.136-4.5"
					transform="translate(441 -282)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
				<path
					id="Pfad_1321"
					data-name="Pfad 1321"
					d="M55.4,1072.632s4.237,2.311,7.61,2.521a36.412,36.412,0,0,0,8.494-.422,117.9,117.9,0,0,1,14.657-1.219c3.163.135,11.467.45,12.1.412s9.705.885,10.623,1.3,11.073,3.58,13.393,3.652,6.695-2.393,7.136-4.5"
					transform="translate(441 -282)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
				<path id="Pfad_1322" data-name="Pfad 1322" d="M128.348,1063.818" transform="translate(441 -282)" fill="#1a1718" />
				<path id="Pfad_1323" data-name="Pfad 1323" d="M111.007,948.585" transform="translate(441 -282)" fill="#1a1718" />
				<!-- <path
					id="Pfad_1324"
					data-name="Pfad 1324"
					d="M95.377,955.37H93.261l-.022-4.923,2.117-.009Z"
					transform="translate(441 -282)"
					fill="#1a1718"
				/> -->
				<!-- <path
					id="Pfad_1325"
					data-name="Pfad 1325"
					d="M93.782,953.8a.247.247,0,0,0,.1.022c.045,0,.075-.02.075-.048s-.025-.046-.077-.062c-.071-.023-.113-.053-.115-.108s.056-.106.148-.106a.21.21,0,0,1,.1.02l-.016.053a.21.21,0,0,0-.085-.017c-.047,0-.068.021-.068.045s.025.04.082.06.108.057.109.108-.049.115-.158.115a.3.3,0,0,1-.114-.025Z"
					transform="translate(441 -282)"
					fill="#c6c8c9"
				/>
				<path
					id="Pfad_1326"
					data-name="Pfad 1326"
					d="M94.177,953.5l0,.372h-.076L94.1,953.5Z"
					transform="translate(441 -282)"
					fill="#c6c8c9"
				/>
				<path
					id="Pfad_1327"
					data-name="Pfad 1327"
					d="M94.24,953.832l.209-.274h-.194V953.5h.3v.042l-.21.272h.215v.057H94.24Z"
					transform="translate(441 -282)"
					fill="#c6c8c9"
				/>
				<path
					id="Pfad_1328"
					data-name="Pfad 1328"
					d="M94.85,953.707h-.157v.107h.176l0,.057H94.62l-.007-.372h.245v.056h-.166v.094h.158Z"
					transform="translate(441 -282)"
					fill="#c6c8c9"
				/>
				<path
					id="Pfad_1329"
					data-name="Pfad 1329"
					d="M94.651,954.142l-.324.485L94,954.153l-.006-.011H93.8l.011.887h.175l-.007-.627.35.512.34-.512.009.627h.174l-.012-.887Z"
					transform="translate(441 -282)"
					fill="#c6c8c9"
				/> -->
				<path
					id="Pfad_1330"
					data-name="Pfad 1330"
					d="M82.3,949.7c2.887-.221,7.328-.488,11.449-.488,4.841,0,10.756.158,13.65.285"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1331"
					data-name="Pfad 1331"
					d="M106.824,950.694c-2.531-.1-8.21-.253-13.121-.253-3.724,0-7.9.277-10.634.506"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1332"
					data-name="Pfad 1332"
					d="M106.947,950.441c-2.545-.1-8.262-.277-13.272-.277-3.852,0-8,.26-10.786.5"
					transform="translate(441 -282)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
				<path
					id="Pfad_1333"
					data-name="Pfad 1333"
					d="M82.456,949.959c2.869-.215,7.238-.466,11.273-.466,4.769,0,10.675.111,13.558.233"
					transform="translate(441 -282)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
				<path id="Pfad_1334" data-name="Pfad 1334" d="M110.847,949.833" transform="translate(441 -282)" fill="#1a1718" />
				<path
					id="Pfad_1335"
					data-name="Pfad 1335"
					d="M93.914,959.137c-.847-1.047-2.474-.053-4.714.9a69.807,69.807,0,0,0-8.72,5.422,1,1,0,0,1-1.584-.213,44.145,44.145,0,0,1-5.291-9.2c-1.506-3.339-1.12-4.9-1.12-4.9a13.214,13.214,0,0,0,3.583-4.533c1.429-2.948,1.391-4.618,4.712-4.973a121.012,121.012,0,0,1,13.866-1.066h.309s9.423.32,11.972.462,2.82.426,3.67.959,1.313,3.268,1.815,4.9,2.178,3.262,3.722,4.47,0,4.475-1.12,7.672-2.679,6.719-3.452,7.339-2.766-1.2-2.766-1.2c-1.2-.614-1.444-.909-3.722-3.182a10.049,10.049,0,0,0-6.373-2.984c-1.893-.248-2.133,1.834-2.133,1.834"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1336"
					data-name="Pfad 1336"
					d="M107.535,949.182c-.146.347-2.119,4.636-3.077,5.461s-3.134,2.771-4.381,3.091-2.214.148-2.892,1.792"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1337"
					data-name="Pfad 1337"
					d="M94.321,960.575a3.8,3.8,0,0,0-.813-2.131,11.87,11.87,0,0,0-3.443-1.883,18.155,18.155,0,0,1-4.14-1.775c-1.122-.817-3.986-5.684-3.986-5.684"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1338"
					data-name="Pfad 1338"
					d="M88.581,960.34l1.406,10.217s.128-5.826.836-7.744.836-2.557,1.287-2.593a8.57,8.57,0,0,1,1.769.355h.515"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1339"
					data-name="Pfad 1339"
					d="M96.034,965.485a1.5,1.5,0,1,1-1.495-1.59A1.545,1.545,0,0,1,96.034,965.485Z"
					transform="translate(441 -282)"
					fill="#fff"
				/>
				<path
					id="Pfad_1340"
					data-name="Pfad 1340"
					d="M96.034,965.485a1.5,1.5,0,1,1-1.495-1.59A1.545,1.545,0,0,1,96.034,965.485Z"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<ellipse
					id="Ellipse_1"
					data-name="Ellipse 1"
					cx="0.145"
					cy="0.154"
					rx="0.145"
					ry="0.154"
					transform="translate(535.734 683.024)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1341"
					data-name="Pfad 1341"
					d="M94.973,965.953a.145.145,0,1,1-.145-.154A.15.15,0,0,1,94.973,965.953Z"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1342"
					data-name="Pfad 1342"
					d="M94.348,965.953a.145.145,0,1,1-.145-.154A.15.15,0,0,1,94.348,965.953Z"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<ellipse
					id="Ellipse_2"
					data-name="Ellipse 2"
					cx="0.145"
					cy="0.154"
					rx="0.145"
					ry="0.154"
					transform="translate(535.104 683.024)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1343"
					data-name="Pfad 1343"
					d="M96.469,974.987a1.5,1.5,0,1,1-1.5-1.59A1.545,1.545,0,0,1,96.469,974.987Z"
					transform="translate(441 -282)"
					fill="#fff"
				/>
				<path
					id="Pfad_1344"
					data-name="Pfad 1344"
					d="M96.469,974.987a1.5,1.5,0,1,1-1.5-1.59A1.545,1.545,0,0,1,96.469,974.987Z"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1345"
					data-name="Pfad 1345"
					d="M95.458,974.679a.145.145,0,1,1-.145-.154A.15.15,0,0,1,95.458,974.679Z"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<ellipse
					id="Ellipse_3"
					data-name="Ellipse 3"
					cx="0.145"
					cy="0.154"
					rx="0.145"
					ry="0.154"
					transform="translate(536.117 693.301)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1346"
					data-name="Pfad 1346"
					d="M94.782,975.455a.145.145,0,1,1-.145-.154A.15.15,0,0,1,94.782,975.455Z"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1347"
					data-name="Pfad 1347"
					d="M94.828,974.679a.145.145,0,1,1-.145-.154A.15.15,0,0,1,94.828,974.679Z"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1348"
					data-name="Pfad 1348"
					d="M100.313,961.5s-5.587-.915-7.555-1.2c0,0-.812-.563-1.282.727a14.9,14.9,0,0,0-1.254,5.359,39.1,39.1,0,0,0-.171,6.2,69.911,69.911,0,0,1,.483,7.14c-.033,2.557-.29,5.9-.386,7.068,0,0-.161,1.279.45,1.386A47.829,47.829,0,0,0,96.131,988c1.866-.142,3.281-.177,3.506-.568a6.388,6.388,0,0,0,.386-2.487c.033-1.207.033-4.4-.193-6.891s-.643-10.106-.1-13.027S100.552,961.538,100.313,961.5Z"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1349"
					data-name="Pfad 1349"
					d="M99.862,961.5a8.108,8.108,0,0,1-.25,1.493c-.093.48-.268,1.291-.5,2.638-.472,2.728-.111,9.844.084,12.166s.194,5.307.166,6.435a6.416,6.416,0,0,1-.333,2.322c-.194.365-1.416.4-3.026.531a38.065,38.065,0,0,1-4.775.166c-.527-.1-.388-1.294-.388-1.294.083-1.094.305-4.213.333-6.6s-.278-4.975-.417-6.667A39.448,39.448,0,0,1,90.9,966.9a14.832,14.832,0,0,1,1.082-5s.489-1.025.773-1.6"
					transform="translate(441 -282)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
				<path
					id="Pfad_1350"
					data-name="Pfad 1350"
					d="M102.955,960.34a5.29,5.29,0,0,1-2.642,1.159"
					transform="translate(441 -282)"
					fill="none"
					stroke="#47484a"
					stroke-width="0.162"
				/>
				<path
					id="Pfad_1351"
					data-name="Pfad 1351"
					d="M90.92,985.262s3.538.107,5.275,0,3.41-.355,3.41-.355"
					transform="translate(441 -282)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
				<path
					id="Pfad_1352"
					data-name="Pfad 1352"
					d="M82.024,949.1s.008.286-.137.057c-.627-.994-2.49-4.164-2.584-5.225,0,0,.145-1.2.4,0A18.4,18.4,0,0,0,82.024,949.1Z"
					transform="translate(441 -282)"
					fill="#47484a"
				/>
				<path
					id="Pfad_1353"
					data-name="Pfad 1353"
					d="M107.45,949.182s-.1.554.142.026a36.167,36.167,0,0,0,1.72-4.714c.406-1.785.174-1.705,0-1.093S108.026,947.944,107.45,949.182Z"
					transform="translate(441 -282)"
					fill="#47484a"
				/>
				<line
					id="Linie_279"
					data-name="Linie 279"
					x2="1.013"
					y2="7.664"
					transform="translate(530.109 678.218)"
					fill="none"
					stroke="#5b5c5e"
					stroke-width="0.08"
					stroke-dasharray="0.64 0.32"
				/>
			</g>
			<g id="SHADING">
				<path
					id="Pfad_1354"
					data-name="Pfad 1354"
					d="M107.535,949.182c-.146.347-2.119,4.636-3.077,5.461s-3.134,2.771-4.381,3.091-2.214.148-2.892,1.792a3.585,3.585,0,0,0-.617,1.32l-2.247-.271a3.8,3.8,0,0,0-.813-2.131,11.87,11.87,0,0,0-3.443-1.883,18.155,18.155,0,0,1-4.14-1.775c-1.122-.817-3.986-5.684-3.986-5.684s-3.66-5.6-2.12-7.294a141.5,141.5,0,0,1,14.827-1.231c5.775,0,13.838.6,13.838.6a1.282,1.282,0,0,1,1.054,1.828C108.965,944.608,107.535,949.182,107.535,949.182Z"
					transform="translate(441 -282)"
					opacity="0.73"
					fill="url(#linear-gradient)"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1355"
					data-name="Pfad 1355"
					d="M51.3,964.1c.417-.384.261-1.364.154-1.89-.172-.833-.12-.891.612-1.523a3.157,3.157,0,0,1,3.53-.553c1.485.6,2.452,2.058,4.023,2.622.755.269,1.54.39,2.284.708a11.812,11.812,0,0,1,1.918,1.257,48.419,48.419,0,0,0,4.2,2.526c.948.515,2.218,1.385,3.312,1.361a6.044,6.044,0,0,0-1.24-1.034c-.458-.384-.76-.9-1.25-1.269a20.523,20.523,0,0,0-4.65-2.59c-1.665-.69-3.165-1.49-4.77-2.285-.713-.355-3.022-2.417-1.126-2.544,1.373-.092,2.915.866,4.136,1.385,1.73.736,3.634.84,5.364,1.522a48.639,48.639,0,0,0,5.087,1.489,6.652,6.652,0,0,0,3.83.127c-.425-2.683-5.349-2.475-7.382-3.07-1.3-.38-2.553-.712-3.881-1a7.429,7.429,0,0,1-2.25-.778,11.421,11.421,0,0,0-1.6-1.138,6.416,6.416,0,0,1-1.3-.474c.965-1.136,2.772-.4,3.957-.148a28.834,28.834,0,0,0,7.218.274c2.249-.118,4.418.752,6.658.905,1.418.1,1.662.7,3.085.888.75.1,2.3.014,1.577-.409-1.069-.623.24-.627.321-.7-.676-.851-1.816-.514-2.905-.649-1.739-.217-2.191-.806-3.771-1.549-.649-.3-.694-.267-.587-.845.13-.726-.476.024-1.223-.447-.6-.377-.869-.4-1.151-1.1a12.107,12.107,0,0,1-.5-2.025c-2.476.01-3.643,1.068-5.976,1.738-2.521.72-4.756,1.99-7.206,2.806a62.357,62.357,0,0,1-6.842,2.335c-.781.164-1.885.327-2.32,1.233-.24.5-.177.148.03.578.268.558-.333,1.209-.469,1.733a2.476,2.476,0,0,0-.209,1.385c.738.222.964,1.115,1.456,1.26-.019-.116-.107.005-.142-.051"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1356"
					data-name="Pfad 1356"
					d="M137.419,963.409c.035-.876-2.193-1.456-2.849-1.812-1.284-.7-2.585-.381-4.021-.172-2.332.339-4.616.606-6.923,1.156a45.747,45.747,0,0,1-5.376,1.1,3.9,3.9,0,0,1-1.388-.046,14.6,14.6,0,0,1,4.613-1.515c2.39-.528,4.705-1.34,7.109-1.864.624-.131,3.212-.4,3.505-1.081.406-.942-3.142-.636-3.654-.686-1.354-.127-2.777-.43-4.2-.566-2.32-.218-4.735-.421-7.065-.421a51.789,51.789,0,0,1-8.194-.7,3.652,3.652,0,0,1-.911-.2,19.842,19.842,0,0,1,4,.473c1.359.18,2.619.281,4.01.354,3.015.152,6.056.152,9.036.294,1.016.052,2.126.153,3.178.2.818.035,2.443.132,3.077-.368a4.439,4.439,0,0,0-1.409-.775,13.365,13.365,0,0,1-1.484-.982,6.546,6.546,0,0,0-3.454-1.035c-1.644-.064-3.285.465-4.924.561a19.921,19.921,0,0,1-4.343-.141,28.366,28.366,0,0,0-3.374-.714c.353-.56.969-.587,1.183-1.314a16.508,16.508,0,0,0,3.937,1.324,18.891,18.891,0,0,0,3.4.357,7.88,7.88,0,0,0,2.643-.089,4.813,4.813,0,0,0-2.1-.826c-1.065-.3-2.158-.436-3.238-.689-.765-.176-1.541-.394-2.274-.575a6.13,6.13,0,0,1-1.756-.478c2.61.008,5.109,1.027,7.745,1.547,2.157.427,4.206.969,6.228,1.5.974.26,1.98.615,2.972.836a20.679,20.679,0,0,1,3.089.975c1.319.533,4.484,1.671,4.321,2.72-.1.668-.713,3.22-1.251,3.649"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1357"
					data-name="Pfad 1357"
					d="M50.485,969.362s1.1,3.9,1.753,5.373c0,0,.09-1.106.192-1.58s.2,3.636.534,4.848A42.389,42.389,0,0,1,50.485,969.362Z"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1358"
					data-name="Pfad 1358"
					d="M52.86,977.475c-.547.187-.907-.641-1.122-1.04.1.492.35.966.439,1.451-.31-.057-.515-.357-.782-.51.111.393,1.294,1.339.8,1.718.709.208.435,1.1-.093.7-.031.344.422.9.536,1.244a1.915,1.915,0,0,1,.18,1.268c1.031.369,1.776,2.18,2.1,3.107s1.134,2.311.969,3.274c-.455-.21-.807-1-1.078-1.416a11.818,11.818,0,0,0-1.284-1.617,9.45,9.45,0,0,1-1.818-3c.084,1.228,1.1,2.426,1.692,3.46a10.058,10.058,0,0,1,1.507,3.407,20.381,20.381,0,0,1-1.559-2.184,21.639,21.639,0,0,1-1.662-2.434c-1.272-2.351-1.84-5.068-2.9-7.532-.259-.6-.561-1.178-.8-1.777.3,1.059.45,2.107.844,3.144.408,1.072.8,2.129,1.147,3.217a22.368,22.368,0,0,0,2.9,5.255,18.429,18.429,0,0,1,3.081,6c-.322-.379-.481-.906-.9-1.189.072.754.845,1.372.745,2.2-1.139-.843-1.565-2.626-2.711-3.518.294,1.1,1.286,2.036,1.835,3.048a10.934,10.934,0,0,1,1.13,2.826c-.487-.591-.825-1.3-1.279-1.932-.529-.731-1.109-1.439-1.636-2.156a10.459,10.459,0,0,1-1.45-2.32,9.924,9.924,0,0,0-.968-2.515,12.674,12.674,0,0,0,.942,2.424,13.945,13.945,0,0,0,.945,1.885c.66,1.044,1.39,2.083,2.036,3.138-.747-.067-1.423-1.64-1.815-2.287-.443-.728-.884-1.456-1.369-2.173-1.193-1.758-1.62-4.059-2.985-5.723a39.6,39.6,0,0,0,3.342,7.057c1.061,1.779,2.112,3.557,3.1,5.356,1.023,1.868,1.515,4.011,3.042,5.57a2.81,2.81,0,0,0-.018-1.455c-.079-.481.482-.882.474-1.371a19.261,19.261,0,0,0-.417-2.939,17.277,17.277,0,0,1-.011-2.867c0-1.218.486-2.294.525-3.506.028-.77-.2-1.525-.24-2.28a4.632,4.632,0,0,1,.312-1.528,5.853,5.853,0,0,0-.108-3.193,1.149,1.149,0,0,0-.265.551c-.406.043-.84-.6-1.111-.87,0,.468.419.858.483,1.3.057.4-.218.726-.19,1.108.028.427.344.848.283,1.315a3.979,3.979,0,0,1-.354,1.111c-.417.17-1.221-1.537-1.393-1.848-.428-.781-.888-1.53-1.294-2.319a11.058,11.058,0,0,1-1.209-3.2c-.1-.554-.2-1.111-.334-1.646-.1-.375-.524-1.59-.225-1.843"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1359"
					data-name="Pfad 1359"
					d="M139.162,963.885c-.681.228-1.1,2.609-1.2,3.294-.189,1.289-.467,2.63-.571,3.929a29.993,29.993,0,0,1-1.137,7.535c-.137.391-.584.955-.537,1.375.017.2.293.326.338.565a2.983,2.983,0,0,1-.128.93,39.818,39.818,0,0,1-1.113,4.338c-.381,1.23-.5,2.447-.852,3.641a8.54,8.54,0,0,1-1.65,2.56,3.492,3.492,0,0,0-.621.884c-.1-1.314.523-1.939-.311-3.1-.738-1.029-.623-2.079-1.019-3.23a26.348,26.348,0,0,1-1.14-3.482,21.739,21.739,0,0,1-.377-3.861,5.541,5.541,0,0,0-.419-1.99c-.182-.513-.2-1.254-.573-1.642-.9,2.22-.165,5.435.508,7.614a22.716,22.716,0,0,1,.921,3.5c.154,1.04.983,2.834.613,3.825-.672-.63-.937-2.439-1.76-.678-.551,1.175.034,2.613.271,3.806.383,1.923-.827,3.572-1.032,5.424-.13,1.169-.023,2.339-.162,3.486a6.737,6.737,0,0,1-1.545,3.019c-.514.727-1,1.678-1.561,2.328-.571-.3-.724-1.431-.917-2.013-.387-1.177-.752-2.388-1.18-3.543-.578-1.561-1.13-3.284-1.571-4.937-.322-1.211-.32-2.434-.7-3.631-.231-.734-.513-1.46-.7-2.189-.359-1.455-.98-2.875-1.374-4.344-.677-2.535-1.485-5.611-3.037-7.725A31.782,31.782,0,0,0,117,986.059a19.736,19.736,0,0,1,1.112,3.727c.278,1.306.9,2.455,1.163,3.759.614,2.986.815,6,1.673,8.943.444,1.53.722,3.107,1.154,4.631a31.8,31.8,0,0,1,.972,5.3,15.506,15.506,0,0,1,.091,1.9,9.877,9.877,0,0,0,1.4-2.6c.6-1.14,1.294-2.23,1.9-3.37a14.25,14.25,0,0,1,1.748-2.8c.471-.534,1.122-.744,1.495-1.31a3.163,3.163,0,0,0,2.6.884.99.99,0,0,0,.806-1.342c-.145-.555-.246-1.063-.345-1.666a5.1,5.1,0,0,1,.315-3.41c.128-.255.279-.346.3-.619.026-.317-.235-.743-.207-1.118.043-.616.218-1.2.251-1.822.028-.573-.1-1.183-.021-1.746a4.339,4.339,0,0,1,.836-1.661,11.182,11.182,0,0,0,1.369-3.213,19.528,19.528,0,0,1,1.141-3.433c.5-1,1.292-1.827,1.79-2.818a7.283,7.283,0,0,0,.6-2.93c.1.677-.414,2.584-1.051,2.855.254-2.152.731-4.385.8-6.581a4.943,4.943,0,0,1,.546-1.721,8.818,8.818,0,0,0,.29-1.577,18.551,18.551,0,0,0,.391-1.856,9.645,9.645,0,0,1,.134-1.828c-.6.8-.326,2.28-.671,3.205-.105.276-.384,1.372-.667,1.5-.63.279-.473-.648-.421-1.019a21.59,21.59,0,0,0,.182-3.021,32.528,32.528,0,0,1,.481-5.56"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1360"
					data-name="Pfad 1360"
					d="M144.8,975.831c.019,1.571.378,2.985.465,4.545a40.837,40.837,0,0,1-.131,5.7c-.192,2.342-.552,5.1-1.976,7.061a12.446,12.446,0,0,1-2.617,2.462c1.184-1.964,2.559-3.772,2.987-6.1.2-1.1.036-2.258.231-3.37a26.394,26.394,0,0,0,.441-3.292c.054-1.118.05-2.242.15-3.309a15.633,15.633,0,0,1,.383-3.487"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1361"
					data-name="Pfad 1361"
					d="M143.318,981.939a17.172,17.172,0,0,0-.3,2.6c-.146,1.046-.135,2.159-.386,3.189-.4,1.643-1.135,3.249-1.677,4.857a9.064,9.064,0,0,1-1.74,3.3,13.25,13.25,0,0,0-2.208,2.335c-.055.116-.163.209-.224.317,4.575-2.184,5.771-8.236,6.87-12.676a11.92,11.92,0,0,0,.643-2.447c-.235.424-.406,1.047-.8,1.342-.07-.9-.168-1.784-.184-2.67"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1362"
					data-name="Pfad 1362"
					d="M142.183,969.377a6.5,6.5,0,0,0-.37,3.295,11.589,11.589,0,0,1-.55,4.119c-.386,1.176-.577,2.32-.884,3.505-.3,1.138-.88,2.191-1.23,3.291a18.194,18.194,0,0,1-2.606,5.716,11.242,11.242,0,0,0-1.449,2.182,9.633,9.633,0,0,0-1.179,2.322c.95.088,2-2.351,2.576-3.025a11.615,11.615,0,0,0,2.292-3.7A16.712,16.712,0,0,1,140.3,984c.655-1.038.564-2.081.975-3.175a10.454,10.454,0,0,0,.806-2.526c.132-1.037.129-2.115.165-3.154a53.391,53.391,0,0,0-.2-5.495"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1363"
					data-name="Pfad 1363"
					d="M112.371,1011.036a19.2,19.2,0,0,1,.666,2.477c.228.769.525,1.5.816,2.245.618,1.592,1.19,3.2,1.851,4.763.548,1.3,1.019,2.9,2.1,3.845,1.142,1,2.271,1.873,3.342,2.965a44.221,44.221,0,0,1,5.41,6.886c1.327,2.051,1.731,4.621,2.208,6.883.476,2.277-.487,4.407-.343,6.745a7.9,7.9,0,0,1,.081,1.755,11.9,11.9,0,0,1-.684,1.776c-.349,1.053-.388,2.137-.71,3.213-.211.7-1.022,2.613-1.342,1.035a18.288,18.288,0,0,1-.182-3.283,14.905,14.905,0,0,1-.069-1.618c.052-.611.224-1.2.255-1.824.04-.906.267-2.845-.239-3.577a40.7,40.7,0,0,0-1.239,4.929,5.618,5.618,0,0,1-.381,1.67c-.441.706-.49.213-.5-.424a34.922,34.922,0,0,1,.861-8.125c.61-2.716.864-5.737-.97-8.05-.508-.643-2.117-2.562-2.561-1.034-.185.642.212,1.216-.35,1.744a1.1,1.1,0,0,1-1.4.1c-.873-.683-1.022-3.117-1.23-4.093-.282-1.3-.909-2.437-1.292-3.723a14.234,14.234,0,0,0-.546-1.894,23.66,23.66,0,0,1-1.146-2.458c-.218-.686-.588-1.407-.778-2.111a18.86,18.86,0,0,1-.254-2.09c-.248-1.584-.613-3.167-.967-4.73a8.5,8.5,0,0,1-.4-3.928"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1364"
					data-name="Pfad 1364"
					d="M104.035,1029.793c.2,2.225,1.533,4.362,2.19,6.486a25.4,25.4,0,0,0,2.815,6c1.739,2.78,2.743,5.89,4.533,8.656,1.325,2.05,2.723,3.953,4.233,5.886.793,1.016,1.672,1.911,2.472,2.9.9,1.106,2.019,2.05,2.994,3.092.867.927,1.81,1.73.314,2.456a6.736,6.736,0,0,1-.909.335c.613.171,2.724,1.54,1.226,1.865-.821.176-1.788-1.537-2.566-1.99-1.363-.784-3.073-1.095-4.247-2.321-.875-.917-1.534-2.1-2.369-3.069-.819-.954-1.739-1.83-2.549-2.778a15.721,15.721,0,0,1-2.827-5.683c-.641-2.012-1.181-4.01-1.9-6.012-.509-1.419-1.381-2.675-1.878-4.093-.231-.664-.463-1.278-.074-1.767.482-.612.839-.535.686-1.46-.168-1.042-.644-1.953-.812-3.022-.134-.843-.192-2.446-.671-3.092"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1365"
					data-name="Pfad 1365"
					d="M125.336,1053.745a3.747,3.747,0,0,0-.12,1.424c-.036.829-.12,1.673-.187,2.5a13.729,13.729,0,0,1-.52,1.839,7.35,7.35,0,0,0,.587,3.372c.543,1.753,1.518,3.286,1.895,5.112a8.107,8.107,0,0,1,.11,3.738,5.171,5.171,0,0,1-1.771,2.829,2.786,2.786,0,0,0-.4,2.978,1.818,1.818,0,0,1,.057-.729,43.62,43.62,0,0,1-.371,5.578c.362-.729,2.383-1.327,3.02-1.8a5.79,5.79,0,0,0,1.7-3.935c-.082-1.32-.387-2.625-.47-3.935-.078-1.2.016-2.417-.114-3.584-.128-1.151-.121-2.328-.251-3.512a17,17,0,0,0-.818-3.369,28.1,28.1,0,0,0-.816-3.235,22.4,22.4,0,0,1-1.148-3.443c-.144-.507-.483-1.24-.249-1.754"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1366"
					data-name="Pfad 1366"
					d="M118.229,1075.383a8.36,8.36,0,0,0-1.989-.756,22.11,22.11,0,0,0-3.07-.582,9.1,9.1,0,0,1-1.89-.845c-1.572-.725-3.185-.774-4.843-1.206-1.527-.4-3.067-.776-4.566-1.268-1.54-.5-2.933-.085-4.5-.332a8.655,8.655,0,0,0-1.622-.425c-.632.021-1.2.415-1.825.425-.522.011-1.019-.308-1.615-.281s-.927.4-1.4-.069-.19-1.031-.3-1.617c-.119-.615-.468-.829-.876-1.26a9.118,9.118,0,0,0-1.7-1.757c-.514-.306-1.025-.181-1.587-.421a29.163,29.163,0,0,1-3.195-2.127c-1.5-.885-3.219-1.708-4.824-2.458-1.706-.793-3.437-1.622-5.075-2.511-1.306-.709-2.525-1.707-3.836-2.336-2.215-1.064-5.146-.595-7.248.279a6.872,6.872,0,0,1-1.468.423c-.6.092-1.182-.148-1.741.069a.812.812,0,0,0-.39,1.335c.319.372,1.547.505,2.026.581a30.291,30.291,0,0,0,4.785.066c1.573,0,3.242-.3,4.691.472.81.429,1.5.942,2.364,1.3a16.913,16.913,0,0,1,2.033.916,31.709,31.709,0,0,1,4.19,2.435,26.634,26.634,0,0,0,2.609,1.973c.728.457,1.522.746,2.2,1.261a24.9,24.9,0,0,0,3.6,2.482c.942.481,2.433.747,2.866,1.859.472,1.226-1.516,1.454-2.444,1.354-.725-.08-1.415-.5-2.164-.636-.641-.115-1.295-.147-1.936-.208-1.492-.136-2.97-.072-4.5-.072a42.566,42.566,0,0,1-4.374-.266c-1.172-.117-2.493-.32-3.48.427a9.651,9.651,0,0,0-2.982,3.547c.6.1,1.03-.525,1.456-.828a5.384,5.384,0,0,1,1.939-.964,21.858,21.858,0,0,1,4.718-.512c1.342,0,2.662.088,3.99.144a21.837,21.837,0,0,0,4.089.2,30.878,30.878,0,0,1,3.773-.129,24.614,24.614,0,0,1,4.525.65,16.481,16.481,0,0,1,3.736.737c1.13.468,2.3.945,3.456,1.351a8.844,8.844,0,0,0,3.451.918,13.288,13.288,0,0,0,3.637-1.212,12.565,12.565,0,0,1,4.412-1.1,12.244,12.244,0,0,1,3.241.572,5.865,5.865,0,0,1,2.685,1.318c.369.341,1.576,1.643,2.075.925C119.741,1076.629,118.623,1075.654,118.229,1075.383Z"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1367"
					data-name="Pfad 1367"
					d="M58.553,1066.811c.5.253,1.148.181,1.692.4a5.013,5.013,0,0,1,1.47.97,5.076,5.076,0,0,1,1.068,1.313c.226.378.428,1.09.789,1.339.888.6,2.41-.288,3.388.118,1.79.744-.285,1.355-.761,2.022-.242.338.054.485-.394.735-.327.183-.86.037-1.212.176-.4.154-.5.59-1.117.387-.41-.136-.665-.392-.5-.844-.42-.235-.491-.51-.721-.857-.373-.559-.4-.409-.135-.987.533-1.173.049-.971-1.067-1.121-1.42-.2-3.362-1.949-3.4-3.433.392.036.72-.095,1.11-.082"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1368"
					data-name="Pfad 1368"
					d="M94.087,1077.278c-.562-.232-.735-.92-1.156-1.322a7.276,7.276,0,0,0-1.33-.871,4.59,4.59,0,0,0-3.305-.354c-1.248.188-2.148-.3-3.37.158a9.375,9.375,0,0,0-3.065,1.261c.418.988,2.134-.742,2.863-.558.372.091.683.678,1.08.9a3.318,3.318,0,0,0,1.535.366,25.276,25.276,0,0,1,3.447.347,17.348,17.348,0,0,0,3.093.218"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1369"
					data-name="Pfad 1369"
					d="M66.4,1076.154c-.907.291.534.936.788,1.139a7,7,0,0,1,1.069,1.179c.5.654.534.709,1.342.721a4.413,4.413,0,0,1,1.484.405c.533.19,1.2.315.766-.495-.229-.424-1.039-.789-1.2-1.205-.111-.282.188-.542-.038-.834-.209-.272-.611-.19-.9-.208-.911-.055-.7-.183-1.361-.685a2.026,2.026,0,0,0-2.087.123"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1370"
					data-name="Pfad 1370"
					d="M71.138,1016.8a19.73,19.73,0,0,1,1.457,3.935,11.808,11.808,0,0,1,.22,5.283c-.438,3.247.191,7.566-1.979,10.316a10.273,10.273,0,0,1-1.261,1.455c-.881.738-2.138.852-3.188,1.274-1.227.5-1.487,1.748-2.083,2.828-.207.376-1.017,1.309-.687,1.73.318.4,1.068-.055,1.175.732.188,1.408-2.873,1.173-3.631,1.953-.449.463-.484,1.138-1.087,1.453-1.055.545-1.094-.222-.784-.983.826-2.025,1.79-3.956,2.719-5.929a37.382,37.382,0,0,1,1.672-3.679,25.424,25.424,0,0,0,2.309-3.792,61.307,61.307,0,0,0,2.852-7.661c.226-.9.588-1.767.814-2.665.269-1.07.3-2.227.514-3.307.146-.732.448-2.581.948-3.079a.609.609,0,0,1,.094.417"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1371"
					data-name="Pfad 1371"
					d="M61.587,1008.652c-.146-.4.1-.541.237-.878a4.553,4.553,0,0,0,.165-1.517,5.873,5.873,0,0,1,.4-1.5c.225-.634.244-.471.806-.74a9.736,9.736,0,0,0,.917-.662c.264-.167,1.025-.728,1.337-.421.254.254-.078.418-.089.619-.007.086.008.46.008.46-.2.878-1.118.542-1.535,1.361a3.274,3.274,0,0,0,.663-.037c-.109.4-.462.381-.786.545a2.113,2.113,0,0,0-.961.921c-.191.32-.23.75-.445,1.033-.259.34-.716.38-.776.817"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1372"
					data-name="Pfad 1372"
					d="M62.115,1011.546c.621.142,1.217.574,1.859.692a3.986,3.986,0,0,0,2.191-.488c.53-.218,1.378-.792,1.981-.7,1.171.184-.265.891-.663,1.092a11.685,11.685,0,0,1-3.3,1.2c-.654.1-.861-.443-1.495-.381-.616.058-.5.809-.926.914-.281.067-.7-.5-.738-.731-.067-.381.232-.55.289-.862s-.181-.565.047-.816c.212-.233.723-.12.989-.037a.772.772,0,0,0,.076.175"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1373"
					data-name="Pfad 1373"
					d="M63.915,1017.4a4.291,4.291,0,0,1,.633,1.3,4.711,4.711,0,0,0,1.112,1.648c.422.411.877.688,1.279,1.11.14.15.393.321.472.507.151.356-.067.44-.106.614a.7.7,0,0,0-.135.262c-.019.119.129.348.146.48a.928.928,0,0,1-.1.691c-.373.46-.725.006-.87.724-.129.637.111,1.225-.859,1.05-.688-.121-1.267-.567-1.974-.676a4.509,4.509,0,0,0-1.893.114,3.132,3.132,0,0,1,.539-1.4c.411-.337,1.323-.135,1.83-.187a9.8,9.8,0,0,1,1.358.174c.523.009.669-.429,1.051-.679-.5-.776-2.894-.4-3.7-.445.478-.906,2.039-.57,2.933-.613-.069-.66-.609-1.116-.95-1.651-.652-1.022-.6-2.172-1.013-3.268"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1374"
					data-name="Pfad 1374"
					d="M98.3,959.526c.625.29,2.172.358,3,1.274.436.485.821,1.072,1.286,1.493a5.606,5.606,0,0,0,1.178.768l5.415,2.894c.293.157.622.313.872.146-.628-.5-1.322-.718-1.968-1.153a12.292,12.292,0,0,1-2.063-2.014,14.53,14.53,0,0,0-3.653-2.821,7.12,7.12,0,0,0-4.484-1.047S97.671,959.237,98.3,959.526Z"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1375"
					data-name="Pfad 1375"
					d="M91.159,958.762a35.821,35.821,0,0,0-5.691,3.495,6.452,6.452,0,0,1,1.985.9,1.337,1.337,0,0,0,.327.3c.293.134.622-.348.513-.751a3.4,3.4,0,0,0-.314-.576c-.1-.2-.1-.545.067-.619l.964.63a.541.541,0,0,1,.191.179.9.9,0,0,1,.084.315c.184,1.3.391,5.695.575,7,.226-.147.227-3.665.233-4.014a22.83,22.83,0,0,1,.544-4.033q.2-1.009.4-2.018c.061-.313.227-.1.125-.391"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1376"
					data-name="Pfad 1376"
					d="M72.4,952.407c.184.8.21,1.631.38,2.434a11.64,11.64,0,0,0,.666,2,39.181,39.181,0,0,0,4.831,8.575c.271.361.621.747.961.634a1.645,1.645,0,0,0-.238-.8c-.844-1.613-2.048-2.829-2.938-4.4-.786-1.385-1.3-3-2.017-4.45a13.061,13.061,0,0,1-.913-2.015c-.247-.818-.352-1.834-.916-2.282"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_1377"
					data-name="Pfad 1377"
					d="M87.267,961.365c.393.123.6.674.72,1.174.449,1.9.374,3.942.764,5.863a18.735,18.735,0,0,1,.674,3.6c.01,1.847-.918,3.548-.842,5.394.069,1.7.974,3.163,1.322,4.812a15.071,15.071,0,0,1,.148,3.771,19.354,19.354,0,0,1-.477,4.1,5.849,5.849,0,0,0-.408,2.765,23.469,23.469,0,0,0,1.767-7.235,31.069,31.069,0,0,0-.295-4.187c-.161-1.542-.265-3.091-.369-4.639-.139-2.065-.277-4.131-.333-6.2a16.224,16.224,0,0,0-.184-2.5c-.177-.956-.545-1.852-.649-2.824a18.283,18.283,0,0,0-.17-2.145,4.905,4.905,0,0,1-.421-2.212c.043-.169.112-.373.019-.505-.113-.162-.315-.019-.439.125l-.978,1.138"
					transform="translate(441 -282)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<g id="POSITIONEN" ref="position-wrapper">
				<circle
					id="left"
					ref="position-left"
					cx="0.5"
					cy="0.5"
					r="0.5"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
				<circle
					id="right"
					ref="position-right"
					cx="0.5"
					cy="0.5"
					r="0.5"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g>
			<g id="SIZE_REF" transform="translate(238.7 193.939)">
				<circle
					id="ref-point-left"
					ref="ref-point-left"
					data-name="left"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(278.305 473.922)"
					fill="transparent"
				/>
				<circle
					id="ref-point-right"
					ref="ref-point-right"
					data-name="right"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(315.305 473.922)"
					fill="transparent"
				/>
			</g>
			<g
				id="PRINT-AREA"
				transform="translate(504.955 690.811)"
				fill="none"
				stroke="#000"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
				stroke-dasharray="4 4"
				stroke-width="0.4"
			>
				<rect width="60.4" height="77.9" fill="none" ref="print-area" />
			</g>
			<g
				id="BLOCKED-AREA"
				transform="translate(526.505 690.861)"
				v-multi-ref:blocked-area-wrapper
				fill="none"
				stroke="red"
				stroke-width="0.15"
				:opacity="hideBlockedAreas ? 0 : 1"
			>
				<rect width="18" height="19.5" stroke="none" fill="red" opacity="0.1" />
				<rect v-multi-ref:blocked-area x="0.05" y="0.05" width="17.9" height="19.4" fill="none" />
			</g>
			<!-- <g
				id="PRINT-AREA"
				transform="translate(501.005 690.861)"
				fill="none"
				stroke="#000"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
				stroke-dasharray="4 4"
				stroke-width="0.4"
			>
				<rect ref="print-area" x="0.05" y="0.05" width="65.9" height="96.9" fill="none" />
			</g> -->
		</g>
	</svg>
</template>

<script>
import { setPositions } from '../mockupHelpers'

export default {
	name: 'PoloFront',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
		hideBlockedAreas: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		setPositions(this, 17.5)
	},
}
</script>