<template>
	<svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
		<g id="CAP_UNSTRUCTURED_SNAPBACK_BACK" ref="main-wrapper" transform="matrix(0.563761, 0, 0, 0.563761, -4.982758, -4.837538)">
			<g id="COLORS">
				<path
					:fill="mainColor"
					d="M202.9,165.5c0-2.6-0.9-4.4-2.7-8.7c-1.5-4.2-1.7-4.4-2.4-10.2c0-3.4-2.4-9.4-3.6-12.4 c-3.4-11.9-7.6-16.6-11.1-18.9c-3.9-2.6-12.3-4.9-14.4-5.3c-3.4-0.6-5.1-0.9-10.2-1.7c-4-0.6-8-1-12-1.2c-2.6-0.1-5-0.2-7.6-0.2 c1.1-0.1,3-0.2,3-0.2s1.8,0,3.2,0.2c1.8,0.2,1.8-0.2,1.8-0.2c0-1.2-1.1-2.6-4.7-2.6s-4.9,1-5.1,2c-0.1,0.4,0,0.7,0.2,0.8H137 c-3.2,0-6.3,0.2-9.5,0.7c-3.2,0.2-11.9,1.5-13.5,1.9c-8.5,2-5.6,1.2-15.5,5.4c-7.4,4.6-8.7,10.2-12.9,16.5 c-1.9,2.8-2.7,8.3-3.6,12.8c-1.1,4.6,0.3,11.5-0.3,14.5c-0.2,1.6-0.7,3.4-1.5,7.8c-0.5,2.4-0.4,2.4,0.8,6c0.7,2.1,5.8,3,7.5,3.6 c4.8,1.7,5.1,0.9,6.3,1.2c2.5,0.6,2.9-0.2,7.4,0.5c2.4,0.5,4.8,0.9,7.3,1l0,0c0.3,0.1,0.5,0.1,0.8,0l15.5-0.2 c8.3,0.4,16.6,0.5,25,0.4l0,0c7.2,0.1,17.9-0.7,21.4-0.9h1.1c0.6,2.2,5.5,0.5,6.9,0.3c2.3-0.4,14.2-3,15.3-3.1 c1.7-0.5,3.3-1.1,4.9-1.9c0.7-0.6,2.5-0.6,3.3-1.7S203.2,167.1,202.9,165.5z M173.3,164.5c-4,0.5-22.4,0.9-22.6,0.9h-2.8 l-29.3-0.4l-7.7-0.3c0-0.4-0.1-1.6-0.2-2.9c1.7-0.2,18.9-1.8,31.8-1.7c11.2,0.1,15.8,0.4,24.9,0.9l6.6,0.4 C173.8,162.5,173.6,163.5,173.3,164.5L173.3,164.5z"
				/>
			</g>
			<g id="LINES">
				<g id="Gruppe_787" class="st1">
					<path
						id="Pfad_3569"
						fill="none"
						stroke="#47484a"
						stroke-width="0.3"
						d="M109.8,179.2c-0.3-0.3-0.7,0-1-0.2s-0.5-0.6-0.6-0.9c-0.3,0.7-1.2,0.6-1.2-0.2 c-0.4,0.3-1,0.3-1.5-0.1c-0.2-0.1-0.3-0.5-0.6-0.6s-0.4,0.2-0.8,0.2c-1.1-0.2-1.9-1.1-3-1.2c0.3,0.9,2.3,1.7,3.2,2 c1.7,0.5,3.4,0.8,5.1,0.8"
					/>
				</g>
				<path
					id="Pfad_3570"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M109.6,165.2c0,0-0.1-0.4,0.5-0.4l8.4,0.3l29.4,0.4h3.6c0,0,7.4,0,7.4,6.5 c0,3.7-3,6.8-6.7,6.8c-13.8,0.3-27.5-0.1-41.3-1.2h-0.7c0,0-0.9,0.1-0.8-0.9L109.6,165.2z"
				/>
				<path
					id="Pfad_3571"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M109.7,165.9l8.8,0.2l29.4,0.4h3.6c0.6,0,6.4,0.2,6.3,5.5c0,3.2-2.5,5.8-5.7,5.8 c0,0-4.8,0.1-11.6,0.1c-7.7-0.1-19.1-0.3-29.5-1.3h-0.1l-1.5-0.1"
				/>
				<path
					id="Pfad_3572"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M117.6,171.6c0,0.5-0.4,1-0.9,1s-1-0.4-1-0.9s0.3-0.9,0.8-1 C117,170.7,117.5,171.1,117.6,171.6z"
				/>
				<path
					id="Pfad_3573"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M123.1,172.2c-0.1,0.5-0.5,0.9-1,0.8c-0.5-0.1-0.8-0.5-0.8-1c0-0.4,0.4-0.7,0.8-0.8 C122.6,171.2,123.1,171.6,123.1,172.2z"
				/>
				<path
					id="Pfad_3574"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M128.8,172.3c0,0.3-0.3,0.6-0.7,0.6c-0.3,0-0.6-0.3-0.6-0.6l0,0c0-0.3,0.3-0.6,0.6-0.6l0,0 C128.5,171.7,128.8,172,128.8,172.3L128.8,172.3z"
				/>
				<path
					id="Pfad_3575"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M129.1,172.3c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.5-0.8-0.9s0.5-0.9,0.9-0.8 C128.7,171.4,129.1,171.8,129.1,172.3z"
				/>
				<path
					id="Pfad_3576"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M129.9,172.3c0,1-0.7,1.8-1.7,1.8s-1.8-0.7-1.8-1.7s0.7-1.8,1.7-1.8h0.1l0,0 C129.1,170.6,129.8,171.4,129.9,172.3L129.9,172.3z"
				/>
				<path
					id="Pfad_3577"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M123.6,172.3c-0.1,0.9-0.9,1.5-1.8,1.4s-1.5-0.8-1.5-1.7l0,0c0.1-0.8,0.8-1.4,1.6-1.4 C122.9,170.6,123.6,171.4,123.6,172.3z"
				/>
				<path
					id="Pfad_3578"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M117.9,171.8c-0.2,0.8-0.9,1.3-1.7,1.2c-0.8-0.1-1.4-0.8-1.3-1.6l0,0c0.1-0.7,0.8-1.2,1.5-1.2 C117.3,170.2,117.9,170.9,117.9,171.8L117.9,171.8z"
				/>
				<path
					id="Pfad_3579"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M134.8,172.4c0,0.3-0.3,0.6-0.7,0.6c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6 C134.5,171.7,134.8,172,134.8,172.4L134.8,172.4z"
				/>
				<path
					id="Pfad_3580"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M135.1,172.4c-0.1,0.5-0.5,0.9-1,0.8c-0.4,0-0.8-0.4-0.8-0.8c0.1-0.5,0.5-0.9,1-0.8 C134.7,171.6,135,171.9,135.1,172.4z"
				/>
				<path
					id="Pfad_3581"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M135.9,172.4c0,1-0.7,1.8-1.7,1.8s-1.8-0.7-1.8-1.7s0.7-1.8,1.7-1.8h0.1l0,0 C135.1,170.7,135.9,171.4,135.9,172.4C135.9,172.3,135.9,172.3,135.9,172.4L135.9,172.4z"
				/>
				<path
					id="Pfad_3582"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M141.5,172.4c0,0.3-0.3,0.6-0.7,0.6c-0.3,0-0.7-0.2-0.7-0.5s0.2-0.7,0.5-0.7 c0.1,0,0.1,0,0.2,0C141.2,171.8,141.5,172,141.5,172.4L141.5,172.4L141.5,172.4z"
				/>
				<path
					id="Pfad_3583"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M141.8,172.4c-0.1,0.5-0.5,0.9-1,0.8c-0.4,0-0.8-0.4-0.8-0.8c0-0.5,0.4-0.9,0.9-0.9 C141.3,171.5,141.7,171.9,141.8,172.4C141.7,172.4,141.7,172.4,141.8,172.4L141.8,172.4z"
				/>
				<path
					id="Pfad_3584"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M142.6,172.4c0,1-0.7,1.8-1.7,1.8s-1.8-0.7-1.8-1.7s0.7-1.8,1.7-1.8h0.1 C141.8,170.7,142.5,171.5,142.6,172.4z"
				/>
				<path
					id="Pfad_3585"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M147.9,172.4c0,0.4-0.3,0.7-0.6,0.7c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7 C147.6,171.8,147.9,172.1,147.9,172.4z"
				/>
				<path
					id="Pfad_3586"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M148.2,172.4c0,0.5-0.4,0.9-0.9,0.9l0,0c-0.5,0-0.9-0.4-0.9-0.9l0,0c0-0.5,0.4-0.9,0.9-0.9 S148.2,171.9,148.2,172.4L148.2,172.4L148.2,172.4z"
				/>
				<path
					id="Pfad_3587"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M149,172.5c0,1-0.7,1.8-1.7,1.8s-1.8-0.7-1.8-1.7s0.7-1.8,1.7-1.8h0.1l0,0 C148.2,170.8,149,171.5,149,172.5C149,172.4,149,172.4,149,172.5L149,172.5z"
				/>
				<path
					id="Pfad_3588"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M154.7,172.4c0,0.4-0.3,0.8-0.7,0.8s-0.8-0.3-0.8-0.7s0.3-0.8,0.7-0.8l0,0l0,0 C154.3,171.7,154.7,172,154.7,172.4z"
				/>
				<path
					id="Pfad_3589"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M161.7,172.4c0,0.6-0.5,1.2-1.1,1.2s-1.2-0.5-1.2-1.1s0.5-1.2,1.1-1.2c0,0,0,0,0.1,0 C161.2,171.2,161.7,171.7,161.7,172.4C161.7,172.3,161.7,172.3,161.7,172.4z"
				/>
				<path
					id="Pfad_3590"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M161.7,172.5c0,0.6-0.4,1-1,1c-0.5,0.1-1.1-0.2-1.2-0.8c-0.1-0.5,0.2-1.1,0.8-1.2 c0.1,0,0.3,0,0.4,0l0,0C161.3,171.6,161.7,172,161.7,172.5z"
				/>
				<path
					id="Pfad_3591"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M167.8,172c0,0.6-0.5,1.1-1.2,1.1l0,0c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.2-1.1 C167.3,170.9,167.8,171.4,167.8,172L167.8,172z"
				/>
				<path
					id="Pfad_3592"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M167.8,172.1c0,0.5-0.4,1-0.9,1s-1-0.4-1-0.9s0.4-1,0.9-1c0,0,0,0,0.1,0l0,0 C167.3,171.3,167.7,171.7,167.8,172.1z"
				/>
				<path
					id="Pfad_3593"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M154.7,172.3c0,0.5-0.4,0.9-0.9,1s-0.9-0.4-1-0.9c0-0.5,0.4-0.9,0.9-1l0,0l0,0 C154.3,171.4,154.7,171.8,154.7,172.3z"
				/>
				<path
					id="Pfad_3594"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M155.5,172.3c0,1-0.7,1.8-1.7,1.8s-1.8-0.7-1.8-1.7s0.7-1.8,1.7-1.8h0.1 C154.7,170.6,155.5,171.4,155.5,172.3z"
				/>
				<path
					id="Pfad_3595"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M153.7,174.8c1.4,0,2.6-1.1,2.6-2.5s-1.2-2.5-2.6-2.5l0,0c0,0-27.9-0.1-37.6-0.5l-1.3-0.1 c0,0-0.7-0.1-0.7,0.5v3.3c0,0.5,0.3,0.9,0.8,0.9l0,0h0.1c0,0,13,0.6,21.3,0.7S151.7,174.9,153.7,174.8z"
				/>
				<path
					id="Pfad_3596"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M150.7,165.6c0,0,18.6-0.4,22.7-0.9c0,0,1.2-0.2,1.2,1.1l-0.1,11.5c0,0,0,0.6-1.2,0.7 s-14.3,1.1-22.5,1"
				/>
				<path
					id="Pfad_3597"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M155.4,178c5.8-0.2,12.3-0.6,15.5-0.9l2.2-0.1h0.2l0.1-11.2v-0.1c-2.8,0.4-12.3,0.7-18.1,0.8"
				/>
				<path
					id="Pfad_3598"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M110.7,164.8c-0.1-0.6-0.4-5.9-0.4-5.9c-1.1,5.5-1.7,11.1-1.9,16.7c0,2.9,1.1,3.1,1.1,3.1 c1.3,0.4,1.6-1.1,1.6-1.1"
				/>
				<path
					id="Pfad_3599"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M173.3,164.6c0.5-0.1,0.7-3.9,0.7-3.9s0.2-9.2-5.1-15.9s-16.8-7.7-28.3-7.7 c-6.4-0.2-12.7,0.8-18.6,3.1c-10.9,5.4-11.8,18.9-11.8,18.9"
				/>
				<path
					id="Pfad_3600"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M108.2,178.8c-1.3-2.7-0.8-8.7-0.8-8.7c0.5-9.3,3-21.4,8.7-27.7s24.4-7,24.4-7 s19.8-0.3,28,6.7s7.5,21.7,7.4,26.3s0.1,10.2-1.3,10.6"
				/>
				<path
					id="Pfad_3601"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M105.7,178.2c-1.2-11.8,0.9-23.8,6.1-34.5c4.8-10.4,28.8-11.2,28.8-11.2s32.8-1.8,36,21.4 c0,0,3.2,21.5,0.8,24.9"
				/>
				<path id="Pfad_3602" fill="none" stroke="#47484a" stroke-width="0.3" d="M142,106.9c0,0-1.4,29.2-1.4,30.3" />
				<path id="Pfad_3603" fill="none" stroke="#47484a" stroke-width="0.3" d="M144.9,106.9l-0.3,30.3" />
				<path id="Pfad_3604" fill="none" stroke="#47484a" stroke-width="0.3" d="M139.2,107c0,0-2.3,29.1-2.3,30.1" />
				<path
					id="Pfad_3605"
					class="st5"
					d="M141.9,106.8c0,0-2.8,0.2-3.5,0.3s-1.6,0.1-1.4-0.9s1.5-2,5.1-2s4.7,1.4,4.7,2.6 c0,0,0,0.4-1.8,0.2C143.7,106.8,141.9,106.8,141.9,106.8z"
				/>
				<path
					id="Pfad_3606"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M109.4,178.7c-2.5-0.1-4.9-0.5-7.3-1c-4.5-0.7-4.9,0.1-7.4-0.5c-1.2-0.2-1.4,0.5-6.3-1.2 c-1.7-0.6-6.8-1.5-7.5-3.6c-1.3-3.6-1.3-3.6-0.8-6c0.8-4.4,1.3-6.2,1.5-7.8c0.6-3-0.7-9.9,0.3-14.5c0.9-4.4,1.7-9.9,3.6-12.8 c4.3-6.3,5.6-11.9,12.9-16.5c9.9-4.2,7-3.4,15.5-5.4c1.6-0.4,10.3-1.7,13.5-1.9c3.1-0.4,6.3-0.7,9.5-0.7c3.3,0,6.3,0,9.6,0.2 c4,0.1,8,0.5,12,1.2c5.1,0.8,6.8,1,10.2,1.7c2.1,0.4,10.5,2.8,14.4,5.3c3.4,2.3,7.6,7,11.1,18.9c1.2,3,3.6,9,3.6,12.4 c0.7,5.8,0.9,6,2.4,10.2c1.9,4.3,2.7,6.1,2.7,8.7c0.3,1.6,1.2,5.1,0.5,6.1s-2.6,1.1-3.3,1.7c-1.6,0.7-3.2,1.3-4.9,1.9 c-1,0-12.9,2.7-15.3,3.1c-1.4,0.1-6.3,1.9-6.9-0.3"
				/>
				<path
					id="Pfad_3607"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M110.4,161.7c0,0,18.3-1.9,31.9-1.8s17.5,0.5,31.6,1.4"
				/>
				<path
					id="Pfad_3608"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M168.9,144.9c4.5,5.6,5.1,13.1,5.1,15.2h0.1v-0.3c-0.1-2.5-0.8-9.6-5.1-14.9 c-5.4-6.6-16.8-7.7-28.3-7.8l0,0C152.2,137.2,163.6,138.3,168.9,144.9z"
				/>
				<path id="Pfad_3610" fill="none" stroke="#47484a" stroke-width="0.3" d="M137.2,179h-1H137.2z" />
				<path id="Pfad_3611" fill="none" stroke="#47484a" stroke-width="0.3" d="M139.7,179L139.7,179z" />
				<path id="Pfad_3612" fill="none" stroke="#47484a" stroke-width="0.3" d="M146.1,179L146.1,179z" />
				<path id="Pfad_3613" fill="none" stroke="#47484a" stroke-width="0.3" d="M147,179L147,179z" />
				<path id="Pfad_3614" fill="none" stroke="#47484a" stroke-width="0.3" d="M138.5,179L138.5,179z" />
				<path id="Pfad_3615" fill="none" stroke="#47484a" stroke-width="0.3" d="M147.9,179L147.9,179z" />
				<path id="Pfad_3617" fill="none" stroke="#47484a" stroke-width="0.3" d="M144.2,179L144.2,179z" />
				<path id="Pfad_3618" fill="none" stroke="#47484a" stroke-width="0.3" d="M145.2,179L145.2,179z" />
				<path id="Pfad_3620" fill="none" stroke="#47484a" stroke-width="0.3" d="M151.3,179h-0.8H151.3z" />
				<path id="Pfad_3621" fill="none" stroke="#47484a" stroke-width="0.3" d="M143.2,179L143.2,179z" />
				<path id="Pfad_3622" fill="none" stroke="#47484a" stroke-width="0.3" d="M150.6,179L150.6,179z" />
				<path id="Pfad_3623" fill="none" stroke="#47484a" stroke-width="0.3" d="M148.6,179L148.6,179z" />
				<path id="Pfad_3624" fill="none" stroke="#47484a" stroke-width="0.3" d="M149.4,179L149.4,179z" />
				<path id="Pfad_3625" fill="none" stroke="#47484a" stroke-width="0.3" d="M136,178.9h-1.1H136z" />
				<path id="Pfad_3626" fill="none" stroke="#47484a" stroke-width="0.3" d="M134.6,178.9h-1.2H134.6z" />
				<path id="Pfad_3627" fill="none" stroke="#47484a" stroke-width="0.3" d="M150,179L150,179z" />
				<path id="Pfad_3628" fill="none" stroke="#47484a" stroke-width="0.3" d="M134.9,178.9L134.9,178.9z" />
				<path id="Pfad_3629" fill="none" stroke="#47484a" stroke-width="0.3" d="M133.5,178.9L133.5,178.9z" />
				<path id="Pfad_3631" fill="none" stroke="#47484a" stroke-width="0.3" d="M148.8,179L148.8,179z" />
				<path id="Pfad_3633" fill="none" stroke="#47484a" stroke-width="0.3" d="M149.5,179L149.5,179z" />
				<path id="Pfad_3636" fill="none" stroke="#47484a" stroke-width="0.3" d="M144.5,179L144.5,179z" />
				<path id="Pfad_3638" fill="none" stroke="#47484a" stroke-width="0.3" d="M145.4,179L145.4,179z" />
				<path id="Pfad_3646" class="st6" d="M171,118c-0.3,0.5-1.3,0.5-2.3,0s-1.7-1.3-1.4-1.8s1.3-0.5,2.3,0S171.2,117.5,171,118z" />
				<path
					id="Pfad_3647"
					class="st6"
					d="M116.7,116.6c0.2,0.5-0.5,1.2-1.6,1.5s-2,0.2-2.2-0.3s0.5-1.2,1.6-1.5 S116.5,116.1,116.7,116.6z"
				/>
			</g>
			<g
				id="PRINT-AREA"
				transform="matrix(1, 0, 0, 1, 123, 121)"
				ref="print-area-wrapper"
				fill="none"
				stroke="#000"
				:opacity="currentStep === 'motive' ? 1 : 0"
				stroke-dasharray="4 4"
				stroke-width="0.4"
			>
				<rect ref="print-area" width="38" height="14.25" />
			</g>
			<g id="POSITIONEN" ref="position-wrapper" transform="matrix(1, 0, 0, 1, 142, 128.125)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import { setPositions } from '../mockupHelpers'

export default {
	name: 'CapUnstructuredSnapbackBack',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
	mounted() {
		setPositions(this, 0, true)
	},
}
</script>