var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"drag-wrapper",staticClass:"drag-wrapper",class:{ 'selected-finish': this.selectedFinishId.toString() === this.finish.idx },style:({
		width: _vm.model.image.width + 'px',
		height: _vm.model.image.height + 'px',
		left: _vm.model.image.x + 'px',
		top: _vm.model.image.y + 'px',
		transform: _vm.model.image.transform.translate + ' ' + _vm.model.image.transform.rotate + ' translateZ(0)', // translateZ(0) fixes the artifacts bug in Safari
		zIndex: this.selectedFinishId.toString() === this.finish.idx && 11,
	}),attrs:{"id":"drag-finish"}},[(_vm.showSizeInfo)?_c('div',{staticClass:"size-rect",style:({ left: _vm.model.image.width / 2 - 65 + 'px' })},[(_vm.finish.sizeMeasure === 'width')?_c('span',[_vm._v("Breite: "+_vm._s(_vm.cmSize.width.toFixed(1))+"cm")]):_vm._e(),(_vm.finish.sizeMeasure === 'height')?_c('span',[_vm._v("Höhe: "+_vm._s(_vm.cmSize.height.toFixed(1))+"cm")]):_vm._e()]):_vm._e(),(_vm.showPositionInfo)?_c('div',{staticClass:"position-rect",style:({ left: _vm.model.image.width / 2 - 65 + 'px' })},[_vm._v(" X: "+_vm._s(_vm.cmPosition.x.toFixed(1))+"cm, Y: "+_vm._s(_vm.cmPosition.y.toFixed(1))+"cm ")]):_vm._e(),_c('img',{staticClass:"image-element",style:({
			width: _vm.model.image.width + 'px',
			height: _vm.model.image.height + 'px',
			outline: this.selectedFinishId.toString() === this.finish.idx && _vm.currentStep === 'motive' ? '1px dashed white' : 'none',
			'outline-offset': '1px', // does not work in ie
		}),attrs:{"src":_vm.image}}),_c('div',{staticClass:"move-rect",style:({
			width: _vm.model.image.width + 'px',
			height: _vm.model.image.height + 'px',
			//border: this.selectedFinishId.toString() === this.finish.idx && currentStep === 'motive' ? '1px dashed white' : 'none',
			cursor: _vm.currentStep !== 'motive' ? 'default' : 'move',
		})}),(_vm.currentStep === 'motive')?_c('div',{staticClass:"resize-btn resize-br"},[_c('v-icon',[_vm._v("mdi-arrow-expand")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }