import { mapFields } from 'vuex-map-fields'
import { EventBus } from '@/utils/eventbus'

import finishSettings from '@/assets/data/finishSettings.js'

export default {
	props: {
		mockupType: {
			type: String,
			required: true,
		},
		mockupSide: {
			type: String,
			required: true,
		},
		mainColor: {
			type: String,
			default: 'white',
			required: false,
		},
		secColor: {
			type: String,
			default: 'white',
			required: false,
		},
		allFinishes: {
			type: Object,
			// required: true,
		},
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
		currentStep: {
			type: String,
			required: true,
		},
		hideBlockedAreas: {
			type: Boolean,
			default: false,
		},
		GET_FILE_BY_NAME_ALT: { type: Function, required: false },
	},
	data() {
		return {
			isMounted: false,
			CONTAINER_OFFSET_LEFT: 0,
			CONTAINER_OFFSET_TOP: 0,
			REF_CM_TO_PIXEL: 0,
			MAX_PRINT_AREA_TOP: 0,
			MAX_PRINT_AREA_LEFT: 0,
			MAX_PRINT_AREA_RIGHT: 0,
			MAX_PRINT_AREA_BOTTOM: 0,
			BLOCKED_AREAS: [],
		}
	},
	beforeDestroy() {
		EventBus.$off('svgComponentData/getMaxSizes', this.getMaxSizes)
	},
	mounted() {
		// console.log('Mounted: ' + this.mockupSide + ' : ' + this.mockupType)
		this.init()

		EventBus.$on('svgComponentData/getMaxSizes', this.getMaxSizes)
	},
	computed: {
		cssVars() {
			// invert color
			function invertColor(hex, bw) {
				console.log(hex)
				if (hex.indexOf('#') === 0) {
					hex = hex.slice(1)
				}
				// convert 3-digit hex to 6-digits.
				if (hex.length === 3) {
					hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
				}
				if (hex.length !== 6) {
					throw new Error('Invalid HEX color.')
				}
				var r = parseInt(hex.slice(0, 2), 16),
					g = parseInt(hex.slice(2, 4), 16),
					b = parseInt(hex.slice(4, 6), 16)
				if (bw) {
					// http://stackoverflow.com/a/3943023/112731
					return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF'
				}
				// invert color components
				r = (255 - r).toString(16)
				g = (255 - g).toString(16)
				b = (255 - b).toString(16)
				// pad each with zeros and return
				return '#' + padZero(r) + padZero(g) + padZero(b)
			}
			function padZero(str, len) {
				len = len || 2
				var zeros = new Array(len).join('0')
				return (zeros + str).slice(-len)
			}
			let color = this.mainColor
			if (this.mainColor !== 'transparent') color = invertColor(this.mainColor, true)
			if (this.currentStep !== 'motive') color = 'transparent'
			// invert color END

			// hide dots if custom position
			let dotOpacity = 0.4
			if (this.allFinishes && this.selectedFinishId > -1) {
				const currentFinish = this.allFinishes[this.selectedFinishId]
				if (currentFinish && currentFinish.position && currentFinish.position.match(/custom/gi)) dotOpacity = 0
			}
			// hide dots if custom position END

			return {
				'--color': color,
				'--invertColor': color !== 'transparent' ? invertColor(color, true) : 'transparent',
				'--dotOpacity': dotOpacity,
			}
		},
		...mapFields('Configurator', {
			// allFinishes: 'finishes',
			selectedFinishId: 'selectedFinishId',
		}),
		filteredFinishes() {
			const sideFinishes = []

			for (let key in this.allFinishes) {
				const finish = this.allFinishes[key]
				const finishSide = this.getFinishSide(finish)
				if (this.mockupSide === finishSide) {
					const finishPosition = this.findPositionPoint(finish.position)
					if (finishPosition !== 'position-custom') {
						finish.idx = key
						sideFinishes.push(finish)
					}
				}
			}

			return sideFinishes
		},
		customFinishes() {
			const sideFinishes = []

			for (let key in this.allFinishes) {
				const finish = this.allFinishes[key]
				const finishSide = this.getFinishSide(finish)
				if (this.mockupSide === finishSide) {
					const finishPosition = this.findPositionPoint(finish.position)
					if (finishPosition === 'position-custom') {
						finish.idx = key
						sideFinishes.push(finish)
					}
				}
			}

			return sideFinishes
		},
		printAreaSizes() {
			return finishSettings.printAreaSizes
		},
		referenceDistances() {
			return finishSettings.referenceDistances
		},
	},
	methods: {
		getMaxSizes({ finish, cb }) {
			// max possible sizes for custom positions
			let maxPossibleHeight = this.MAX_PRINT_AREA_BOTTOM - this.MAX_PRINT_AREA_TOP
			let maxPossibleWidth = this.MAX_PRINT_AREA_RIGHT - this.MAX_PRINT_AREA_LEFT

			if (!finish.position.match(new RegExp('custom', 'gi'))) {
				// max possible sizes for non-custom positions
				const positionPoint = this.findPositionPoint(finish.position)
				const pointRect = this.$refs['mockupSvg'].$refs[positionPoint].getBoundingClientRect()
				const POINT_TOP = pointRect.top - this.CONTAINER_OFFSET_TOP + pointRect.height / 2
				const POINT_LEFT = pointRect.left - this.CONTAINER_OFFSET_LEFT + pointRect.width / 2
				const POINT_RIGHT = pointRect.right - this.CONTAINER_OFFSET_LEFT - pointRect.width / 2
				const POINT_BOTTOM = pointRect.bottom - this.CONTAINER_OFFSET_TOP - pointRect.height / 2

				maxPossibleHeight = Math.min(POINT_TOP - this.MAX_PRINT_AREA_TOP, this.MAX_PRINT_AREA_BOTTOM - POINT_BOTTOM) * 2
				maxPossibleWidth = Math.min(this.MAX_PRINT_AREA_RIGHT - POINT_RIGHT, POINT_LEFT - this.MAX_PRINT_AREA_LEFT) * 2
			}

			// file sizes
			const file = this.$getFileById(finish.fileName)
			const imageHeight = file.height
			const imageWidth = file.width

			// blocked areas
			let refBlockedAreas = this.$refs['mockupSvg'].$refs['blocked-area']
			if (refBlockedAreas) {
				// only one blocked area, transform to array
				if (!refBlockedAreas[0]) refBlockedAreas = [refBlockedAreas]

				refBlockedAreas.forEach((ba) => {
					console.log(ba)
					const baRect = ba.getBoundingClientRect()

					const BA_LEFT = baRect.left - this.CONTAINER_OFFSET_LEFT
					const BA_RIGHT = baRect.right - this.CONTAINER_OFFSET_LEFT
					const BA_TOP = baRect.top - this.CONTAINER_OFFSET_TOP
					const BA_BOTTOM = baRect.bottom - this.CONTAINER_OFFSET_TOP
					const toleratedOffset = 1 // in pixel

					// blocked area touches top / bottom
					if (
						BA_TOP >= this.MAX_PRINT_AREA_TOP - toleratedOffset &&
						BA_TOP <= this.MAX_PRINT_AREA_TOP + toleratedOffset &&
						BA_BOTTOM >= this.MAX_PRINT_AREA_BOTTOM - toleratedOffset &&
						BA_BOTTOM <= this.MAX_PRINT_AREA_BOTTOM + toleratedOffset
					) {
						maxPossibleWidth = Math.max(BA_LEFT - this.MAX_PRINT_AREA_LEFT, BA_RIGHT - this.MAX_PRINT_AREA_RIGHT)
					}

					// blocked area touches left / right
					if (
						BA_LEFT >= this.MAX_PRINT_AREA_LEFT - toleratedOffset &&
						BA_LEFT <= this.MAX_PRINT_AREA_LEFT + toleratedOffset &&
						BA_RIGHT >= this.MAX_PRINT_AREA_RIGHT - toleratedOffset &&
						BA_RIGHT <= this.MAX_PRINT_AREA_RIGHT + toleratedOffset
					) {
						maxPossibleHeight = Math.max(BA_TOP - this.MAX_PRINT_AREA_TOP, BA_BOTTOM - this.MAX_PRINT_AREA_BOTTOM)
					}

					// check if blocked area touches other blocked area and then blocks full-height/-width
					const fullHeight = this.MAX_PRINT_AREA_BOTTOM - this.MAX_PRINT_AREA_TOP
					const fullWidth = this.MAX_PRINT_AREA_RIGHT - this.MAX_PRINT_AREA_LEFT
					if (refBlockedAreas.length > 1) {
						refBlockedAreas.forEach((oba) => {
							if (oba !== ba) {
								const obaRect = oba.getBoundingClientRect()
								// is left/right full width
								if (
									obaRect.height + baRect.height >= fullHeight - toleratedOffset &&
									obaRect.height + baRect.height <= fullHeight + toleratedOffset
								) {
									const OBA_LEFT = obaRect.left - this.CONTAINER_OFFSET_LEFT
									const OBA_RIGHT = obaRect.right - this.CONTAINER_OFFSET_LEFT

									// check which distance to the border is bigger
									const leftDist = OBA_LEFT - this.MAX_PRINT_AREA_LEFT
									const rightDist = this.MAX_PRINT_AREA_RIGHT - OBA_RIGHT

									if (leftDist > toleratedOffset && leftDist > rightDist) maxPossibleWidth = leftDist
									if (rightDist > toleratedOffset && rightDist > leftDist) maxPossibleWidth = rightDist
								}
								// is top/bottom full height
								if (
									obaRect.width + baRect.width >= fullWidth - toleratedOffset &&
									obaRect.width + baRect.width <= fullWidth + toleratedOffset
								) {
									const OBA_TOP = obaRect.top - this.CONTAINER_OFFSET_TOP
									const OBA_BOTTOM = obaRect.bottom - this.CONTAINER_OFFSET_TOP

									// check which distance to the border is bigger
									const topDist = OBA_TOP - this.MAX_PRINT_AREA_TOP
									const bottomDist = this.MAX_PRINT_AREA_BOTTOM - OBA_BOTTOM

									if (topDist > toleratedOffset && topDist > bottomDist) maxPossibleWidth = topDist
									if (bottomDist > toleratedOffset && bottomDist > topDist) maxPossibleWidth = bottomDist
								}
							}
						})
					}
				})
			}

			// with set height
			const adjustedWidth = (maxPossibleHeight * imageWidth) / imageHeight
			if (adjustedWidth <= maxPossibleWidth) {
				const maxWidth = parseInt(this.convertPixelToCm(adjustedWidth) * 10) / 10
				const maxHeight = parseInt(this.convertPixelToCm(maxPossibleHeight) * 10) / 10
				cb({ maxWidth, maxHeight })
			}

			// with set width
			const adjustedHeight = (maxPossibleWidth * imageHeight) / imageWidth
			if (adjustedHeight <= maxPossibleHeight) {
				const maxWidth = parseInt(this.convertPixelToCm(maxPossibleWidth) * 10) / 10
				const maxHeight = parseInt(this.convertPixelToCm(adjustedHeight) * 10) / 10
				cb({ maxWidth, maxHeight })
			}
		},
		init() {
			this.isMounted = false

			// container offset
			this.CONTAINER_OFFSET_LEFT = this.$refs.svgContainer.getBoundingClientRect().left
			this.CONTAINER_OFFSET_TOP = this.$refs.svgContainer.getBoundingClientRect().top

			// print area
			const refPrintArea = this.$refs['mockupSvg'].$refs['print-area']
			const rect = refPrintArea.getBoundingClientRect()
			this.MAX_PRINT_AREA_TOP = rect.top - this.CONTAINER_OFFSET_TOP
			this.MAX_PRINT_AREA_LEFT = rect.left - this.CONTAINER_OFFSET_LEFT
			this.MAX_PRINT_AREA_RIGHT = rect.right - this.CONTAINER_OFFSET_LEFT
			this.MAX_PRINT_AREA_BOTTOM = rect.bottom - this.CONTAINER_OFFSET_TOP

			// ref size
			// #1 print area width method
			const mockupRefPointDistance = this.MAX_PRINT_AREA_RIGHT - this.MAX_PRINT_AREA_LEFT // ref point distance in pixel
			let realRefPointDistance = this.printAreaSizes[this.mockupType][this.mockupSide].maxWidth // ref point distance in cm

			// #2 ref point method
			// const refPointLeft = this.$refs['mockupSvg'].$refs['ref-point-left']
			// const refPointRight = this.$refs['mockupSvg'].$refs['ref-point-right']
			// const mockupRefPointDistance = Math.floor(
			// 	refPointRight.getBoundingClientRect().left - refPointLeft.getBoundingClientRect().left
			// )
			// let realRefPointDistance = this.referenceDistances[this.mockupType][this.mockupSide] // ref point distance in cm

			this.REF_CM_TO_PIXEL = mockupRefPointDistance / realRefPointDistance - 0.05 // remove a little offset to make it just a little smaller to prevent calc errors
			// console.log('NORMAL: One CM is ' + this.REF_CM_TO_PIXEL + ' Pixel')

			// blocked areas
			let refBlockedArea = this.$refs['mockupSvg'].$refs['blocked-area']
			if (refBlockedArea && !refBlockedArea[0]) {
				// only one blocked area, transform to array
				refBlockedArea = [refBlockedArea]
			}

			if (refBlockedArea) {
				refBlockedArea.forEach((ba) => {
					const baRect = ba.getBoundingClientRect()
					this.BLOCKED_AREAS.push({
						top: baRect.top - this.CONTAINER_OFFSET_TOP,
						left: baRect.left - this.CONTAINER_OFFSET_LEFT,
						right: baRect.right - this.CONTAINER_OFFSET_LEFT,
						bottom: baRect.bottom - this.CONTAINER_OFFSET_TOP,
					})
				})
			}

			this.isMounted = true
		},
		hasCustomFinish() {
			return !!this.customFinishes.find((f) => f.position === 'custom-front')
		},
		getFinishPosition(finish) {
			const positionOnMockup = {
				width: '0px',
				height: '0px',
				top: '0px',
				left: '0px',
				cursor: 'default',
			}

			if (finish) {
				if (finish.position.match(/custom/gi)) positionOnMockup.cursor = 'move'

				const file = this.$getFileById(finish.fileName)

				if (file) {
					// calc new image size
					const imageHeight = file.height
					const imageWidth = file.width
					let finishWidth = 0
					let finishHeight = 0
					if (finish.sizeMeasure === 'height') {
						const adjustedWidth = (this.convertCmToPixel(finish.size['height']) * imageWidth) / imageHeight
						finishWidth = adjustedWidth
						finishHeight = this.convertCmToPixel(finish.size['height'])
					} else if (finish.sizeMeasure === 'width') {
						const adjustedHeight = (this.convertCmToPixel(finish.size['width']) * imageHeight) / imageWidth
						finishWidth = this.convertCmToPixel(finish.size['width'])
						finishHeight = adjustedHeight
					}

					// calc new image position
					const positionPoint = this.findPositionPoint(finish.position)
					// console.log('POSITION: ', positionPoint)
					if (positionPoint && positionPoint !== '') {
						// validate new position/size, if the motiv is not in the print-area an error is shown
						this.validateSizePosition(finishWidth, finishHeight, positionPoint)

						// set new image size and position
						// image size
						positionOnMockup.width = finishWidth + 'px'
						positionOnMockup.height = finishHeight + 'px'

						// image position
						if (positionPoint === 'position-custom') {
							positionOnMockup.left =
								this.$refs['mockupSvg'].$refs['print-area'].getBoundingClientRect().left -
								this.CONTAINER_OFFSET_LEFT +
								0 +
								'px'
							positionOnMockup.top =
								this.$refs['mockupSvg'].$refs['print-area'].getBoundingClientRect().top -
								this.CONTAINER_OFFSET_TOP +
								0 +
								'px'
						} else {
							positionOnMockup.left =
								this.getPixelPositionOfImageCorner('left', positionPoint, finishWidth, finishHeight) + 'px'
							positionOnMockup.top =
								this.getPixelPositionOfImageCorner('top', positionPoint, finishWidth, finishHeight) + 'px'
						}
					} else {
						// console.log('DO NOT SHOW THE SELECTED FINISH')
						positionOnMockup.display = 'none'
					}
				}
			}

			// console.log(positionOnMockup)
			// set width / height of img child element
			// if (this.$refs[finishRef] && this.$refs[finishRef][0]) {
			// 	this.$refs[finishRef][0].children[0].style.width = positionOnMockup.width
			// 	this.$refs[finishRef][0].children[0].style.height = positionOnMockup.height
			// }

			return positionOnMockup
		},
		convertCmToPixel(cmValue) {
			return cmValue * this.REF_CM_TO_PIXEL
		},
		convertPixelToCm(pixelValue) {
			return pixelValue / this.REF_CM_TO_PIXEL
		},
		getPixelPositionOfImageCorner(corner, positionPoint, finishWidth, finishHeight) {
			const pointRect = this.$refs['mockupSvg'].$refs[positionPoint].getBoundingClientRect()
			if (corner === 'left') {
				return pointRect.left + pointRect.width / 2 - this.CONTAINER_OFFSET_LEFT - finishWidth / 2
			} else if (corner === 'top') {
				return pointRect.top + pointRect.height / 2 - this.CONTAINER_OFFSET_TOP - finishHeight / 2
			} else if (corner === 'right') {
				return pointRect.left + pointRect.width / 2 - this.CONTAINER_OFFSET_LEFT + finishWidth / 2
			} else if (corner === 'bottom') {
				return pointRect.top + pointRect.height / 2 - this.CONTAINER_OFFSET_TOP + finishHeight / 2
			}
		},
		validateSizePosition(finishWidth, finishHeight, positionPoint) {
			if (positionPoint === 'position-custom') positionPoint = 'position-center'
			// validate new position/size, if the motiv is not in the print-area an error is shown
			const finishPositionLeft = this.getPixelPositionOfImageCorner('left', positionPoint, finishWidth, finishHeight)
			const finishPositionTop = this.getPixelPositionOfImageCorner('top', positionPoint, finishWidth, finishHeight)
			const finishPositionRight = this.getPixelPositionOfImageCorner('right', positionPoint, finishWidth, finishHeight)
			const finishPositionBottom = this.getPixelPositionOfImageCorner('bottom', positionPoint, finishWidth, finishHeight)

			if (
				finishPositionLeft < this.MAX_PRINT_AREA_LEFT ||
				finishPositionTop < this.MAX_PRINT_AREA_TOP ||
				finishPositionRight > this.MAX_PRINT_AREA_RIGHT ||
				finishPositionBottom > this.MAX_PRINT_AREA_BOTTOM
			) {
				// this.sizeWarning = true
				this.$store.dispatch('ConfiguratorDisplay/setSizeWarning', true)
			} else {
				let overlapping = false
				this.BLOCKED_AREAS.forEach((blockedArea) => {
					const overlap = !(
						finishPositionRight < blockedArea.left ||
						finishPositionLeft > blockedArea.right ||
						finishPositionBottom < blockedArea.top ||
						finishPositionTop > blockedArea.bottom
					)
					if (overlap) overlapping = true
				})

				// this.sizeWarning = overlapping
				this.$store.dispatch('ConfiguratorDisplay/setSizeWarning', overlapping)
			}
		},
		findPositionPoint(finishPosition) {
			let positionPoint = ''
			if (finishPosition === 'front-left') positionPoint = 'left'
			else if (finishPosition === 'front-right') positionPoint = 'right'
			else if (
				finishPosition === 'front-center' ||
				finishPosition === 'left-center' ||
				finishPosition === 'right-center' ||
				finishPosition === 'back-center'
			)
				positionPoint = 'center'
			else if (
				finishPosition === 'shoulder-sleeve-left-custom' ||
				finishPosition === 'shoulder-sleeve-right-custom' ||
				finishPosition === 'custom-front' ||
				finishPosition === 'custom-back' ||
				finishPosition === 'custom-left' ||
				finishPosition === 'custom-right'
			)
				positionPoint = 'custom'
			return positionPoint && 'position-' + positionPoint
		},
		getFinishSide(finish) {
			let finishSide = 'front'
			const sideViewValues = ['shoulder-sleeve', 'label-sleeve', 'label-hip', 'left', 'right'] // shoulder-sleeve
			const frontViewValues = ['front', 'label-hem', 'label-front', 'neck', 'label-standard-neck', 'label-loop-neck'] // front
			const backViewValues = ['back'] // back

			const sideLeftPositions = ['shoulder-sleeve-left-custom', 'left-center', 'label-sleeve-left', 'label-hip-left', 'custom-left']
			const sideRightPositions = [
				'shoulder-sleeve-right-custom',
				'right-center',
				'label-sleeve-right',
				'label-hip-right',
				'custom-right',
			]

			if (frontViewValues.indexOf(finish.side) > -1) finishSide = 'front'
			else if (backViewValues.indexOf(finish.side) > -1) finishSide = 'back'
			else if (sideViewValues.indexOf(finish.side) > -1) {
				if (sideLeftPositions.indexOf(finish.position) > -1) finishSide = 'left'
				if (sideRightPositions.indexOf(finish.position) > -1) finishSide = 'right'
			}
			return finishSide
		},
	},
}
