<template>
	<svg viewBox="0 0 150 150" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com">
		<g id="CAP_UNSTRUCTURED_SNAPBACK_RIGHT" ref="main-wrapper" transform="matrix(-0.599124, 0, 0, 0.599124, 159.937561, -9.934724)">
			<g id="COLORS">
				<path
					:fill="mainColor"
					d="M156.45,171.07m25.17-2.34s-1.26-7.78-1.87-13.64-2.15-21.4-3.9-26.38-3.9-11.36-13-14.11a125.62,125.62,0,0,0-19.95-4.27c-6-1-12-2-14.75-2.33h0c-.91-.15-1.82-.28-2.74-.36l-3.28-.29c1.75.14,4.58.39,5.68.56,1.65.24,1.43-.89,1.43-.89,0-2.52-5.15-2.81-6.34-2.81s-2.83.08-3.14,1.55c-.22,1.07.35,1.35.67,1.43a5.48,5.48,0,0,0-2,.75l.53-.28c-1.94,1.09-15.94,3.53-20.83,4.77-5.46,1.38-13.79,3.68-18.77,6.91s-8.51,5.58-11.45,9.66-1.87,3.56-3.63,5.76-2.52,2.76-2.64,7.37a24,24,0,0,0,.71,8.05A70.7,70.7,0,0,1,64.41,158c.12,1,.21,2.84.32,4.92l0-.2s-3.89.73-3.85,1.61.47,1.65.43,2.57-.51.84-.43,1.8-.09,1.84,0,2.27a3.39,3.39,0,0,0,.48,1.22,1.82,1.82,0,0,1,.23,1,9.1,9.1,0,0,1-.19,1.57c0,.31-.31.58.35.77a6.58,6.58,0,0,1,1.49.58,13.09,13.09,0,0,0,3.26.87v-.11a2.56,2.56,0,0,0,2.47,1.83c2.76.06,14.91.29,20.42,0a64.47,64.47,0,0,1,8.9.08l1,0a54,54,0,0,0,7.42-.16c3-.39,7-.81,9.12-1l5.76-.48,3.53-.19c1.64-.1,7.57-.48,9.08-.62s5.28-.67,6.77-.87a14.58,14.58,0,0,1,4.22-.22,4.45,4.45,0,0,0,2.57.35l-.29-.2a6.66,6.66,0,0,0,1.06.46c1.43.23,17,2,20,2.22s17.67,1.2,22.67,1.16,14.88-.2,20-.88,7.8-1,10.16-2.35,1.58-3.79-1.79-4.19-15.12-1.63-19.63-2-13.61-1.11-18.25-1.08"
				/>
			</g>
			<g id="LINES">
				<path
					id="Pfad_2999"
					data-name="Pfad 2999"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M181.62,168.73l.07.15A.26.26,0,0,0,181.62,168.73Z"
				/>
				<path
					id="Pfad_3000"
					data-name="Pfad 3000"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M66.46,177a13.09,13.09,0,0,1-3.26-.87,6.58,6.58,0,0,0-1.49-.58c-.66-.19-.39-.46-.35-.77a9.1,9.1,0,0,0,.19-1.57,1.82,1.82,0,0,0-.23-1,3.39,3.39,0,0,1-.48-1.22c-.08-.43.09-1.31,0-2.27s.39-.89.43-1.8-.39-1.69-.43-2.57c0-.34.73-.73,1.43-1l-.19.08s-1.14.83,0,1.21a18.57,18.57,0,0,0,2.76.69l.6,7.27Z"
				/>
				<path
					id="Pfad_3001"
					data-name="Pfad 3001"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M118.9,107.67c-1.94,1.09-15.94,3.53-20.83,4.77-5.46,1.38-13.79,3.68-18.77,6.91s-8.51,5.58-11.45,9.66-1.87,3.56-3.63,5.76-2.52,2.76-2.64,7.37a24,24,0,0,0,.71,8.05A70.7,70.7,0,0,1,64.41,158c.29,2.41.41,9.49,1,13.58s.77,7,3.52,7.08,14.91.29,20.42,0a64.47,64.47,0,0,1,8.9.08l1,0a54,54,0,0,0,7.42-.16c3-.39,7-.81,9.12-1l5.76-.48,3.53-.19c1.64-.1,7.57-.48,9.08-.62s5.28-.67,6.77-.87a14.58,14.58,0,0,1,4.22-.22,4.45,4.45,0,0,0,2.57.35,14.46,14.46,0,0,1-2-1.54s-.59-.39.51-.72a12.3,12.3,0,0,1,3.24-.56c1.56-.12,5.46,0,6.34,0,0,0,1.71.22.68-1.72a23,23,0,0,1-3.18-10.15c-.27-4.84-.34-10.55-.28-17.83,0,0,.6-13.35-1-18.43a15.65,15.65,0,0,0-6.46-10.53s-4.06-3.56-16.49-6q-1.8-.34-3.63-.51l-5-.45a5.46,5.46,0,0,0-2,.75"
				/>
				<path
					id="Pfad_3002"
					data-name="Pfad 3002"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M64.21,134.77a14.62,14.62,0,0,1,0,4.76c-.42,2.61.2,5.14,1.37,11.58A151.43,151.43,0,0,1,67.45,167c.16,2.45.94,10.23,2.27,11.48"
				/>
				<path
					id="Pfad_3003"
					data-name="Pfad 3003"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M64.7,162.74s-3.89.73-3.85,1.61.47,1.65.43,2.57-.51.84-.43,1.8-.09,1.84,0,2.27a3.39,3.39,0,0,0,.48,1.22,1.82,1.82,0,0,1,.23,1,9.1,9.1,0,0,1-.19,1.57c0,.31-.31.58.35.77a6.58,6.58,0,0,1,1.49.58,13.09,13.09,0,0,0,3.26.87"
				/>
				<path
					id="Pfad_3004"
					data-name="Pfad 3004"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M62.09,163.44s-1.14.84,0,1.22a18.57,18.57,0,0,0,2.76.69"
				/>
				<path
					id="Pfad_3005"
					data-name="Pfad 3005"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M62.15,164.67a3.62,3.62,0,0,0,0,1.67,29.55,29.55,0,0,1,.45,3.51"
				/>
				<path
					id="Pfad_3006"
					data-name="Pfad 3006"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M62.62,171.64a13.27,13.27,0,0,1-.27,4.07"
				/>
				<path
					id="Pfad_3007"
					data-name="Pfad 3007"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M62.15,169.85h.4a.75.75,0,0,1,.65.69,1.41,1.41,0,0,1,0,.2c0,.1-.06.17-.08.26s0,.2,0,.27a.65.65,0,0,1-.54.37h-.4"
				/>
				<path
					id="Pfad_3008"
					data-name="Pfad 3008"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M62.73,170.74c0,.5-.25.89-.55.89s-.55-.4-.55-.89.24-.89.55-.89S62.73,170.25,62.73,170.74Z"
				/>
				<path
					id="Pfad_3009"
					data-name="Pfad 3009"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M63.65,170.38h.4c.37,0,.66.4.66.89s-.29.89-.66.89h-.4"
				/>
				<path
					id="Pfad_3010"
					data-name="Pfad 3010"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M64.23,171.27c0,.5-.25.89-.55.89s-.55-.4-.55-.89.24-.89.55-.89S64.23,170.78,64.23,171.27Z"
				/>
				<path
					id="Pfad_3011"
					data-name="Pfad 3011"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M64.71,164.52s-2.29-.27-2.57-.39-.92-.63,2.56-1.39"
				/>
				<path
					id="Pfad_3012"
					data-name="Pfad 3012"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M148.46,172.79a12.41,12.41,0,0,0-2,.44c-.77.28-1.22.56-.81.84a6.28,6.28,0,0,0,2.8,1.8c1.43.23,17,2,20,2.22s17.67,1.2,22.67,1.16,14.88-.2,20-.88,7.8-1,10.16-2.35,1.58-3.79-1.79-4.19-15.12-1.63-19.63-2-13.61-1.11-18.25-1.08c0,0,.9,1-3.29,1.92s-6.38,2.23-23.53,2.15Z"
				/>
				<path
					id="Pfad_3013"
					data-name="Pfad 3013"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M181.5,167.91s-.32.94-4,1.41-7.5,1.26-21.34,1.2c0,0-9.57,1.32-11.88,1.73s-3.36.48-3.36,1.77v.69"
				/>
				<path
					id="Pfad_3014"
					data-name="Pfad 3014"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M145.66,174.07s-16.18,2.19-24.16,3.09c0,0,12.13-.6,15.73-1.14a43.64,43.64,0,0,1,7.56-.84,5.28,5.28,0,0,0,2.88.43A13.72,13.72,0,0,1,145.66,174.07Z"
				/>
				<path
					id="Pfad_3015"
					data-name="Pfad 3015"
					class="cls-6"
					d="M120.62,107.23s-1.19,0-.89-1.46,2-1.55,3.14-1.55,6.34.29,6.34,2.81c0,0,.22,1.13-1.43.89S120.62,107.23,120.62,107.23Z"
				/>
				<path
					id="Pfad_3016"
					data-name="Pfad 3016"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M118.9,107.67s-14,13-14.89,24a339,339,0,0,0-.83,47.16"
				/>
				<path
					id="Pfad_3017"
					data-name="Pfad 3017"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M124.45,107.67s-13.27,6-16.07,18.61-3.07,19.61-2.42,52.25"
				/>
				<path
					id="Pfad_3018"
					data-name="Pfad 3018"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M115.79,109.55s-14,11.71-14.71,24.5-1.71,41.45-.73,44.76"
				/>
				<path
					id="Pfad_3019"
					data-name="Pfad 3019"
					class="cls-7"
					d="M126,119s-1,.41-.92,1a1.14,1.14,0,0,0,.77,1.16,3,3,0,0,0,2.38.26,1.05,1.05,0,0,0,.48-1.43,1.27,1.27,0,0,0-.13-.19,2.17,2.17,0,0,0-1.54-.87A2.61,2.61,0,0,0,126,119Z"
				/>
				<path
					id="Pfad_3020"
					data-name="Pfad 3020"
					class="cls-7"
					d="M87.39,122.64s-1.46.55-1.82,1.37-.33,1.62.24,1.54a4,4,0,0,0,2.42-1.34C88.7,123.44,89.13,122.31,87.39,122.64Z"
				/>
				<path
					id="Pfad_3021"
					data-name="Pfad 3021"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M153.41,172.76c.69,0,.09-1.88.09-1.88-3.78-8.73-3.05-24.87-3.53-42.58s-21-20.14-21-20.14"
				/>
				<path
					id="Pfad_3022"
					data-name="Pfad 3022"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M141.72,112s13.37,3.55,13.37,16-1.59,35.61,2.93,42.58c0,0,.85,1.48.49,2.23"
				/>
				<path
					id="Pfad_3023"
					data-name="Pfad 3023"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M181.62,168.73s-1.26-7.78-1.87-13.64-2.15-21.4-3.9-26.38-3.9-11.36-13-14.11a125.62,125.62,0,0,0-19.95-4.27c-6-1-12-2-14.75-2.33"
				/>
				<path
					id="Pfad_3024"
					data-name="Pfad 3024"
					class="cls-7"
					d="M162,116.86c1,.62,1.59,1.38,1.37,1.7s-1.17.06-2.14-.56-1.58-1.39-1.37-1.7S161,116.24,162,116.86Z"
				/>
				<path
					id="Pfad_3025"
					data-name="Pfad 3025"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M197.2,169.55s1.69,2.89-18,2.89H159"
				/>
				<path
					id="Pfad_3026"
					data-name="Pfad 3026"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M201.57,170.08s1.68,3.19-20,3.19c-23,0-25.37-.89-25.37-.89"
				/>
				<path
					id="Pfad_3027"
					data-name="Pfad 3027"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M205.43,170.42s3.27,3.7-21,3.7c-25.66,0-30.37-1.36-30.37-1.36"
				/>
				<path
					id="Pfad_3028"
					data-name="Pfad 3028"
					fill="none"
					stroke="#47484a"
					stroke-width="0.3"
					d="M208.82,170.88s4.85,4.25-25,4.25c-25.25,0-33.25-2.33-33.25-2.33"
				/>
			</g>
			<g
				id="PRINT-AREA"
				transform="matrix(1, 0, 0, 1, 83.186623, 137.590347)"
				ref="print-area-wrapper"
				fill="none"
				stroke="#000"
				:opacity="currentStep === 'motive' ? 1 : 0"
				stroke-dasharray="4 4"
				stroke-width="0.4"
			>
				<rect ref="print-area" width="50" height="31.25" />
			</g>
			<g id="POSITIONEN" ref="position-wrapper" transform="matrix(1, 0, 0, 1, 113.431023, 152.099243)">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g>
		</g>
	</svg>
</template>

<script>
import { setPositions } from '../mockupHelpers'

export default {
	name: 'CapUnstructuredSnapbackLeft',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
	mounted() {
		setPositions(this, 0, true)
	},
}
</script>