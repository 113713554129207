<template>
	<svg
		width="100%"
		height="100%"
		viewBox="0 0 150 150"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		ref="svgElement"
	>
		<g id="HOODIE_BACK" ref="main-wrapper" transform="matrix(1, 0, 0, 1, -717.764709, -247.592056)">
			<g id="COLORS">
				<path
					id="Pfad_2031"
					data-name="Pfad 2031"
					d="M977.423,1005.455c-1.843.754-3.345,1.4-3.345,1.4s-1.472,1.047-5.866,2.152a23.4,23.4,0,0,0-7.29,2.991c-.787.471-.225,1.469.323,3.358s2.719,12.279,2.719,12.279c.883,4.513,4.005,21.883,4.005,21.883s.359,1.905.711,4.121l.064.922c.263,1.7.489,3.436.537,4.554a87.8,87.8,0,0,0,1.184,10.207,6.469,6.469,0,0,1,.293,1.831c-.581,4.5-.789,21.3-.837,25.228a31.835,31.835,0,0,0,1.106,7.957c.049.261.76,2.147,1.155,4.028s-.788,4.4-1.171,5.6a20.819,20.819,0,0,0-.679,4.081c-.121,1.308-.513,3.191-.7,5.914a36.506,36.506,0,0,0-.077,6.176c.155.158.252.89.816,1.834s3.685,1.67,6.406,2.089,10.051-.438,14.83-1.25c4.6-.783,9.569-.68,13.451-.995a63.818,63.818,0,0,1,11.165.655c1.891.216,5.95-.185,13.286.286a21.833,21.833,0,0,0,13.411-3.351c1.005-.732.5-2.145.342-2.565a4.852,4.852,0,0,1-.03-2.252,13.879,13.879,0,0,0-.3-4.5,24.024,24.024,0,0,1-.641-3.246,13.376,13.376,0,0,0-1.533-3.351,1.185,1.185,0,0,1,.072-1.464,26.917,26.917,0,0,0,2.3-3.874c.864-1.884-.155-8.428-.755-10.833a64.6,64.6,0,0,0-2.34-7.016c-.471-1.152-.683-2.878-.316-7.171s.943-12.351.943-12.613.116-4.972.111-8.858c0-1.39.149-2.515.155-3.433a8.912,8.912,0,0,1,.217-2.3l.034-.27.071-.13.263-1.674c.328-2.015.64-3.66.64-3.66s3.123-17.37,4.007-21.884c0,0,2.167-10.389,2.717-12.28s1.112-2.884.323-3.357a23.37,23.37,0,0,0-7.287-2.991c-4.4-1.1-5.868-2.151-5.868-2.151s-2.055-.871-4.28-1.749l-6.48-2.335"
					transform="translate(-213 -741)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_2032"
					data-name="Pfad 2032"
					d="M977.423,1005.455c-1.843.754-3.345,1.4-3.345,1.4s-1.472,1.047-5.866,2.152a23.4,23.4,0,0,0-7.29,2.991c-.787.471-.225,1.469.323,3.358s2.719,12.279,2.719,12.279c.883,4.513,4.005,21.883,4.005,21.883s.359,1.905.711,4.121l.064.922c.263,1.7.489,3.436.537,4.554a87.8,87.8,0,0,0,1.184,10.207,6.469,6.469,0,0,1,.293,1.831c-.581,4.5-.789,21.3-.837,25.228a31.835,31.835,0,0,0,1.106,7.957c.049.261.76,2.147,1.155,4.028s-.788,4.4-1.171,5.6a20.819,20.819,0,0,0-.679,4.081c-.121,1.308-.513,3.191-.7,5.914a36.506,36.506,0,0,0-.077,6.176c.155.158.252.89.816,1.834s3.685,1.67,6.406,2.089,10.051-.438,14.83-1.25c4.6-.783,9.569-.68,13.451-.995a63.818,63.818,0,0,1,11.165.655c1.891.216,5.95-.185,13.286.286a21.833,21.833,0,0,0,13.411-3.351c1.005-.732.5-2.145.342-2.565a4.852,4.852,0,0,1-.03-2.252,13.879,13.879,0,0,0-.3-4.5,24.024,24.024,0,0,1-.641-3.246,13.376,13.376,0,0,0-1.533-3.351,1.185,1.185,0,0,1,.072-1.464,26.917,26.917,0,0,0,2.3-3.874c.864-1.884-.155-8.428-.755-10.833a64.6,64.6,0,0,0-2.34-7.016c-.471-1.152-.683-2.878-.316-7.171s.943-12.351.943-12.613.116-4.972.111-8.858c0-1.39.149-2.515.155-3.433a8.912,8.912,0,0,1,.217-2.3l.034-.27.071-.13.263-1.674c.328-2.015.64-3.66.64-3.66s3.123-17.37,4.007-21.884c0,0,2.167-10.389,2.717-12.28s1.112-2.884.323-3.357a23.37,23.37,0,0,0-7.287-2.991c-4.4-1.1-5.868-2.151-5.868-2.151s-2.055-.871-4.28-1.749l-6.48-2.335"
					transform="translate(-213 -741)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_2033"
					data-name="Pfad 2033"
					d="M1067.612,1122.331s-1.024,6-1.6,6.391a21.282,21.282,0,0,1-4.8.239c-3.5-.08-8.715-.161-10.076-1.025,0,0-.6-.87-.725-4.1s-.2-6.8-.724-10.9-1.748-13.285-2.987-17.8l-2.712-11.891-.834-3.378-.883-11.1-.779-9a32.916,32.916,0,0,1-.353-4.262c.007-.881,2.15-11.1,3.938-21.253,1.955-11.1,4.47-20.382,4.509-20.867a15.968,15.968,0,0,1,1.4,1.838c.377.669,3.649,6.22,4.265,8.1s3.824,16.771,3.824,16.771l3.918,13.315,2.6,15.236.665,6.739,1.8,10.327.784,7.345.042,12.674-.492,10.329Z"
					transform="translate(-213 -741)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_2034"
					data-name="Pfad 2034"
					d="M968.738,1053.464l-.183,2.336-.795,7.283s-1,9.306-1.169,10.557-.283,6-.283,6-.342,1.478-.571,2.389-1.991,11.494-1.991,11.494l-1.2,11.354s-1.166,12.094-1.236,13.144,0,4.807,0,4.807a24.318,24.318,0,0,1-.449,4.7c-.448,1.734-12.282,1.587-13.679,1.587s-1.376-.923-1.376-.923c-.861-1.834-2.048-10.149-2.048-10.149l-.7-7.679s-.312-9.558-.368-11.235-.178-5.833-.1-8.453.9-9.667.9-9.667l.58-6.039.365-5.114,1.26-8.76,1.484-7.878,1.791-5.72s.952-3.243,1.321-4.551.872-3.926.872-3.926l1.158-5.836.99-4.673,1.085-4.415,1.062-2.489,2.4-4.515a11.469,11.469,0,0,1,2.63-4.356c.1.038,1.595,5.681,3.05,12.908C965.944,1037.556,968.738,1053.464,968.738,1053.464Z"
					transform="translate(-213 -741)"
					:fill="mainColor"
				/>
				<path
					id="Pfad_2035"
					data-name="Pfad 2035"
					d="M1025.284,1003.453c-.3-1.869-2.983-4.767-3.935-6.4s-1.251-4.317-3.829-4.26-10.5,1.208-12.861,1.249-6.748.49-9,.527-10.062-.968-12.067-.3-3.326,3.571-3.746,5.564a10.581,10.581,0,0,1-1.908,4.463c-1.621,1.8,1.093,4.131,1.534,4.71,3.318,4.35,4.231,9.3,7.994,12.384,7.081,5.8,9.759,9.688,15.218,9.39,5.907-.328,9.856-6.4,13.835-12.15,2.338-3.374,3.148-7.028,5.851-9.573C1023.917,1007.589,1025.483,1004.715,1025.284,1003.453Z"
					transform="translate(-213 -741)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2036"
					data-name="Pfad 2036"
					d="M1042.483,1119.481a22.259,22.259,0,0,1-12.985,3.066c-7.332-.473-11.389-.075-13.279-.284a63.163,63.163,0,0,0-11.159-.656c-3.88.313-8.842.208-13.444.989-4.776.815-12.1,1.672-14.821,1.253s-5.839-1.147-6.4-2.089a6.37,6.37,0,0,1-.429-.865l-.041.4c-.1.787-.2,1.675-.268,2.676a36.6,36.6,0,0,0-.078,6.169c.156.159.252.89.816,1.833s3.683,1.668,6.4,2.087,10.045-.437,14.821-1.249c4.6-.782,9.564-.679,13.444-.994a63.859,63.859,0,0,1,11.159.655c1.89.215,5.947-.185,13.279.285a21.829,21.829,0,0,0,13.4-3.348c1-.731.5-2.143.341-2.562a4.86,4.86,0,0,1-.03-2.25,13.852,13.852,0,0,0-.3-4.495c-.041-.172-.15-.637-.188-.8Z"
					transform="translate(-213 -741)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2037"
					data-name="Pfad 2037"
					d="M942.6,1090.789c.082-2.607.9-9.641.9-9.641l.583-6.019.364-5.1,1.266-8.734,1.489-7.86,1.8-5.7s.953-3.231,1.322-4.537.874-3.915.874-3.915l1.166-5.815.988-4.662.985-4.4,1.072-2.5,2.293-4.479a13.507,13.507,0,0,1,2.585-4.464l.218-.215c.079.8.7,2.393,1.095,3.985.936,3.8,2.076,9.309,2.4,11.129.489,2.79,1.009,5.2,1.389,7.3.892,4.928,1.439,8.033,1.736,9.612.536,2.842,1.626,9.7,1.626,9.7l-.111,1.539-.8,7.265s-1,9.278-1.169,10.526-.286,5.987-.286,5.987-.341,1.476-.569,2.383-2,11.462-2,11.462l-1.2,11.32s-1.17,12.059-1.241,13.11c-.028.4-.034,1.2-.032,2.028-2.382.926-15.3.558-17.184.5-.235-1.442-.388-2.505-.388-2.505l-.7-7.659s-.314-9.532-.367-11.205S942.52,1093.4,942.6,1090.789Z"
					transform="translate(-213 -741)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2038"
					data-name="Pfad 2038"
					d="M961.228,1120.085c0,1.339.031,2.766.031,2.766a24.27,24.27,0,0,1-.447,4.684c-.446,1.73-12.25,1.583-13.639,1.583s-1.373-.917-1.373-.917a46.077,46.077,0,0,1-1.659-7.617C946.013,1120.643,958.86,1121.011,961.228,1120.085Z"
					transform="translate(-213 -741)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2039"
					data-name="Pfad 2039"
					d="M1045.78,1030.058c1.718-9.314,3.621-15.714,3.81-16.67a20.365,20.365,0,0,1,1.592,2.074,80.149,80.149,0,0,1,4.232,8.124c.615,1.874,3.778,16.526,3.778,16.526l1.438,5.645,2.382,7.464,1.542,8.285,1.077,7.311.664,6.722,1.8,10.3.576,3.763.209,3.562.038,12.638-.032,3.854-.459,6.445-.391,4.326c-1.954.06-15.79.582-17.554-.434-.009-.005,0-.058-.023-.071-.1-2.16-.4-4.413-.721-6.932-.524-4.09-1.752-13.251-2.986-17.752l-2.711-11.859-.835-3.367-.88-11.068-.779-8.977a32.345,32.345,0,0,1-.353-4.249C1041.2,1054.7,1043.676,1041.464,1045.78,1030.058Z"
					transform="translate(-213 -741)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2040"
					data-name="Pfad 2040"
					d="M1025.284,1003.453c-.3-1.869-2.983-4.767-3.935-6.4s-1.251-4.317-3.829-4.26-10.5,1.208-12.861,1.249-6.748.49-9,.527-10.062-.968-12.067-.3-3.326,3.571-3.746,5.564a10.581,10.581,0,0,1-1.908,4.463c-1.621,1.8,1.093,4.131,1.534,4.71,3.318,4.35,4.231,9.3,7.994,12.384,7.081,5.8,9.759,9.688,15.218,9.39,5.907-.328,9.856-6.4,13.835-12.15,2.338-3.374,3.148-7.028,5.851-9.573C1023.917,1007.589,1025.483,1004.715,1025.284,1003.453Z"
					transform="translate(-213 -741)"
					:fill="secColor"
				/>
				<path
					id="Pfad_2041"
					data-name="Pfad 2041"
					d="M1067.884,1120.429l-.238,1.923s-1.021,5.976-1.593,6.374a21.527,21.527,0,0,1-4.8.235c-3.5-.082-8.708-.161-10.068-1.021,0,0-.6-.868-.725-4.086-.048-1.236.017-2.509-.044-3.857.022.013.058-.012.067-.007C1052.249,1121.006,1065.931,1120.488,1067.884,1120.429Z"
					transform="translate(-213 -741)"
					:fill="secColor"
				/>
			</g>
			<g id="LINES">
				<path
					id="Pfad_2042"
					data-name="Pfad 2042"
					d="M955.448,1089.065s4.78,2.6,9.048,1.424C964.5,1090.489,956.814,1089.562,955.448,1089.065Z"
					transform="translate(-213 -741)"
					fill="none"
				/>
				<path
					id="Pfad_2043"
					data-name="Pfad 2043"
					d="M956.642,1086.855s3.926,3.2,7.854,2.21Z"
					transform="translate(-213 -741)"
					fill="none"
				/>
				<path
					id="Pfad_2044"
					data-name="Pfad 2044"
					d="M955.448,1092.419s2.9.623,4.525,0l-4.525-.546Z"
					transform="translate(-213 -741)"
					fill="none"
				/>
				<path
					id="Pfad_2045"
					data-name="Pfad 2045"
					d="M959.659,1083.966s-4.781,1.788-9.048.981C950.611,1084.947,958.294,1084.308,959.659,1083.966Z"
					transform="translate(-213 -741)"
					fill="none"
				/>
				<path
					id="Pfad_2046"
					data-name="Pfad 2046"
					d="M958.463,1082.448s-3.926,2.2-7.852,1.518Z"
					transform="translate(-213 -741)"
					fill="none"
				/>
				<path
					id="Pfad_2047"
					data-name="Pfad 2047"
					d="M959.659,1086.272a14.91,14.91,0,0,1-4.523,0l4.523-.374Z"
					transform="translate(-213 -741)"
					fill="none"
				/>
				<!--<path
					id="Pfad_2048"
					data-name="Pfad 2048"
					d="M969.523,1115.209c.207-.123,1.073.215,1.073.215l.441-1.576.43-1.072.479-1.625s-1.179-.256-1.22-.136S969.277,1115.359,969.523,1115.209Z"
					transform="translate(-213 -741)"
					fill="#1a1818"
				/>-->
				<path
					id="Pfad_2049"
					data-name="Pfad 2049"
					d="M960.582,1012.475a22.356,22.356,0,0,1,7.062-2.828c4.419-1.1,5.9-2.151,5.9-2.151s1.758-.655,3.936-1.445"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.089"
					stroke-dasharray="0.708 0.354"
				/>
				<path
					id="Pfad_2050"
					data-name="Pfad 2050"
					d="M1049.509,1013.317a23.681,23.681,0,0,0-7.325-2.989c-4.419-1.1-5.9-2.149-5.9-2.149s-7.319-2.9-10.983-4.165"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.089"
					stroke-dasharray="0.708 0.354"
				/>
				<path
					id="Pfad_2051"
					data-name="Pfad 2051"
					d="M1004.435,1083.006c-.164.593-.166,1.409-.391,2.044a38.658,38.658,0,0,1-1.649,3.532,12.9,12.9,0,0,0-1.384,3.965,45.632,45.632,0,0,1-.878,6.006,14.787,14.787,0,0,1-1.464,3.7c-.525.877-1.129,1.71-1.62,2.623a36.127,36.127,0,0,1-3.3,5.17c-.578.741-1.347,1.329-1.965,2.048-.35.4-1.21,1.18-1.246,1.749-.068,1.072.938-.255,1.181-.576a17.5,17.5,0,0,1,1.6-1.776c-.01.246.136.308.2.471a3.055,3.055,0,0,0,1.157-1.617,11.131,11.131,0,0,1,1.2-2.185c.985-1.555,1.636-3.241,2.787-4.721,2.166-2.787,2.114-6.222,3.032-9.4a14.39,14.39,0,0,0,.549-3.2,4.889,4.889,0,0,1,.1-1.4,8.59,8.59,0,0,1,.926-1.333,24.488,24.488,0,0,0,.9-2.509,3.576,3.576,0,0,0,.383-1.121c.053-.412-.223-.969.156-1.259"
					transform="translate(-213 -741)"
					fill="none"
				/>
				<path id="Pfad_2052" data-name="Pfad 2052" d="M970.454,1116.318" transform="translate(-213 -741)" fill="#1a1818" />
				<path id="Pfad_2053" data-name="Pfad 2053" d="M988.036,1003.69" transform="translate(-213 -741)" fill="#1a1818" />
				<path
					id="Pfad_2054"
					data-name="Pfad 2054"
					d="M1041.112,1055.8s1.779,18.6,1.859,20.25.246,3.82.246,3.82c3.339,13.274,5.374,24.294,5.374,24.294,1.615,11.533,1.842,15.274,1.842,15.274s-.213,7.372.766,8.5,14.169,1.214,14.874.786,1.548-6.173,1.94-8.461c.4-2.345.694-8.114.856-10.457s.02-14.036.02-14.036c.361-8.721-2.3-15.117-2.731-22.187s-3.146-20.36-3.146-20.36c-.231-.537-3.356-10.356-3.739-12.413s-2.856-14.43-3.7-16.811a54.191,54.191,0,0,0-3.353-6.576,12.936,12.936,0,0,0-2.706-4.1"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.199"
				/>
				<path
					id="Pfad_2055"
					data-name="Pfad 2055"
					d="M968.738,1054.639s-2.224,19.485-2.263,21.137-.14,3.822-.14,3.822a181.932,181.932,0,0,0-3.639,24.394c-1.288,11.566-1.418,15.311-1.418,15.311s.391,7.366-.534,8.511-13.773,1.561-14.469,1.15-1.659-6.135-2.1-8.414c-.448-2.333-.877-8.1-1.094-10.435s-.364-14.03-.364-14.03c-.569-8.712,1.2-15.151,1.447-22.234.244-7.066,3.056-20.63,3.056-20.63.224-.537,3.269-10.356,3.644-12.413s2.781-14.43,3.609-16.811a53.739,53.739,0,0,1,3.268-6.576c.827-1.643,1.625-3.652,2.793-4.681"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.196"
				/>
				<path
					id="Pfad_2056"
					data-name="Pfad 2056"
					d="M1042.747,1119.394a22,22,0,0,1-13.277,3.259c-7.335-.473-11.4-.073-13.288-.283a63.181,63.181,0,0,0-11.166-.659c-3.881.317-8.845.211-13.453.994-4.78.815-12.107,1.675-14.828,1.256s-5.842-1.151-6.408-2.1a6.158,6.158,0,0,1-.429-.868"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.179"
				/>
				<path
					id="Pfad_2057"
					data-name="Pfad 2057"
					d="M1042.694,1119.087a22,22,0,0,1-13.224,3.221c-7.335-.47-11.4-.068-13.288-.28a63.276,63.276,0,0,0-11.166-.659c-3.881.318-8.845.211-13.453.995-4.78.815-12.107,1.674-14.828,1.255s-5.842-1.15-6.408-2.095a6.051,6.051,0,0,1-.429-.867"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.089"
					stroke-dasharray="0.708 0.354"
				/>
				<path
					id="Pfad_2058"
					data-name="Pfad 2058"
					d="M1042.884,1119.639a21.836,21.836,0,0,1-13.414,3.355c-7.335-.473-11.4-.072-13.288-.284a63.226,63.226,0,0,0-11.166-.657c-3.881.317-8.845.21-13.453.993-4.78.815-12.107,1.676-14.828,1.255s-5.842-1.149-6.408-2.095a6.147,6.147,0,0,1-.429-.866"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.089"
					stroke-dasharray="0.708 0.354"
				/>
				<path
					id="Pfad_2059"
					data-name="Pfad 2059"
					d="M1067.943,1120.18s-15.905.535-17.373-.605"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.089"
					stroke-dasharray="0.708 0.354"
				/>
				<path
					id="Pfad_2060"
					data-name="Pfad 2060"
					d="M1067.943,1120.864s-15.905.535-17.373-.607"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.089"
					stroke-dasharray="0.708 0.354"
				/>
				<line
					id="Linie_391"
					data-name="Linie 391"
					x1="0.275"
					y2="8.259"
					transform="translate(845.65 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_392"
					data-name="Linie 392"
					x1="0.275"
					y2="8.123"
					transform="translate(844.966 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_393"
					data-name="Linie 393"
					x1="0.276"
					y2="8.123"
					transform="translate(844.282 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_394"
					data-name="Linie 394"
					x1="0.276"
					y2="8.123"
					transform="translate(843.6 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_395"
					data-name="Linie 395"
					x1="0.278"
					y2="8.123"
					transform="translate(842.915 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_396"
					data-name="Linie 396"
					x1="0.278"
					y2="8.123"
					transform="translate(842.232 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_397"
					data-name="Linie 397"
					x1="0.276"
					y2="8.123"
					transform="translate(841.55 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_398"
					data-name="Linie 398"
					x1="0.311"
					y2="8.129"
					transform="translate(840.834 379.46)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_399"
					data-name="Linie 399"
					x1="0.274"
					y2="8.046"
					transform="translate(840.183 379.46)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_400"
					data-name="Linie 400"
					x1="0.263"
					y2="8.085"
					transform="translate(839.517 379.306)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_401"
					data-name="Linie 401"
					x1="0.259"
					y2="7.926"
					transform="translate(838.838 379.306)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_402"
					data-name="Linie 402"
					x1="0.172"
					y2="7.631"
					transform="translate(838.242 379.306)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_403"
					data-name="Linie 403"
					x1="0.189"
					y2="4.307"
					transform="translate(837.542 378.965)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_404"
					data-name="Linie 404"
					y2="1.161"
					transform="translate(854.801 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_405"
					data-name="Linie 405"
					x1="0.193"
					y2="5.669"
					transform="translate(853.927 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_406"
					data-name="Linie 406"
					x1="0.275"
					y2="8.123"
					transform="translate(853.158 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_407"
					data-name="Linie 407"
					x1="0.277"
					y2="8.123"
					transform="translate(852.475 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_408"
					data-name="Linie 408"
					x1="0.275"
					y2="8.123"
					transform="translate(851.793 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_409"
					data-name="Linie 409"
					x1="0.275"
					y2="8.276"
					transform="translate(851.111 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_410"
					data-name="Linie 410"
					x1="0.277"
					y2="8.324"
					transform="translate(850.427 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_411"
					data-name="Linie 411"
					x1="0.278"
					y2="8.276"
					transform="translate(849.744 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_412"
					data-name="Linie 412"
					x1="0.274"
					y2="8.324"
					transform="translate(849.062 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_413"
					data-name="Linie 413"
					x1="0.338"
					y2="8.312"
					transform="translate(848.319 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_414"
					data-name="Linie 414"
					x1="0.279"
					y2="8.324"
					transform="translate(847.695 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_415"
					data-name="Linie 415"
					x1="0.275"
					y2="8.295"
					transform="translate(847.015 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_416"
					data-name="Linie 416"
					x1="0.277"
					y2="8.264"
					transform="translate(846.331 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2061"
					data-name="Pfad 2061"
					d="M1012.191,1121.917c.1,3.426.257,6.85.347,10.277"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2062"
					data-name="Pfad 2062"
					d="M1011.533,1121.855c.1,3.407.245,6.81.337,10.217"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2063"
					data-name="Pfad 2063"
					d="M1010.881,1121.9c.093,3.391.239,6.782.319,10.175"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2064"
					data-name="Pfad 2064"
					d="M1010.226,1121.751c.087,3.425.226,6.843.3,10.265"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2065"
					data-name="Pfad 2065"
					d="M1009.572,1121.711c.083,3.422.215,6.84.292,10.262"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2066"
					data-name="Pfad 2066"
					d="M1008.919,1121.679c.077,3.422.2,6.836.278,10.255"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2067"
					data-name="Pfad 2067"
					d="M1008.265,1121.654c.074,3.418.2,6.834.264,10.251"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2068"
					data-name="Pfad 2068"
					d="M1007.6,1121.64c.062,3.414.17,6.829.227,10.248"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2069"
					data-name="Pfad 2069"
					d="M1006.957,1121.635c.061,3.416.173,6.829.235,10.243"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2070"
					data-name="Pfad 2070"
					d="M1006.309,1121.645c.057,3.413.165,6.824.227,10.237"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2071"
					data-name="Pfad 2071"
					d="M1005.658,1121.67c.052,3.411.156,6.822.215,10.235"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2072"
					data-name="Pfad 2072"
					d="M1005.021,1121.711c.068,3.41.185,6.815.262,10.223"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2073"
					data-name="Pfad 2073"
					d="M1014.159,1122.127c.12,3.432.29,6.857.39,10.288"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2074"
					data-name="Pfad 2074"
					d="M1013.5,1122.053c.114,3.43.282,6.854.375,10.283"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2075"
					data-name="Pfad 2075"
					d="M1012.845,1121.981c.108,3.428.269,6.853.364,10.281"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2076"
					data-name="Pfad 2076"
					d="M1022.048,1122.41c.168,3.414.4,6.826.541,10.242"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2077"
					data-name="Pfad 2077"
					d="M1021.387,1122.41c.167,3.414.395,6.824.534,10.242"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2078"
					data-name="Pfad 2078"
					d="M1020.729,1122.412c.162,3.416.386,6.824.519,10.24"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2079"
					data-name="Pfad 2079"
					d="M1020.073,1122.42c.158,3.414.376,6.82.507,10.238"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2080"
					data-name="Pfad 2080"
					d="M1019.416,1122.427c.154,3.415.366,6.819.5,10.235"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2081"
					data-name="Pfad 2081"
					d="M1018.758,1122.432c.15,3.412.359,6.824.483,10.238"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2082"
					data-name="Pfad 2082"
					d="M1018.1,1122.435c.145,3.416.349,6.827.468,10.241"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2083"
					data-name="Pfad 2083"
					d="M1017.438,1122.432c.131,3.419.323,6.83.436,10.246"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2084"
					data-name="Pfad 2084"
					d="M1016.782,1122.412c.138,3.422.334,6.838.45,10.259"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2085"
					data-name="Pfad 2085"
					d="M1016.133,1122.36c.133,3.432.323,6.854.441,10.286"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2086"
					data-name="Pfad 2086"
					d="M1015.476,1122.285c.123,3.431.315,6.86.433,10.294"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2087"
					data-name="Pfad 2087"
					d="M1014.835,1122.206c.139,3.434.345,6.864.486,10.3"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2088"
					data-name="Pfad 2088"
					d="M1024.027,1122.427c.179,3.419.428,6.835.577,10.257"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2089"
					data-name="Pfad 2089"
					d="M1023.365,1122.42c.178,3.415.421,6.828.566,10.25"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2090"
					data-name="Pfad 2090"
					d="M1022.705,1122.412c.176,3.419.411,6.828.555,10.249"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2091"
					data-name="Pfad 2091"
					d="M1032,1122.676c.219,3.4.513,6.8.688,10.2"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2092"
					data-name="Pfad 2092"
					d="M1031.333,1122.7c.217,3.41.51,6.812.685,10.223"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2093"
					data-name="Pfad 2093"
					d="M1030.667,1122.7c.216,3.416.507,6.825.677,10.242"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2094"
					data-name="Pfad 2094"
					d="M1030,1122.681c.213,3.419.5,6.834.669,10.26"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2095"
					data-name="Pfad 2095"
					d="M1029.334,1122.643c.212,3.427.495,6.846.663,10.279"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2096"
					data-name="Pfad 2096"
					d="M1028.668,1122.6c.207,3.427.487,6.849.654,10.282"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2097"
					data-name="Pfad 2097"
					d="M1028,1122.566c.2,3.427.478,6.848.643,10.279"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2098"
					data-name="Pfad 2098"
					d="M1027.328,1122.535c.19,3.424.455,6.841.616,10.27"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2099"
					data-name="Pfad 2099"
					d="M1026.666,1122.5c.195,3.427.466,6.844.633,10.272"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2100"
					data-name="Pfad 2100"
					d="M1026.011,1122.48c.19,3.422.456,6.842.626,10.266"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2101"
					data-name="Pfad 2101"
					d="M1025.349,1122.461c.186,3.422.448,6.834.621,10.262"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2102"
					data-name="Pfad 2102"
					d="M1024.7,1122.427c.2,3.427.486,6.846.679,10.277"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2103"
					data-name="Pfad 2103"
					d="M1033.993,1122.5c.225,3.383.526,6.754.711,10.143"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2104"
					data-name="Pfad 2104"
					d="M1033.322,1122.472c.222,3.428.529,6.843.709,10.271"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2105"
					data-name="Pfad 2105"
					d="M1032.665,1122.639c.221,3.4.519,6.782.695,10.183"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2106"
					data-name="Pfad 2106"
					d="M1041.909,1119.914c.235,4.415.556,4.924.748,9.858"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2107"
					data-name="Pfad 2107"
					d="M1041.25,1120.27c.241,4.107.609,5.049.807,9.8"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2108"
					data-name="Pfad 2108"
					d="M1040.594,1120.6c.245,3.905.616,5.211.808,9.838"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2109"
					data-name="Pfad 2109"
					d="M1039.939,1120.889c.247,3.757.62,5.361.808,9.876"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2110"
					data-name="Pfad 2110"
					d="M1039.28,1121.156c.252,3.646.623,5.5.807,9.911"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2111"
					data-name="Pfad 2111"
					d="M1038.622,1121.4c.255,3.561.625,5.616.806,9.938"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2112"
					data-name="Pfad 2112"
					d="M1037.965,1121.62c.257,3.5.622,5.731.8,9.975"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2113"
					data-name="Pfad 2113"
					d="M1037.3,1121.823c.228,3.406.571,6.494.773,10.006"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2114"
					data-name="Pfad 2114"
					d="M1036.64,1122c.24,3.4.591,6.486.8,10.026"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2115"
					data-name="Pfad 2115"
					d="M1035.988,1122.152c.241,3.386.59,6.484.8,10.051"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2116"
					data-name="Pfad 2116"
					d="M1035.329,1122.288c.241,3.379.592,6.475.8,10.076"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2117"
					data-name="Pfad 2117"
					d="M1034.687,1122.4c.263,3.359.634,6.445.865,10.086"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2118"
					data-name="Pfad 2118"
					d="M1042.566,1119.514c.232,4.751.459,4.515.659,9.595"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2119"
					data-name="Pfad 2119"
					d="M972.671,1123.095c-.124,3.373-.263,6.748-.377,10.121"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2120"
					data-name="Pfad 2120"
					d="M972.006,1122.877c-.126,3.365-.267,6.728-.384,10.093"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2121"
					data-name="Pfad 2121"
					d="M971.345,1122.61c-.128,3.344-.27,6.692-.392,10.042"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2122"
					data-name="Pfad 2122"
					d="M970.683,1122.232c-.124,3.272-.266,6.549-.385,9.821"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2123"
					data-name="Pfad 2123"
					d="M970.043,1121.329c-.116,3.1-.251,6.2-.372,9.292"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2124"
					data-name="Pfad 2124"
					d="M974.672,1123.5c-.12,3.416-.25,6.835-.36,10.251"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2125"
					data-name="Pfad 2125"
					d="M974,1123.444c-.123,3.384-.255,6.769-.368,10.154"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2126"
					data-name="Pfad 2126"
					d="M973.337,1123.281c-.123,3.38-.257,6.759-.371,10.141"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2127"
					data-name="Pfad 2127"
					d="M982.67,1123.737q-.13,5.2-.264,10.387"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2128"
					data-name="Pfad 2128"
					d="M982,1123.916q-.135,5.132-.274,10.263"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2129"
					data-name="Pfad 2129"
					d="M981.334,1123.964q-.139,5.132-.281,10.26"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2130"
					data-name="Pfad 2130"
					d="M980.669,1124.005q-.145,5.13-.295,10.259"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2131"
					data-name="Pfad 2131"
					d="M980,1124.038q-.152,5.125-.3,10.256"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2132"
					data-name="Pfad 2132"
					d="M979.336,1124.061q-.157,5.124-.312,10.248"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2133"
					data-name="Pfad 2133"
					d="M978.668,1123.993c-.105,3.439-.218,6.88-.319,10.319"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2134"
					data-name="Pfad 2134"
					d="M977.988,1124.061q-.181,5.149-.356,10.3"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2135"
					data-name="Pfad 2135"
					d="M977.326,1124.028q-.169,5.121-.333,10.243"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2136"
					data-name="Pfad 2136"
					d="M976.665,1123.952c-.112,3.4-.231,6.8-.337,10.2"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2137"
					data-name="Pfad 2137"
					d="M976,1123.844c-.114,3.375-.234,6.754-.34,10.133"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2138"
					data-name="Pfad 2138"
					d="M975.36,1123.726c-.092,3.311-.332,6.849-.42,10.161"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2139"
					data-name="Pfad 2139"
					d="M984.662,1123.663q-.115,5.133-.232,10.269"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2140"
					data-name="Pfad 2140"
					d="M984,1123.735q-.12,5.133-.244,10.267"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2141"
					data-name="Pfad 2141"
					d="M983.334,1123.8q-.126,5.138-.254,10.271"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2142"
					data-name="Pfad 2142"
					d="M992.586,1122.546c-.029,3.413-.041,6.824-.079,10.237"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2143"
					data-name="Pfad 2143"
					d="M991.931,1122.645c-.035,3.421-.054,6.842-.1,10.259"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2144"
					data-name="Pfad 2144"
					d="M991.272,1122.755c-.038,3.394-.061,6.787-.111,10.182"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2145"
					data-name="Pfad 2145"
					d="M990.615,1122.862c-.043,3.43-.074,6.851-.125,10.278"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2146"
					data-name="Pfad 2146"
					d="M989.953,1122.965c-.046,3.422-.08,6.844-.137,10.266"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2147"
					data-name="Pfad 2147"
					d="M989.294,1123.068c-.049,3.42-.091,6.845-.149,10.267"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2148"
					data-name="Pfad 2148"
					d="M988.633,1123.161c-.053,3.421-.1,6.837-.163,10.256"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2149"
					data-name="Pfad 2149"
					d="M987.96,1123.258c-.065,3.425-.122,6.847-.2,10.267"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2150"
					data-name="Pfad 2150"
					d="M987.3,1123.345c-.061,3.4-.113,6.805-.181,10.208"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2151"
					data-name="Pfad 2151"
					d="M986.649,1123.429c-.065,3.454-.122,6.909-.194,10.361"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2152"
					data-name="Pfad 2152"
					d="M985.987,1123.511c-.067,3.382-.127,6.761-.2,10.141"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2153"
					data-name="Pfad 2153"
					d="M985.351,1123.527c-.05,3.38-.1,6.941-.151,10.319"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2154"
					data-name="Pfad 2154"
					d="M994.557,1122.311c-.019,3.409-.017,6.82-.042,10.228"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2155"
					data-name="Pfad 2155"
					d="M993.9,1122.38c-.023,3.412-.027,6.817-.059,10.224"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2156"
					data-name="Pfad 2156"
					d="M993.244,1122.461c-.025,3.413-.033,6.832-.07,10.248"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2157"
					data-name="Pfad 2157"
					d="M1002.4,1121.869c.034,3.411.1,6.822.132,10.235"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2158"
					data-name="Pfad 2158"
					d="M1001.745,1121.9c.029,3.415.093,6.827.117,10.239"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2159"
					data-name="Pfad 2159"
					d="M1001.092,1121.923c.026,3.414.085,6.826.1,10.238"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2160"
					data-name="Pfad 2160"
					d="M1000.442,1121.951c.017,3.413.072,6.826.083,10.238"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2161"
					data-name="Pfad 2161"
					d="M999.786,1121.979c.018,3.413.064,6.825.073,10.238"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2162"
					data-name="Pfad 2162"
					d="M999.134,1122.008c.012,3.413.054,6.823.057,10.238"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2163"
					data-name="Pfad 2163"
					d="M998.483,1122.034c.006,3.416.042,6.831.042,10.241"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2164"
					data-name="Pfad 2164"
					d="M997.819,1122.07c0,3.413.016,6.827.006,10.241"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2165"
					data-name="Pfad 2165"
					d="M997.168,1122.11c0,3.412.024,6.824.017,10.239"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2166"
					data-name="Pfad 2166"
					d="M996.518,1122.152c-.005,3.411.017,6.824.007,10.24"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2167"
					data-name="Pfad 2167"
					d="M995.865,1122.2c-.009,3.414.008,6.828,0,10.242"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2168"
					data-name="Pfad 2168"
					d="M995.228,1122.248c.008,3.41.039,6.819.042,10.229"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2169"
					data-name="Pfad 2169"
					d="M1004.356,1121.761c.047,3.411.134,6.821.173,10.229"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2170"
					data-name="Pfad 2170"
					d="M1003.7,1121.8c.045,3.408.125,6.819.159,10.231"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2171"
					data-name="Pfad 2171"
					d="M1003.051,1121.836c.038,3.412.114,6.824.145,10.236"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2172"
					data-name="Pfad 2172"
					d="M1066.37,1128.368s-.052.427-1.077.492a73.644,73.644,0,0,1-13.2-.492,1.6,1.6,0,0,1-1.141-1.046s-.554-1-.47-4.374.014-3.065.036-3.053c.044.027.1.059.123.07,1.849,1.08,17.3.556,17.3.556S1067.107,1127.081,1066.37,1128.368Z"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.233"
				/>
				<path
					id="Pfad_2173"
					data-name="Pfad 2173"
					d="M944.31,1120.18s15.583.535,17.018-.605"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.089"
					stroke-dasharray="0.708 0.354"
				/>
				<path
					id="Pfad_2174"
					data-name="Pfad 2174"
					d="M944.31,1120.864s15.583.535,17.018-.607"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.089"
					stroke-dasharray="0.708 0.354"
				/>
				<line
					id="Linie_417"
					data-name="Linie 417"
					x2="0.272"
					y2="8.259"
					transform="translate(740.143 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_418"
					data-name="Linie 418"
					x2="0.273"
					y2="8.123"
					transform="translate(740.811 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_419"
					data-name="Linie 419"
					x2="0.271"
					y2="8.123"
					transform="translate(741.482 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_420"
					data-name="Linie 420"
					x2="0.272"
					y2="8.123"
					transform="translate(742.15 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_421"
					data-name="Linie 421"
					x2="0.271"
					y2="8.123"
					transform="translate(742.82 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_422"
					data-name="Linie 422"
					x2="0.271"
					y2="8.123"
					transform="translate(743.487 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_423"
					data-name="Linie 423"
					x2="0.271"
					y2="8.123"
					transform="translate(744.157 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_424"
					data-name="Linie 424"
					x2="0.301"
					y2="8.129"
					transform="translate(744.827 379.46)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_425"
					data-name="Linie 425"
					x2="0.268"
					y2="8.046"
					transform="translate(745.499 379.46)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_426"
					data-name="Linie 426"
					x2="0.259"
					y2="8.085"
					transform="translate(746.163 379.306)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_427"
					data-name="Linie 427"
					x2="0.255"
					y2="7.926"
					transform="translate(746.831 379.306)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_428"
					data-name="Linie 428"
					x2="0.167"
					y2="7.631"
					transform="translate(747.5 379.306)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_429"
					data-name="Linie 429"
					x2="0.185"
					y2="4.307"
					transform="translate(748.17 378.965)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_430"
					data-name="Linie 430"
					y2="1.161"
					transform="translate(731.449 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_431"
					data-name="Linie 431"
					x2="0.19"
					y2="5.669"
					transform="translate(732.117 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_432"
					data-name="Linie 432"
					x2="0.271"
					y2="8.123"
					transform="translate(732.787 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_433"
					data-name="Linie 433"
					x2="0.272"
					y2="8.123"
					transform="translate(733.455 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_434"
					data-name="Linie 434"
					x2="0.274"
					y2="8.123"
					transform="translate(734.123 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_435"
					data-name="Linie 435"
					x2="0.272"
					y2="8.276"
					transform="translate(734.794 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_436"
					data-name="Linie 436"
					x2="0.272"
					y2="8.324"
					transform="translate(735.462 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_437"
					data-name="Linie 437"
					x2="0.272"
					y2="8.276"
					transform="translate(736.13 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_438"
					data-name="Linie 438"
					x2="0.272"
					y2="8.324"
					transform="translate(736.798 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_439"
					data-name="Linie 439"
					x2="0.329"
					y2="8.312"
					transform="translate(737.469 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_440"
					data-name="Linie 440"
					x2="0.269"
					y2="8.324"
					transform="translate(738.139 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_441"
					data-name="Linie 441"
					x2="0.272"
					y2="8.295"
					transform="translate(738.805 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<line
					id="Linie_442"
					data-name="Linie 442"
					x2="0.272"
					y2="8.264"
					transform="translate(739.475 379.649)"
					fill="none"
					stroke="#464749"
					stroke-width="0.126"
				/>
				<path
					id="Pfad_2175"
					data-name="Pfad 2175"
					d="M945.85,1128.368s.05.427,1.056.492a70.65,70.65,0,0,0,12.925-.492,1.579,1.579,0,0,0,1.119-1.046s.543-1,.46-4.374-.082-3.032-.082-3.032c-1.435,1.141-17.018.6-17.018.6S945.131,1127.081,945.85,1128.368Z"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.233"
				/>
				<path
					id="Pfad_2176"
					data-name="Pfad 2176"
					d="M1025.284,1003.453c-.3-1.869-2.983-4.767-3.935-6.4s-1.251-4.317-3.829-4.26-10.5,1.208-12.861,1.249-6.748.49-9,.527-10.062-.968-12.067-.3-3.326,3.571-3.746,5.564a10.581,10.581,0,0,1-1.908,4.463c-1.621,1.8,1.093,4.131,1.534,4.71,3.318,4.35,4.231,9.3,7.994,12.384,7.081,5.8,9.759,9.688,15.218,9.39,5.907-.328,9.856-6.4,13.835-12.15,2.338-3.374,3.148-7.028,5.851-9.573C1023.917,1007.589,1025.483,1004.715,1025.284,1003.453Z"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.361"
				/>
				<path
					id="Pfad_2177"
					data-name="Pfad 2177"
					d="M1002.68,1030.776s-3.551-31.01-1.854-36.523"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.179"
				/>
				<path
					id="Pfad_2178"
					data-name="Pfad 2178"
					d="M1003.022,1030.776s-3.551-31.01-1.856-36.523"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.084"
					stroke-dasharray="0.672 0.336"
				/>
				<path
					id="Pfad_2179"
					data-name="Pfad 2179"
					d="M1002.338,1030.776s-3.549-31.01-1.854-36.523"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#5a5b5e"
					stroke-width="0.084"
					stroke-dasharray="0.672 0.336"
				/>
				<path
					id="Pfad_2180"
					data-name="Pfad 2180"
					d="M977.423,1005.455c-1.843.754-3.345,1.4-3.345,1.4s-1.472,1.047-5.866,2.152a23.4,23.4,0,0,0-7.29,2.991c-.787.471-.225,1.469.323,3.358s2.719,12.279,2.719,12.279c.883,4.513,4.005,21.883,4.005,21.883s.359,1.905.711,4.121l.064.922c.263,1.7.489,3.436.537,4.554a87.8,87.8,0,0,0,1.184,10.207,6.469,6.469,0,0,1,.293,1.831c-.581,4.5-.789,21.3-.837,25.228a31.835,31.835,0,0,0,1.106,7.957c.049.261.76,2.147,1.155,4.028s-.788,4.4-1.171,5.6a20.819,20.819,0,0,0-.679,4.081c-.121,1.308-.513,3.191-.7,5.914a36.506,36.506,0,0,0-.077,6.176c.155.158.252.89.816,1.834s3.685,1.67,6.406,2.089,10.051-.438,14.83-1.25c4.6-.783,9.569-.68,13.451-.995a63.818,63.818,0,0,1,11.165.655c1.891.216,5.95-.185,13.286.286a21.833,21.833,0,0,0,13.411-3.351c1.005-.732.5-2.145.342-2.565a4.852,4.852,0,0,1-.03-2.252,13.879,13.879,0,0,0-.3-4.5,24.024,24.024,0,0,1-.641-3.246,13.376,13.376,0,0,0-1.533-3.351,1.185,1.185,0,0,1,.072-1.464,26.917,26.917,0,0,0,2.3-3.874c.864-1.884-.155-8.428-.755-10.833a64.6,64.6,0,0,0-2.34-7.016c-.471-1.152-.683-2.878-.316-7.171s.943-12.351.943-12.613.116-4.972.111-8.858c0-1.39.149-2.515.155-3.433a8.912,8.912,0,0,1,.217-2.3l.034-.27.071-.13.263-1.674c.328-2.015.64-3.66.64-3.66s3.123-17.37,4.007-21.884c0,0,2.167-10.389,2.717-12.28s1.112-2.884.323-3.357a23.37,23.37,0,0,0-7.287-2.991c-4.4-1.1-5.868-2.151-5.868-2.151s-2.055-.871-4.28-1.749l-6.48-2.335"
					transform="translate(-213 -741)"
					fill="none"
					stroke="#464749"
					stroke-width="0.199"
				/>
			</g>
			<g id="SHADING">
				<path
					id="Pfad_2181"
					data-name="Pfad 2181"
					d="M1062.5,1088.462s-6.966,1.316-9.072,2.267-2.361,1.291-3.82,1.52a23.8,23.8,0,0,1-2.492.266l.141,1.108s4.843-.5,7.057-1.768S1056.993,1089.555,1062.5,1088.462Z"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2182"
					data-name="Pfad 2182"
					d="M1054.491,1095.753s-4.388,1.407-7.025.953l.1,1.379S1053.628,1097.476,1054.491,1095.753Z"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2183"
					data-name="Pfad 2183"
					d="M949.2,1077.511s8.062,5.454,16.939,3.012C966.137,1080.523,952.035,1078.544,949.2,1077.511Z"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2184"
					data-name="Pfad 2184"
					d="M951.683,1072.915s6.584,6.487,14.746,4.423Z"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2185"
					data-name="Pfad 2185"
					d="M949.2,1084.485s6.032,1.3,9.405,0l-9.405-1.132Z"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2186"
					data-name="Pfad 2186"
					d="M945.372,1118.675s.15,5.971,6.124,7.316c0,0-4.183-.3-6.124-2.539S945.372,1118.675,945.372,1118.675Z"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2187"
					data-name="Pfad 2187"
					d="M1047.955,1022.3s-1.1,3.879-1.746,5.347c0,0-.093-1.1-.19-1.574s-.2,3.621-.53,4.826A42.487,42.487,0,0,0,1047.955,1022.3Z"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2188"
					data-name="Pfad 2188"
					d="M1045.59,1030.375c.545.188.905-.64,1.117-1.033-.1.487-.349.957-.434,1.444.3-.06.512-.358.778-.51-.112.4-1.289,1.332-.8,1.71-.7.2-.429,1.1.094.7.032.342-.421.891-.532,1.24a1.913,1.913,0,0,0-.181,1.265c-1.025.362-1.768,2.165-2.089,3.09s-1.128,2.3-.964,3.256c.45-.206.8-.994,1.07-1.412a12.232,12.232,0,0,1,1.281-1.608,9.32,9.32,0,0,0,1.807-2.987c-.082,1.221-1.093,2.412-1.682,3.443a10.051,10.051,0,0,0-1.5,3.391,19.905,19.905,0,0,0,1.551-2.174,21.609,21.609,0,0,0,1.653-2.423c1.264-2.338,1.834-5.044,2.887-7.5.26-.6.56-1.17.8-1.767-.3,1.053-.448,2.1-.841,3.129-.4,1.067-.794,2.119-1.141,3.2a22.123,22.123,0,0,1-2.884,5.232c-1.217,1.823-2.607,3.813-3.068,5.974.322-.377.48-.9.9-1.185-.073.748-.843,1.366-.744,2.191,1.137-.839,1.56-2.614,2.7-3.5-.294,1.1-1.28,2.029-1.825,3.037a10.92,10.92,0,0,0-1.125,2.813c.483-.589.822-1.3,1.274-1.925.524-.726,1.1-1.434,1.625-2.145a10.376,10.376,0,0,0,1.444-2.31,9.942,9.942,0,0,1,.965-2.5,12.832,12.832,0,0,1-.937,2.409,14.144,14.144,0,0,1-.942,1.873c-.659,1.042-1.382,2.076-2.029,3.13.749-.067,1.418-1.636,1.81-2.277.442-.726.875-1.451,1.362-2.161,1.189-1.754,1.612-4.044,2.971-5.7a28.906,28.906,0,0,1-1.42,3.44,28.5,28.5,0,0,1-1.909,3.578c-1.054,1.77-2.1,3.543-3.08,5.33-1.017,1.859-1.507,3.993-3.029,5.545a2.822,2.822,0,0,1,.019-1.447c.078-.476-.48-.878-.473-1.365a19.38,19.38,0,0,1,.419-2.924,17.137,17.137,0,0,0,.01-2.856c0-1.208-.482-2.283-.524-3.487-.027-.766.2-1.519.24-2.272a4.655,4.655,0,0,0-.31-1.52,5.817,5.817,0,0,1,.106-3.178,1.149,1.149,0,0,1,.264.546c.4.047.836-.6,1.106-.861,0,.465-.418.85-.478,1.29-.06.4.213.725.185,1.1-.027.424-.341.84-.281,1.308a4.082,4.082,0,0,0,.351,1.106c.417.17,1.218-1.529,1.389-1.842.423-.776.886-1.523,1.286-2.3a11.079,11.079,0,0,0,1.208-3.191c.094-.549.192-1.1.33-1.634.1-.375.522-1.585.223-1.837"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2189"
					data-name="Pfad 2189"
					d="M959.691,1016.846c.675.231,1.09,2.6,1.189,3.281.188,1.282.464,2.615.57,3.91a29.844,29.844,0,0,0,1.131,7.5c.135.392.58.953.534,1.369-.018.2-.291.326-.336.563a3.041,3.041,0,0,0,.127.926,41.021,41.021,0,0,0,1.106,4.317c.382,1.225.5,2.436.849,3.627a8.589,8.589,0,0,0,1.644,2.547,3.51,3.51,0,0,1,.617.878c.095-1.309-.52-1.929.309-3.083.736-1.026.62-2.071,1.016-3.217a26.509,26.509,0,0,0,1.134-3.466,21.745,21.745,0,0,0,.377-3.844,5.387,5.387,0,0,1,.417-1.98c.18-.51.194-1.248.569-1.634.9,2.209.164,5.409-.5,7.579a22.72,22.72,0,0,0-.919,3.483c-.153,1.036-.977,2.82-.61,3.8.67-.624.931-2.428,1.756-.673.547,1.171-.037,2.606-.272,3.791-.381,1.915.824,3.555,1.026,5.4.13,1.164.024,2.326.163,3.471a6.72,6.72,0,0,0,1.535,3.006c.516.722.995,1.668,1.554,2.319.57-.3.723-1.43.914-2.009.385-1.169.749-2.378,1.174-3.525.575-1.551,1.125-3.269,1.565-4.916.321-1.2.32-2.418.694-3.614.232-.728.511-1.449.694-2.178.358-1.443.976-2.864,1.367-4.325.673-2.52,1.479-5.581,3.024-7.686a31.615,31.615,0,0,1-2.359,6.458,19.681,19.681,0,0,0-1.1,3.708c-.279,1.3-.893,2.447-1.158,3.744-.613,2.973-.814,5.967-1.666,8.9-.442,1.52-.719,3.091-1.149,4.609a31.725,31.725,0,0,0-.968,5.28,14.531,14.531,0,0,0-.089,1.888,9.813,9.813,0,0,1-1.391-2.583c-.6-1.133-1.286-2.22-1.889-3.352a14.22,14.22,0,0,0-1.74-2.79c-.47-.533-1.118-.741-1.489-1.3a3.144,3.144,0,0,1-2.594.882.986.986,0,0,1-.8-1.333c.145-.557.246-1.061.345-1.661a5.072,5.072,0,0,0-.315-3.4c-.124-.252-.275-.344-.3-.616-.028-.317.233-.739.205-1.108-.043-.616-.219-1.2-.249-1.816-.026-.571.1-1.178.017-1.736a4.252,4.252,0,0,0-.832-1.655,11.122,11.122,0,0,1-1.359-3.2,19.291,19.291,0,0,0-1.138-3.414c-.495-1-1.284-1.823-1.78-2.807a7.241,7.241,0,0,1-.594-2.915c-.094.675.412,2.569,1.047,2.84-.255-2.14-.728-4.367-.8-6.55a4.855,4.855,0,0,0-.544-1.711,8.869,8.869,0,0,1-.288-1.572,18.1,18.1,0,0,1-.388-1.848,9.811,9.811,0,0,0-.133-1.814c.59.792.322,2.264.668,3.187.1.274.38,1.365.662,1.489.627.277.472-.647.42-1.014a21.355,21.355,0,0,1-.182-3.005,32.84,32.84,0,0,0-.478-5.536"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2190"
					data-name="Pfad 2190"
					d="M954.077,1028.737c-.021,1.564-.379,2.971-.463,4.526a40.594,40.594,0,0,0,.128,5.668c.194,2.332.549,5.075,1.967,7.03a12.466,12.466,0,0,0,2.606,2.451c-1.178-1.955-2.545-3.755-2.973-6.074-.2-1.095-.036-2.246-.229-3.348a26.324,26.324,0,0,1-.441-3.281c-.051-1.114-.049-2.232-.147-3.294a15.563,15.563,0,0,0-.382-3.472"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2191"
					data-name="Pfad 2191"
					d="M955.555,1034.819a17.658,17.658,0,0,1,.293,2.583,31.8,31.8,0,0,0,.386,3.173c.4,1.637,1.127,3.236,1.665,4.834a9.062,9.062,0,0,0,1.735,3.282,12.832,12.832,0,0,1,2.2,2.325c.057.114.165.206.224.312-4.553-2.173-5.745-8.2-6.837-12.612a12.117,12.117,0,0,1-.64-2.437c.237.421.4,1.04.795,1.336.07-.894.164-1.775.181-2.659"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2192"
					data-name="Pfad 2192"
					d="M956.685,1022.314a6.457,6.457,0,0,1,.367,3.279,11.432,11.432,0,0,0,.55,4.1c.382,1.168.571,2.308.879,3.487.293,1.132.873,2.182,1.223,3.276a18.117,18.117,0,0,0,2.593,5.69,11.156,11.156,0,0,1,1.443,2.17,9.7,9.7,0,0,1,1.173,2.316c-.945.083-1.993-2.344-2.562-3.014a11.68,11.68,0,0,1-2.286-3.678,16.52,16.52,0,0,0-1.51-3.074c-.654-1.032-.561-2.068-.971-3.158a10.531,10.531,0,0,1-.806-2.517c-.128-1.032-.125-2.1-.161-3.138a53.188,53.188,0,0,1,.2-5.47"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2193"
					data-name="Pfad 2193"
					d="M986.354,1063.78a19.2,19.2,0,0,0-.659,2.464c-.226.769-.524,1.491-.812,2.235-.616,1.587-1.182,3.187-1.842,4.744-.547,1.286-1.014,2.887-2.089,3.822-1.14.993-2.262,1.865-3.329,2.955a44.329,44.329,0,0,0-5.386,6.854c-1.317,2.041-1.724,4.6-2.194,6.851s.483,4.382.338,6.713a8.069,8.069,0,0,0-.078,1.746,12.115,12.115,0,0,0,.678,1.769c.35,1.045.389,2.126.709,3.2.208.694,1.017,2.6,1.334,1.028a18.109,18.109,0,0,0,.181-3.268,14.506,14.506,0,0,0,.07-1.608c-.051-.609-.223-1.193-.253-1.818-.041-.9-.267-2.833.235-3.558a39.724,39.724,0,0,1,1.234,4.905,5.55,5.55,0,0,0,.382,1.662c.436.7.487.212.495-.421a34.539,34.539,0,0,0-.858-8.09c-.6-2.7-.858-5.706.968-8.01.507-.639,2.108-2.553,2.55-1.031.185.638-.212,1.21.346,1.733a1.089,1.089,0,0,0,1.395.1c.873-.675,1.016-3.1,1.226-4.073.28-1.3.9-2.428,1.284-3.7a14.081,14.081,0,0,1,.546-1.888,22.865,22.865,0,0,0,1.136-2.443c.221-.682.588-1.4.778-2.1a19.448,19.448,0,0,0,.252-2.081c.248-1.577.612-3.15.96-4.705a8.386,8.386,0,0,0,.4-3.913"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2194"
					data-name="Pfad 2194"
					d="M994.654,1082.448c-.2,2.216-1.525,4.341-2.18,6.456a25.184,25.184,0,0,1-2.8,5.972c-1.732,2.77-2.729,5.864-4.511,8.618-1.319,2.039-2.71,3.934-4.215,5.86-.789,1.007-1.661,1.9-2.46,2.878-.9,1.1-2.012,2.044-2.979,3.079-.863.923-1.8,1.721-.312,2.446a6.976,6.976,0,0,0,.9.333c-.611.169-2.709,1.532-1.22,1.855.819.177,1.78-1.53,2.556-1.979,1.355-.783,3.057-1.09,4.225-2.309.87-.912,1.529-2.089,2.359-3.056.815-.95,1.733-1.819,2.536-2.764a15.685,15.685,0,0,0,2.813-5.658c.643-2,1.175-3.988,1.891-5.983.506-1.413,1.377-2.664,1.871-4.072.229-.661.461-1.274.075-1.762-.481-.61-.835-.534-.687-1.452.168-1.041.642-1.944.81-3.01.135-.838.194-2.432.667-3.075"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2195"
					data-name="Pfad 2195"
					d="M973.451,1106.291a3.676,3.676,0,0,1,.121,1.415c.035.826.117,1.668.186,2.483a13.687,13.687,0,0,0,.518,1.838,7.311,7.311,0,0,1-.586,3.352c-.542,1.746-1.51,3.27-1.883,5.092a8.074,8.074,0,0,0-.115,3.718,5.151,5.151,0,0,0,1.768,2.817,2.778,2.778,0,0,1,.395,2.963,1.843,1.843,0,0,0-.059-.723,43.479,43.479,0,0,0,.367,5.547c-.36-.723-2.37-1.319-3-1.79a5.764,5.764,0,0,1-1.7-3.919c.083-1.311.386-2.608.468-3.916.076-1.2-.018-2.4.112-3.565.13-1.144.12-2.318.253-3.5a16.7,16.7,0,0,1,.812-3.35,27.939,27.939,0,0,1,.811-3.221,22.155,22.155,0,0,0,1.145-3.426c.141-.505.48-1.234.248-1.747"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2196"
					data-name="Pfad 2196"
					d="M980.527,1127.826a8.341,8.341,0,0,1,1.981-.752,21.874,21.874,0,0,1,3.055-.58,9.365,9.365,0,0,0,1.879-.836,27.113,27.113,0,0,1,4.82-1.2c1.523-.4,3.055-.772,4.546-1.259,1.533-.5,2.918-.087,4.476-.332a8.134,8.134,0,0,1,1.613-.421c.629.02,1.2.407,1.818.421.519.01,1.014-.306,1.608-.282s.918.405,1.4-.068c.464-.458.192-1.027.3-1.609.118-.616.47-.823.871-1.255a9.232,9.232,0,0,1,1.692-1.749c.515-.3,1.022-.179,1.584-.417a29.4,29.4,0,0,0,3.179-2.117c1.5-.882,3.2-1.7,4.8-2.446,1.7-.792,3.42-1.613,5.05-2.5,1.3-.709,2.516-1.7,3.82-2.329,2.2-1.058,5.121-.589,7.21.282a6.937,6.937,0,0,0,1.464.419c.6.091,1.174-.145,1.733.069a.807.807,0,0,1,.388,1.327c-.318.374-1.539.5-2.018.58a29.961,29.961,0,0,1-4.76.065c-1.57,0-3.227-.3-4.673.471-.805.424-1.486.936-2.352,1.293a17.4,17.4,0,0,0-2.023.914,31.215,31.215,0,0,0-4.171,2.424,26.237,26.237,0,0,1-2.6,1.963c-.726.458-1.515.741-2.189,1.257a24.8,24.8,0,0,1-3.579,2.468c-.939.479-2.425.747-2.852,1.85-.473,1.22,1.508,1.45,2.429,1.35.724-.081,1.408-.5,2.155-.636.638-.11,1.291-.147,1.929-.206,1.485-.137,2.952-.071,4.472-.071a42.235,42.235,0,0,0,4.356-.264c1.163-.116,2.479-.319,3.461.422a9.638,9.638,0,0,1,2.971,3.533c-.595.1-1.026-.52-1.451-.824a5.368,5.368,0,0,0-1.93-.961,21.846,21.846,0,0,0-4.7-.508c-1.338,0-2.649.085-3.969.142a21.254,21.254,0,0,1-4.075.192,31.292,31.292,0,0,0-3.75-.127,24.179,24.179,0,0,0-4.507.651,16.139,16.139,0,0,0-3.718.733c-1.125.463-2.285.936-3.439,1.344a8.879,8.879,0,0,1-3.438.912,13.279,13.279,0,0,1-3.618-1.207,12.666,12.666,0,0,0-4.392-1.1,12.371,12.371,0,0,0-3.227.572,5.814,5.814,0,0,0-2.674,1.311c-.366.339-1.566,1.636-2.063.92C979.022,1129.067,980.134,1128.1,980.527,1127.826Z"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2197"
					data-name="Pfad 2197"
					d="M1039.923,1119.3c-.5.249-1.141.179-1.685.4a4.94,4.94,0,0,0-1.462.965,5.091,5.091,0,0,0-1.063,1.307c-.223.372-.426,1.085-.783,1.331-.885.6-2.4-.285-3.374.118-1.783.74.281,1.35.758,2.011.241.339-.054.486.391.736.325.178.853.035,1.208.172.4.156.493.589,1.108.385.41-.132.664-.39.495-.838.419-.236.49-.511.719-.857.37-.554.394-.4.132-.979-.528-1.167-.045-.965,1.065-1.118,1.412-.2,3.344-1.939,3.386-3.416-.391.036-.717-.094-1.107-.08"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2198"
					data-name="Pfad 2198"
					d="M1004.554,1129.713c.56-.231.731-.915,1.153-1.314a7.172,7.172,0,0,1,1.322-.868,4.561,4.561,0,0,1,3.29-.353c1.243.186,2.139-.3,3.355.159a9.391,9.391,0,0,1,3.053,1.254c-.419.983-2.125-.738-2.852-.557-.371.091-.68.676-1.075.9a3.324,3.324,0,0,1-1.526.362,25.071,25.071,0,0,0-3.434.347,17.239,17.239,0,0,1-3.077.213"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2199"
					data-name="Pfad 2199"
					d="M1032.116,1128.594c.906.291-.532.932-.781,1.133a6.84,6.84,0,0,0-1.067,1.175c-.5.652-.527.7-1.338.715a4.461,4.461,0,0,0-1.47.406c-.536.189-1.2.314-.766-.494.228-.422,1.032-.782,1.2-1.2.108-.282-.192-.54.032-.831.211-.268.611-.186.9-.205.91-.053.693-.183,1.352-.679a2.018,2.018,0,0,1,2.082.119"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2200"
					data-name="Pfad 2200"
					d="M1027.4,1069.511a19.614,19.614,0,0,0-1.447,3.919,11.742,11.742,0,0,0-.22,5.258c.437,3.233-.19,7.533,1.969,10.265a10.077,10.077,0,0,0,1.255,1.449c.877.741,2.127.849,3.172,1.275,1.224.491,1.481,1.736,2.075,2.812.2.372,1.009,1.3.682,1.722-.313.4-1.064-.056-1.167.726-.188,1.4,2.859,1.169,3.614,1.945.446.463.479,1.134,1.082,1.447,1.05.543,1.09-.219.779-.979-.82-2.017-1.784-3.936-2.706-5.905a37.051,37.051,0,0,0-1.662-3.658,25.1,25.1,0,0,1-2.3-3.773,60.91,60.91,0,0,1-2.838-7.625c-.224-.9-.586-1.761-.81-2.652-.269-1.067-.3-2.219-.513-3.292-.146-.73-.446-2.573-.941-3.068a.6.6,0,0,0-.095.416"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2201"
					data-name="Pfad 2201"
					d="M1036.9,1061.406c.144-.392-.094-.536-.236-.874a4.589,4.589,0,0,1-.164-1.511,5.824,5.824,0,0,0-.4-1.492c-.224-.633-.243-.469-.8-.736a10.956,10.956,0,0,1-.91-.659c-.265-.165-1.026-.726-1.333-.419-.252.254.077.416.091.615.005.087-.008.458-.008.46.195.874,1.11.536,1.525,1.353a2.747,2.747,0,0,1-.659-.041c.109.4.46.384.782.548a2.123,2.123,0,0,1,.956.915,10.833,10.833,0,0,0,.444,1.027c.258.339.71.381.773.814"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2202"
					data-name="Pfad 2202"
					d="M1036.379,1064.286c-.619.143-1.214.569-1.849.692a4.013,4.013,0,0,1-2.184-.487c-.527-.219-1.371-.786-1.97-.695-1.166.184.263.891.657,1.089a11.691,11.691,0,0,0,3.286,1.193c.651.1.857-.44,1.488-.38.614.059.5.806.92.908.283.073.7-.5.736-.722.068-.381-.232-.548-.288-.86s.181-.564-.048-.812c-.208-.232-.717-.12-.985-.035a.711.711,0,0,1-.074.173"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2203"
					data-name="Pfad 2203"
					d="M1034.588,1070.116a4.289,4.289,0,0,0-.634,1.286,4.626,4.626,0,0,1-1.1,1.643c-.417.406-.873.685-1.272,1.106-.141.146-.39.315-.47.5-.152.352.066.437.1.609a.637.637,0,0,1,.14.261c.015.118-.13.345-.147.478a.913.913,0,0,0,.1.688c.371.459.721.005.863.719.13.632-.108,1.222.857,1.047.684-.122,1.262-.563,1.966-.671a4.526,4.526,0,0,1,1.883.108,3.173,3.173,0,0,0-.537-1.39c-.408-.334-1.317-.133-1.824-.181a9.57,9.57,0,0,0-1.348.173c-.522.006-.667-.425-1.048-.675.5-.774,2.883-.4,3.69-.443-.477-.906-2.034-.57-2.921-.612.068-.657.606-1.11.946-1.642.647-1.021.6-2.162,1.007-3.256"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
				<path
					id="Pfad_2204"
					data-name="Pfad 2204"
					d="M987.611,1010.707a6.2,6.2,0,0,0-.087,2.111,6.924,6.924,0,0,1-.524,2.867c-.32.8-.772,1.441-1.063,2.244a3.221,3.221,0,0,0-.334.959c0,.56.347.55.5.76a1.068,1.068,0,0,1,.337.269c.092.153.03.545.086.732a1.36,1.36,0,0,0,.541.87c.77.4.975-.419,1.591.46.547.775.572,1.707,1.772.9.851-.567,1.367-1.5,2.25-2.068a6.743,6.743,0,0,1,2.609-.965,4.693,4.693,0,0,0-1.545-1.554c-.748-.212-1.857.6-2.569.832s-1.094.747-1.716,1.026c-.7.32-1.151-.173-1.809-.289.218-1.339,3.652-2.237,4.708-2.774-1.175-.94-3.074.43-4.3.9-.3-.927.16-1.856.3-2.774.272-1.756-.471-3.266-.564-4.983"
					transform="translate(-213 -741.43)"
					fill="#e1e1e1"
					opacity="0.4"
					style="mix-blend-mode: multiply; isolation: isolate"
				/>
			</g>
			<g id="SIZE_REF" transform="translate(495.403 -211.672)">
				<circle
					id="ref-point-left"
					ref="ref-point-left"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(278.305 473.922)"
					fill="transparent"
				/>
				<circle
					id="ref-point-right"
					ref="ref-point-right"
					cx="0.5"
					cy="0.5"
					r="0.5"
					transform="translate(313.371 473.922)"
					fill="transparent"
				/>
			</g>
			<g id="POSITIONEN" ref="position-wrapper">
				<circle
					id="center"
					ref="position-center"
					cx="0.5"
					cy="0.5"
					r="0.5"
					:fill="currentStep === 'motive' ? 'black' : 'transparent'"
				/>
			</g>
			<g
				id="PRINT-AREA"
				transform="translate(759.886 272.2)"
				stroke="#000"
				stroke-dasharray="4 4"
				stroke-width="0.4"
				ref="print-area-wrapper"
				:opacity="currentStep === 'motive' ? 0.4 : 0"
			>
				<rect width="64.9" height="107.9" fill="none" ref="print-area" />
			</g>
		</g>
	</svg>
</template>

<script>
import { setPositions } from '../mockupHelpers'

export default {
	name: 'HoodieBack',
	props: {
		currentStep: { type: String, required: true },
		mainColor: { type: String, default: '#fff' },
		secColor: { type: String, default: '#fff' },
		mockupWidth: {
			type: String,
			required: false,
		},
		mockupHeight: {
			type: String,
			required: false,
		},
	},
	mounted() {
		setPositions(this, 22.5)
	},
}
</script>